import React, { useState, useRef, useEffect } from "react";
import { ChevronsUpDown, Search } from "lucide-react";

export default function SelectV2({ options, defaultValue, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);

  const handleSelect = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    setSearchTerm("");
    if (onChange) {
      onChange(value);
    }
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedOption = options.find(
    (option) => option.value === selectedValue
  );

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [options]);

  return (
    <div className="relative w-64 ">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full text-left bg-ree focus:outline-none "
      >
        <div className="flex items-center">
          <div
            style={{ backgroundColor: selectedOption?.color }}
            className="flex items-center justify-center w-8 h-8 mr-2 text-white rounded-md"
          >
            {selectedOption ? selectedOption?.label[0]?.toUpperCase() : ""}
          </div>
          <span className="text-sm font-medium text-gray-900">
            {selectedOption
              ? selectedOption.label
              : "Sélectionner un espace..."}
          </span>
        </div>
        {/* <ChevronsUpDown
          className="w-5 h-5 ml-2 -mr-1 text-gray-400"
          aria-hidden="true"
        /> */}
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="sticky top-0 z-20 px-3 py-2 bg-white border-b border-gray-200">
            <div className="flex items-center">
              <Search className="w-5 h-5 text-gray-400" />
              <input
                ref={inputRef}
                type="text"
                className="w-full px-2 py-1 ml-2 text-sm bg-transparent border-none focus:ring-0 focus:outline-none"
                placeholder="Rechercher..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <ul
            className="py-1 overflow-auto text-base sm:text-sm"
            style={{ maxHeight: "15rem" }}
          >
            {filteredOptions.length === 0 ? (
              <li className="px-3 py-2 text-sm text-gray-500">
                Aucun résultat trouvé
              </li>
            ) : (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  className={`cursor-pointer select-none font-poppins relative text-black py-2 hover:text-black pl-3 pr-9 hover:bg-gray-100 ${
                    selectedValue === option.value
                      ? " border-l-4 border-colorText hover:text-black"
                      : "text-gray-900"
                  } `}
                  onClick={() => handleSelect(option.value)}
                >
                  {option.label}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
