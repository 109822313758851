import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionChangePassword } from "../../redux/actions/UserAction";
import { Link } from "react-router-dom";

export default function Reset() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("noreply"));
    if (!searchParams.get("noreply")) {
      navigate("/");
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === confirm) {
      dispatch(
        actionChangePassword(password, token, (status) => {
          if (status === 200) {
            navigate("/home");
          }
        })
      );
    } else {
      alert("password are not confirm");
      setConfirm("");
    }
  };

  return (
    <div className="relative flex flex-col min-h-screen overflow-hidden bg-white/2">
      <header className="z-10 flex items-center justify-end gap-3 p-4">
        <span className="font-semibold font-poppins">
          Vous avez déjà un compte ?
        </span>
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6875 3.9375C20.4135 3.9375 21 4.52402 21 5.25C21 5.97598 20.4135 6.5625 19.6875 6.5625H11.1562V3.9375H19.6875ZM13.125 11.8125C13.851 11.8125 14.4375 12.399 14.4375 13.125C14.4375 13.851 13.851 14.4375 13.125 14.4375H10.5C9.77402 14.4375 9.1875 13.851 9.1875 13.125C9.1875 12.399 9.77402 11.8125 10.5 11.8125H13.125ZM15.75 9.1875C15.75 9.91348 15.1635 10.5 14.4375 10.5H12.4688C11.7428 10.5 11.1562 9.91348 11.1562 9.1875C11.1562 8.46152 11.7428 7.875 12.4688 7.875H14.4375C15.1635 7.875 15.75 8.46152 15.75 9.1875ZM11.8125 15.75C12.5385 15.75 13.125 16.3365 13.125 17.0625C13.125 17.7885 12.5385 18.375 11.8125 18.375H9.1875C8.46152 18.375 7.875 17.7885 7.875 17.0625C7.875 16.3365 8.46152 15.75 9.1875 15.75H11.8125ZM8.20312 11.8125H8.22773C8.00625 12.198 7.875 12.6451 7.875 13.125C7.875 13.6664 8.03906 14.1668 8.31797 14.5852C7.29668 14.942 6.5625 15.9182 6.5625 17.0625C6.5625 17.5424 6.68965 17.9895 6.91523 18.375H6.5625C2.93672 18.375 0 15.4383 0 11.8125V9.28184C0 7.54277 0.693164 5.87344 1.92363 4.64297L2.39941 4.16719C3.38379 3.17871 4.7209 2.625 6.11133 2.625H7.21875C8.6666 2.625 9.84375 3.80215 9.84375 5.25V8.85938C9.84375 9.76582 9.10957 10.5 8.20312 10.5C7.29668 10.5 6.5625 9.76582 6.5625 8.85938V6.5625C6.5625 6.20156 6.26719 5.90625 5.90625 5.90625C5.54531 5.90625 5.25 6.20156 5.25 6.5625V8.85938C5.25 10.4918 6.5707 11.8125 8.20312 11.8125Z"
            fill="#04B6EE"
          />
        </svg>

        <Link
          to="/"
          className="px-4 py-2 font-semibold text-white rounded-md outline-none bg-colorText font-poppins hover:outline-none"
        >
          Se connecter
        </Link>
      </header>
      <div className="absolute top-0 left-0 flex items-start justify-center w-full h-full">
        <img
          src="./update/logo.png"
          alt=""
          srcset=""
          className="relative  left-0 top-[15%]"
        />
        <svg
          className="absolute bottom-[-20%] left-0 right-0 size-full 2xl:bottom-[-40%] "
          viewBox="0 0 1440 448"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="719.5"
            cy="535"
            rx="535"
            ry="978.5"
            transform="rotate(-90 719.5 535)"
            fill="url(#paint0_linear_715_2262)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_715_2262"
              x1="1254.23"
              y1="535.076"
              x2="184.958"
              y2="535.076"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.00549451" stop-color="#21263C" />
              <stop offset="0.475" stop-color="#4C557D" />
              <stop offset="0.93" stop-color="#586398" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <main className="z-10 flex flex-col items-center justify-center flex-grow font-poppins">
        <form
          action=""
          className="flex overflow-hidden flex-col px-7 py-10 rounded-3xl  w-[540px]  border-3 border-black bg-white shadow-2xl font-poppins"
          onSubmit={onSubmit}
        >
          <h1 className="flex-1 shrink gap-3 self-center w-full text-2xl font-bold  whitespace-nowrap max-w-[497px]  max-md:max-w-full font-poppins">
            Rénitialiser votre mot de passe
          </h1>

          <div className="flex flex-col gap-2 mb-4">
            <label for="" className="font-poppins font-[400px]">
              Mot de passe
            </label>
            <div className="relative">
              <input
                id="password"
                className=" w-full border-inputBorderColor p-2 outline-none rounded-[10px] border border-solid border-zinc-200"
                type="password"
                placeholder="saisir votre nouveau mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength={8}
              />
              <i
                class="fa-regular fa-eye absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={(e) => {
                  if (document.getElementById("password").type === "password") {
                    e.target.className =
                      "absolute transform -translate-y-1/2 cursor-pointer fa fa-eye-slash right-3 top-1/2";

                    document.getElementById("password").type = "text";
                  } else {
                    e.target.className =
                      "absolute transform -translate-y-1/2 cursor-pointer fa-regular fa-eye right-3 top-1/2";

                    document.getElementById("password").type = "password";
                  }
                }}
              ></i>
            </div>
          </div>

          <div className="flex flex-col gap-2 mb-4">
            <label for="" className="font-poppins font-[400px]">
              Confirmer le mot de passe
            </label>
            <div className="relative">
              <input
                id="password1"
                className=" w-full border-inputBorderColor p-2 outline-none rounded-[10px] border border-solid border-zinc-200"
                type="password"
                placeholder="saisir votre nouveau mot de passe"
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                required
                minLength={8}
              />
              <i
                class="fa-regular fa-eye absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={(e) => {
                  if (
                    document.getElementById("password1").type === "password"
                  ) {
                    e.target.className =
                      "absolute transform -translate-y-1/2 cursor-pointer fa fa-eye-slash right-3 top-1/2";

                    document.getElementById("password1").type = "text";
                  } else {
                    e.target.className =
                      "absolute transform -translate-y-1/2 cursor-pointer fa-regular fa-eye right-3 top-1/2";

                    document.getElementById("password1").type = "password";
                  }
                }}
              ></i>
            </div>
          </div>

          <button
            className="w-full bg-colorText text-white p-2 rounded-[10px] font-poppins  font-semibold outline-none hover:outline-none"
            type="submit"
            disabled={loading}
          >
            {loading ? "Modifier..." : "Modifier"}
          </button>
        </form>
      </main>
    </div>
  );
}
