import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header";
import Footer from "../Footer";
import { TeamOutlined } from "@ant-design/icons";
import { AspectRatioRounded, CloseOutlined } from "@material-ui/icons";
import ContentLoader from "react-content-loader";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  actionCreateTeam,
  actionDeleteTeam,
  actionGetTeamsByUser,
  actionUpdateTeam,
} from "../../redux/actions/teamAction";
import Modal from "../Views/Modal";
import ListCard from "../Views/ListCardUpdate";
import ListSubTeam from "../Views/ListSubTeam";
import CustomSelect from "../Views/CustomSelect";
import { Search } from "lucide-react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  RefreshCwIcon,
  PencilIcon,
  TrashIcon,
} from "lucide-react";

import { truncateText } from "../utils/truncateWords";

import TableRowLoader from "../Views/TableLoading";
import { actionGetWorkspaceByCompany } from "../../redux/actions/workspaceAction";
let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Home({ workspace }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [team, setTeam] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [updateVisibility, setUpdateVisibility] = useState(false);
  const [parent, setParent] = useState(null);

  const selectedWorkspace = workspace.id;
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const teams = useSelector((state) => state.team.teams);
  const isAdd = useSelector((state) => state.team.isAdd);
  const isGet = useSelector((state) => state.team.isGet);
  const isUpdate = useSelector((state) => state.team.isUpdate);
  const isDelete = useSelector((state) => state.team.isDelete);

  let formattedTeams = teams.map((team) => {
    return { label: team.name, value: team.idteam };
  });
  if (team) {
    formattedTeams = formattedTeams.filter((t) => t.value !== team.idteam);
  }

  useEffect(() => {
    const data = localStorage.getItem("data");

    if (!data) {
      navigate("/");
    } else {
      dispatch(
        actionGetWorkspaceByCompany(
          "Bearer " + JSON.parse(data).token,
          (result) => {
            if (result) {
              // console.log(result);
            }
          }
        )
      );
      if (selectedWorkspace) {
        dispatch(actionGetTeamsByUser(selectedWorkspace, token));
      }
    }
  }, [selectedWorkspace]);

  const onDelete = (e) => {
    e.preventDefault();
    dispatch(
      actionDeleteTeam(
        team.idteam,
        selectedWorkspace,
        token,
        ({ status, message }) => {
          if (status) {
            setDeleteVisibility(false);
            enqueueSnackbar(message || "", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(message || "", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const onAdd = (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      enqueueSnackbar("entrez un nom valide", {
        variant: "warning",
      });
      return;
    }
    if (name) {
      const responses = teams.filter(
        (team) => team.name.toLocaleLowerCase() === name.toLocaleLowerCase()
      );
      if (responses.length >= 1) {
        enqueueSnackbar("Une équipe porte déjà ce nom.", {
          variant: "warning",
        });
        return;
      }
      dispatch(
        actionCreateTeam(
          name.trim(),
          parent ? parent.value : null,
          description,
          selectedWorkspace,
          token,
          ({ status, message }) => {
            console.log(status, message);
            if (status) {
              setAddVisibility(false);
              setParent(null);
              enqueueSnackbar(message || "", {
                variant: "success",
              });
            } else {
              enqueueSnackbar(message || "", {
                variant: "error",
              });
            }
          }
        )
      );
    }
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (name.trim()) {
      if (
        team.name.trim().toLocaleLowerCase() !== name.trim().toLocaleLowerCase()
      ) {
        const responses = teams.filter(
          (team) =>
            team.name.trim().toLocaleLowerCase() ===
            name.trim().toLocaleLowerCase()
        );
        if (responses.length >= 1) {
          enqueueSnackbar("Une équipe porte déjà ce nom.", {
            variant: "warning",
          });
          return;
        }
      }

      dispatch(
        actionUpdateTeam(
          name.trim(),
          parent ? parent.value : null,
          description,
          team.idteam,
          selectedWorkspace,
          token,
          ({ status, message }) => {
            if (status) {
              setUpdateVisibility(false);
              setParent(null);
              enqueueSnackbar(message || "", {
                variant: "success",
              });
            } else {
              enqueueSnackbar(message || "", {
                variant: "error",
              });
            }
          }
        )
      );
    }
  };

  const showAdd = () => {
    setName("");
    setDescription("");
    setAddVisibility(true);
  };

  const showUpdate = (team) => {
    setTeam(team);
    setName(team.name);
    setParent(
      team.parent
        ? { label: team.parent.name, value: team.parent.idteam }
        : null
    );
    setDescription(team.description);
    setUpdateVisibility(true);
  };
  useEffect(() => {
    if (!deleteVisibility && !updateVisibility) {
      setTeam(null);
      setName("");
      setDescription("");
    }
  }, [deleteVisibility, updateVisibility]);

  return (
    <div className="h-full ">
      <main className="h-[98%]  w-[98%] mx-auto ">
        <>
          <div className="px-2 mb-3 w-full row actions-nav mx-auto border-r-black rounded-[20px]  items-center gap-2">
            <div className="flex items-center justify-between w-full gap-2 font-poppins ">
              <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center ml-1">
                <div className="relative flex items-center ">
                  <div className="relative flex items-center ">
                    <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                    <input
                      type="text"
                      placeholder="Rechercher"
                      className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <p
                  className="hover:text-white hover:bg-colorText border-2 border-colorText text-colorText  rounded-[5px] p-0 m-0 text-center font-poppins py-1 w-[180px] font-semibold cursor-pointer text-[12px]"
                  type="submit"
                  onClick={showAdd}
                >
                  +Ajouter une équipe
                </p>
              </div>
            </div>
          </div>

          <div className="w-full  bg-customBg h-[83vh] rounded-xl overflow-auto">
            <table
              id="sources"
              className="relative w-full overflow-auto bg-white border-collapse shadow-sm"
            >
              <thead className="mb-[600px] font-poppins leading-[50px] sticky ">
                <tr className="h-10 bg-white rounded-xl">
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                    <span>Nom de l'équipe</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                    <span>Description</span>
                  </th>

                  <th className="p-1 pl-2 font-semibold text-center text-black border-b text-[12px] rounded-xl">
                    <span>Membre</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-center text-black border-b text-[12px] rounded-xl">
                    <span>Parent</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-center text-black border-b text-[12px] rounded-xl">
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="text-[12px] mt-[900px] border-t-8 border-customBg h-full">
                {isGet
                  ? [...Array(5)].map((_, index) => (
                      <tr
                        key={index}
                        className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold"
                      >
                        <td colSpan={6}>
                          <TableRowLoader />
                        </td>
                      </tr>
                    ))
                  : teams?.map((team) => {
                      // console.log(team.role);
                      const isAdmin = team.role === "OWNER";
                      return (
                        <tr
                          id={team.iditeam}
                          key={team.iditeam}
                          className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold my-3"
                        >
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {truncateText(team.name)}
                          </td>
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {/* {period?.startdate?.split("T")[0]} */}
                            {team.description}
                          </td>
                          <td className="p-1 pl-2 text-[12px] text-center text-black font-[500px]">
                            {/* {period?.startdate?.split("T")[0]} */}
                            {team.userTeams.length || 0}
                          </td>
                          <td className="p-1 pl-2 text-[12px] text-center text-black font-[500px]">
                            {/* {period?.enddate?.split("T")[0]} */}
                            {team?.parent ? team.parent.name : ""}
                          </td>
                          <td className="p-1 text-[12px]">
                            <div className="flex items-center justify-around gap-2">
                              <button
                                disabled={!isAdmin}
                                className="text-gray-600 hover:text-gray-900 focus:outline-none disabled:opacity-70 disabled:hover:opacity-70"
                                onClick={() => {
                                  if (isAdmin) {
                                    showUpdate(team);
                                  }
                                }}
                              >
                                <PencilIcon className="w-5 h-5" />
                              </button>
                              <button
                                disabled={!isAdmin}
                                className="text-gray-600 hover:text-gray-900 focus:outline-none disabled:opacity-70"
                                onClick={() => {
                                  if (isAdmin) {
                                    setTeam(team);
                                    setDeleteVisibility(true);
                                  }
                                }}
                              >
                                <TrashIcon className="w-5 h-5" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </>
      </main>
      {/* <main className="homemain mt-[40px]">
        <div className="container">
          <div className="head">
            <h1 className="title-1">Equipes</h1>
          </div>

          <div className="row cards">
            <Row>
              {!isGet ? (
                <>
                  <Card className="ajout-planche to_that" onClick={showAdd}>
                    <span
                      title="Ajouter un tableau"
                      target="_self"
                      class="bind_me"
                      style={{
                        fontWeight: "bold",
                        marginBottom: 8,
                      }}
                    >
                      Ajouter une nouvelle équipe
                    </span>
                    <span
                      class="add-card"
                      style={{
                        color: "rgb(84, 183, 237)",
                      }}
                    >
                      <i class="fa fa-plus"></i>
                    </span>
                  </Card>
                  {teams.map((team, index) => (
                    <CardContainer key={`team-${team.idteam}`}>
                      {team.role === "OWNER" && (
                        <DeleteButton
                          onClick={() => {
                            setTeam(team);
                            setDeleteVisibility(true);
                          }}
                        >
                          <CloseOutlined color="white" fontSize="25px" />
                        </DeleteButton>
                      )}
                      <CardTop onClick={() => showUpdate(team)}>
                        <CardImg
                          src={
                            index % 2 === 0
                              ? "./planche2.jpg"
                              : "./planche1.jpg"
                          }
                        />
                      </CardTop>
                      <CardBottom>
                        <CardBottomContent>
                          <Title className="title-4">{team.name}</Title>
                          <Small>
                            <Span>
                              <TeamOutlined />
                              &nbsp; {team.userTeams.length}
                            </Span>
                          </Small>
                        </CardBottomContent>
                        <CardText>
                          <IconButton onClick={() => showUpdate(team)}>
                            <AspectRatioRounded style={{ fontSize: 27 }} />
                          </IconButton>
                        </CardText>
                      </CardBottom>
                    </CardContainer>
                  ))}
                </>
              ) : (
                <>
                  {[1, 2, 3, 4].map(() => (
                    <ContentLoader
                      height={"250px"}
                      width={"100%"}
                      speed={1}
                      backgroundColor={"#80808075"}
                      foregroundColor={"#a9a9a975"}
                      className="planche"
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="9px"
                        ry="9px"
                        width="100%"
                        height="160px"
                      />
                      <rect
                        x="8px"
                        y="170px"
                        rx="9px"
                        ry="9px"
                        width="70%"
                        height="13px"
                      />
                    </ContentLoader>
                  ))}
                </>
              )}
            </Row>
          </div>
        </div>
      </main> */}

      <Modal
        visible={deleteVisibility}
        title={`Confirmer la suppression de l'équipe "${team?.name}"`}
        onCancel={isDelete ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDelete ? null : () => setDeleteVisibility(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                disabled={isDelete}
                onClick={isDelete ? null : onDelete}
              >
                {isDelete ? "Supprimer..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={addVisibility}
        title={"Ajouter une équipe"}
        onCancel={
          isAdd
            ? null
            : () => {
                setParent(null);
                setAddVisibility(false);
              }
        }
        width={500}
      >
        <form onSubmit={onAdd}>
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Nom de l'équipe *
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Equipe parente
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="refresh"
                    isClearable
                    value={parent}
                    style={{ textSize: "5px" }}
                    options={formattedTeams}
                    onChange={(val) => {
                      setParent(val);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default"></label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Entrer une description de l'équipe"
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isAdd ? null : () => setAddVisibility(false)}
                >
                  Annuler
                </button>
                <button className="btn-full" type="submit" disabled={isAdd}>
                  {isAdd ? "Ajouter..." : "Ajouter"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        visible={updateVisibility}
        large={"400px"}
        title={
          team?.role === "OWNER" ? "Modifier une équipe" : "Détail de l'équipe"
        }
        onCancel={
          isUpdate
            ? null
            : () => {
                setParent(null);
                setUpdateVisibility(false);
              }
        }
      >
        <form onSubmit={isUpdate ? null : onUpdate}>
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Nom de l'équipe *
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    type="text"
                    disabled={team?.role !== "OWNER"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
              <div className="input-container">
                <label for="nom" className="label-default">
                  Equipe parente
                </label>
                <span className="span-block">
                  <CustomSelect
                    id="refresh"
                    isClearable
                    value={parent}
                    style={{ textSize: "5px" }}
                    options={formattedTeams}
                    onChange={(val) => {
                      setParent(val);
                    }}
                    status={team?.role !== "OWNER"}
                  />
                </span>
              </div>
              <div className="input-container">
                <label for="nom" className="label-default">
                  Description de l'équipe
                </label>
                <span className="span-block">
                  <textarea
                    value={description}
                    disabled={team?.role !== "OWNER"}
                    className="input-default inputCustom"
                    placeholder={
                      team?.role === "OWNER"
                        ? "Entrer une description de l'équipe"
                        : ""
                    }
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ minHeight: 60 }}
                  />
                </span>
              </div>
              {team?.role === "OWNER" && (
                <div className="col-md-12 wrap-btn-modal">
                  <ButtonGroup
                    style={{
                      marginTop: 10,
                      marginBottom: 20,
                      columnGap: 10,
                    }}
                  >
                    <button
                      className="btn-full"
                      type="submit"
                      disabled={isUpdate}
                    >
                      {isUpdate
                        ? "Loading..."
                        : "Enregistrer les modifications"}
                    </button>
                  </ButtonGroup>
                </div>
              )}
              <ListCard
                users={team?.userTeams || []}
                id={team?.idteam}
                color={theme}
                role={team?.role}
              />
              {/* {Array.isArray(team?.childreen) && team.childreen?.length > 0 && ( */}
              <ListSubTeam
                subteams={team?.childreen || []}
                id={team?.idteam}
                color={theme}
                role={team?.role}
              />
              {/* )} */}
            </div>
          </div>
        </form>
      </Modal>
      {/* <Footer /> */}
    </div>
  );
}

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 400px));
  justify-content: center;
  gap: 10px;
  height: auto;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, minmax(200px, 400px));
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, minmax(200px, 400px));
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, minmax(200px, 550px));
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  height: 250px;
  position: relative;
`;

const CardTop = styled.div`
  width: 100%;
  height: 72%;
  cursor: pointer;
`;

const CardBottom = styled.div`
  height: 28%;
  display: flex;
  justify-content: space-between;
  padding: 2px 8px;
  background: ${theme === "0" ? "#323541" : "#e0e0e0"};
`;
const CardBottomContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 3;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 16px;
  margin-bottom: 0;
  padding: 0;
  font-weight: 600;
  margin-bottom: 2px;
`;
const CardImg = styled.img`
  background-position-y: center;
  background-size: cover;
  background-position-x: center;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const CardText = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 3;
  align-items: center;
`;

const Small = styled.small`
  font-size: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  padding-top: 2;
`;

const Span = styled.span`
  font-size: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconButton = styled.span`
  color: rgb(84, 183, 237);
  cursor: pointer;
  transition: ease-in 0.4s;
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 25px;
  text-align: center;
  border-radius: 5px;
  background-color: #ff725e;
  color: #6d1f1f;
  cursor: pointer;
`;

const Card = styled.div`
  background: ${theme === "0" ? "transparent" : "#E0E0E0"};
  margin: 0;
  margin-bottom: 8px;
  border: ${theme === "0" ? "1px dashed #FFF" : "1px solid #E0E0E0"};
  height: 250px;
`;

const ButtonGroup = styled.div`
  margin-top: 50px;
  column-gap: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
