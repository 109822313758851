import {
  ACTION_IS_ADD_WORKSPACE,
  ACTION_IS_DELETE_WORKSPACE,
  ACTION_IS_GET_WORKSPACE,
  ACTION_IS_UPDATE_WORKSPACE,
  ACTION_ADD_WORKSPACE,
  ACTION_REMOVE_WORKSPACE,
  ACTION_SET_ALL_WORKSPACE,
  ACTION_UPDATE_WORKSPACE,
  ACTION_IS_SEND_INVITATION,
  ACTION_CHANGE_ROLE,
  ACTION_IS_CHANGE_ROLE,
  ACTION_IS_REMOVE_USER,
  ACTION_REMOVE_USER,
} from "../actions/workspaceAction";

const initialState = {
  workspaces: [],
  isAddWorkspace: false,
  isUpdateWorkspace: false,
  isDeleteWorkspace: false,
  isGetWorkspace: false,
  isSendInvitation: false,
  isChangeRole: false,
  isRemoveUser: false,
};

const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_IS_ADD_WORKSPACE: {
      return {
        ...state,
        isAddWorkspace: action.payload,
      };
    }

    case ACTION_IS_UPDATE_WORKSPACE: {
      return {
        ...state,
        isUpdateWorkspace: action.payload,
      };
    }

    case ACTION_IS_DELETE_WORKSPACE: {
      return {
        ...state,
        isDeleteWorkspace: action.payload,
      };
    }

    case ACTION_IS_GET_WORKSPACE: {
      return {
        ...state,
        isGetWorkspace: action.payload,
      };
    }
    case ACTION_IS_SEND_INVITATION: {
      return {
        ...state,
        isSendInvitation: action.payload,
      };
    }
    case ACTION_IS_CHANGE_ROLE: {
      return {
        ...state,
        isChangeRole: action.payload,
      };
    }
    case ACTION_IS_REMOVE_USER: {
      return {
        ...state,
        isRemoveUser: action.payload,
      };
    }
    case ACTION_CHANGE_ROLE: {
      const workspaces = [...state.workspaces];

      for (let i = 0; i < workspaces.length; i++) {
        const item = workspaces[i].userWorkspaces;
        for (let j = 0; j < item.length; j++) {
          if (item[j].id === action.payload.id) {
            workspaces[i].userWorkspaces[j].role = action.payload.role;
          }
        }
      }

      return {
        ...state,
        workspaces,
        // isChangeRole: action.payload,
      };
    }

    case ACTION_REMOVE_USER: {
      const workspaces = [...state.workspaces];
      const { id, idworkspace } = action.payload;

      const data = workspaces.map((item) => {
        if (item.id === idworkspace) {
          return {
            ...item,
            userWorkspaces: item.userWorkspaces.filter(
              (userWorkspace) => userWorkspace.id !== id
            ),
          };
        }
        return item;
      });

      return {
        ...state,
        workspaces: data,
      };
    }

    case ACTION_UPDATE_WORKSPACE: {
      const workspaces = [...state.workspaces];

      for (let i = 0; i < workspaces.length; i++) {
        const item = workspaces[i];
        if (+item.id === +action.payload.id) {
          workspaces[i] = action.payload;
        }
      }

      return {
        ...state,
        workspaces: [...workspaces],
      };
    }

    case ACTION_ADD_WORKSPACE: {
      return {
        ...state,
        workspaces: [...state.workspaces, action.payload],
      };
    }

    case ACTION_REMOVE_WORKSPACE: {
      const workspaces = [...state.workspaces];

      for (let i = 0; i < workspaces.length; i++) {
        const item = workspaces[i];
        if (+item.id === +action.payload) {
          workspaces.splice(i, 1);
        }
      }

      return {
        ...state,
        workspaces: [...workspaces],
      };
    }
    case ACTION_SET_ALL_WORKSPACE: {
      return {
        ...state,
        workspaces: action.payload,
      };
    }

    default:
      return state;
  }
};

export default workspaceReducer;
