import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header";
import Footer from "../Footer";
import Planche from "../Views/Planche";
import Modal from "../Views/Modal";
import {
  actionAddDashboardMid,
  actionDeleteDashboard,
  actionGetDashboardsByUser,
  actionUpdateDashboard,
  actionMoveDashboards,
  actionSetDashboards,
} from "../../redux/actions/dashboardAction";
import ContentLoader from "react-content-loader";
import { useSnackbar } from "notistack";
import CustomSelect from "../Views/CustomSelect";
import { Pagination } from "antd";
import styled from "styled-components";
import { Fragment } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import ViewDashboard from "../Views/ViewDashBoard";
import Select from "../Views/SelectV2";
import { Search } from "lucide-react";
import { ChevronDownIcon } from "lucide-react";
import { i } from "mathjs";
import { actionGetWorkspaceByCompany } from "../../redux/actions/workspaceAction";
import { useNavigate } from "react-router-dom";
import { actionSetWorkpsace } from "../../redux/actions/UserAction";
import { select } from "@material-tailwind/react";
import SelectedIndicator from "../RapportInfos/SelectedIndicator";

const ResponsiveGridLayout = WidthProvider(Responsive);

const _ = require("lodash");
let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const roleCompany = useSelector((state) => state.user.role);
  const isAdmin = ["ADMIN", "OWNER"].includes(roleCompany);
  const options = [
    { value: 2, label: "Simple tableau de board" },
    // { value: 1, label: "Tableau de bord avec list" },
  ];
  const [type, setType] = useState(options[0]);
  const [filter, setFilter] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [id, setId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );
  const [workspace, setWorkspace] = useState(+selectedWorkspace);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.id);
  const dashboards = useSelector((state) => state.board.dashboards);
  const isAdd = useSelector((state) => state.board.isAdd);
  const isDelete = useSelector((state) => state.board.isDelete);
  const isGet = useSelector((state) => state.board.isGet);
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const optionsWorkspaces = workspaces.map((item) => ({
    label: item.name,
    value: item.id,
    color: item.color,
    key: item.id,
  }));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dashboards.filter((item) => {
    if (filter === "") {
      return item;
    }
    return item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  });

  useEffect(() => {
    if (workspaces.length > 0) {
      // setWorkspace(workspaces.find((item) => item.id === +idworkspace));
      localStorage.setItem("idworkspace", workspaces[0].id);
      if (+workspaces[0].id) {
        dispatch(actionGetDashboardsByUser(workspaces[0].id, token));
      }
      if (+workspaces[0].id) {
        dispatch(actionGetDashboardsByUser(workspaces[0].id, token));
      }
      dispatch(actionSetWorkpsace(workspaces[0].id));

      setWorkspace(workspaces[0].id);
    } else {
      navigate("/workspaces");
    }
  }, [workspaces]);

  useEffect(() => {
    const data = localStorage.getItem("data");
    const idworkspace = localStorage.getItem("idworkspace");

    if (!data || !idworkspace) {
      navigate("/workspaces");
    } else {
      dispatch(
        actionGetWorkspaceByCompany(
          "Bearer " + JSON.parse(data).token,
          (result) => {
            if (result) {
              // console.log(result);
            }
          }
        )
      );
      if (+idworkspace) {
        dispatch(actionGetDashboardsByUser(idworkspace, token));
      }
    }
  }, []);

  const addBoard = (e) => {
    e.preventDefault();
    try {
      if (name.trim() === "") {
        throw Error("entrez un nom valide");
      } else if (
        currentItems.find(
          (item) =>
            item.name.toLocaleLowerCase().trim() ===
            name.toLocaleLowerCase().trim()
        )
      ) {
        throw Error("un dashboard avec ce nom existe déjà");
      }
    } catch (error) {
      enqueueSnackbar(error?.message || "", {
        variant: "warning",
      });
      return;
    }

    dispatch(
      actionAddDashboardMid(
        name.trim(),
        type.value,
        workspace,
        token,
        ({ status, message }) => {
          if (status) {
            setVisibility(false);
            enqueueSnackbar(message || "", {
              variant: "success",
            });
            setName("");
          } else {
            enqueueSnackbar(message || "", {
              variant: "warning",
            });
          }
        }
      )
    );
  };

  const deleteBoard = () => {
    dispatch(
      actionDeleteDashboard(id, workspace, token, ({ status, message }) => {
        if (status) {
          setId(null);
          setDeleteVisibility(false);
          enqueueSnackbar(message || "", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(message || "", {
            variant: "warning",
          });
        }
      })
    );
  };

  const confirmDeleteBoard = (id) => {
    setId(id);
    setDeleteVisibility(true);
  };

  const updateBoard = (name, id) => {
    dispatch(
      actionUpdateDashboard(
        name,
        id,
        workspace,
        token,
        ({ status, message }) => {
          if (status) {
            enqueueSnackbar(message || "", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(message || "", {
              variant: "warning",
            });
          }
        }
      )
    );
  };

  const [ordre, setOrdre] = useState(false);

  const setDash = (data) => {
    dispatch(actionSetDashboards({ type: "all", data }));
    dispatch(
      actionMoveDashboards(
        data.map((item, index) => {
          return {
            iduser: item.iduser,
            iddashboard: item.iddashboard,
            positionx: index,
          };
        }),
        token
      )
    );
  };

  return (
    <div className="h-[100vh]">
      <Modal
        visible={ordre}
        title={"Modifier l'ordre des dashboards"}
        onCancel={() => setOrdre(false)}
      >
        <>
          <ViewDashboard items={dashboards} setItems={setDash} />
        </>
      </Modal>
      <Modal
        visible={deleteVisibility}
        title={"Confirmer la suppression"}
        onCancel={isDelete ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDelete ? null : () => setDeleteVisibility(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDelete}
                onClick={deleteBoard}
              >
                {isDelete ? "Supprimer..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibility}
        title={"Ajouter un tableau"}
        onCancel={
          isAdd
            ? null
            : () => {
                setVisibility(false);
                setName("");
              }
        }
        width={500}
      >
        <div className="flex flex-col w-full h-full gap-1 p-0 m-0">
          <form onSubmit={addBoard}>
            <div className="flex flex-col w-full h-full gap-1 p-0 m-0">
              <div className="flex flex-col gap-2">
                <label for="nom" className="font-poppins font-[400px]">
                  Entrer le nom *
                </label>

                <input
                  className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor hover:outline-none active:outline-none"
                  name="nom"
                  id="nom"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Obligatoire"
                  required
                />
              </div>

              <div className="flex flex-col gap-2">
                <label className="font-poppins font-[400px]">
                  Type de dashboard
                </label>

                <CustomSelect
                  value={type}
                  style={{ textSize: "5px" }}
                  options={options}
                  onChange={setType}
                  disabled
                />
              </div>

              <div className="flex items-center justify-center gap-4 mt-2">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isAdd ? null : () => setVisibility(false)}
                >
                  Annuler
                </button>
                <button className="btn-full" type="submit" disabled={isAdd}>
                  {isAdd ? "Ajouter..." : "+ Ajouter"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Header />
      <main className="homemain mt-[60px] w-[98%] mx-auto  ">
        <div className=" bg-white w-full   row actions-nav m-3 mx-auto border-r-black rounded-[5px] flex-col items-center gap-2 py-3">
          <div className="flex items-center justify-between w-full gap-1 px-4 pr-6 h-[40px] font-poppins">
            <Select
              options={optionsWorkspaces}
              value={+workspace}
              defaultValue={+selectedWorkspace}
              onChange={(value) => {
                localStorage.setItem("idworkspace", value);
                dispatch(actionSetWorkpsace(value));
                setWorkspace(value);
              }}
            />

            {/* <div className="flex items-center mr-2">
              <i
                aria-describedby={false}
                className="fa-light fa-ellipsis more"
                style={{
                  color:
                    theme === "0"
                      ? "#fff"
                      : theme === "1"
                      ? "#1f2128"
                      : "#1f2128",
                }}
                onClick={() => {}}
              ></i>
            </div> */}
          </div>

          <div className="w-full h-[40px] flex items-center justify-between px-6 gap-2 font-poppins ">
            {/* <ModalPrint interval={interval} /> */}
            <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center">
              <p className="font-semibold text-center text-[18px]  mt-[20px]">
                Mes Tableaux de bord
              </p>
              <div className="relative flex items-center m-">
                <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                <input
                  type="text"
                  placeholder="Recherche"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                />
              </div>
            </div>

            <div className="flex items-center gap-2">
              {isAdmin && (
                <p
                  className="hover:text-white hover:bg-colorText border-2 border-colorText text-colorText  rounded-[5px] p-0 m-0 text-center font-poppins py-1 w-[180px] font-semibold cursor-pointer text-[12px]"
                  onClick={() => setVisibility(true)}
                >
                  + Nouveau tableau de bord
                </p>
              )}
            </div>
          </div>
        </div>
        {/* <div className="head">
            <h1 className="title-1">Mes tableaux de bord</h1>
            <button
              className="btn-border close-btn-submit"
              type="reset"
              onClick={() => {
                setOrdre(true);
              }}
            >
              Modifier l'ordre des dashboards
            </button>
          </div> */}
        <div className="w-full mx-auto pb-12 h-[80vh] rounded-xl overflow-y-auto z-4">
          <div className="flex flex-wrap gap-2" ref={containerRef}>
            {isGet ? (
              <Fragment>
                {[0, 1, 2, 3].map((i) => (
                  <div
                    className="overflow-hidden h-[280px] flex justify-between flex-col transition-shadow bg-white cursor-pointer shadow group hover:shadow-lg"
                    key={i}
                  >
                    <ContentLoader
                      height={"280px"}
                      width={"100%"}
                      speed={1}
                      backgroundColor={"#80808075"}
                      foregroundColor={"#a9a9a975"}
                      className="overflow-hidden h-[280px] flex justify-between flex-col transition-shadow bg-white cursor-pointer shadow group hover:shadow-lg"
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="9px"
                        ry="0px"
                        width="100%"
                        height="160px"
                      />
                      <rect
                        x="8px"
                        y="170px"
                        rx="9px"
                        ry="9px"
                        width="70%"
                        height="13px"
                      />
                    </ContentLoader>
                  </div>
                ))}
              </Fragment>
            ) : (
              currentItems.map((item, index) => (
                <Planche
                  key={item.iddashboard}
                  id={item.iddashboard}
                  dashboard={item}
                  name={item.name}
                  isViewOnly={Boolean(item.role === "VIEW")}
                  isOwner={isAdmin}
                  image={
                    index % 2 === 0 ? "./update/d1.png" : "./update/d2.png"
                  }
                  onDelete={confirmDeleteBoard}
                  onUpdate={updateBoard}
                />
              ))
            )}
          </div>
        </div>

        {/* {totalPages > 1 && (
            <div className="flex justify-center mt-[10px] items-center gap-[10px] flex-wrap">
              <button
                onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
                className="cursor-pointer "
                aria-label="Page précédente"
              >
                &#8592;
              </button>
              {getVisiblePageNumbers().map((page, index) =>
                page === -1 ? (
                  <span
                    key={`ellipsis-${index}`}
                    className="pagination-ellipsis"
                  >
                    ...
                  </span>
                ) : (
                  <div
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`p-[8px] pt-[4px] pb-[4px] rounded-[8px] font-poppins cursor-pointer ${
                      currentPage === page
                        ? "bg-colorText  text-white "
                        : "text-gray"
                    }`}
                  >
                    {page}
                  </div>
                )
              )}
              <button
                onClick={() =>
                  handlePageChange(Math.min(totalPages, currentPage + 1))
                }
                disabled={currentPage === totalPages}
                className=""
                aria-label="Page suivante"
              >
                &#8594;
              </button>
            </div>
          )} */}

        {/* {dashboards.length > 3 ? (
          <Pagination
            total={Math.ceil(dashboards.length / 3) * 10}
            current={page}
            onChange={handleChange}
            className="text-right col-xs-12 col-md-12"
          />
        ) : (
          " "
        )} */}
      </main>
      {/* <Footer /> */}
    </div>
  );
}
const Container = styled(ResponsiveGridLayout)`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    border-radius: 8px;
    margin: 10px;
    width: 5px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-corner {
    border-radius: 8px;
    margin: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #05baf3;
    border-radius: 8px;
    border-color: #05bcf341;
    margin: 3px;
    border-width: 2px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    margin: 3px;
  }
`;
