import host from "./host";
export const ACTION_SET_GOALS = "ACTION_SET_GOALS";
export const ACTION_IS_GET_GOALS = "ACTION_IS_GET_GOALS";
export const ACTION_IS_ADD_GOAL = "ACTION_IS_ADD_GOAL";
export const ACTION_IS_UPDATE_GOAL = "ACTION_IS_UPDATE_GOAL";
export const ACTION_IS_DELETE_GOAL = "ACTION_IS_DELETE_GOAL";

// * --------- Begin actions --------

export const actionSetGoals = (payload) => {
  return { type: ACTION_SET_GOALS, payload };
};

export const actionIsGetGoals = (payload) => {
  return { type: ACTION_IS_GET_GOALS, payload };
};

export const actionIsAddGoal = (payload) => {
  return { type: ACTION_IS_ADD_GOAL, payload };
};

export const actionIsUpdateGoal = (payload) => {
  return { type: ACTION_IS_UPDATE_GOAL, payload };
};

export const actionIsDeleteGoal = (payload) => {
  return { type: ACTION_IS_DELETE_GOAL, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionAddGoal = (
  name,
  iddatasource,
  idmetric,
  formule,
  idaxedate,
  columns,
  periodique,
  dateBeginOperation,
  periodicite,
  startdate,
  enddate,
  idperiod,
  operator,
  targetvalue,
  trigger,
  idUsers,
  iduser,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsAddGoal(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      iddatasource: parseInt(iddatasource),
      name,
      periodique,
      dateBeginOperation,
      startdate,
      enddate,
      targetvalue: parseInt(targetvalue),
      iduser: parseInt(iduser),
      periodicite: parseInt(periodicite),
      columns,
      operator,
      idperiod,
      trigger,
      idUsers,
      idmetric: parseInt(idmetric),
      idaxedate: parseInt(idaxedate),
      formule,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/goal/create`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetGoals({ type: "ADD", data: result.new }));
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => dispatch(actionIsAddGoal(false)));
  };
};

export const actionUpdateGoal = (
  idgoal,
  name,
  iddatasource,
  idmetric,
  formule,
  idaxedate,
  columns,
  periodique,
  dateBeginOperation,
  periodicite,
  startdate,
  enddate,
  idperiod,
  operator,
  targetvalue,
  trigger,
  idUsers,
  iduser,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsUpdateGoal(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      iddatasource: parseInt(iddatasource),
      name,
      periodique,
      dateBeginOperation,
      startdate,
      enddate,
      targetvalue: parseInt(targetvalue),
      iduser: parseInt(iduser),
      periodicite: parseInt(periodicite),
      columns,
      operator,
      idperiod,
      trigger,
      idUsers,
      idmetric: parseInt(idmetric),
      idaxedate: parseInt(idaxedate),
      formule,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/goal/update/${idgoal}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(
            actionSetGoals({ type: "UPDATE", data: result.update, id: idgoal })
          );
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch((e) => {
        console.log("e", e);
        callback(400);
      })
      .then(() => dispatch(actionIsUpdateGoal(false)));
  };
};

export const actionGetGoals = (idUser, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetGoals(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/goal/get-by-user/${idUser}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetGoals({ type: "ALL", data: result }));
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => dispatch(actionIsGetGoals(false)));
  };
};

export const actionDeleteGoal = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsDeleteGoal(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/goal/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(400);
          // return null;
        } else {
          dispatch(actionSetGoals({ type: "DELETE", data: id }));
          callback(200);
        }
        // return response.json();
      })
      // .then((result) => {
      //   if (result) {
      //     dispatch(actionSetGoals({ type: "DELETE", data: id}));
      //     callback(200);
      //   } else {
      //     callback(400);
      //   }
      // })
      .catch((/* error */) => {
        // console.log('error', error);
        callback(400);
      })
      .then(() => dispatch(actionIsDeleteGoal(false)));
  };
};

export const actionGetGoalColumn = (id, token, callback) => {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/goal/get-column/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(400);
          // return null;
        } else {
          callback(200);
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          callback(result);
        } else {
          callback(400);
        }
      })
      .catch((error) => {
        console.log("error", error);
        callback(400);
      });
  };
};

// * --------- End --------
