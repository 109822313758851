import React, { useEffect, useCallback, useRef } from "react";
import Header from "../Header";
import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";
import { actionGetConnectors } from "../../redux/actions/connectorAction";
import {
  actionGetDatasources,
  actionSetOneDatasource,
} from "../../redux/actions/datasourceAction";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { truncateText } from "../utils/truncateWords";
import Select from "react-select";
import { Search } from "lucide-react";
import { PencilIcon, TrashIcon } from "lucide-react";
// import { CheckBox } from "@material-ui";
import Condition from "./condition";
import { Checkbox } from "antd";
import {
  actionCreateCondition,
  actionDeleteCondition,
  actionGetConditionByDdataSource,
  actionChangeStatusCondition,
} from "../../redux/actions/ConditionAction";
import Modal from "../Views/Modal";

import TableRowLoader from "../Views/TableLoading";

const _ = require("lodash");

const xlsxHost = "https://xlsx.viabber.com";

export default function Columns({ datasource }) {
  const conditions = useSelector((state) => state.condition.conditions) || [];

  const [columns, setColumns] = useState(
    datasource.columns.map((t) => ({ ...t, generated: false }))
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const [columnsM, setColumnsM] = useState(
    datasource.columns.map((item) => ({
      type: item.dataType == 1 ? "number" : "string",
      label: item.name.toLocaleLowerCase(),
      id: +item.idcolumn,
    }))
  );
  const [isUpdate, setIsUpdate] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [condition, setCondition] = useState(null);
  const [loading, setLoading] = useState(false);
  const conditionRef = useRef();
  conditionRef.current = condition;
  const [filter, setFilter] = useState("");
  const [deleteVisibility, setDeleteVisibility] = useState(false);

  const variableNameRegex =
    /^(?!\b(?:abstract|await|boolean|break|byte|case|catch|char|class|const|continue|debugger|default|delete|do|double|else|enum|export|extends|false|final|finally|float|for|function|goto|if|implements|import|in|instanceof|int|interface|long|native|new|null|package|private|protected|public|return|short|static|super|switch|synchronized|this|throw|throws|transient|true|try|typeof|var|void|volatile|while|with|yield)\b)[A-Za-z_$][A-Za-z0-9_$]*$/;

  function isValidVariableName(name) {
    return variableNameRegex.test(name);
  }

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data) {
      navigate("/");
    } else {
      if (datasource.iddatasource) {
        dispatch(
          actionGetConditionByDdataSource(
            datasource.iddatasource,
            "Bearer " + JSON.parse(data).token,
            (status) => {}
          )
        );
      }
    }
  }, [datasource]);

  const submit = (e) => {
    e.preventDefault();
  };

  const customStylesVue = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff", // bg-red-600
      border: "none",
      boxShadow: "none",
      outline: "none",
      padding: "0",
      fontWeight: "500",
      fontFamily: "Poppins",
      fontSize: "16 px", // tailwind equivalent of small font
      minHeight: "20px",
      minWidth: "130px",
      cursor: "pointer",
      padding: 0,
      margin: 0,
      width: 320,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "5px",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Poppins",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "15px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "15px",
    }),
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const containerRef = useRef(null);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = columns

    .filter((elt) => {
      if (filter === "") {
        return elt;
      }
      return elt.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
    })
    ?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(
    columns.filter((elt) => {
      if (filter === "") {
        return elt;
      }
      return elt.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
    }).length / itemsPerPage
  );

  const isAdd = useSelector((state) => state.datasource.isAdd);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getVisiblePageNumbers = useCallback(() => {
    const maxVisiblePages = 5; // Nombre maximum de pages visibles
    const halfVisible = Math.floor(maxVisiblePages / 2);

    if (totalPages <= maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= halfVisible + 1) {
      return [
        ...Array.from({ length: maxVisiblePages - 1 }, (_, i) => i + 1),
        -1,
        totalPages,
      ];
    }

    if (currentPage >= totalPages - halfVisible) {
      return [
        1,
        -1,
        ...Array.from(
          { length: maxVisiblePages - 1 },
          (_, i) => totalPages - maxVisiblePages + 2 + i
        ),
      ];
    }

    return [
      1,
      -1,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      -1,
      totalPages,
    ];
  }, [currentPage, totalPages]);

  const remove = (e) => {
    e.preventDefault();
    console.log("coucou", condition);
    if (condition) {
      console.log(condition);
      dispatch(
        actionDeleteCondition(condition?.id, token, (status) => {
          if (status) {
            enqueueSnackbar("Condition retirer avec succès", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar("Erreur lors de la suppression", {
              variant: "error",
            });
          }
        })
      );
    }
  };
  const onChangeStatus = (id) => {
    if (id) {
      dispatch(
        actionChangeStatusCondition(id, token, (status) => {
          if (status) {
            enqueueSnackbar("Condition mis à jour avec succès", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar("Erreur lors de la mise à jour de la condition", {
              variant: "error",
            });
          }
        })
      );
      console.log(id);
    }
  };
  const cancel = () => {
    setCondition(null);
    setDeleteVisibility(false);
  };
  const isDeleting = useSelector((state) => state.period.isDeletePeriod);
  return (
    <div className="h-full ">
      {condition && (
        <Modal
          visible={deleteVisibility && condition}
          title={`Supprimer la condition "${condition?.name}"`}
          onCancel={isDeleting ? null : cancel}
        >
          <div className="row">
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isDeleting ? null : cancel}
                >
                  Annuler
                </button>
                <button
                  className="btn-full"
                  type="submit"
                  disabled={isDeleting}
                  onClick={isDeleting ? null : remove}
                >
                  {isDeleting ? "Supprimer..." : "Supprimer"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {visibility && (
        <Condition
          visibility={visibility}
          isUpdate={isUpdate}
          setVisibility={setVisibility}
          columns={columnsM}
          iddatasource={datasource.iddatasource}
          condition={condition}
          setIsUpdate={setIsUpdate}
          setCondition={setCondition}
        />
      )}

      <main className="w-full h-full mx-auto ">
        <>
          <div className="px-2 mb-3 w-full row actions-nav mx-auto border-r-black rounded-[20px]  items-center gap-2">
            <div className="flex items-center justify-between w-full gap-2 font-poppins ">
              <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center ml-1">
                <div className="relative flex items-center ">
                  <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                  <input
                    type="text"
                    placeholder="Recherche"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                  />
                </div>
              </div>

              <div className="flex items-center gap-2">
                <p
                  className="hover:text-white hover:bg-colorText border-2 border-colorText text-colorText  rounded-[5px] p-0 m-0 text-center font-poppins py-1 w-[180px] font-semibold cursor-pointer text-[12px]"
                  onClick={() => {
                    setVisibility(true);
                    setIsUpdate(false);
                  }}
                  type="submit"
                >
                  +Ajouter une condition
                </p>
              </div>
            </div>
          </div>

          {/* <div className="bloc-fond-fonce2 wrap-table"> */}
          <div className="w-full px-3 bg-customBg h-[83vh] rounded-xl overflow-auto">
            <table className="w-full bg-white shadow-sm ">
              <thead className="mb-[600px] font-poppins leading-[50px] ">
                <tr className="bg-white rounded-xl">
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] w-[25%] rounded-xl">
                    Nom
                  </th>
                  <th className="p-[3px] font-semibold text-left text-black border-b text-[12px] w-[60%] ">
                    Formule complète
                  </th>

                  <th className="p-[3px] font-semibold text-center text-black border-b text-[12px] w-[10%]">
                    statut
                  </th>

                  <th className="p-[3px] font-semibold text-center text-black border-b text-[12px] w-[15%]">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-[12px] mt-[900px] border-t-8 border-customBg overflow-y-auto">
                {loading
                  ? [...Array(5)].map((_, index) => (
                      <tr
                        key={index}
                        className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-[40px]  cursor-pointer font-[400px]"
                      >
                        <td colSpan={4}>
                          <TableRowLoader />
                        </td>
                      </tr>
                    ))
                  : conditions.map((item) => {
                      return (
                        <tr
                          key={item.id}
                          className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-[40px]  cursor-pointer font-[400px]"
                        >
                          <td className="p-0 pl-2 text-[12px] text-black font-[500px]">
                            {item.name}
                          </td>
                          <td className="p-0 text-[12px] text-black font-[400px]">
                            {item.expression}
                          </td>

                          <td className=" text-center text-black font-[400px]">
                            <Checkbox
                              checked={item.active}
                              onChange={() => onChangeStatus(item.id)}
                            />
                          </td>

                          <td className="p-0 text-[12px]">
                            <div className="flex justify-center gap-3 items-left">
                              <button
                                className={"hover:text-gray-900"}
                                onClick={() => {
                                  setCondition(item);
                                  setIsUpdate(true);
                                  setVisibility(true);
                                }}
                              >
                                <PencilIcon className="w-5 h-5" />
                              </button>
                              <button
                                className="text-gray-600 hover:text-gray-900"
                                onClick={() => {
                                  setDeleteVisibility(true);
                                  setCondition(item);
                                }}
                              >
                                <TrashIcon className="w-5 h-5" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          {/* </div> */}
        </>
      </main>
      {/* <Footer /> */}
    </div>
  );
}
