import host from "./host";

export const ACTION_ADD_CONDITION = "ACTION_ADD_CONDITION";
export const ACTION_IS_ADD_CONDITION = "ACTION_IS_ADD_CONDITION";
export const ACTION_IS_DELETE_CONDITION = "ACTION_IS_DELETE_CONDITION";
export const ACTION_IS_GET_CONDITION = "ACTION_IS_GET_CONDITION";
export const ACTION_IS_UPDATE_CONDITION = "ACTION_IS_UPDATE_CONDITION";
export const ACTION_UPDATE_CONDITION = "ACTION_UPDATE_CONDITION";
export const ACTION_SET_ALL_CONDITION = "ACTION_SET_ALL_CONDITIONE";
export const ACTION_REMOVE_CONDITION = "ACTION_REMOVE_CONDITION";
export const ACTION_CHANGE_STATUS_CONDITION = "ACTION_CHANGE_STATUS_CONDITION";

export const actionAddCondition = (payload) => ({
  type: ACTION_ADD_CONDITION,
  payload: payload,
});

export const actionchangeStatusCondition = (payload) => ({
  type: ACTION_CHANGE_STATUS_CONDITION,
  payload: payload,
});

export const actionRemoveCondition = (payload) => ({
  type: ACTION_REMOVE_CONDITION,
  payload: payload,
});

export const actionSetAllCondition = (payload) => ({
  type: ACTION_SET_ALL_CONDITION,
  payload: payload,
});

export const actionIsAddCondition = (payload) => ({
  type: ACTION_IS_ADD_CONDITION,
  payload: payload,
});

export const actionIsDeleteCondition = (payload) => ({
  type: ACTION_IS_DELETE_CONDITION,
  payload: payload,
});

export const actionIsUpdateCondition = (payload) => ({
  type: ACTION_IS_UPDATE_CONDITION,
  payload: payload,
});

export const actionIsGetCondition = (payload) => ({
  type: ACTION_IS_GET_CONDITION,
  payload: payload,
});

export const addCondition = (payload) => ({
  type: ACTION_ADD_CONDITION,
  payload: payload,
});

export const actionSetCondition = (payload) => ({
  type: ACTION_UPDATE_CONDITION,
  payload: payload,
});

export const actionCreateCondition = (
  name,
  root,
  iddatasource,
  expression,
  active,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsAddCondition(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        name,
        root,
        iddatasource,
        expression,
        active,
      }),
      redirect: "follow",
    };

    fetch(`${host}/condition/create`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionAddCondition(result.new));
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch((error) => {
        callback(false);
      })
      .then(() => {
        dispatch(actionIsAddCondition(false));
      });
  };
};

export const actionUpdateCondition = (
  id,
  name,
  root,
  iddatasource,
  expression,
  active,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsUpdateCondition(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      root,
      iddatasource,
      expression,
      active,
      token,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/condition/update/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetCondition(result.update));
          callback(true);
        }
      })
      .catch(() => {})
      .then(() => {
        dispatch(actionIsUpdateCondition(false));
      });
  };
};
export const actionChangeStatusCondition = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsUpdateCondition(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: "",
      redirect: "follow",
    };

    fetch(`${host}/condition/change-status/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetCondition(result.update));
          callback(true);
        }
      })
      .catch(() => {})
      .then(() => {
        dispatch(actionIsUpdateCondition(false));
      });
  };
};

export const actionGetConditionByDdataSource = (
  iddatasource,
  token,
  callback
) => {
  return (dispatch) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/condition/get-by-datasource/${iddatasource}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          // console.log(result);
          dispatch(actionSetAllCondition(result));
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch(() => {
        callback(false);
      });
  };
};

export const actionDeleteCondition = (id, token, callback) => {
  console.log(id);
  return (dispatch) => {
    dispatch(actionIsDeleteCondition(true));
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/condition/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionRemoveCondition(id));
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch(() => {
        callback(false);
      })
      .then(() => {
        dispatch(actionIsDeleteCondition(false));
      });
  };
};
