import { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "react-resizable/css/styles.css";

export default function CartTable({ rows, columns, x, y }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const cardSize = {
    width: Number(x),
    height: Number(y),
  };

  useEffect(() => {
    const updateItemsPerPage = () => {
      const tableHeight = Number(y) - 100;
      const newItemsPerPage = Math.floor(tableHeight / 35) - 1;
      setItemsPerPage(Math.max(newItemsPerPage, 1));
    };
    setCurrentPage(1);
    updateItemsPerPage();
  }, [x, y]);

  const totalPages = Math.ceil((rows.length - 1) / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = rows.slice(startIndex, endIndex);
  const paginate = !(itemsPerPage >= rows.length - 1);
  const renderPaginationNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 4;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftOffset = Math.max(
        currentPage - Math.floor(maxVisiblePages / 2),
        1
      );
      const rightOffset = Math.min(
        leftOffset + maxVisiblePages - 1,
        totalPages
      );

      if (leftOffset > 1) {
        pageNumbers.push(1);
        if (leftOffset > 2) pageNumbers.push("...");
      }

      for (let i = leftOffset; i <= rightOffset; i++) {
        pageNumbers.push(i);
      }

      if (rightOffset < totalPages) {
        if (rightOffset < totalPages - 1) pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((number, index) => (
      <button
        key={index}
        onClick={() => typeof number === "number" && setCurrentPage(number)}
        className={`w-8 h-8 rounded-[4px] text-sm transition-all duration-300 ease-in-out transform hover:scale-110 ${
          number === currentPage
            ? "bg-baseColor text-white"
            : "bg-white text-baseColor hover:bg-baseColor"
        } ${typeof number !== "number" ? "cursor-default" : ""}`}
        disabled={typeof number !== "number"}
      >
        {number}
      </button>
    ));
  };

  const columnSums =
    rows?.[0]?.slice(2)?.map((_, colIndex) => {
      return rows.reduce((sum, row) => {
        // Vérifiez que la ligne et la colonne existent
        if (Array.isArray(row) && row[colIndex + 2]) {
          let value = row[colIndex + 2]?.value; // Ignorer les deux premiers objets

          // Remplacez les valeurs non positives ou invalides par 0
          if (typeof value !== "number") {
            value = 0;
          }

          return sum + value;
        }
        return sum;
      }, 0);
    }) || Array(rows?.[0]?.slice(2)?.length || 0).fill(0);

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-hidden bg-white rounded-lg">
        <div className="flex-grow overflow-auto">
          <table className="w-full">
            <thead className="bg-customBg">
              <tr className="border-b">
                {columns
                  .filter((item) => item.isVisible)
                  .map((item, index) => {
                    return (
                      <th
                        className={`px-4 py-2 text-left text-sm font-medium text-gray-600 ${
                          item?.type === "string" ? "text-left" : "text-center"
                        }`}
                        key={index}
                      >
                        {item.label.toString()}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {/* <tr className="font-bold transition-all duration-300 ease-in-out border-b hover:bg-gray-50 bg-customBg">
                <td className={`px-4 py-2 text-sm text-gray-800 text-right`}>
                  somme Total
                </td>
                {columnSums.map((element, i) => {
                  return (
                    <td
                      className={`px-4 py-2 text-sm text-gray-800  text-center`}
                      key={i}
                    >
                      {element}
                    </td>
                  );
                })}
              </tr> */}
              {currentData.map((items, index) => {
                return (
                  <tr
                    key={index}
                    className="transition-all duration-300 ease-in-out border-b hover:bg-gray-50"
                    // style={{ ...items[0], fontWeight: "bold" }}
                  >
                    {items
                      .slice(1)
                      .filter((item) => item.isVisible)
                      .map((element, i) => {
                        return (
                          <td
                            className={`px-4 py-2 text-sm text-gray-800 ${
                              i === 0 ? "text-left" : "text-center"
                            }`}
                            key={i}
                            style={i === 0 ? {} : { ...element.style }}
                          >
                            {element && element.value?.toString()
                              ? element.value.toString()
                              : element.value}
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {paginate && (
          <div className="p-2 bg-white-100">
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center">
                <button
                  className=" rounded-[4px] bg-baseColor text-white text-center  text-sm hover:bg-baseColor disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 ease-in-out transform hover:scale-110 mr-2"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                {renderPaginationNumbers()}
                <button
                  className="rounded-[4px] bg-baseColor text-white text-center text-sm hover:bg-baseColor disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 ease-in-out transform hover:scale-110 ml-2"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
