import React, { useState } from "react";
import {
  BarChartIcon as ChartBarIcon,
  EllipsisIcon as EllipsisHorizontalIcon,
} from "lucide-react";
import Header from "../Header";
import Team from "../Equipes";
import Periode from "../Periode";
import Profile from "../Profile";
import WorkSpace from "./workspace";
import UserCompany from "./userCompany";
import {
  BarChart3,
  Users,
  Layout,
  Settings,
  LogOut,
  UserCircle,
  Plug,
  SwitchCamera,
  UsersIcon,
  CalendarDays,
  Workflow,
} from "lucide-react";
import Connectors from "../Connectors";

const TabContent = ({ activeTab }) => {
  switch (activeTab) {
    case "profile":
      return <Profile />;
    case "appearance":
      return <div className="p-4">Paramètres d'apparence</div>;

    case "userCompanies":
      return <UserCompany />;
    case "workspaces":
      return <WorkSpace />;

    case "plans":
      return <div className="p-4">Gestion des plans</div>;
    case "connectors":
      return <Connectors />;

    default:
      return <div className="p-4">Sélectionnez un onglet</div>;
  }
};

export default function WorkspaceDashboard() {
  const [activeTab, setActiveTab] = useState("workspaces");

  return (
    <div className="h-[100vh] bg-customBg">
      <Header />

      <main className=" mt-[70px]  w-[98%]  mx-auto p-0 ">
        <div className="flex h-[calc(100vh-100px)] bg-white rounded-lg  p-0">
          {/* Sidebar */}
          <aside className="flex flex-col justify-between py-2 pt-0 bg-white border-r-8 w-60 border-customBg ">
            <nav className="w-full mt-0 ">
              {[
                { name: "Profil", key: "profile", icon: UserCircle },
                // { name: "Appearance", key: "appearance" },
                // { name: "Equipes", key: "teams", icon: Users },
                // { name: "Plans", key: "plans", icon: Settings },
                { name: "Connecteurs", key: "connectors", icon: Plug },
                // { name: "Periodes", key: "periodes", icon: CalendarDays },
                {
                  name: "Utilisateurs",
                  key: "userCompanies",
                  icon: UsersIcon,
                },
                { name: "workspaces", key: "workspaces", icon: Workflow },
                // { name: "workspaces", key: "workspaces", icon: CalendarDays },
              ].map((item) => (
                <div
                  key={item.key}
                  // href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(item.key);
                  }}
                  className={`  flex items-center gap-3 rounded-lg px-3 py-2 text-sm font-medium cursor-pointer ${
                    activeTab === item.key
                      ? "bg-customBg text-gray-900"
                      : "text-gray-700 hover:bg-gray-100"
                  }`}
                >
                  {item.icon && <item.icon className="w-4 h-4" />}
                  {item.name}
                </div>
              ))}
            </nav>
            <div className="flex flex-col items-end pt-4 mt-auto space-y-2 border-t border-gray-200 w">
              <div
                className="flex w-full gap-5 px-4 py-2 text-left text-gray-700 transition-colors rounded-lg cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  localStorage.removeItem("data");
                  localStorage.removeItem("token");
                  localStorage.removeItem("idworkspace");
                  window.location.replace("/");
                }}
              >
                <LogOut className="w-4 h-4" />
                <p className="text-[12px] font-poppins m-0 p-0 font-[500]">
                  Se déconnecter
                </p>
              </div>
            </div>
          </aside>

          {/* Main Content */}
          <main className="flex-1 ">
            <TabContent activeTab={activeTab} />
          </main>
        </div>
      </main>
    </div>
  );
}
