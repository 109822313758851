import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export const SocketProvider = ({ url, children }) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [rooms, setRooms] = useState(new Set());

  // Connexion automatique au montage du provider
  useEffect(() => {
    const socketInstance = io(url);

    socketInstance.on("connect", () => {
      setIsConnected(true);
      // console.log("Connected to Socket.io server");
    });

    socketInstance.on("disconnect", () => {
      setIsConnected(false);
      setRooms(new Set());
      // console.log("Disconnected from Socket.io server");
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, [url]);

  const joinRoom = useCallback(
    (datasourceId) => {
      if (socket && datasourceId) {
        socket.emit("joinRoom", datasourceId);
        setRooms((prevRooms) => new Set([...prevRooms, datasourceId]));
        // console.log(`Joined room for datasource: ${datasourceId}`);
      }
    },
    [socket]
  );

  const leaveRoom = useCallback(
    (datasourceId) => {
      if (socket && rooms.has(datasourceId)) {
        socket.emit("leaveRoom", datasourceId);
        setRooms((prevRooms) => {
          const newRooms = new Set(prevRooms);
          newRooms.delete(datasourceId);
          return newRooms;
        });
        // console.log(`Left room for datasource: ${datasourceId}`);
      }
    },
    [socket, rooms]
  );

  const listenDatasource = useCallback(
    (callback) => {
      if (socket) {
        // Nettoyer l'ancien listener avant d'en ajouter un nouveau
        socket.off("dataUpdate");

        socket.on("dataUpdate", (data) => {
          // console.log("Received data update:", data);
          try {
            const parsedData = JSON.parse(data);
            callback(parsedData);
          } catch (error) {
            // console.error("Error parsing data:", error);
            callback(data);
          }
        });

        // Retourner une fonction de nettoyage
        return () => {
          socket.off("dataUpdate");
        };
      }
    },
    [socket]
  );

  return (
    <SocketContext.Provider
      value={{
        socket,
        isConnected,
        rooms: Array.from(rooms),
        joinRoom,
        leaveRoom,
        listenDatasource,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error(
      "useSocket doit être utilisé à l'intérieur d'un SocketProvider"
    );
  }
  return context;
};
