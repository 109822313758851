import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actionGetPeriodByUserH } from "../../redux/actions/periodAction";
import Select from "react-select";
const { RangePicker } = DatePicker;

export default function CalendarView({
  value,
  interval,
  onChange,
  period,
  onPeriodChange,
  selectStyle,
  calendarStyle,
}) {
  const dispatch = useDispatch();
  const periodes = useSelector((state) => state.period.periodes);

  useEffect(() => {
    const data = localStorage.getItem("data");
    const idworkspace = localStorage.getItem("idworkspace");
    if (data && idworkspace) {
      dispatch(
        actionGetPeriodByUserH(
          idworkspace,
          "Bearer " + JSON.parse(data).token,
          (status) => {
            console.log(status);
          }
        )
      );
    }
  }, [dispatch]);

  const onChangeValue = (value) => {
    if (value !== "0") {
      let a = moment();
      let b = moment();
      switch (value) {
        case "1":
          a.hours(0);
          b.hours(23);
          a.minutes(0);
          b.minutes(59);
          a.seconds(0);
          b.seconds(59);
          a.milliseconds(0);
          b.milliseconds(0);
          break;
        case "2":
          a.hours(0);
          b.hours(23);
          a.minutes(0);
          b.minutes(59);
          a.seconds(0);
          b.seconds(59);
          a.milliseconds(0);
          b.milliseconds(0);
          a.weekday(1);
          b.weekday(7);
          break;
        case "3":
          a.hours(0);
          b.hours(0);
          a.minutes(0);
          b.minutes(0);
          a.seconds(0);
          b.seconds(0);
          a.milliseconds(0);
          b.milliseconds(0);
          a.date(1);
          b.date(1);
          b.month(b.month() + 1);
          b.dayOfYear(b.dayOfYear() - 1);
          break;
        case "4":
          a.hours(0);
          b.hours(0);
          a.minutes(0);
          b.minutes(0);
          a.seconds(0);
          b.seconds(0);
          a.milliseconds(0);
          b.milliseconds(0);
          // a.date(1);
          // b.date(1);
          // a.month(a.month() - 2);
          // b.month(b.month() + 1);
          if (a.quarter() === 1) {
            a.month(0);
            b.month(2);
            a.date(1);
            b.date(31);
          } else if (a.quarter() === 2) {
            a.month(3);
            b.month(5);
            a.date(1);
            b.date(30);
          } else if (a.quarter() === 3) {
            a.month(6);
            b.month(8);
            a.date(1);
            b.date(30);
          } else {
            a.month(9);
            b.month(11);
            a.date(1);
            b.date(31);
          }
          break;
        case "5":
          a.hours(0);
          b.hours(0);
          a.minutes(0);
          b.minutes(0);
          a.seconds(0);
          b.seconds(0);
          a.milliseconds(0);
          b.milliseconds(0);
          a.date(1);
          b.date(1);
          if (a.month() > 5) {
            a.month(6);
            b.month(11);
            b.date(31);
          } else {
            a.month(0);
            b.month(6);
            b.dayOfYear(b.dayOfYear() - 1);
          }
          // a.month(a.month() - 5);
          // b.month(b.month() + 1);
          break;
        case "6":
          a.hours(0);
          b.hours(0);
          a.minutes(0);
          b.minutes(0);
          a.seconds(0);
          b.seconds(0);
          a.milliseconds(0);
          b.milliseconds(0);
          a.date(1);
          a.month(0);
          b.month(11);
          b.date(31);
          break;
        default:
          a = moment();
          b = moment();
          break;
      }
      if (onChange) {
        onChange([a, b], value);
      }
    } else {
      if (onChange) {
        onChange([null, null], "0");
      }
    }
  };

  const onChangeInterval = (interval, dateStrings, info) => {
    onPeriodChange && onPeriodChange(null);
    if (interval) {
      const [debut, fin] = interval;
      if (onChange) {
        onChange([debut, fin], "0", null);
      }
    } else {
      if (onChange) {
        onChange([null, null], "0", null);
      }
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F3F5F8", // bg-red-600
      border: "none",
      boxShadow: "none",
      outline: "none",
      padding: "0",
      fontWeight: "600",
      fontFamily: "Poppins",
      fontSize: "16 px", // tailwind equivalent of small font
      minHeight: "20px",
      minWidth: "130px",
      cursor: "pointer",
      padding: 0,
      margin: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "5px",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Poppins", // tailwind equivalent of small font
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      // color: "#ffffff", fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "15px", // tailwind equivalent of small font
    }),
    singleValue: (provided) => ({
      ...provided,
      // color: "#ffffff", // text color inside the select fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "15px", // tailwind equivalent of small font
    }),
  };
  const items = [
    { label: "Personnalisée", value: "0" },
    { label: "Aujourd'hui", value: "1" },
    { label: "Cette semaine", value: "2" },
    { label: "Ce mois", value: "3" },
    { label: "Ce trimestre", value: "4" },
    { label: "Ce semestre", value: "5" },
    { label: "Cette année", value: "6" },
    ...periodes?.map((item) => ({
      value: `id-${item.idperiod}`,
      label: item.periodName,
    })),
  ];
  return (
    <div className="flex items-center justify-center gap-3 ">
      <Select
        defaultValue={"0"}
        style={{ width: 200 }}
        styles={customStyles}
        value={
          period
            ? items.find((item) => item.value === `id-${period}`)
            : items.find((item) => item.value === value)
        }
        onChange={({ value, label }) => {
          const values = value.split("-");
          if (values.length > 1) {
            onPeriodChange && onPeriodChange(values[1]);
            const period = periodes.find(
              (item) => +item.idperiod === +values[1]
            );
            if (period) {
              onChange(
                [
                  moment(new Date(period.startdate)),
                  moment(new Date(period.enddate)),
                ],
                null,
                values[1]
              );
            }
          } else {
            onPeriodChange && onPeriodChange(null);
            onChangeValue(value);
          }
        }}
        options={items}
      />

      <RangePicker
        className="border-none outline-none"
        placeholder={["Du", "Au"]}
        value={interval}
        onCalendarChange={onChangeInterval}
        style={calendarStyle || { width: 250 }}
        format={"DD-MM-YYYY"}
      />
    </div>
  );
}
