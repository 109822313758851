import { actionSetCompanies } from "./companyAction";
import host from "./host";
export const ACTION_SET_USER = "ACTION_SET_USER";
export const ACTION_USER_IS_GET = "ACTION_USER_IS_GET";
export const ACTION_USER_IS_UPDATE = "ACTION_USER_IS_UPDATE";
export const ACTION_USER_IS_DELETE = "ACTION_USER_IS_DELETE";
export const ACTION_SET_LOADING = "ACTION_SET_LOADING";
export const ACTION_SET_TOKEN = "ACTION_SET_TOKEN";
export const ACTION_SET_WORKSPACE = "ACTION_SET_WORKSPACE";

// /** --------- Begin actions -------- */

export const actionUserIsGet = (payload) => {
  return { type: ACTION_USER_IS_GET, payload };
};

export const actionUserIsUpdate = (payload) => {
  return { type: ACTION_USER_IS_UPDATE, payload };
};

export const actionUserIsDelete = (payload) => {
  return { type: ACTION_USER_IS_DELETE, payload };
};

export const actionSetUser = (payload) => {
  return { type: ACTION_SET_USER, payload };
};

export const actionSetLoading = (payload) => {
  return { type: ACTION_SET_LOADING, payload };
};

export const actionSetToken = (payload) => {
  return { type: ACTION_SET_TOKEN, payload };
};

export const actionSetWorkpsace = (payload) => {
  return { type: ACTION_SET_WORKSPACE, payload };
};

// /** --------- End actions -------- */

// /** --------- Begin -------- */

export const actionLogin = (email, password, callback) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      email,
      password,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/auth/login`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        callback(result);
        const { data } = result;
        if (result.status) {
          localStorage.setItem("data", JSON.stringify(result.data));

          dispatch(
            actionSetUser({
              id: data.userId,
              email: data.email,
              idcompany: data.idcompany,
              role: data.role,
              token: `Bearer ${data.token}`,
              lastName: data.lastname,
              firstName: data.firstname,
              companies: data.companies,
            })
          );
          dispatch(actionSetCompanies(data.companies));
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};

export const actionRegister = (
  firstname,
  lastname,
  email,
  password,
  type,
  entreprise,
  siret,
  callback
) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      email,
      firstname,
      lastname,
      email,
      password,
      type,
      companyName: entreprise,
      siret,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/auth/signup`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        callback(result);
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};
export const actionConfirmOtp = (otp, email, callback) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      otp,
      email,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/auth/activate`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log(result);
        callback(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const actionGetUser = (id, token) => {
  return (dispatch) => {
    dispatch(actionUserIsGet(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/users/${id}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data } = result;
        if (result.status) {
          dispatch(
            actionSetUser({
              id: data.userId,
              email: data.email,
              idcompany: data.idcompany,
              role: data.role,
              lastName: data.lastname,
              firstName: data.firstname,
              companies: data.companies,
            })
          );
          dispatch(actionSetCompanies(data.companies));
        }
      })
      .catch((/* error */) => {
        /* console.log("error", error) */
      })
      .then(() => dispatch(actionUserIsGet(false)));
  };
};

export const actionUpdateUser = (user, token, callback) => {
  return (dispatch) => {
    dispatch(actionUserIsUpdate(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      telephone: user.telephone,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/users/update/${user.id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(400);
          return null;
        }
        callback(200);
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        if (result) {
          dispatch(actionUserIsUpdate(false));
          dispatch(actionSetUser(user));
          // message.success(
          //   this.props.t("Modifications réalisées avec succés")
          // );
        } else {
          // message.error(this.props.t(" bad_insert"));
        }
      })
      .catch((/* error */) => {
        // console.log("error", error);
        // message.error(this.props.t(" bad_insert"));
      })
      .then(() => {
        dispatch(actionUserIsUpdate(false));
      });
  };
};

export const actionDeleteUser = (id, token) => {
  return (dispatch) => {};
};

export const actionForgetPassword = (email, callback) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      email,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/users/email-reset`, requestOptions)
      .then((response) => {
        if (response.status === 404) {
          return 404;
        }
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result === 404) {
          callback(404);
          return null;
        }
        if (result) {
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};

export const actionChangePassword = (password, token, callback) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      newPassword: password,
      token,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/users/reset`, requestOptions)
      .then((response) => {
        if (response.status === 400 || response.status === 401) {
          return 401;
        }
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result === 401) {
          callback(401);
          return null;
        }
        if (result) {
          localStorage.setItem("data", JSON.stringify(result.data));
          const { data } = result;
          dispatch(
            actionSetUser({
              id: data.userId,
              email: data.email,
              idcompany: data.idcompany,
              role: data.role,
              token: `Bearer ${data.token}`,
              lastName: data.lastname,
              firstName: data.firstname,
              companies: data.companies,
            })
          );
          dispatch(actionSetCompanies(data.companies));
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};
export const actionChangePassword2 = (
  password,
  newpassword,
  id,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));
    console.log(id);
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      oldPassword: password,
      newPassword: newpassword,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/auth/change-password/${id}`, requestOptions)
      .then((response) => {
        if (response.status === 400 || response.status === 401) {
          return 401;
        }
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result === 401) {
          callback(401);
          return null;
        }
        if (result) {
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};

export const actionAcceptInvitation = (token, callback) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({ token });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/accepte-invitation`, requestOptions)
      .then((response) => {
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          localStorage.setItem("data", JSON.stringify(result.data));
          const data = result.data;
          sessionStorage.setItem("dashboard", JSON.stringify(result.dashboard));
          localStorage.setItem(
            "idworkspace",
            JSON.stringify(result.data.idworkspace)
          );
          dispatch(
            actionSetUser({
              id: data.userId,
              email: data.email,
              idcompany: data.idcompany,
              role: data.role,
              token: `Bearer ${data.token}`,
              lastName: data.lastname,
              firstName: data.firstname,
              companies: data.companies,
            })
          );
          dispatch(actionSetCompanies(data.companies));
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};

export const actionAcceptInvitationNew = (
  firstName,
  lastName,
  password,
  telephone,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      firstName,
      lastName,
      password,
      telephone,
      token,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/accepte-invitation-new`, requestOptions)
      .then((response) => {
        if (response.status == 409) {
          return 409;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result === 409) {
          callback(409);
          return null;
        }

        if (result) {
          localStorage.setItem("data", JSON.stringify(result.data));
          sessionStorage.setItem("dashboard", JSON.stringify(result.dashboard));
          dispatch(
            actionSetUser({
              id: result.data.userId,
              token: `Bearer ${result.data.token}`,
              email: result.data.email,
            })
          );
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};

export const actionAcceptInvitationTeam = (
  firstName,
  lastName,
  password,
  telephone,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      firstName,
      lastName,
      password,
      telephone,
      token,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/team/accepte-invitation`, requestOptions)
      .then((response) => {
        if (response.status === 409) {
          return 409;
        }
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result === 409) {
          callback(409);
          return null;
        }

        if (result) {
          localStorage.setItem("data", JSON.stringify(result.data));
          dispatch(
            actionSetUser({
              id: result.data.userId,
              token: `Bearer ${result.data.token}`,
              email: result.data.email,
            })
          );
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};

export const actionAcceptInvitationViewOnly = (token, callback) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      token,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/view-dashboard`, requestOptions)
      .then((response) => {
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          sessionStorage.setItem("dashboard", JSON.stringify(result.dashboard));
          sessionStorage.setItem(
            "token",
            JSON.stringify({ token: "Bearer " + token })
          );
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => callback(400))
      .then(() => {
        dispatch(actionSetLoading(false));
      });
  };
};

export const actionAcceptInvitationLogin = (
  email,
  password,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      email,
      password,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/auth/login`, requestOptions)
      .then((response) => {
        if (response.status == 400 || response.status == 401) {
          return 401;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result === 401) {
          dispatch(actionSetLoading(false));
          callback(401);
          return null;
        }
        if (result) {
          localStorage.setItem("data", JSON.stringify(result));
          dispatch(
            actionSetUser({
              id: result.userId,
              token: `Bearer ${result.token}`,
              email: result.email,
            })
          );

          let headers = new Headers();
          headers.append("Content-Type", "application/json");

          let data = JSON.stringify({
            iduser: result.userId,
            token,
          });

          let options = {
            method: "POST",
            headers: headers,
            body: data,
            redirect: "follow",
          };

          fetch(`${host}/dashboard/accepte-invitation-link`, options)
            .then((response) => {
              if (response.status != 200 && response.status != 201) {
                return null;
              }
              return response.json();
            })
            .then((result) => {
              if (result) {
                sessionStorage.setItem(
                  "dashboard",
                  JSON.stringify(result.dashboard)
                );
                callback(200);
              } else {
                callback(400);
              }
            })
            .catch(() => callback(400))
            .then(() => {
              dispatch(actionSetLoading(false));
            });
        } else {
          dispatch(actionSetLoading(false));
          callback(400);
        }
      })
      .catch(() => {
        dispatch(actionSetLoading(false));
        callback(400);
      });
  };
};

export const actionAcceptInvitationRegister = (
  email,
  firstName,
  lastName,
  password,
  telephone,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionSetLoading(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      email,
      firstName,
      lastName,
      password,
      telephone,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/users/register`, requestOptions)
      .then((response) => {
        if (response.status == 409) {
          return 409;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result === 409) {
          dispatch(actionSetLoading(false));
          callback(409);
          return null;
        }

        if (result) {
          localStorage.setItem("data", JSON.stringify(result.data));
          dispatch(
            actionSetUser({
              id: result.data.userId,
              token: `Bearer ${result.data.token}`,
              email: result.data.email,
            })
          );

          let headers = new Headers();
          headers.append("Content-Type", "application/json");

          let data = JSON.stringify({
            iduser: result.data.userId,
            token,
          });

          let options = {
            method: "POST",
            headers: headers,
            body: data,
            redirect: "follow",
          };

          fetch(`${host}/dashboard/accepte-invitation-link`, options)
            .then((response) => {
              if (response.status != 200 && response.status != 201) {
                return null;
              }
              return response.json();
            })
            .then((result) => {
              if (result) {
                sessionStorage.setItem(
                  "dashboard",
                  JSON.stringify(result.dashboard)
                );
                callback(200);
              } else {
                callback(400);
              }
            })
            .catch(() => callback(400))
            .then(() => {
              dispatch(actionSetLoading(false));
            });
        } else {
          dispatch(actionSetLoading(false));
          callback(400);
        }
      })
      .catch(() => {
        dispatch(actionSetLoading(false));
        callback(400);
      });
  };
};

// /** --------- End -------- */
