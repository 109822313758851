import {
  ACTION_SET_DASHBOARD,
  ACTION_SET_DASHBOARDS,
  ACTION_IS_GET_DASHBOARD,
  ACTION_IS_UPDATE_DASHBOARD,
  ACTION_IS_DELETE_DAHSBOARD,
  ACTION_ADD_DASHBOARD,
  ACTION_REMOVE_DASHBOARD,
  ACTION_IS_ADD_DAHSBOARD,
  ACTION_SET_MANAGER_DAHSBOARD,
  ACTION_SET_ROLES_DAHSBOARD,
  ACTION_GET_ONE_DASHBOARD,
} from "../actions/dashboardAction";

const initialStateUser = {
  id: null,
  dashboard: null,
  manager: null,
  dashboards: [],
  isDelete: false,
  isUpdate: false,
  isGet: false,
  isAdd: false,
  isViewOnly: true,
  isOwner: false,
};

const dashboardReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case ACTION_SET_MANAGER_DAHSBOARD: {
      return {
        ...state,
        manager: action.payload,
      };
    }

    case ACTION_GET_ONE_DASHBOARD: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }

    case ACTION_SET_DASHBOARD: {
      // console.log(action.payload);
      return {
        ...state,
        dashboard: action.payload,
      };
    }

    case ACTION_REMOVE_DASHBOARD: {
      const dashboards = [...state.dashboards];
      for (let i = 0; i < dashboards.length; i++) {
        const element = dashboards[i];
        if (parseInt(element.iddashboard) === parseInt(action.payload)) {
          dashboards.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        dashboards: [...dashboards],
      };
    }

    case ACTION_ADD_DASHBOARD: {
      const dashboards = [...state.dashboards];
      console.log(action.payload);
      console.log(dashboards);
      dashboards.push(action.payload);
      return {
        ...state,
        dashboards: [...dashboards],
      };
    }

    case ACTION_SET_DASHBOARDS: {
      if (action.payload.type === "one") {
        const dashboards = [...state.dashboards];
        for (let i = 0; i < dashboards.length; i++) {
          const element = dashboards[i];
          if (parseInt(element.iddashboard) === parseInt(action.payload.id)) {
            dashboards[i].dashboard.name = action.payload.name;
            break;
          }
        }
        return {
          ...state,
          dashboards: [...dashboards],
        };
      } else if (action.payload.type === "update") {
        const dashboard1 = state.dashboard;
        for (let index = 0; index < dashboard1.userDashboards.length; index++) {
          const element = dashboard1.userDashboards[index];
          if (parseInt(element.iduser) === parseInt(action.payload.iduser)) {
            dashboard1.userDashboards[index].role = action.payload.data.role;
            break;
          }
        }
        return { ...state, dashboard: dashboard1 };
      } else if (action.payload.type === "delete") {
        const dashboard1 = state.dashboard;
        for (let index = 0; index < dashboard1.userDashboards.length; index++) {
          const element = dashboard1.userDashboards[index];
          if (parseInt(element.iduser) === parseInt(action.payload.iduser)) {
            dashboard1.userDashboards.splice(index, 1);
            break;
          }
        }
        return { ...state, dashboard: dashboard1 };
      } else if (action.payload.type === "deleteuserlistDashboard") {
        const dashboard1 = state.dashboard;
        for (let index = 0; index < dashboard1.userDashboards.length; index++) {
          const element = dashboard1.userDashboards[index];
          for (let i = 0; i < element.userDashboardLists.length; i++) {
            const list = element.userDashboardLists[i];
            if (
              list.iduserdashboard === action.payload.iduserdashboard &&
              list.idlist == action.payload.idlist
            ) {
              dashboard1.userDashboards[index].userDashboardLists.splice(i, 1);
            }
          }
        }
        return { ...state, dashboard: dashboard1 };
      } else if (action.payload.type === "deleteteamdashboardlist") {
        const dashboard1 = state.dashboard;
        for (let index = 0; index < dashboard1.teamDashboards.length; index++) {
          const element = dashboard1.teamDashboards[index];
          for (let i = 0; i < element.teamDashboardLists.length; i++) {
            const list = element.teamDashboardLists[i];
            if (
              list.idteamdashboard === action.payload.idteamdashboard &&
              list.idlist == action.payload.idlist
            ) {
              dashboard1.teamDashboards[index].teamDashboardLists.splice(i, 1);
            }
          }
        }
        return { ...state, dashboard: dashboard1 };
      } else if (action.payload.type === "updateteamdashboard") {
        const dashboard1 = state.dashboard;
        for (let index = 0; index < dashboard1.teamDashboards.length; index++) {
          const element = dashboard1.teamDashboards[index];
          if (parseInt(element.idteam) === parseInt(action.payload.idteam)) {
            dashboard1.teamDashboards[index].role = action.payload.data.role;
            break;
          }
        }
        return { ...state, dashboard: dashboard1 };
      } else if (action.payload.type === "deleteteamdashboard") {
        const dashboard1 = state.dashboard;
        console.log(dashboard1);
        for (let index = 0; index < dashboard1.teamDashboards.length; index++) {
          const element = dashboard1.teamDashboards[index];
          if (parseInt(element.idteam) === parseInt(action.payload.idteam)) {
            dashboard1.teamDashboards.splice(index, 1);
            break;
          }
        }
        return { ...state, dashboard: dashboard1 };
      } else {
        return {
          ...state,
          dashboards: [...action.payload.data],
        };
      }
    }

    case ACTION_IS_GET_DASHBOARD: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    case ACTION_IS_ADD_DAHSBOARD: {
      return {
        ...state,
        isAdd: action.payload,
      };
    }

    case ACTION_IS_UPDATE_DASHBOARD: {
      return {
        ...state,
        isUpdate: action.payload,
      };
    }

    case ACTION_IS_DELETE_DAHSBOARD: {
      return {
        ...state,
        isDelete: action.payload,
      };
    }

    case ACTION_SET_ROLES_DAHSBOARD: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
