import { Popover } from "antd";

const truncateText = (text = "", isSelected = false, maxLength = 30) => {
  // if (text?.length <= maxLength) {
  //   return text;
  // }
  // return isSelected ? (
  //   text.substr(0, maxLength) + "..."
  // ) : (
  //   <Popover content={text}>{text.substr(0, maxLength) + "..."}</Popover>
  // );
  return text;
};

export { truncateText };
