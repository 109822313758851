import {
  ACTION_SET_USER,
  ACTION_USER_IS_GET,
  ACTION_USER_IS_UPDATE,
  ACTION_USER_IS_DELETE,
  ACTION_SET_LOADING,
  ACTION_SET_TOKEN,
  ACTION_SET_WORKSPACE,
} from "../actions/UserAction";

const initialStateUser = {
  id: null,
  email: null,
  idcompany: null,
  role: null,
  firstName: null,
  lastName: null,
  selectedWorkspace: null,
  companies: [],

  token: null,
  telephone: null,
  createdAt: null,
  updatedAt: null,
  userDashboards: [],
  userLists: [],
  userindicators: [],
  goalusers: [],
  datasources: [],
  parametragenotifications: [],
  commentaires: [],
  company: null,
  isDelete: false,
  isUpdate: false,
  isGet: false,
  loading: false,
};

const userReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case ACTION_SET_USER:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION_SET_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: action.payload,
      };

    case ACTION_USER_IS_GET:
      return {
        ...state,
        isGet: action.payload,
      };

    case ACTION_USER_IS_UPDATE:
      return {
        ...state,
        isUpdate: action.payload,
      };

    case ACTION_USER_IS_DELETE:
      return {
        ...state,
        isDelete: action.payload,
      };

    case ACTION_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ACTION_SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
