import {
  ACTION_ADD_CONDITION,
  ACTION_IS_ADD_CONDITION,
  ACTION_IS_UPDATE_CONDITION,
  ACTION_SET_ALL_CONDITION,
  ACTION_REMOVE_CONDITION,
  ACTION_IS_DELETE_CONDITION,
  ACTION_UPDATE_CONDITION,
} from "../actions/ConditionAction";

const initialState = {
  conditions: [],
  isAddCondition: false,
  isUpdateCondition: false,
  isDeleteCondition: false,
};

const conditionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_IS_ADD_CONDITION: {
      return {
        ...state,
        isAddCondition: action.payload,
      };
    }

    case ACTION_UPDATE_CONDITION: {
      const conditions = [...state.conditions];

      for (let i = 0; i < conditions.length; i++) {
        const item = conditions[i];
        if (+item.id === +action.payload.id) {
          conditions[i] = action.payload;
        }
      }

      return {
        ...state,
        conditions: [...conditions],
      };
    }
    case ACTION_ADD_CONDITION: {
      return {
        ...state,
        conditions: [...state.conditions, action.payload],
      };
    }

    case ACTION_REMOVE_CONDITION: {
      const conditions = [...state.conditions];

      for (let i = 0; i < conditions.length; i++) {
        const item = conditions[i];
        if (+item.id === +action.payload) {
          conditions.splice(i, 1);
        }
      }

      return {
        ...state,
        conditions: [...conditions],
      };
    }

    case ACTION_IS_UPDATE_CONDITION: {
      return {
        ...state,
        isUpdatePeriod: action.payload,
      };
    }

    case ACTION_IS_DELETE_CONDITION: {
      return {
        ...state,
        isDeleteCondition: action.payload,
      };
    }

    case ACTION_SET_ALL_CONDITION: {
      return {
        ...state,
        conditions: action.payload,
      };
    }

    default:
      return state;
  }
};

export default conditionReducer;
