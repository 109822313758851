import {
  ACTION_SET_COMPANIES,
  ACTION_GET_USERS,
  ACTION_IS_GET_USERS,
  ACTION_IS_SEND_INVITATION,
  ACTION_CHANGE_ROLE,
  ACTION_IS_CHANGE_ROLE,
  ACTION_IS_REMOVE_USER,
  ACTION_REMOVE_USER,
} from "../actions/companyAction";

const initialState = {
  companies: [],
  userCompanies: [],
  isGet: false,
  isSendInvitation: false,
  isChangeRole: false,
  isRemoveUser: false,
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_IS_GET_USERS: {
      return {
        ...state,
        isGet: action.payload,
      };
    }
    case ACTION_IS_SEND_INVITATION: {
      return {
        ...state,
        isSendInvitation: action.payload,
      };
    }
    case ACTION_IS_CHANGE_ROLE: {
      return {
        ...state,
        isChangeRole: action.payload,
      };
    }
    case ACTION_IS_REMOVE_USER: {
      return {
        ...state,
        isRemoveUser: action.payload,
      };
    }
    case ACTION_CHANGE_ROLE: {
      const userCompanies = [...state.userCompanies];
      for (let i = 0; i < userCompanies.length; i++) {
        const element = userCompanies[i];
        if (element.id === action.payload.id) {
          userCompanies[i].role = action.payload.role;
        }
      }
      return {
        ...state,
        userCompanies,
      };
    }
    case ACTION_REMOVE_USER: {
      const id = action.payload;
      const userCompanies = [...state.userCompanies];
      for (let i = 0; i < userCompanies.length; i++) {
        const element = userCompanies[i];
        if (element.id === id) {
          userCompanies.splice(i, 1);
        }
      }
      return {
        ...state,
        userCompanies,
      };
    }
    case ACTION_SET_COMPANIES: {
      return {
        ...state,
        companies: [...action.payload],
      };
    }
    case ACTION_GET_USERS: {
      return {
        ...state,
        userCompanies: [...action.payload],
      };
    }

    default:
      return state;
  }
};

export default companiesReducer;
