import host from "./host";
import { actionGetUser, actionSetUser } from "./UserAction";

export const ACTION_SET_COMPANIES = "SET_COMPANIES";

export const ACTION_GET_USERS = "GET_USERS";

export const ACTION_IS_GET_USERS = "ACTION_IS_GET_USERS";

export const ACTION_IS_SEND_INVITATION = "ACTION_IS_SEND_INVITATION";

export const ACTION_IS_CHANGE_ROLE = "ACTION_IS_CHANGE_ROLE";

export const ACTION_CHANGE_ROLE = "ACTION_CHANGE_ROLE";

export const ACTION_IS_REMOVE_USER = "ACTION_IS_REMOVE_USER";

export const ACTION_REMOVE_USER = "ACTION_REMOVE_USER";

// export const ACTION_CHANGE_DEFAULT = "ACTION_CHANGE_DEFAULT";

// export const actionChangeDefault = (payload) => ({
//   type: ACTION_CHANGE_DEFAULT,
//   payload: payload,
// })

export const actionIsGetUsers = (payload) => ({
  type: ACTION_IS_GET_USERS,
  payload: payload,
});
export const actionIsSendInvitation = (payload) => ({
  type: ACTION_IS_SEND_INVITATION,
  payload: payload,
});

export const actionIsChangeRole = (payload) => ({
  type: ACTION_IS_CHANGE_ROLE,
  payload: payload,
});

export const actionChangeRole = (payload) => ({
  type: ACTION_CHANGE_ROLE,
  payload: payload,
});

export const actionIsRemoveUser = (payload) => ({
  type: ACTION_IS_REMOVE_USER,
  payload: payload,
});

export const actionRemoveUser = (payload) => ({
  type: ACTION_REMOVE_USER,
  payload: payload,
});

// /** --------- Begin actions -------- */
export const actionGetUsers = (payload) => ({
  type: ACTION_GET_USERS,
  payload: payload,
});

export const actionSetCompanies = (payload) => {
  return { type: ACTION_SET_COMPANIES, payload };
};

export const actionGetUsersByCompany = (token, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetUsers(true));
    const myHeaders = new Headers();
    console.log(token);
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/company/getUsers`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        callback(result);
        if (status) {
          dispatch(actionGetUsers(data));
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actionIsGetUsers(false)));
  };
};

export const actionSendInvitationCompany = (
  email,
  role,
  idcompany,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsSendInvitation(true));
    const myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const emails = [{ email, role }];

    const raw = JSON.stringify({ emails, idcompany });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/company/send-invitation`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        callback(result);
        if (status) {
          console.log(result);
          // dispatch(actionGetUsers(data));
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actionIsSendInvitation(false)));
  };
};

export const actionChangeRoleCompany = (id, role, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsChangeRole(true));
    const myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ id, role });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/company/change-user-role`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        callback(result);
        if (status) {
          console.log(result);
          dispatch(actionChangeRole(data));
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actionIsChangeRole(false)));
  };
};
export const actionRemoveUserCompany = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsRemoveUser(true));
    const myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/company/remove-user/${id}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        callback(result);
        if (status) {
          dispatch(actionRemoveUser(id));
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actionIsRemoveUser(false)));
  };
};

export const actionChangeDefaultCompany = (id, idUser, token, callback) => {
  return (dispatch) => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ idcompany: id });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${host}/company/change-company`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        if (status) {
          // dispatch(actionSetUser({ idcompany: data.idcompany }));

          dispatch(actionGetUser(idUser, token));
          callback(result);
          // dispatch(actionChangeDefault(data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
