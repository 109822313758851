import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RadioButton({
  options,
  onChange,
  defaultValue,
  value,
  setValue,
  ...props
}) {
  const { t } = useTranslation();
  // const [value, setValue] = useState(defaultValue);

  return (
    <>
      <div className="options" {...props}>
        <ul className="flex gap-2">
          {options.map((option, index) => {
            return (
              <li key={index}>
                <Link
                  // className={option.value === value ? "h-actived " : ""}
                  className={`p-2 border border-gray-300 rounded-lg text-gray-600 text-center font-poppins font-[300px] bg-gray-100 shadow-sm cursor-pointer ${
                    value === option.value ? "h-actived" : ""
                  }`}
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (option.value !== value) {
                      setValue(option.value);
                      if (onChange) {
                        onChange(option.value);
                      }
                    }
                  }}
                >
                  {option.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
