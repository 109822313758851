import React, { useEffect, useLayoutEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Board from "./components/Board";
import DataSource from "./components/DataSource";
import Goals from "./components/Goals";
import Connectors from "./components/Connectors";
import ForgetPassword from "./components/ForgetPassword";
import Home from "./components/Home";
import Indicator from "./components/Indicator";
import Login from "./components/Login";
import Periode from "./components/Periode";
import Rapport from "./components/Rapport";
import Equipes from "./components/Equipes";
import Primes from "./components/Primes";
import Conditions from "./components/Conditions";
import Profile from "./components/Profile";
import Register from "./components/Register";
import Team from "./components/Team";
import Reset from "./components/Reset";
import Invited from "./components/Invited";
import InvitedNew from "./components/InvitedNew";
import InvitedLink from "./components/InvitedLink";
import { useTranslation } from "react-i18next";
import NotFound from "./components/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { actionSetUser } from "./redux/actions/UserAction";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { InvitedOnlyView } from "./components/InvitedOnlyView";
import Indicators from "./components/Indicators";
import Workspaces from "./components/workSpaces";
import Colonnes from "./components/Colonnes";
import Settings from "./components/Settings";
import Otp from "./components/Otp";
import { actionSetConnectors } from "./redux/actions/connectorAction";
import { actionSetIndicator } from "./redux/actions/indicatorAction";
import { actionSetAllCondition } from "./redux/actions/ConditionAction";
import InvitedCompany from "./components/InvitedCompany";
import InvitedFailed from "./components/InvitedFailed";
import InvitedSuccess from "./components/InvitedSuccess";
import InvitedWorkspace from "./components/InvitedWorkspace";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

function App() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const roleCompany = useSelector((state) => state.user.role);
  // console.log(roleCompany);
  // const idcompany = useSelector((state) => state.user.idcompany);
  // const name = useSelector((state) => state.user);
  // console.log({ roleCompany, idcompany, name });
  // console.log("Testing ------->>");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const lang = window.localStorage.getItem("lang");
  const selectedWorkSpace = useSelector(
    (state) => state.user.selectedWorkSpace
  );
  if (lang) {
    i18n.changeLanguage(lang);
  }

  useLayoutEffect(() => {
    theme === "0"
      ? (document.getElementById("myBody").style.backgroundColor = "#1f2128")
      : (document.getElementById("myBody").style.backgroundColor = "#f3f5f8");
  });

  const data = localStorage.getItem("data");

  const pathname = window.location.pathname;
  if (!data) {
    if (
      pathname === "/home" ||
      pathname === "/indicators" ||
      pathname === "/profile" ||
      pathname === "/datasources" ||
      // pathname === "/rapport" ||
      // pathname === "/equipes" ||
      pathname === "/primes" ||
      pathname === "/conditions" ||
      pathname === "/colonnes" ||
      pathname === "/workspaces"
    ) {
      window.location.replace("/");
    }
  } else {
    const d = JSON.parse(data);
    const idworkspace = localStorage.getItem("idworkspace");
    // if (defaultLink && pathname !== `/${defaultLink}`) {
    //   window.location.replace(defaultLink ? `/${defaultLink}` : "/home");
    // } else
    if (pathname === "/") {
      window.location.replace("/home");
    }

    dispatch(
      actionSetUser({
        id: d.userId,
        email: d.email,
        idcompany: d.idcompany,
        role: d.role,
        token: `Bearer ${d.token}`,
        lastName: d.lastname,
        firstName: d.firstname,
        companies: d.companies,
        selectedWorkspace: idworkspace,
      })
    );
  }

  useEffect(() => {
    let key = null;
    window.addEventListener("offline", () => {
      key = enqueueSnackbar("Vous êtes hors ligne", {
        variant: "error",
        persist: true,
      });
    });

    window.addEventListener("online", () => {
      if (key !== null) {
        closeSnackbar(key);
        key = null;
      }
      enqueueSnackbar("Vous êtes en ligne", {
        variant: "success",
      });
    });

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (selectedWorkSpace) {
      localStorage.setItem("idworkspace", selectedWorkSpace);
      dispatch(actionSetAllCondition([]));
    }
  }, [selectedWorkSpace]);

  return (
    <div className="w-screen h-screen overflow-hidden">
      {loading ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden"></span>
          </Spinner>
        </div>
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path="/team" element={<Team />} /> */}
            <Route path="/register" element={<Register />} />
            <Route path="/board" element={<Board />} />
            <Route path="/invited" element={<Invited />} />
            <Route path="/invited-new" element={<InvitedNew />} />
            <Route path="/invited-failed" element={<InvitedFailed />} />
            <Route path="/invited-success" element={<InvitedSuccess />} />
            <Route path="/invited-company" element={<InvitedCompany />} />
            <Route path="/invited-workspace" element={<InvitedWorkspace />} />
            <Route path="/invited-link" element={<InvitedLink />} />

            <Route path="/datasources" element={<DataSource />} />

            {/* <Route path="/connectors" element={<Connectors />} /> */}
            {/* <Route path="/goals" element={<Goals />} /> */}
            {/* <Route path="/indicators" element={<Indicators />} /> */}
            {/* <Route path="/colonnes" element={<Colonnes />} /> */}
            {/* <Route path="/conditions" element={<Conditions />} /> */}
            <Route path="/invited-only-view" element={<InvitedOnlyView />} />
            {/* <Route path="/rapport" element={<Rapport />} /> */}
            {/* <Route path="/equipes" element={<Equipes />} /> */}
            {/* <Route path="/primes" element={<Primes />} /> */}
            {/* <Route path="/periode" element={<Periode />} /> */}
            <Route path="/indicator" element={<Indicator />} />
            <Route path="/reset" exact element={<Reset />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/workspaces" element={<Workspaces />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
