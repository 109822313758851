import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Chart } from "react-google-charts";
import FilterView from "./FilterView";
import RadioButton from "./RadioButton";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Popover, Checkbox } from "antd";
import {
  actionGetOptions,
  actionSetIndicatorData,
  actionGetDataByFilter,
} from "../../redux/actions/indicatorAction";
import moment from "moment";
import CustomSelect from "./CustomSelect";
import Map from "./Map";
import { getChartColumnsAndRows, getChartType } from "../../tools/Chart";
import CartTable from "./CartTable";
import CardBar from "./CardBar";
import CardCalendar from "./CardCalendar";
import CardLine from "./CardLine";
import CardPie from "./CardPie";
import CardBarHorizontal from "./CardBarHorizontal";
const _ = require("lodash");
const numeral = require("numeral");
const MONTHS = [
  "Janv.",
  "Févr.",
  "Mars",
  "Avr.",
  "Mai",
  "Juin",
  "Juill.",
  "Août",
  "Sept.",
  "Oct.",
  "Nov.",
  "Déc.",
];

let theme2 = "0";
const data2 = localStorage.getItem("theme");

if (data2) {
  theme2 = JSON.parse(data2).theme;
}

/**
 * Cette fonction permet de regrouper des données en jour, mois ou année selon l'option
 * @param {*} rows est un tableau contenant les données deja en format jour
 * @param {*} option définit sous quelle forme doit être regrouper les données  1 => jour, 2 => mois, 3 => année
 */
export const regroupe = (rows, option) => {
  if (option == "1") {
    return rows;
  }
  if (rows.length < 1) {
    return [];
  }
  let debut = rows[0][0];
  let fin = rows[rows.length - 1][0];

  if (typeof debut !== "object") {
    return [];
  }

  switch (option) {
    case "2":
      if (
        debut.getFullYear() === fin.getFullYear() &&
        debut.getMonth() === fin.getMonth()
      ) {
        let total = 0;
        for (let i = 0; i < rows.length; i++) {
          const element = rows[i];
          total += element[1];
        }
        return [[MONTHS[debut.getMonth()], total]];
      } else {
        let result = [];
        let is = debut.getFullYear() === fin.getFullYear();
        const years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          if (Object.hasOwnProperty.call(years, key)) {
            const year = years[key];
            const month = _.groupBy(year, (e) => e[0].getMonth());
            for (const tag in month) {
              let total = 0;
              if (Object.hasOwnProperty.call(month, tag)) {
                const days = month[tag];
                for (let i = 0; i < days.length; i++) {
                  const item = days[i];
                  total += item[1];
                }
              }
              result.push([
                is ? `${MONTHS[tag]}` : `${MONTHS[tag]} ${key}`,
                total,
              ]);
            }
          }
        }
        return result;
      }
      break;
    case "3":
      if (debut.getFullYear() === fin.getFullYear()) {
        let total = 0;
        for (let i = 0; i < rows.length; i++) {
          const element = rows[i];
          total += element[1];
        }
        return [[debut.getFullYear(), total]];
      } else {
        let result = [];
        let years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          let total = 0;
          if (Object.hasOwnProperty.call(years, key)) {
            const days = years[key];
            for (let i = 0; i < days.length; i++) {
              const item = days[i];
              total += item[1];
            }
            result.push([key, total]);
          }
        }
        return result;
      }
      break;
    case "4":
      let data = _.groupBy(rows, (item) => {
        let current = moment(item[0]);
        current = current.startOf("isoWeek");
        return current.year() + "-" + current.isoWeek();
      });
      let response = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          let total = 0;
          for (let index = 0; index < element.length; index++) {
            const item = element[index];
            total += item[1];
          }
          let first = moment(element[0][0]);
          first = first.startOf("isoWeek");

          let last = moment(element[0][0]);
          last = last.endOf("isoWeek");

          if (debut.getFullYear() === fin.getFullYear()) {
            response.push([
              `${first.date()} ${MONTHS[first.month()]} - ${last.date()} ${
                MONTHS[last.month()]
              }`,
              total,
            ]);
          } else {
            response.push([
              `${first.date()} ${
                MONTHS[first.month()]
              } ${first.year()} - ${last.date()} ${
                MONTHS[last.month()]
              } ${last.year()}`,
              total,
            ]);
          }
        }
      }
      return response;
      break;
  }
  return [];
};

export const regroupeByInterval = (rows, option) => {
  if (rows.length < 1) {
    return [];
  }
  let debut = rows[0][0];
  let fin = rows[rows.length - 1][0];

  switch (option) {
    case "2":
      if (
        debut.getFullYear() === fin.getFullYear() &&
        debut.getMonth() === fin.getMonth()
      ) {
        let total = 0;
        for (let i = 0; i < rows.length; i++) {
          const element = rows[i];
          total += element[1];
        }
        return [
          {
            month: debut.getMonth(),
            year: debut.getFullYear(),
            debut: null,
            fin: null,
          },
        ];
      } else {
        let result = [];
        let is = debut.getFullYear() === fin.getFullYear();
        const years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          if (Object.hasOwnProperty.call(years, key)) {
            const year = years[key];
            const month = _.groupBy(year, (e) => e[0].getMonth());
            for (const tag in month) {
              let total = 0;
              if (Object.hasOwnProperty.call(month, tag)) {
                const days = month[tag];
                for (let i = 0; i < days.length; i++) {
                  const item = days[i];
                  total += item[1];
                }
              }
              // result.push([
              //   is ? `${MONTHS[tag]}` : `${MONTHS[tag]} ${key}`,
              //   total,
              // ]);
              result.push({ month: tag, year: key, debut: null, fin: null });
            }
          }
        }
        return result;
      }
      break;
    case "3":
      if (debut.getFullYear() === fin.getFullYear()) {
        let total = 0;
        for (let i = 0; i < rows.length; i++) {
          const element = rows[i];
          total += element[1];
        }
        return [
          { month: null, year: debut.getFullYear(), debut: null, fin: null },
        ];
      } else {
        let result = [];
        let years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          let total = 0;
          if (Object.hasOwnProperty.call(years, key)) {
            const days = years[key];
            for (let i = 0; i < days.length; i++) {
              const item = days[i];
              total += item[1];
            }
            // result.push([key, total]);
            result.push({ month: null, year: key, debut: null, fin: null });
          }
        }
        return result;
      }
      break;
    case "4":
      let data = _.groupBy(rows, (item) => {
        let current = moment(item[0]);
        current = current.startOf("isoWeek");
        return current.year() + "-" + current.isoWeek();
      });
      let response = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          let total = 0;
          for (let index = 0; index < element.length; index++) {
            const item = element[index];
            total += item[1];
          }
          let first = moment(element[0][0]);
          first = first.startOf("isoWeek");

          let last = moment(element[0][0]);
          last = last.endOf("isoWeek");

          if (debut.getFullYear() === fin.getFullYear()) {
            // response.push([
            //   `${first.date()} ${MONTHS[first.month()]} - ${last.date()} ${
            //     MONTHS[last.month()]
            //   }`,
            //   total,
            // ]);
            response.push({
              month: null,
              year: null,
              debut: new Date(first.format("YYYY-MM-DD")).getTime(),
              fin: new Date(last.format("YYYY-MM-DD")).getTime(),
            });
          } else {
            // response.push([
            //   `${first.date()} ${
            //     MONTHS[first.month()]
            //   } ${first.year()} - ${last.date()} ${
            //     MONTHS[last.month()]
            //   } ${last.year()}`,
            //   total,
            // ]);
            response.push({
              month: null,
              year: null,
              debut: new Date(first.format("YYYY-MM-DD")).getTime(),
              fin: new Date(last.format("YYYY-MM-DD")).getTime(),
            });
          }
        }
      }
      return response;
      break;
  }
  return [];
};

const options = [
  { label: "Jour", value: "1" },
  { label: "Semaine", value: "4" },
  { label: "Mois", value: "2" },
  { label: "Année", value: "3" },
];

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}
export const numberFormat = (nbre) => {
  let response = numeral(nbre)
    .format("0,0.0")
    .split(",")
    .join(" ")
    .split(".")
    .join(",");

  if (response.split(",0").length > 1) {
    return response.split(",0")[0];
  }
  return response;
};

export default function IndicatorView({ large, viewRef }) {
  const userEmail = useSelector((state) => state.user.email);

  const interval = useSelector((state) => state.indicator.interval);
  const intervalRef = useRef();
  intervalRef.current = interval;

  const indicator = useSelector((state) => state.indicator.indicator);
  const indicatorRef = useRef();
  indicatorRef.current = indicator;

  const isGet = useSelector((state) => state.indicator.isGet);

  const filters = useSelector((state) => state.indicator.filters);
  const filtersRef = useRef();
  filtersRef.current = filters;

  const [chartRows, setChartRows] = useState([]);
  const [chartColumns, setChartColumns] = useState([]);

  const axes = useSelector((state) => state.indicator.axes);
  const axesOptions = useRef();
  axesOptions.current = axes;

  const data = useSelector((state) => state.indicator.data);
  const dataRef = useRef();
  dataRef.current = data;

  const timeInterval = useSelector((state) => state.time.interval);
  const timeValue = useSelector((state) => state.time.value);

  const [axe, setAxe] = useState(undefined);
  const [value, setValue] = useState("1");
  const [isDate, setIsDate] = useState(true);
  const [total, setTotal] = useState(0);
  const [avg, setAvg] = useState(0);
  const [counter, setCounter] = useState(0);
  const [resultat, setResultat] = useState([]);

  const [appearanceAvg, setAppearanceAvg] = useState(null);
  const [appearanceTotal, setAppearanceTotal] = useState(null);
  const [appearanceCount, setAppearanceCount] = useState(null);

  const [lastValue, setLastValue] = useState(0);

  const axeRef = useRef();
  axeRef.current = axe;

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && indicator && axe) {
      let axeColumn = null;
      let isDate = false;
      for (let i = 0; i < indicator.columns.length; i++) {
        const column = indicator.columns[i];

        if (parseInt(column.idcolumn) === parseInt(axe.value)) {
          isDate = Boolean(parseInt(column.dataType) === 3);
          axeColumn = column;
          break;
        }
      }
      const { avg, columns, counter, rows, total, resultat } =
        getChartColumnsAndRows(
          indicator,
          axeColumn,
          data.data,
          isDate && +indicator.type !== 5,
          value
        );

      setAvg(avg);
      setTotal(total);
      setCounter(counter);
      setResultat(resultat);
      setAppearanceCount(data.appearance_count);
      setAppearanceTotal(data.appearance);
      setAppearanceAvg(data.appearance_avg);

      setIsDate(isDate);

      setChartRows(rows);
      setChartColumns(columns);
    }
  }, [data, indicator, axe, value]);

  function getStyle(operation) {
    let styles = {};
    switch (+operation) {
      case 1:
        styles = appearanceTotal
          ? {
              color: appearanceTotal.color,
              fontStyle: appearanceTotal.italic ? "italic" : "",
              fontWeight: appearanceTotal.bold ? "bold" : "",
              size: appearanceTotal.bold ? appearanceTotal.bold : "20px",
              textDecoration: appearanceTotal.underline ? "underline" : "",
            }
          : {};
        break;
      case 2:
        styles = appearanceAvg
          ? {
              color: appearanceAvg.color,
              fontStyle: appearanceAvg.italic ? "italic" : "",
              fontWeight: appearanceAvg.bold ? "bold" : "",
              size: appearanceAvg.bold ? appearanceAvg.bold : "20px",
              textDecoration: appearanceAvg.underline ? "underline" : "",
            }
          : {};
        break;
      case 4:
        styles = appearanceCount
          ? {
              color: appearanceCount.color,
              fontStyle: appearanceCount.italic ? "italic" : "",
              fontWeight: appearanceCount.bold ? "bold" : "",
              size: appearanceCount.bold ? appearanceCount.bold : "20px",
              textDecoration: appearanceCount.underline ? "underline" : "",
            }
          : {};
        break;
      default:
        styles = {};
        break;
    }
    return styles;
  }

  useEffect(() => {
    if (indicator) {
      let filterItems = [];
      const optionAxes = [];

      if (Array.isArray(indicator.columns)) {
        const axesLanda = _.filter(indicator.columns, (o) => {
          return o.columnType !== "indicator";
        });

        for (let i = 0; i < indicator.columns.length; i++) {
          const element = indicator.columns[i];
          if (
            !(
              +indicator.idEmailColumn === +element.idcolumn &&
              indicator.showByEmail
            )
          ) {
            filterItems.push({
              id: element.idcolumn,
              name: element.name,
              type: parseInt(element.dataType),
              checked: element.idcolumn == indicator.idfilter ? true : false,
              indicator: Boolean(element.columnType === "indicator"),
              min: null,
              max: null,
              loading: parseInt(element.dataType) === 2 ? true : false,
              value: null,
              values: [],
              options: [],
              interval:
                element.idcolumn == indicator.idfilter
                  ? [intervalRef.current[0], intervalRef.current[1]]
                  : [null, null],
            });
          }

          if (parseInt(element.dataType) === 2) {
            dispatch(
              actionGetOptions(
                element.iddatasource,
                element.name,
                (status, result) => {
                  if (status === 200) {
                    const filterItems = [...filtersRef.current];
                    for (let j = 0; j < filterItems.length; j++) {
                      const item = filterItems[j];
                      if (parseInt(item.id) === parseInt(element.idcolumn)) {
                        filterItems[j]["options"] = result;
                        filterItems[j]["loading"] = false;
                        dispatch(
                          actionSetIndicatorData({
                            filters: [...filterItems],
                          })
                        );
                      }
                    }
                  }
                }
              )
            );
          }
        }

        for (let j = 0; j < axesLanda.length; j++) {
          const element = axesLanda[j];
          optionAxes.push({ value: element.idcolumn, label: element.name });
        }
      }

      dispatch(
        actionSetIndicatorData({
          data: indicator.data,
          axes: [...optionAxes],
          filters: filterItems,
        })
      );

      if (indicator.showperiode) {
        setValue(indicator.periode);
      }

      for (let index = 0; index < optionAxes.length; index++) {
        const element = optionAxes[index];
        if (parseInt(element.value) === parseInt(indicator.iddefault)) {
          setAxe(element);
          break;
        }
      }

      setLastValue(indicator.lastValue ? indicator.lastValue : 0);
    }
  }, [indicator]);

  const renderLastValue = () => {
    const pourcentage = ((total - lastValue) * 100) / lastValue;

    const periode = [
      "Hier",
      "La semaine dernière",
      "Le mois dernier",
      "Le trimestre dernier",
      "Le semestre dernier",
      "L'année dernière",
    ];

    let text = "";

    const nbre = parseInt(timeValue) - 1;

    if (nbre >= 0) {
      text = periode[nbre];
    } else {
      text = `du ${new Date(timeInterval[0]).toISOString().split("T")[0]} au ${
        new Date(timeInterval[1]).toISOString().split("T")[0]
      }`;
    }

    if (total > lastValue) {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="haut" src="/haut.png" height={"20px"} />
          <i
            style={{
              color: "#2ECC71",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    } else {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="bas" src="/bas.png" height={"20px"} />
          <i
            style={{
              color: "#E74C3C",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    }
  };

  const handlerChangeAxe = (axe) => {
    setAxe(axe);
    dispatch(
      actionSetIndicatorData({
        isGet: true,
      })
    );
    setTimeout(() => {
      synchronazeData();
    }, 100);
  };

  const synchronazeData = () => {
    let alphas = [];
    for (let index = 0; index < filtersRef.current.length; index++) {
      const element = filtersRef.current[index];
      if (element.checked) {
        if (element.type == "1" && (element.min || element.max)) {
          alphas.push({
            name: element.name,
            type: 1,
            class: element.indicator ? "metrics" : "dimensions",
            min: element.min,
            max: element.max,
          });
        } else if (element.type == "2" && element.values.length) {
          alphas.push({
            name: element.name,
            type: 2,
            class: "dimensions",
            values: element.values,
          });
        } else if (
          element.type == "3" &&
          (element.interval[0] || element.interval[1])
        ) {
          alphas.push({
            name: element.name,
            type: 3,
            class: "time",
            interval: [
              element.interval[0] ? new Date(element.interval[0]) : null,
              element.interval[1] ? new Date(element.interval[1]) : null,
            ],
          });
        }
      }
    }

    let axe = null;
    for (let index = 0; index < indicatorRef.current.columns.length; index++) {
      const element = indicatorRef.current.columns[index];
      if (parseInt(element.idcolumn) === parseInt(axeRef.current.value)) {
        axe = element;
        break;
      }
    }

    let emailAxe = null;
    if (
      indicatorRef.current.idEmailColumn &&
      indicatorRef.current.showByEmail
    ) {
      for (
        let index = 0;
        index < indicatorRef.current.columns.length;
        index++
      ) {
        const element = indicatorRef.current.columns[index];
        if (
          parseInt(element.idcolumn) ===
          parseInt(indicatorRef.current.idEmailColumn)
        ) {
          emailAxe = element;
          break;
        }
      }
    }

    if (emailAxe) {
      alphas.push({
        name: emailAxe.name,
        type: 2,
        class: "dimensions",
        values: [userEmail],
      });
    }

    dispatch(
      actionSetIndicatorData({
        isGet: true,
      })
    );

    dispatch(
      actionGetDataByFilter(
        +axe.iddatasource,
        indicatorRef.current.indicateurs.map((item) => item.id),
        indicatorRef.current.idindicator,
        {
          name: axe.name,
          class: +axe.dataType === 3 ? "time" : "dimensions",
        },
        alphas,
        (status, result) => {
          if (status === 200) {
            dispatch(
              actionSetIndicatorData({
                data: result,
                isGet: false,
              })
            );
          }
        }
      )
    );
  };

  return (
    <div
      className=""
      style={{
        cursor: "default",
        paddingBottom: "30px",
        height: "100%",
      }}
    >
      <div className="wrap-flex filtres">
        <div className="row wrap-inputs">
          <div
            className="col-md-6"
            style={{ display: "flex", flexDirection: "row", minWidth: "350px" }}
          >
            <label
              htmlFor=""
              className="label-default"
              style={{ marginTop: "13px" }}
            >
              Filtres
            </label>
            <FilterView filtersOptions={filters} />
          </div>
        </div>
      </div>
      <div className="filtres-actions">
        <ul>
          <li>
            <Popover
              placement={"bottom"}
              content={
                <div>
                  {!filters
                    ? null
                    : filters.map((e, index) => (
                        <p>
                          <Checkbox
                            checked={filters[index].checked}
                            onClick={() => {
                              let landa = [...filters];
                              landa[index].checked = !filters[index].checked;
                              dispatch(
                                actionSetIndicatorData({
                                  filters: [...landa],
                                })
                              );
                            }}
                          >
                            <span
                              style={{
                                color: "#1f2128",
                              }}
                              className="color-black"
                            >
                              {e.name}
                            </span>
                          </Checkbox>
                        </p>
                      ))}
                </div>
              }
              title="Ajouter un filtre"
              trigger="click"
            >
              <Link to="">
                <i className="fa-light fa-plus"></i>
              </Link>
            </Popover>
          </li>
          <li>
            <Link to="" onClick={synchronazeData}>
              <i className="fa-light fa-arrow-rotate-right"></i>
            </Link>
          </li>
        </ul>
      </div>
      <div className="wrap-flex">
        <div className="row wrap-select axes">
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "13px",
            }}
          >
            <label htmlFor="axes-card" className="label-default">
              Axes
            </label>
            <div
              style={{
                display: "inline-block",
                minWidth: "197px",
                paddingLeft: "13px",
              }}
            >
              <CustomSelect
                id="axes-card"
                value={axe}
                options={axes}
                onChange={handlerChangeAxe}
                noSearchable
              />
            </div>
          </div>
        </div>
        <div className="options">
          {/* {indicator && +indicator.type !== 5 && isDate ? (
            <RadioButton
              defaultValue={"1"}
              value={value}
              setValue={setValue}
              options={options}
            />
          ) : null} */}
        </div>
      </div>
      <div
        ref={viewRef}
        style={{
          background: !viewRef
            ? "transparent"
            : theme === "0"
            ? "#232731"
            : "#f5f5f5",
          marginTop: 10,
          height: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: 0,
            margin: 0,
          }}
        >
          {resultat.map((item, index) => {
            return (
              <div
                className="inline-flex items-center px-3 py-2 text-sm bg-gray-100 rounded-full"
                key={index}
              >
                <span className="mr-2 text-gray-600">{item.name} : </span>
                <span className="font-semibold text-gray-800">
                  {item && +item.operation === 1
                    ? numberFormat(item.sum)
                    : item && +item.operation === 2
                    ? numberFormat(item.avg)
                    : item && +item.operation === 4
                    ? numberFormat(item.count)
                    : numberFormat(item.sum)}{" "}
                  {item?.symbole || ""}
                </span>
              </div>
            );
          })}
        </div>
        <div className="gchart" style={{}}>
          {isGet || !indicator ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <PropagateLoader
                color={
                  theme === "0" ? "#fff" : theme === "1" ? "#1f2128" : "#1f2128"
                }
                loading={true}
                size={5}
                speedMultiplier={0.75}
              />
            </div>
          ) : indicator && indicator?.type == "3" ? (
            <Map
              data={chartRows.map((items) => {
                return items.slice(1);
              })}
            />
          ) : indicator && indicator?.type == "4" ? (
            <CartTable
              columns={chartColumns}
              rows={chartRows}
              x={200}
              y={200}
            />
          ) : indicator && indicator?.type == "5" ? (
            <CardCalendar
              x={200}
              y={200}
              rows={chartRows}
              columns={chartColumns}
            />
          ) : indicator && indicator?.type == "2" ? (
            <CardPie x={200} y={200} rows={chartRows} columns={chartColumns} />
          ) : indicator && indicator?.type == "0" ? (
            <CardBar x={200} y={200} rows={chartRows} columns={chartColumns} />
          ) : indicator && indicator?.type == "6" ? (
            <CardBarHorizontal
              x={200}
              y={200}
              rows={chartRows}
              columns={chartColumns}
            />
          ) : indicator && indicator?.type == "1" ? (
            <CardLine x={200} y={200} rows={chartRows} columns={chartColumns} />
          ) : (
            <Chart
              width={"100%"}
              height={"100%"}
              chartLanguage="fr"
              chartType={getChartType(indicator?.type)}
              // chartType={"Bar"}
              loading={true}
              loader={
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50px",
                  }}
                >
                  <PropagateLoader
                    color={
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128"
                    }
                    loading={true}
                    size={5}
                    speedMultiplier={0.75}
                  />
                </div>
              }
              columns={chartColumns}
              rows={chartRows.map((items) => {
                return items.slice(1);
              })}
              options={{
                calendar: {
                  cellSize: (200 * 7.25) / 440,
                  daysOfWeek: "DLMMJVS",
                },
                showRowNumber: true,
                height: "100%",
                width: "100%",
                chartArea: {
                  height:
                    indicator && parseInt(indicator.type) === 2 ? "95%" : "70%",
                  width:
                    indicator && parseInt(indicator.type) === 2 ? "95%" : "80%",
                },
                crosshair: { trigger: "both", orientation: "both" },
                hAxis: {
                  gridlines: {
                    color: "transparent",
                  },
                  minorGridlines: {
                    count: 0,
                  },

                  textStyle: {
                    color:
                      theme === "0"
                        ? "#FFF"
                        : theme === "1"
                        ? "black"
                        : "black",
                  },
                },
                ...(indicator && indicator.type == "0"
                  ? {
                      seriesType: "bars",

                      isStacked: false,
                    }
                  : {}),
                vAxis: {
                  baselineColor: theme === "0" ? "#FFF" : "#000",
                  minorGridlines: {
                    count: 0,
                  },
                  textStyle: {
                    color:
                      theme === "0"
                        ? "#FFF"
                        : theme === "1"
                        ? "#1f2128"
                        : "#1f2128",
                  },
                },
                backgroundColor: "transparent",
                colors: [
                  "#05BAF3",
                  "#2979FF",
                  "#C62828",
                  "#F50057",
                  "#AB47BC",
                  "#4527A0",
                  "#FFA726",
                  "#EF5350",
                  "#FF3D00",
                  "#0277BD",
                  "#C6FF00",
                  "#EF6C00",
                  "#AD1457",
                  "#00B0FF",
                  "#D4E157",
                  "#283593",
                  "#3D5AFE",
                  "#6A1B9A",
                  "#42A5F5",
                  "#5C6BC0",
                  "#9E9D24",
                  "#FF7043",
                  "#651FFF",
                  "#7E57C2",
                  "#FF9100",
                  "#FF1744",
                  "#1565C0",
                  "#D500F9",
                  "#FF3D00",
                  "#EC407A",
                ],
                pieSliceText: "label",
                legend:
                  indicator && parseInt(indicator.type) === 2
                    ? {
                        position: "left",
                        textStyle: {
                          color: theme === "0" ? "#FFF" : "#000",
                          fontSize: 12,
                        },
                      }
                    : {
                        position: "none",
                      },
              }}
            />
          )}
        </div>
        {indicator &&
        indicator.goals &&
        indicator.type !== "2" &&
        indicator.goals.length > 0 ? (
          <div
            style={{
              height: "25px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "#25F700",
                }}
              ></span>{" "}
              <i style={{ fontSize: "15px" }}>Objectif</i>
            </p>

            {parseInt(indicator.operation) === 1 ? (
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    marginLeft: "10px",
                    backgroundColor: "blue",
                  }}
                ></span>{" "}
                <i style={{ fontSize: "15px" }}>Cumul</i>
              </p>
            ) : null}

            <p>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#05BAF3",
                  // backgroundColor:
                  //   theme === "0" || theme === "1" ? "#d02173" : "#007FC1",
                }}
              ></span>{" "}
              <i style={{ fontSize: "15px" }}>Valeur</i>
            </p>
          </div>
        ) : null}

        {indicator &&
        indicator.showcumul &&
        parseInt(indicator.operation) !== 2 &&
        indicator.type !== "2" &&
        !(indicator.goals.length > 0) ? (
          <div
            style={{
              height: "25px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  marginLeft: "10px",
                  backgroundColor: "blue",
                }}
              ></span>{" "}
              <i style={{ fontSize: "15px" }}>Cumul</i>
            </p>

            <p>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#05BAF3",
                }}
              ></span>{" "}
              <i style={{ fontSize: "15px" }}>Valeur</i>
            </p>
          </div>
        ) : null}

        {lastValue ? renderLastValue() : null}
      </div>

      {/* <div className="resume">
        <div className="goal">
          <p className="target">
            <i className="fa-light fa-bullseye-pointer"></i> 26
          </p>
          <p className="final positive">
            <i className="fa-solid fa-circle-arrow-up"></i> <span>22%</span>{" "}
            Above goal
          </p>
        </div>
        <div className="compare">
          <p className="last-month">
            <i className="fa-light fa-clock-rotate-left"></i> Last Month : 36
          </p>
          <p className="percent-compare negative">
            <i className="fa-solid fa-circle-arrow-down"></i> <span>-39%</span>{" "}
            Vs Last Month
          </p>
        </div>
      </div> */}
    </div>
  );
}
