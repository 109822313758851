import React, { useEffect, useState, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "../Views/Modal";
import { useSnackbar } from "notistack";
import CalendarView from "../Views/CalendarView";
import moment from "moment";
import { truncateText } from "../utils/truncateWords";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  RefreshCwIcon,
  PencilIcon,
  TrashIcon,
} from "lucide-react";
import { Search } from "lucide-react";
import TableRowLoader from "../Views/TableLoading";
import { actionGetWorkspaceByCompany } from "../../redux/actions/workspaceAction";
import CustomSelect from "../Views/CustomSelect";
import { use } from "react";
import {
  actionGetUsersByCompany,
  actionSendInvitationCompany,
  actionChangeRoleCompany,
  actionRemoveUserCompany,
} from "../../redux/actions/companyAction";
const _ = require("lodash");

export default function UserWorkSpace() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idUser = useSelector((state) => state.user.id);
  const idcompany = useSelector((state) => state.user.idcompany);
  const token = useSelector((state) => state.user.token);
  const roleCompany = useSelector((state) => state.user.role);
  // console.log(roleCompany);

  const userCompanies = useSelector((state) => state.companies.userCompanies);
  const [searchQuery, setSearchQuery] = useState("");
  const filtereUsersCompanies = userCompanies.filter((userCompnay) =>
    userCompnay.user?.email
      ?.toLowerCase()
      .includes(searchQuery?.toLowerCase() || "")
  );

  const tokenRef = useRef();
  tokenRef.current = token;
  const [role, setRole] = useState("ADD");
  const [email, setEmail] = useState("");
  const options = [
    {
      value: "MEMBER",
      label: "Membre",
      key: 0,
    },
    {
      value: "VISITOR",
      label: "Visiteur",
      key: 1,
    },
    {
      value: "ADMIN",
      label: "Administrateur",
      key: 2,
    },
    {
      value: "OWNER",
      label: "Propriétaire",
      key: 3,
    },
  ];
  const [userRole, setUserRole] = useState(options[0]);

  const [end, setEnd] = useState(null);
  const [value, setValue] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const isDeleting = useSelector((state) => state.workspaces.isDeleteWorkspace);
  const isUpdating = useSelector((state) => state.workspaces.isUpdateWorkspace);
  const isGet = useSelector((state) => state.companies.isGet);
  const isAdd = useSelector((state) => state.workspaces.isAddworkspace);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [userCompany, setUserCompany] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const cancel = () => {
    setRole("ADD");
    setEmail("");
    setUserRole(options[0]);
    setUserCompany(null);
    setVisibility(false);
    setDeleteVisibility(false);
  };

  const add = () => {
    dispatch(
      actionSendInvitationCompany(
        email,
        userRole.value,
        idcompany,
        token,
        ({ status, message }) => {
          if (status) {
            enqueueSnackbar(message || "", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar(message || "", {
              variant: "warning",
            });
          }
        }
      )
    );
  };

  const edit = () => {
    dispatch(
      actionChangeRoleCompany(
        userCompany.id,
        userRole.value,
        token,
        ({ status, message }) => {
          if (status) {
            enqueueSnackbar(message || "", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar(message || "", {
              variant: "warning",
            });
          }
        }
      )
    );
  };

  const submit = (e) => {
    e.preventDefault();

    if (email && userRole) {
      const re = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      const isEmail = re.test(email);
      if (isEmail) {
        const userAlreadyExist = userCompanies.filter(
          (u) => u?.user.email === email
        );

        if (userAlreadyExist.length >= 1 && role === "ADD") {
          enqueueSnackbar(
            "Cet adresse email fait déjà partie de l'espace de travail.",
            {
              variant: "warning",
            }
          );
          return;
        }
      } else {
        enqueueSnackbar("Désolé votre adresse mail esst incorrect.", {
          variant: "warning",
        });
      }
      if (role === "ADD") {
        add();
      } else if (role === "UPDATE" && userCompany) {
        edit();
      }
    } else {
      enqueueSnackbar("Tous les champs sont obligatoires", {
        variant: "warning",
      });
    }
  };

  const remove = (e) => {
    e.preventDefault();
    if (userCompany) {
      dispatch(
        actionRemoveUserCompany(
          userCompany.id,

          token,
          ({ status, message }) => {
            if (status) {
              enqueueSnackbar(message || "", {
                variant: "success",
              });
              cancel();
            } else {
              enqueueSnackbar(message || "", {
                variant: "warning",
              });
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data) {
      navigate("/");
    } else {
      dispatch(
        actionGetUsersByCompany(
          "Bearer " + JSON.parse(data).token,
          (result) => {
            if (result) {
              // console.log(result);
            }
          }
        )
      );
    }
  }, []);

  return (
    <div className="h-full bg-white">
      <Modal
        visible={deleteVisibility && userCompany}
        title={`Retirer l'utilisateur "${userCompany?.user.email}" ?`}
        onCancel={isDeleting ? null : cancel}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDeleting ? null : cancel}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDeleting}
                onClick={isDeleting ? null : remove}
              >
                {isDeleting ? "Supprimer..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibility}
        title={
          role === "ADD" ? "Ajouter un utilisateur" : "Modifier l'utilisateur"
        }
        onCancel={isAdd || isUpdating ? null : cancel}
        width={600}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="input-container">
              <label for="nom" className="label-default">
                Email *
              </label>
              <span className="span-block">
                <input
                  className="input-default inputCustom"
                  name="email"
                  id="email"
                  type="email"
                  value={email}
                  disabled={userCompany}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Obligatoire"
                  required
                />
              </span>
            </div>
          </div>

          <div className="col-md-12">
            <div className="input-container">
              <label for="nom" className="label-default">
                Role *
              </label>
              <span className="span-block">
                <CustomSelect
                  value={userRole}
                  options={options.filter((item) => +item.key !== 3)}
                  className={""}
                  required
                  onChange={setUserRole}
                />
              </span>
            </div>
          </div>
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isAdd ? null : cancel}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isAdd || isUpdating}
                onClick={isAdd || isUpdating ? null : submit}
              >
                {isAdd || isUpdating ? "Enregistrer..." : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <main className="h-[98%]  w-[98%] mx-auto ">
        <div className="bg-white ">
          <div className="bg-white w-full  h-[60px] row actions-nav m-3 mx-auto border-r-black rounded-[20px] flex-col items-center gap-2">
            <div className="flex items-center justify-between w-full gap-2 font-poppins ">
              <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center ml-1">
                <p className="font-semibold text-center text-[18px] mt-[20px]">
                  Utilisateurs
                </p>
                <div className="relative flex items-center ">
                  <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Rechercher"
                    className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                  />
                </div>
              </div>

              <div className="flex items-center gap-2">
                {["ADMIN", "OWNER"].includes(roleCompany) && (
                  <p
                    className="hover:text-white hover:bg-colorText border-2 border-colorText text-colorText  rounded-[5px] p-0 m-0 text-center font-poppins py-1 w-[180px] font-semibold cursor-pointer text-[12px]"
                    type="submit"
                    onClick={() => {
                      setVisibility(true);
                    }}
                  >
                    +Ajouter un utilisateur
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="w-full p-3 bg-customBg h-[83vh] rounded-xl overflow-auto">
            <table
              id="sources"
              className="relative w-full overflow-auto bg-white border-collapse shadow-sm"
            >
              <thead className="mb-[600px] font-poppins leading-[50px] sticky ">
                <tr className="h-10 bg-white">
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] ">
                    <span>Nom</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] ">
                    <span>Prenom</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] ">
                    <span>Email</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] ">
                    <span>Role</span>
                  </th>
                  {["ADMIN", "OWNER"].includes(roleCompany) && (
                    <th className="p-1 pl-2 font-semibold text-center text-black border-b text-[12px] ">
                      <span>Actions</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="text-[12px] mt-[900px] border-t-8 border-customBg h-full">
                {isGet
                  ? [...Array(5)].map((_, index) => (
                      <tr
                        key={index}
                        className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold"
                      >
                        <td colSpan={6}>
                          <TableRowLoader />
                        </td>
                      </tr>
                    ))
                  : filtereUsersCompanies.map((userCompany) => {
                      const userRole = options.find(
                        (item) => item.value === userCompany.role
                      );
                      return (
                        <tr
                          id={userCompany.id}
                          key={userCompany.id}
                          className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold my-3"
                        >
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {truncateText(userCompany.user.firstName)}
                          </td>
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {truncateText(userCompany.user.lastName)}
                          </td>
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {truncateText(userCompany.user.email)}
                          </td>
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {userRole?.label || ""}
                          </td>
                          {["ADMIN", "OWNER"].includes(roleCompany) && (
                            <td className="p-1 text-[12px]">
                              <div className="flex items-center justify-around gap-2">
                                {userCompany.role !== "OWNER" && (
                                  <>
                                    <button
                                      className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                      onClick={() => {
                                        setRole("UPDATE");
                                        setEmail(userCompany.user.email);

                                        setUserRole(userRole);
                                        setUserCompany(userCompany);
                                        setVisibility(true);
                                      }}
                                    >
                                      <PencilIcon className="w-5 h-5" />
                                    </button>
                                    <button
                                      className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                      onClick={() => {
                                        setDeleteVisibility(true);
                                        setUserCompany(userCompany);
                                      }}
                                    >
                                      <TrashIcon className="w-5 h-5" />
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}
