import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useSnackbar } from "notistack";
import { MdError } from "react-icons/md";
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function InvitedFailed() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-50 text-center">
      <div className="p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-red-600 mb-4">
          Invitation Expirée
        </h1>
        <p className="text-gray-600 mb-6">
          Malheureusement, cette invitation n'est plus valide ou a expiré.
        </p>
        <button
          onClick={() => navigate("/")}
          className="px-6 py-3 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
        >
          Retour à l'accueil
        </button>
      </div>
    </div>
  );
}
