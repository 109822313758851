import Vertical from "../graph/0.svg";
import Horizontal from "../graph/6.svg";
import Linear from "../graph/1.svg";
import Pie from "../graph/2.svg";
import Table from "../graph/4.svg";
import Calendar from "../graph/calendar.png";
import Histogram from "../graph/7.svg";
import Map from "../graph/map.png";
import moment from "moment";
import { expression } from "mathjs";
const _ = require("lodash");

export const getChartType = (type) => {
  switch (type) {
    case "0":
      return "ComboChart";

    case "1":
      return "LineChart";

    case "2":
      return "PieChart";

    case "4":
      return "Table";

    case "5":
      return "Calendar";

    case "6":
      return "BarChart";

    case "7":
      return "Histogram";

    default:
      return "ComboChart";
  }
};

export const graphIllustration = (value) => {
  switch (`${value}`) {
    case "0":
      return Vertical;

    case "1":
      return Linear;

    case "2":
      return Pie;

    case "3":
      return Map;

    case "4":
      return Table;

    case "5":
      return Calendar;

    case "6":
      return Horizontal;

    case "7":
      return Histogram;

    default:
      return Vertical;
  }
};

function getStyle(operation, appearanceTotal, appearanceAvg, appearanceCount) {
  let styles = {};
  const dataDefault = {};

  switch (+operation) {
    case 1:
      styles = appearanceTotal
        ? {
            color: appearanceTotal.color,
            fontStyle: appearanceTotal.italic ? "italic" : "",
            fontWeight: appearanceTotal.bold ? "bold" : "",
            size: appearanceTotal.bold ? appearanceTotal.bold : "20px",
            textDecoration: appearanceTotal.underline ? "underline" : "",
          }
        : dataDefault;
      break;
    case 2:
      styles = appearanceAvg
        ? {
            color: appearanceAvg.color,
            fontStyle: appearanceAvg.italic ? "italic" : "",
            fontWeight: appearanceAvg.bold ? "bold" : "",
            size: appearanceAvg.bold ? appearanceAvg.bold : "20px",
            textDecoration: appearanceAvg.underline ? "underline" : "",
          }
        : dataDefault;
      break;
    case 4:
      styles = appearanceCount
        ? {
            color: appearanceCount.color,
            fontStyle: appearanceCount.italic ? "italic" : "",
            fontWeight: appearanceCount.bold ? "bold" : "",
            size: appearanceCount.bold ? appearanceCount.bold : "20px",
            textDecoration: appearanceCount.underline ? "underline" : "",
          }
        : dataDefault;
      break;
    default:
      styles = dataDefault;
      break;
  }
  return styles;
}

export const getChartColumnsAndRows = (
  indicator,
  defaultAxe,
  data,
  canRegroupe,
  regroupeInterval
) => {
  const columns = [];
  const rows = [];

  const indicateurs = indicator.indicateurs.map((item) => {
    const conditions = [];
    for (let i = 0; i < item.indicateurConditions.length; i++) {
      const element = item.indicateurConditions[i];
      if (element.condition.active) {
        conditions.push({
          condition: element.condition.expression,
          color: element.appearances[0].color,
        });
      }
    }

    return {
      name: item.name,
      type: +item.operation,
      metric: item.isExtension ? item.metric.name : "",
      color: item.color,
      isExtension: item.isExtension,
      id: item.id,
      baseColor: item.color,
      isPart: item.isPart,
      isVisible: item.isVisible,
      conditions,
      operation: item.operation,
      symbole: item.symbole,
    };
  });

  let axe = null;
  if (defaultAxe) {
    axe = defaultAxe;
  } else {
    for (let i = 0; i < indicator.columns.length; i++) {
      const column = indicator.columns[i];
      if (column.idcolumn == indicator.iddefault) {
        axe = column;
        break;
      }
    }
  }

  const checkRegroupe = canRegroupe && regroupeInterval && +axe.dataType == 3;
  if (checkRegroupe) {
    columns.push({
      type: +regroupeInterval === 1 ? "datetime" : "string",
      label: axe.name,
      appearance: getStyle(
        indicator.operation,
        axe.appearance,
        axe.appearance_avg,
        axe.appearance_count
      ),
    });
  } else {
    columns.push({
      type: axe.dataType == 3 ? "datetime" : "string",
      label: axe.name,
      appearance: axe.appearance,
      appearance: getStyle(
        indicator.operation,
        axe.appearance,
        axe.appearance_avg,
        axe.appearance_count
      ),
      isVisible: true,
    });
  }

  indicateurs.map((indicateur, index) => {
    columns.push({
      type: "number",
      label: indicateur.name,
      appearance: {
        color: indicateur.color,
        ...getStyle(
          indicator.operation,
          axe.appearance,
          axe.appearance_avg,
          axe.appearance_count
        ),
      },
      conditions: indicateur.conditions,
      baseColor: indicateur.baseColor,
      isPart: indicateur.isPart,
      isVisible: indicateur.isVisible,
    });
  });

  let total = 0;
  let avg = 0;
  let counter = 0;
  let resultat = indicateurs.map((metric) => {
    const name = metric.isExtension ? metric.metric : metric.id;
    // console.log(metric);
    return {
      nameIndicateur: metric.name,
      name,
      sum: 0,
      count: 0,
      avg: 0,
      symbole: metric.symbole,
      operation: +metric.operation,
      lastValue: 0,
    };
  });

  indicator.operation = 1;

  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const color = {};

      indicateurs.map((metric) => {
        avg += parseInt(row[`${metric.metric}_avg`]);
        total += parseInt(row[`${metric.metric}`]);
        color[`${metric.id}`] = metric.isExtension
          ? row[`default_color_${metric.metric}`]
          : row[`default_color_${metric.id}`];
      });

      resultat = resultat.map((result) => {
        // console.log(result);
        result.avg += parseInt(row[`${result.name}_avg`]);
        result.sum += parseInt(row[`${result.name}`]);
        result.count += parseInt(row[`count`]);

        return result;
      });

      counter += parseInt(row.count);
      const appearance = row.appearance || null;
      const appearance_avg = row.appearance_avg || null;
      const appearance_count = row.appearance_count || null;
      const style = getStyle(
        indicator.operation,
        appearance,
        appearance_avg,
        appearance_count
      );
      if (axe.dataType == 3) {
        rows.push([
          { ...style },
          { value: new Date(row._id), color: "#000000", isVisible: true },
          ...indicateurs.map((metric) => {
            const name = metric.isExtension ? metric.metric : metric.id;
            const style = getStyle(
              metric.type,
              row[`apparence_${name}`],
              row[`apparence_avg_${name}`],
              row[`apparence_${name}_count`]
            );
            switch (metric.type) {
              case 2:
                return {
                  value: parseInt(row[`${name}_avg`]),
                  color: metric.color,
                  isVisible: metric.isVisible,
                  style,
                  name: metric.metric,
                };

              case 4:
                return {
                  value: parseInt(row.count),
                  color: metric.color,
                  isVisible: metric.isVisible,
                  style,
                  name: metric.metric,
                };
              default:
                return {
                  value: parseInt(row[`${name}`]),
                  color: metric.color,
                  isVisible: metric.isVisible,
                  style,
                  name: metric.metric,
                };
            }
          }),
        ]);
      } else {
        rows.push([
          { ...style },
          { value: row._id, color: "#000000", isVisible: true },
          ...indicateurs.map((metric) => {
            const name = metric.isExtension ? metric.metric : metric.id;
            const style = getStyle(
              metric.type,
              row[`apparence_${name}`],
              row[`apparence_${name}_avg`],
              row[`apparence_${name}_count`]
            );
            // console.log(metric.isVisible);
            switch (metric.type) {
              case 2:
                return {
                  value: parseInt(row[`${name}_avg`]),
                  color: metric.color,
                  isVisible: metric.isVisible,
                  style,
                  name: metric.metric,
                };

              case 4:
                return {
                  value: parseInt(row.count),
                  color: metric.color,
                  isVisible: metric.isVisible,
                  style,
                  name: metric.metric,
                };
              default:
                return {
                  value: parseInt(row[`${name}`]),
                  color: metric.color,
                  isVisible: metric.isVisible,
                  style,
                  name: metric.metric,
                };
            }
          }),
        ]);
      }
    }
  }
  // console.log({ columns, rows });
  return {
    columns,
    rows: checkRegroupe ? regroupe(rows, `${regroupeInterval}`) : rows,
    total,
    avg: avg / data.length,
    counter,
    resultat,
  };
};

const MONTHS = [
  "Janv.",
  "Févr.",
  "Mars",
  "Avr.",
  "Mai",
  "Juin",
  "Juill.",
  "Août",
  "Sept.",
  "Oct.",
  "Nov.",
  "Déc.",
];

/**
 * Cette fonction permet de regrouper des données en jour, mois ou année selon l'option
 * @param {*} rows est un tableau contenant les données deja en format jour
 * @param {*} option définit sous quelle forme doit être regrouper les données  1 => jour, 2 => mois, 3 => année
 */
export const regroupe = (rows, option) => {
  if (option == "1") {
    return rows;
  }

  if (rows.length < 1) {
    return [];
  }

  let debut = rows[0][0];
  let fin = rows[rows.length - 1][0];

  if (typeof debut !== "object") {
    return [];
  }

  switch (option) {
    case "2":
      if (
        debut.getFullYear() === fin.getFullYear() &&
        debut.getMonth() === fin.getMonth()
      ) {
        const somme = [];
        for (let i = 0; i < rows.length; i++) {
          const row = [...rows[i]];
          row.splice(0, 1);
          row.map((value, index) => {
            somme[index] = somme[index] ? somme[index] + value : value;
          });
        }
        return [[MONTHS[debut.getMonth()], ...somme]];
      } else {
        let result = [];
        let is = debut.getFullYear() === fin.getFullYear();
        const years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          if (Object.hasOwnProperty.call(years, key)) {
            const year = years[key];
            const month = _.groupBy(year, (e) => e[0].getMonth());
            for (const tag in month) {
              const somme = [];
              if (Object.hasOwnProperty.call(month, tag)) {
                const days = month[tag];
                for (let i = 0; i < days.length; i++) {
                  const item = [...days[i]];
                  item.splice(0, 1);
                  item.map((value, index) => {
                    somme[index] = somme[index] ? somme[index] + value : value;
                  });
                }
              }
              result.push([
                is ? `${MONTHS[tag]}` : `${MONTHS[tag]} ${key}`,
                ...somme,
              ]);
            }
          }
        }
        return result;
      }
      break;
    case "3":
      if (debut.getFullYear() === fin.getFullYear()) {
        const somme = [];
        for (let i = 0; i < rows.length; i++) {
          const row = [...rows[i]];
          row.splice(0, 1);
          row.map((value, index) => {
            somme[index] = somme[index] ? somme[index] + value : value;
          });
        }
        return [[debut.getFullYear(), ...somme]];
      } else {
        let result = [];
        let years = _.groupBy(rows, (e) => e[0].getFullYear());
        for (const key in years) {
          const somme = [];
          if (Object.hasOwnProperty.call(years, key)) {
            const days = years[key];
            for (let i = 0; i < days.length; i++) {
              const item = [...days[i]];
              item.splice(0, 1);
              item.map((value, index) => {
                somme[index] = somme[index] ? somme[index] + value : value;
              });
            }
            result.push([key, ...somme]);
          }
        }
        return result;
      }
      break;
    case "4":
      let data = _.groupBy(rows, (item) => {
        let current = moment(item[0]);
        current = current.startOf("isoWeek");
        return current.year() + "-" + current.isoWeek();
      });
      let response = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          const somme = [];
          for (let index = 0; index < element.length; index++) {
            const item = [...element[index]];
            item.splice(0, 1);
            item.map((value, index) => {
              somme[index] = somme[index] ? somme[index] + value : value;
            });
          }
          let first = moment(element[0][0]);
          first = first.startOf("isoWeek");

          let last = moment(element[0][0]);
          last = last.endOf("isoWeek");

          if (debut.getFullYear() === fin.getFullYear()) {
            response.push([
              `${first.date()} ${MONTHS[first.month()]} - ${last.date()} ${
                MONTHS[last.month()]
              }`,
              ...somme,
            ]);
          } else {
            response.push([
              `${first.date()} ${
                MONTHS[first.month()]
              } ${first.year()} - ${last.date()} ${
                MONTHS[last.month()]
              } ${last.year()}`,
              ...somme,
            ]);
          }
        }
      }
      return response;
      break;
  }
  return [];
};
