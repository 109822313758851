import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import { useTranslation } from "react-i18next";
import { Checkbox, Popover as PopoverAntd } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Modal from "./Modal";
import CustomSelect from "./CustomSelect";
import { isInteger, parseInt } from "lodash";
import {
  actionGetOptions,
  actionUpdateIndicator,
} from "../../redux/actions/indicatorAction";
import { useSnackbar } from "notistack";
import RadioButton from "./RadioButton";
import { Link } from "react-router-dom";
import { graphIllustration } from "../../tools/Chart";
import FilterView from "./FilterView";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  Type,
  Bold,
  Italic,
  Underline,
} from "lucide-react";
import { ChevronDown, X, Edit2, AlertCircle, Check } from "lucide-react";
import {
  actionCreateCondition,
  actionDeleteCondition,
  actionGetConditionByDdataSource,
} from "../../redux/actions/ConditionAction";
import host from "../../redux/actions/host";
import {
  actionCreateIndicators,
  actionGetIndicatorsByDataSource,
} from "../../redux/actions/IndicatorsAction";

let theme = "1";
const data = localStorage.getItem("theme");
if (data) {
  theme = JSON.parse(data).theme;
}

export default function ModalUpdateIndicator({ visibility, onCancel, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector((state) => state.user.token);
  const board = useSelector((state) => state.board.dashboard);
  const isUpdate = useSelector((state) => state.indicator.isUpdate);
  const conditions = useSelector((state) => state.condition.conditions);
  const indicateurData = useSelector((state) => state.indicators.indicators);
  const optionsConditions = conditions.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const [description, setDescription, descriptionRef] = useState("");
  const [showRadioRegroupe, setShowRadioRegroupe, showRadioRegroupeRef] =
    useState(false);
  const [
    radioRegroupeDefault,
    setRadioRegroupeDefault,
    radioRegroupeDefaultRef,
  ] = useState("1");

  const formuleOptions = [
    { value: 1, label: "Somme" },
    { value: 2, label: "Moyenne" },
    // { value: 3, label: "Aucun calcul" },
    { value: 4, label: "Compteur" },
  ];

  const GRAPH_TYPES = [
    { value: "0", label: "Bar Graph vertical" },
    { value: "6", label: "Bar Graph horizontal" },
    { value: "1", label: "Linear Graph" },
    { value: "2", label: "Pie Graph" },
    { value: "3", label: "Coordonnées Géographiques" },
    { value: "5", label: "Calendar" },
    // { value: "7", label: "Histogram" },
    { value: "4", label: "Table" },
  ];

  const [operation, setOperation, operationRef] = useState(formuleOptions[0]);
  const [size, setSize, sizeRef] = useState("1");
  const [symbole, setSymbole, symboleRef] = useState("");
  const [indicatorName, setIndicatorName, indicatorNameRef] = useState("");

  const datasources = useSelector((state) => state.datasource.datasources);
  const datasourcesRef = useRef();
  datasourcesRef.current = datasources;

  const lists = useSelector((state) => state.list.lists);
  const listsRef = useRef();
  listsRef.current = lists;

  const [filters, setFilters, filtersRef] = useState([]);
  const [source, setSource, sourceRef] = useState(null);
  const [metrics, setMetrics, metricsRef] = useState([]);
  const [showLastValue, setShowLastValue] = useState(false);
  const [larged, setLarged] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [notShowCumulWithGoal, setNotShowCumulWithGoal] = useState(false);
  const [showCumul, setShowCumul] = useState(false);
  const [axeDate, setAxeDate, axeDateRef] = useState(null);
  const [axeEmail, setAxeEmail, axeEmailRef] = useState(null);
  const [showByEmail, setShowByEmail, showByEmailRef] = useState(false);
  const [axes, setAxes, axesRef] = useState(null);
  const [type, setType, typeRef] = useState(null);
  const [defaultAxe, setDefaultAxe, defaultAxeRef] = useState(null);
  const [indicateurs, setIndicateurs] = useState([]);

  const [optionsMetric, setOptionsMetric] = useState([]);
  const [optionsAxe, setOptionsAxe] = useState([]);
  const [optionsAxeDate, setOptionsAxeDate] = useState([]);
  const [optionsAxeEmail, setOptionsAxeEmail] = useState([]);
  const [optionsDatasources, setOptionsDatasources] = useState([]);
  const [rules, setRules] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [condition, setCondition] = useState(null);
  const [comp, setComp] = useState({ value: "0", label: "Apparence" });
  const targetOptions = [
    { value: 0, label: "en tête" },
    { value: 1, label: "le graphe" },
    { value: 2, label: "les deux" },
  ];
  const [target, setTarget] = useState(targetOptions[0]);

  const [selectedColor, setSelectedColor] = useState("#000000");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [editingId, setEditingId] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingId !== null) {
      setRules(
        rules.map((item) =>
          item.id === editingId
            ? {
                ...item,
                title,
                comp,
                condition,
                color: selectedColor,
                isBold,
                isItalic,
                isUnderline,
                fontSize,
                target,
              }
            : item
        )
      );
      setEditingId(null);
    } else {
      setRules([
        ...rules,
        {
          id: Date.now(),
          title,
          condition,
          comp,
          color: selectedColor,
          isBold,
          isItalic,
          isUnderline,
          fontSize,
          target,
        },
      ]);
    }
    handleCancel();
  };
  const handleCancel = (e) => {
    setTitle("");
    setComp({ value: "0", label: "Apparence" });
    setTarget(targetOptions[0]);
    setCondition("");
    // setRules([]);
    setIndicateurs([]);
    setSelectedColor("#000000");
    setIsBold(false);
    setIsItalic(false);
    setFontSize(14);
    setIsOpen(false);
    setEditingId(null);
    // setVisibility(true);
    setIsUnderline(false);
  };

  const handleEdit = (item) => {
    setTitle(item.title);
    setComp(item.comp);
    setCondition(item.condition);
    setSelectedColor(item.color);
    setIsBold(item.isBold);
    setIsItalic(item.isItalic);
    setIsUnderline(item.isUnderline);
    setFontSize(item.fontSize);
    setEditingId(item.id);
    setTarget(item.target);
    setIsOpen(true);
  };

  const handleDelete = (id) => {
    setRules(rules.filter((item) => item.id !== id));
  };
  const fontSizes = [12, 14, 16, 18, 20, 24];

  useEffect(() => {
    if (visibility) {
      for (let i = 0; i < datasourcesRef.current.length; i++) {
        const datasource = datasourcesRef.current[i];
        if (
          parseInt(datasource.iddatasource) ===
          parseInt(data.columns[0].iddatasource)
        ) {
          const indicateurs = data.indicateurs.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setIndicateurs(indicateurs);
          handlerChangeDatasource({ value: i, label: datasource.name });
          setTimeout(() => {
            setIndicatorName(data.name);
            setDescription(data.description);
            setSize(data.taille);
            setSymbole(data.symbole);
            setLarged(Boolean(data.large));
            setShowCumul(Boolean(data.showcumul));
            setShowByEmail(Boolean(data.showByEmail));
            setNotShowCumulWithGoal(Boolean(data.notshowcumulwithgoal));
            setRadioRegroupeDefault(data.periode);
            setShowRadioRegroupe(data.showperiode);
            setShowLastValue(Boolean(data.showlastvalue));

            const selectedLists = [];
            for (let j = 0; j < lists.length; j++) {
              const list = lists[j];
              for (let k = 0; k < data.listIndicators.length; k++) {
                const listIndicator = data.listIndicators[k];
                if (+listIndicator.idlist === +list.idlist) {
                  selectedLists.push({ value: list.idlist, label: list.name });
                  break;
                }
              }
            }
            setSelectedLists(selectedLists);

            let items = [];
            const metrics = [];

            for (let i = 0; i < data.metrics.length; i++) {
              const metric = data.metrics[i];
              metrics.push({ value: metric.idcolumn, label: metric.name });
            }

            setMetrics(metrics);

            for (let i = 0; i < datasource.columns.length; i++) {
              const item = datasource.columns[i];

              if (
                data.columns.find(
                  (column) => +column.idcolumn === +item.idcolumn
                )
              ) {
                if (parseInt(item.idcolumn) === parseInt(data.idEmailColumn)) {
                  setAxeEmail({ value: item.idcolumn, label: item.name });
                }

                if (parseInt(item.idcolumn) === parseInt(data.idfilter)) {
                  setAxeDate({ value: item.idcolumn, label: item.name });
                  if (!data.iddefault) {
                    setDefaultAxe({ value: item.idcolumn, label: item.name });
                  }
                }

                if (data.iddefault) {
                  if (parseInt(item.idcolumn) === parseInt(data.iddefault)) {
                    setDefaultAxe({ value: item.idcolumn, label: item.name });
                  }
                }

                if (
                  item.columnType !== "indicator" &&
                  parseInt(item.idcolumn) !== parseInt(data.idEmailColumn)
                ) {
                  items.push({ value: item.idcolumn, label: item.name });
                }
              }
            }

            setAxes(items);

            for (let i = 1; i < formuleOptions.length; i++) {
              const value = formuleOptions[i].value;

              if (value === parseInt(data.operation)) {
                setOperation(formuleOptions[i]);
                break;
              }
            }

            for (let i = 0; i < GRAPH_TYPES.length; i++) {
              const graph = GRAPH_TYPES[i];
              if (graph.value === data.type) {
                setType(graph);
              }
            }
          }, 75);
          break;
        }
      }
    }
  }, [visibility, lists]);

  useEffect(() => {
    let datasourcesArray = [];
    for (let i = 0; i < datasources.length; i++) {
      const datasource = datasources[i];
      datasourcesArray.push({ value: i, label: datasource.name });
    }

    setAxes(null);
    setSource(null);
    setMetrics(null);
    setAxeDate(null);
    setDefaultAxe(null);
    setAxeEmail(null);

    setOptionsAxe([]);
    setOptionsMetric([]);
    setOptionsAxeDate([]);
    setOptionsDatasources(datasourcesArray);
  }, [datasources]);

  useEffect(() => {
    if (sourceRef.current) {
      const datasource = datasourcesRef.current[sourceRef.current.value];
      // console.log(datasource.iddatasource);
      const data = localStorage.getItem("data");
      if (datasource.iddatasource) {
        dispatch(
          actionGetConditionByDdataSource(
            datasource.iddatasource,
            "Bearer " + JSON.parse(data).token,
            (status) => {}
          )
        );
        dispatch(
          actionGetIndicatorsByDataSource(
            datasource.iddatasource,
            token,
            (status) => {}
          )
        );
      }
    }
  }, [source, datasources]);

  useEffect(() => {
    if (sourceRef.current) {
      const filters = [];
      const datasource = datasourcesRef.current[sourceRef.current.value];
      const columns = datasource.columns.filter((item) => +item.dataType !== 3);
      if (data.indicatorFilterColumns) {
        for (let i = 0; i < columns.length; i++) {
          const column = columns[i];
          const item = data.indicatorFilterColumns.find(
            (item) => +item.idcolumn === +column.idcolumn
          );

          filters.push({
            id: column.idcolumn,
            name: column.name,
            type: parseInt(column.dataType),
            checked: item ? true : false,
            indicator: Boolean(column.columnType === "indicator"),
            min: item?.min ? +item.min : null,
            max: item?.max ? +item.max : null,
            loading: parseInt(column.dataType) === 2 ? true : false,
            value: null,
            values: item?.values ? JSON.parse(item.values) : [],
            options: [],
            interval: [null, null],
          });

          setFilters([...filters]);

          // if (parseInt(column.dataType) === 2) {
          //   dispatch(
          //     actionGetOptions(
          //       column.iddatasource,
          //       column.name,
          //       (status, result) => {
          //         if (status === 200) {
          //           let alpha = [...filtersRef.current];
          //           for (let j = 0; j < alpha.length; j++) {
          //             const item = alpha[j];
          //             if (parseInt(item.id) === parseInt(column.idcolumn)) {
          //               alpha[j]["options"] = result;
          //               alpha[j]["loading"] = false;
          //               setFilters([...alpha]);
          //             }
          //           }
          //         }
          //       }
          //     )
          //   );
          // }
        }
      }
    } else {
      setFilters([]);
    }
  }, [metrics, axeDate, axeEmail, axes, source, data]);

  const cancel = () => {
    handleCancel();
    onCancel();
    setLarged(false);
    setShowCumul(false);
    setSource(null);
    setMetrics(null);
    setAxeDate(null);
    setAxeEmail(null);
    setShowByEmail(false);
    setAxes(null);
    setType(null);
    setDefaultAxe(null);
    setDescription("");
    setSymbole("");
    setSize("1");
    setSelectedLists([]);
    setRadioRegroupeDefault("1");
    setShowRadioRegroupe(false);
    setShowLastValue(false);
  };

  const handlerChangeDatasource = (data) => {
    setSource(data);
    const { value } = data;
    if (isInteger(parseInt(value))) {
      const index = parseInt(value);
      const src = datasources[index];
      let axesLanda = [];
      let axesEmail = [];
      let metricsLanda = [];
      let timesLanda = [];

      for (let i = 0; i < src.columns.length; i++) {
        const column = src.columns[i];
        if (column.columnType === "indicator") {
          metricsLanda.push({ value: column.idcolumn, label: column.name });
        } else {
          axesLanda.push({ value: column.idcolumn, label: column.name });
        }
        if (parseInt(column.dataType) === 2) {
          axesEmail.push({ value: column.idcolumn, label: column.name });
        }
        if (parseInt(column.dataType) === 3) {
          timesLanda.push({ value: column.idcolumn, label: column.name });
        }
      }

      setOptionsAxe(axesLanda);
      setOptionsAxeEmail(axesEmail);
      setOptionsMetric(metricsLanda);
      setOptionsAxeDate(timesLanda);

      setAxes(null);
      setMetrics(null);
      setAxeDate(null);
      setDefaultAxe(null);
      setAxeEmail(null);

      if (timesLanda.length === 1) {
        setAxeDate(timesLanda[0]);
      }

      if (metricsLanda.length === 1) {
        setMetrics(metricsLanda[0]);
      }
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (
      indicatorNameRef.current &&
      selectedLists.length > 0 &&
      sourceRef.current &&
      Array.isArray(indicateurs) &&
      indicateurs.length > 0 &&
      axeDateRef.current &&
      typeRef.current &&
      axesRef.current &&
      defaultAxeRef.current
    ) {
      const description = descriptionRef.current;
      const name = indicatorNameRef.current;
      const type = typeRef.current.value;
      const datasource = datasourcesRef.current[sourceRef.current.value];

      // const metrics = [];

      // for (let i = 0; i < metricsRef.current.length; i++) {
      //   const metric = metricsRef.current[i];
      //   const column = datasource.columns.find(
      //     (item) => +metric.value === +item.idcolumn
      //   );
      //   if (column) {
      //     metrics.push(column.idcolumn);
      //   }
      // }

      const axeDefault = datasource.columns.find(
        (item) => +defaultAxeRef.current.value === +item.idcolumn
      );

      const axeEmail = axeEmailRef.current
        ? datasource.columns.find(
            (item) => +axeEmailRef.current.value === +item.idcolumn
          )
        : null;

      const axeDate = datasource.columns.find(
        (item) => +axeDateRef.current.value === +item.idcolumn
      );

      const filters = filtersRef.current.filter((item) => item.checked);

      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        if (
          (filter.type === 1 || filter.type === 4) &&
          !filter.max &&
          !filter.min
        ) {
          enqueueSnackbar(`Le filtre ${filter.name} n'est pas renseigné`, {
            variant: "warning",
          });
          return;
        } else if (filter.type === 2 && !filter.values.length > 0) {
          enqueueSnackbar(`Le filtre ${filter.name} n'est pas renseigné`, {
            variant: "warning",
          });
          return;
        }
      }

      const axes = [];
      if (!(parseInt(axeDefault.dataType) === 3 && parseInt(type) === 2)) {
        for (let i = 0; i < axesRef.current.length; i++) {
          const item = axesRef.current[i];
          if (parseInt(item.value) !== parseInt(axeDateRef.current.value)) {
            const id = +item.value;
            const column = datasource.columns.find(
              (item) => +item.idcolumn === +id
            );
            if (column) {
              axes.push(+id);
            }
          }
        }
        dispatch(
          actionUpdateIndicator(
            data.idindicator,
            [...selectedLists.map((item) => item.value)],
            indicateurs.map((metric) => +metric.value),
            parseInt(axeDate.idcolumn),
            parseInt(axeDefault.idcolumn),
            axeEmail ? parseInt(axeEmail.idcolumn) : null,
            [
              ...axes,
              parseInt(axeDate.idcolumn),
              ...(axeEmail ? [parseInt(axeEmail.idcolumn)] : []),
            ],
            filters,
            name,
            description,
            type,
            token,
            operationRef.current.value,
            symboleRef.current,
            sizeRef.current,
            showCumul,
            notShowCumulWithGoal,
            showRadioRegroupeRef.current,
            showLastValue,
            showByEmail,
            radioRegroupeDefaultRef.current,
            (status, indicator) => {
              if (status) {
                window.location.reload();
              }
            }
          )
        );
      } else {
        enqueueSnackbar(
          "Un graph de type Pie ne peut avoir pour axe par défaut une date.",
          {
            variant: "warning",
          }
        );
      }
    } else {
      enqueueSnackbar("Veuillez entrer tous les champs obligatoires", {
        variant: "warning",
      });
    }
  };

  useEffect(() => {
    if (data) {
      async function fetchDataRules() {
        const rules = await getRules(data);

        setRules(rules);
      }
      fetchDataRules();
    }
  }, [data]);

  const getRules = async (data) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    const url = `${host}/view-condition/by-indicator/${data.idindicator}`;

    const response = await fetch(url, {
      method: "GET",
      headers: myHeaders,
    });

    if (response.ok) {
      const result = await response.json();
      const rules =
        result.map((item) => ({
          id: item.id,
          condition: { value: item.condition.id, label: item.condition.name },
          title: item.name,
          isBold: item.appearances[0].bold,
          isItalic: item.appearances[0].italic,
          isUnderline: item.appearances[0].underline,
          fontSize: item.appearances[0].size,
          color: item.appearances[0].color,
          comp: { value: "0", label: "Apparence" },
          target:
            targetOptions.find((t) => +t.value === +item.target) ||
            targetOptions[0],
        })) || [];
      return rules;
    } else {
      return [];
    }
  };

  return (
    <>
      <Modal
        visible={visibility}
        title={"Modifier la vue"}
        onCancel={isUpdate ? null : cancel}
        large={true}
        width={750}
      >
        <form onSubmit={submit}>
          <div className="flex flex-col w-full h-full gap-2 p-0 m-0">
            <div className="flex flex-col gap-2">
              <label for="nom" className="font-poppins font-[400px]">
                Entrer le nom de la vue *
              </label>
              <input
                className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor"
                id="nom"
                type="text"
                value={indicatorName}
                onChange={(e) => setIndicatorName(e.target.value)}
                placeholder="Obligatoire"
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <label for="datasource" className="font-poppins font-[400px]">
                Sélectionner la source de donnée *
              </label>

              <CustomSelect
                id="datasource"
                value={source}
                disabled={true}
                options={optionsDatasources}
                onChange={handlerChangeDatasource}
              />
            </div>
            <div className="flex flex-col flex-1 gap-2">
              <label className="font-poppins font-[400px]">
                Sélectionner type graph
              </label>

              <CustomSelect
                id="type"
                value={type}
                options={GRAPH_TYPES}
                onChange={setType}
              />
            </div>

            <div className="flex justify-between gap-3">
              <div className="flex flex-col flex-1 gap-2">
                <label for="metric" className="font-poppins font-[400px]">
                  Sélectionner indicateurs(s) *
                </label>

                <CustomSelect
                  id="metric"
                  isMulti
                  value={indicateurs}
                  options={indicateurData.map((item) => ({
                    value: item.id,
                    label: item.name,
                    key: item.id,
                  }))}
                  onChange={setIndicateurs}
                />
              </div>
              <div className="flex flex-col flex-1 gap-2">
                <label
                  for="default-axe-date"
                  className="font-poppins font-[400px]"
                >
                  Sélectionner l'axe date par défaut *
                </label>

                <CustomSelect
                  id="default-axe-date"
                  value={axeDate}
                  options={optionsAxeDate}
                  onChange={setAxeDate}
                />
              </div>
            </div>
            <div className="flex justify-between gap-3">
              <div className="flex flex-col flex-1 gap-2">
                <label for="axes" className="font-poppins font-[400px]">
                  Sélectionner axe d'analyse *
                </label>

                <CustomSelect
                  id="axes"
                  isMulti
                  value={axes}
                  options={optionsAxe}
                  onChange={(data) => {
                    setAxes(data);
                    setDefaultAxe(null);
                  }}
                />
              </div>

              <div className="flex flex-col flex-1 gap-2">
                <label for="default-axe" className="font-poppins font-[400px]">
                  Sélectionner l'axe d'analyse par défault
                </label>

                <CustomSelect
                  id="default-axe"
                  value={defaultAxe}
                  options={axes}
                  onChange={(data) => {
                    setDefaultAxe(data);
                    if (axes && data) {
                      const datasource =
                        datasourcesRef.current[sourceRef.current.value];
                      const axeDefault = datasource.columns.find(
                        (item) => +data.value === +item.idcolumn
                      );
                      if (axeDefault.dataType !== "3") {
                        setShowRadioRegroupe(false);
                      }
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex justify-between gap-3 ">
              {board?.type === 2 && (
                <div className="flex flex-col flex-1 gap-2 w-[50%]">
                  <label className="font-poppins font-[400px]">
                    Sélectionner vues *
                  </label>
                  <CustomSelect
                    isMulti
                    value={selectedLists}
                    options={lists.map((item) => {
                      return { value: item.idlist, label: item.name };
                    })}
                    onChange={setSelectedLists}
                  />
                </div>
              )}

              <div className="flex flex-col gap-2 w-[50%]">
                <label for="axe-email" className="font-poppins font-[400px]">
                  Sélectionner l'axe email
                </label>

                <CustomSelect
                  id="axe-email"
                  isClearable
                  value={axeEmail}
                  options={optionsAxeEmail}
                  onChange={setAxeEmail}
                />
              </div>
            </div>
            {/*<div>
              <div>
                <div className="flex justify-end mt-2 mb-2">
                  <div
                    onClick={() => setIsOpen(true)}
                    className="btn-full font-poppins font-[400px] cursor-pointer"
                  >
                    <PlusIcon className="inline-block w-3 h-3 mr-1" />
                    Ajouter une règle
                  </div>
                </div>

                {isOpen && (
                  <Modal
                    visible={isOpen}
                    title={
                      editingId == null
                        ? "Ajouter une règle"
                        : "Modifier une règle"
                    }
                    onCancel={(e) => {
                     
                      handleCancel();
                    }}
                    large={true}
                    width={475}
                  >
                    
                    <div className="w-full max-w-md bg-white rounded-lg">
                      <div className="space-y-4">
                        <div className="flex flex-col gap-2">
                          <label
                            for="nom"
                            className="font-poppins font-[400px]"
                          >
                            Entrer le nom de la règle
                          </label>

                          <input
                            className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor hover:outline-none active:outline-none"
                            id="nom"
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Obligatoire"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <label
                            for="nom"
                            className="font-poppins font-[400px]"
                          >
                            Sélectionner la condition
                          </label>

                          <CustomSelect
                            id="axe-condition"
                            isClearable
                            value={condition}
                            options={optionsConditions}
                            onChange={setCondition}
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <label
                            for="nom"
                            className="font-poppins font-[400px]"
                          >
                            Sélectionner la cible
                          </label>

                          <CustomSelect
                            id="axe-condition"
                            isClearable
                            value={target}
                            options={targetOptions}
                            onChange={setTarget}
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <label
                            for="nom"
                            className="font-poppins font-[400px]"
                          >
                            Sélectionner le comportement
                          </label>

                          <CustomSelect
                            id="axe-comp"
                            isClearable
                            value={comp}
                            disabled
                            options={[{ value: "0", label: "Apparence" }]}
                            onChange={setComp}
                          />
                        </div>

                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Style du texte
                          </label>
                          <div className="flex items-center space-x-4">
                            <button
                              type="button"
                              onClick={() => setIsBold(!isBold)}
                              className={`p-1 rounded ${
                                isBold ? "bg-gray-200" : "hover:bg-gray-100"
                              }`}
                            >
                              <Bold className="w-4 h-4" />
                            </button>
                            <button
                              type="button"
                              onClick={() => setIsItalic(!isItalic)}
                              className={`p-1 rounded ${
                                isItalic ? "bg-gray-200" : "hover:bg-gray-100"
                              }`}
                            >
                              <Italic className="w-4 h-4" />
                            </button>
                            <button
                              type="button"
                              onClick={() => setIsUnderline(!isUnderline)}
                              className={`p-1 rounded ${
                                isUnderline
                                  ? "bg-gray-200 underline"
                                  : "hover:bg-gray-100"
                              }`}
                            >
                              <Underline className="w-4 h-4" />
                            </button>
                            <select
                              value={fontSize}
                              onChange={(e) =>
                                setFontSize(Number(e.target.value))
                              }
                              className="border-gray-300 rounded"
                            >
                              {fontSizes.map((size) => (
                                <option key={size} value={size}>
                                  {size}px
                                </option>
                              ))}
                            </select>
                            <input
                              type="text"
                              value={selectedColor}
                              onChange={(e) => setSelectedColor(e.target.value)}
                              className="w-24 px-2 py-1 text-sm border rounded"
                            />
                            <span
                              type="button"
                              onClick={(e) =>
                                setShowColorPicker(!showColorPicker)
                              }
                              className="w-6 h-6 border rounded"
                              style={{ backgroundColor: selectedColor }}
                            />
                          </div>
                        </div>

                        <div className="flex justify-end space-x-2">
                          <span
                            onClick={handleCancel}
                            className="px-3 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                          >
                            Annuler
                          </span>
                          <span
                            onClick={handleSubmit}
                            className="px-3 py-2 text-sm font-medium text-white bg-blue-500 rounded-md cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            {editingId ? "Modifier" : "Ajouter"}
                          </span>
                        </div>
                      </div>
                    </div>
                   
                  </Modal>
                )}

                <div className="flex flex-wrap p-2 border-gray-200 bg-gray-50">
                  {rules.map((item) => (
                    <div
                      key={item.id}
                      className="m-2 transition-all duration-300 ease-in-out rounded-lg"
                    >
                      <span
                        key={item.id}
                        style={{ backgroundColor: "#05BAF3" }}
                        className="flex items-center px-3 py-1 mb-1 mr-2 text-sm font-medium text-white transition-all duration-300 ease-in-out rounded-full"
                      >
                        {item.title}
                        <span
                          className="ml-2 transition-opacity duration-300 cursor-pointer focus:outline-none hover:opacity-75"
                          onClick={() => handleEdit(item)}
                        >
                          <Edit2 className="w-3 h-3 text-white" />
                        </span>
                        <span
                          className="ml-1 transition-opacity duration-300 cursor-pointer focus:outline-none hover:opacity-75"
                          onClick={() => handleDelete(item.id)}
                        >
                          <X className="w-3 h-3 text-white" />
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>*/}

            {/* <div className="col-12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  marginBottom: "15px",
                }}
              >
                <h4 className="title-4">Filtres</h4>
                <div
                  style={{
                    height: "1px",
                    flex: "2",
                    margin: "0px 10px",
                    border: `1px dashed ${
                      theme === "0" ? "#FFFFFF50" : "#1f212850"
                    }`,
                  }}
                ></div>
              </div>
            </div>

            <div
              className="col-md-6"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <FilterView
                filtersOptions={filters}
                setFilterOptions={setFilters}
              />
              <PopoverAntd
                placement={"right"}
                content={
                  <div>
                    {filters.map((e, index) => (
                      <p>
                        <Checkbox
                          checked={filters[index].checked}
                          onClick={() => {
                            let landa = [...filters];
                            landa[index].checked = !filters[index].checked;
                            setFilters([...landa]);
                          }}
                        >
                          <span
                            style={{
                              color: "#1f2128",
                            }}
                            className="color-black"
                          >
                            {e.name}
                          </span>
                        </Checkbox>
                      </p>
                    ))}
                  </div>
                }
                title="Ajouter un filtre"
                trigger="click"
              >
                <Link to="">
                  <i className="fa-light fa-plus add-filter"></i>
                </Link>
              </PopoverAntd>
            </div> */}

            <div className="mt-3">
              <h4 className="font-poppins font-[400px]">Options d'affichage</h4>
              <div className="flex items-start justify-between w-full h-full">
                <div>
                  <RadioButton
                    onChange={(value) => {}}
                    defaultValue={"1"}
                    value={size}
                    setValue={setSize}
                    options={[
                      { label: "Miniature", value: "0" },
                      { label: "Normal", value: "1" },
                      { label: "En évidence", value: "2" },
                    ]}
                  />
                </div>
                <div>
                  {axeEmail && (
                    <div className="">
                      <Checkbox
                        id="show-by-email"
                        checked={showByEmail}
                        onClick={() => {
                          setShowByEmail(!showByEmail);
                        }}
                      >
                        <span className="font-poppins font-[300px]">
                          Afficher donnée par email
                        </span>
                      </Checkbox>
                    </div>
                  )}
                  {/* <div className="">
                    <Checkbox
                      id="show-cumul"
                      checked={showCumul}
                      onClick={() => {
                        setShowCumul(!showCumul);
                      }}
                    >
                      <span className="font-poppins font-[300px]">
                        Afficher courbe de cumul
                      </span>
                    </Checkbox>
                  </div>
                  <div className="">
                    <Checkbox
                      id="not-show-cumul-with-goal"
                      checked={notShowCumulWithGoal}
                      onClick={() => {
                        setNotShowCumulWithGoal(!notShowCumulWithGoal);
                      }}
                    >
                      <span className="font-poppins font-[300px]">
                        Ne pas afficher cumul avec l'objectif
                      </span>
                    </Checkbox>
                  </div>
                  <div className="">
                    <Checkbox
                      id="idShowLastValue"
                      checked={showLastValue}
                      onClick={() => {
                        setShowLastValue(!showLastValue);
                      }}
                    >
                      <span className="font-poppins font-[300px]">
                        Afficher valeur précédente
                      </span>
                    </Checkbox>
                  </div>
                  <div
                    className=""
                    hidden={(() => {
                      if (sourceRef.current && defaultAxeRef.current) {
                        const datasource =
                          datasourcesRef.current[sourceRef.current.value];
                        const axeDefault = datasource.columns.find(
                          (item) =>
                            +defaultAxeRef.current.value === +item.idcolumn
                        );
                        return (
                          axeDefault.dataType !== "3" || +type?.value === 5
                        );
                      }
                    })()}
                  >
                    <Checkbox
                      checked={showRadioRegroupe}
                      onClick={() => {
                        setShowRadioRegroupe(!showRadioRegroupe);
                      }}
                    >
                      <span className="font-poppins font-[300px]">
                        Afficher periode
                      </span>
                    </Checkbox>
                    <RadioButton
                      defaultValue={"1"}
                      value={radioRegroupeDefault}
                      hidden={!showRadioRegroupe}
                      setValue={setRadioRegroupeDefault}
                      options={[
                        { label: "Jour", value: "1" },
                        { label: "Semaine", value: "4" },
                        { label: "Mois", value: "2" },
                        { label: "Année", value: "3" },
                      ]}
                    />
                  </div> */}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center w-full gap-3 mt-2">
              <button
                className="btn-border close-btn-submit font-poppins font-[400px]"
                type="reset"
                onClick={isUpdate ? null : cancel}
              >
                Annuler
              </button>
              <button
                className="btn-full font-poppins font-[400px]"
                type="submit"
                disabled={isUpdate}
              >
                {isUpdate ? "Modifier..." : "Modifier"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
