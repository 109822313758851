const en = {
  translation: {
    404: "Not found",
    add: "Add",
    add_board: "Add boards",
    add_indicator: "Add a new indicator",
    add_list: "Add list",
    all_required: "All fields are mandatory",
    bad_insert: "An error occurred while inserting",
    bad_login: "An error occurred while logging in",
    bad_register: "An error occurred during registration",
    bar_graph: "Bar graph",
    cancel: "Cancel",
    conflit_register: "Email is already in use",
    connectors: "Connectors",
    data_src: "Data sources",
    delete: "Delete",
    description: "Description",
    empty: "No table",
    good_insert: "Insertion successfully completed",
    good_login: "Connection successfully completed",
    good_register: "Registration successfully completed",
    home: "Home",
    incorrect_identifiers: "incorrect credentials",
    insert_name: "Insert name",
    invite: "Invite",
    invited_title: "Invite on the board",
    language: "Language",
    line_graph: "Line graph",
    mail_title: "E-mail adress",
    manager: "Manager",
    members: "Members",
    min_password: "The password must have at least 8 characters",
    name_new: "Name of new element",
    new_product: "Nouveau produit",
    objectifs: "Objectifs",
    password_not_confirm: "Password and confirmation password are not equal",
    planche: "Boards",
    required: "Please fill in the field",
    select_graph: "Select graph",
    test: "Hello word",
    update: "Update",
    update_indicator: "Update indicator",
  },
};
export default en;
