import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAcceptInvitationNew,
  actionAcceptInvitationTeam,
} from "../../redux/actions/UserAction";
import PhoneInput from "react-phone-input-2";
import { useSnackbar } from "notistack";
import Modal from "../Views/Modal";

export default function Invited() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);
  const [token, setToken] = useState(null);
  const [redirect, setRedirect] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("noreply"));
    setRedirect(searchParams.get("redirect"));
    if (!searchParams.get("noreply")) {
      navigate("/");
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (firstName && lastName && password && confirm) {
      if (password.length >= 8) {
        if (password === confirm) {
          if (redirect === "team") {
            dispatch(
              actionAcceptInvitationTeam(
                firstName,
                lastName,
                password,
                phone,
                token,
                (status) => {
                  if (status === 200) {
                    navigate("/equipes");
                  } else {
                    enqueueSnackbar("Une erreur est survenue.", {
                      variant: "error",
                    });
                  }
                }
              )
            );
          } else {
            dispatch(
              actionAcceptInvitationNew(
                firstName,
                lastName,
                password,
                phone,
                token,
                (status) => {
                  if (status === 200) {
                    navigate("/board");
                  } else {
                    enqueueSnackbar("Une erreur est survenue.", {
                      variant: "error",
                    });
                  }
                }
              )
            );
          }
        } else {
          enqueueSnackbar("Les mots de passe doivent être identiques!", {
            variant: "warning",
          });
          setConfirm("");
        }
      } else {
        enqueueSnackbar(
          "Le mot de passe doit comporter au minimum 8 caractères!",
          {
            variant: "warning",
          }
        );
      }
    } else {
      enqueueSnackbar("Tous les champs sont obligatoires!", {
        variant: "warning",
      });
    }
  };

  return (
    <>
      <Modal
        title={"Inscription"}
        visible={visibility}
        large={true}
        onCancel={() => setVisibility(false)}
      >
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Votre nom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    placeholder="Obligatoire"
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="prenom" className="label-default">
                  Votre prénom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="prenom"
                    id="prenom"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    placeholder="Obligatoire"
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="" className="label-default">
                  Mot de passe
                </label>
                <span className="span-block picto-password">
                  <input
                    id="password"
                    className="input-default inputCustom"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Obligatoire"
                    minLength={8}
                  />
                  <i
                    className="fa-regular fa-eye"
                    onClick={(e) => {
                      if (
                        document.getElementById("password").type === "password"
                      ) {
                        e.target.className = "fa fa-eye-slash";
                        document.getElementById("password").type = "text";
                      } else {
                        e.target.className = "fa-regular fa-eye";
                        document.getElementById("password").type = "password";
                      }
                    }}
                  ></i>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="" className="label-default">
                  Confirmer le mot de passe
                </label>
                <span className="span-block picto-password">
                  <input
                    id="password1"
                    className="input-default inputCustom"
                    type="password"
                    value={confirm}
                    onChange={(e) => setConfirm(e.target.value)}
                    required
                    placeholder="Obligatoire"
                    minLength={8}
                  />
                  <i
                    className="fa-regular fa-eye"
                    onClick={(e) => {
                      if (
                        document.getElementById("password1").type === "password"
                      ) {
                        e.target.className = "fa fa-eye-slash";
                        document.getElementById("password1").type = "text";
                      } else {
                        e.target.className = "fa-regular fa-eye";
                        document.getElementById("password1").type = "password";
                      }
                    }}
                  ></i>
                </span>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="input-container no-margin-md">
                <label for="phone" className="label-default">
                  Votre téléphone (optionnel)
                </label>
                <PhoneInput country={"fr"} value={phone} onChange={setPhone} />
              </div>
            </div> */}
          </div>
          <div className="btn-container">
            <button className="btn-full" type="submit" disabled={loading}>
              {loading ? "Inscription..." : "Inscription"}
            </button>
          </div>
        </form>
      </Modal>

      <main className="intro-pages">
        <div className="filtre"></div>
        <section id="register">
          <div className="container">
            <span className="welcome">Bienvenue sur</span>
            <div className="logo-container"></div>

            <h2 className="title-2">
              Vous avez été invité à rejoindre un Tableau
            </h2>
            <p className="content">
              Bienvenue sur Viabber, un outil d'analyse de données simple,
              rapide, fiable et collaboratif.
            </p>

            <div action="#" className="form-langage">
              <div className="input-container">
                <label className="label-default" for="langue">
                  Langue
                </label>
                <select className="select-border" name="langue" id="langue">
                  <option value="fr">Français</option>
                  <option value="en">Anglais</option>
                </select>
              </div>
              <button
                className="btn-full"
                type="submit"
                onClick={() => setVisibility(true)}
              >
                Accepter l'invitation
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
