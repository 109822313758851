import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Header from "../Header";
import Footer from "../Footer";
import Modal from "../Views/Modal";
import IndicatorView from "../Views/IndicatorView";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  actionSendComment,
  actionSetIndicator,
  actionGetIndicator,
  actionGetTotalByFilter,
  // actionGetDefaultData,
  actionGetDataByFilter,
  actionSetIndicatorData,
} from "../../redux/actions/indicatorAction";
import {
  actionSetTime,
  actionSetTimeValue,
} from "../../redux/actions/timeAction";
import Collapse from "@mui/material/Collapse";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton, Avatar, Tooltip } from "antd";
import stc from "string-to-color";
import { getIntervalByValue } from "../Board";
import ModalAddGoal from "../Views/ModalAddGoal";
import ModalUpdateGoal from "../Views/ModalUpdateGoal";
import GoalList from "../Views/GoalList";
import {
  actionSetGoals,
  actionDeleteGoal,
} from "../../redux/actions/GoalAction";
import { isDate } from "lodash";
import { actionGetTeamsByUser } from "../../redux/actions/teamAction";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Indicator() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const tokenRef = useRef();
  tokenRef.current = token;
  const rows = useSelector((state) => state.indicator.rows);
  const columns = useSelector((state) => state.indicator.columns);
  const isSendComment = useSelector((state) => state.indicator.isSendComment);
  const isGet = useSelector((state) => state.indicator.isGet);
  const commentaires = useSelector((state) => state.indicator.commentaires);
  const indicator = useSelector((state) => state.indicator.indicator);
  const isDeleteGoal = useSelector((state) => state.goal.isDelete);
  const items = useSelector((state) => state.goal.goals);
  const teams = useSelector((state) => state.team.teams);
  const indicatorRef = useRef();
  indicatorRef.current = indicator;
  const email = useSelector((state) => state.user.email);
  const idUser = useSelector((state) => state.user.id);
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [visibilityUpdateGoal, setVisibilityUpdateGoal] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [currentGoal, setCurrentGoal] = useState(null);
  const currentGoalRef = useRef();
  currentGoalRef.current = currentGoal;

  useEffect(() => {
    if (!indicator) {
      const indicator = sessionStorage.getItem("indicator");
      if (!indicator) {
        navigate("/");
      } else {
        dispatch(actionSetTime(getIntervalByValue("3")));
        dispatch(actionSetTimeValue("3"));

        dispatch(
          actionSetIndicatorData({
            interval: getIntervalByValue("3"),
          })
        );
        dispatch(actionSetIndicator(JSON.parse(indicator)));
        dispatch(
          actionGetIndicator(
            JSON.parse(indicator).idindicator,
            (status, response) => {
              if (status === 200) {
                setTimeout(() => {
                  const interval = getIntervalByValue("3");
                  let filter = null;
                  let defaultAxe = null;

                  for (
                    let index = 0;
                    index < response.columns.length;
                    index++
                  ) {
                    const column = response.columns[index];
                    if (column.idcolumn == response.idfilter) {
                      filter = column;
                      break;
                    }
                  }

                  if (response.iddefault) {
                    for (
                      let index = 0;
                      index < response.columns.length;
                      index++
                    ) {
                      const column = response.columns[index];
                      if (column.idcolumn == response.iddefault) {
                        defaultAxe = column;
                        break;
                      }
                    }
                  } else {
                    defaultAxe = { ...filter };
                  }

                  dispatch(
                    actionGetDataByFilter(
                      +defaultAxe.iddatasource,
                      response.metrics.map((item) => item.name),
                      indicator.idindicator,
                      {
                        name: defaultAxe.name,
                        class:
                          parseInt(defaultAxe.dataType) === 3
                            ? "time"
                            : "dimensions",
                      },
                      [
                        {
                          name: filter.name,
                          type: 3,
                          class: "time",
                          interval: [
                            new Date(interval[0]).toISOString().split(".")[0],
                            new Date(interval[1]).toISOString().split(".")[0],
                          ],
                        },
                      ],
                      (status, result) => {
                        if (status === 200) {
                          if (result) {
                            const indicator = response;
                            indicator.data = result;

                            dispatch(
                              actionSetIndicatorData({
                                data: result,
                                indicator,
                                isGet: false,
                              })
                            );
                          }
                        }
                      }
                    )
                  );

                  // if (response.showlastvalue) {
                  //   const debut = new Date(interval[0]).getTime();
                  //   const fin = new Date(interval[1]).getTime();
                  //   const ecart = fin - debut;

                  //   setTimeout(() => {
                  //     dispatch(
                  //       actionGetTotalByFilter(
                  //         parseInt(metric.iddatasource),
                  //         metric.name,
                  //         {
                  //           name: defaultAxe.name,
                  //           class:
                  //             parseInt(defaultAxe.dataType) === 3
                  //               ? "time"
                  //               : "dimensions",
                  //         },
                  //         [
                  //           {
                  //             name: filter.name,
                  //             type: 3,
                  //             class: "time",
                  //             interval: [
                  //               new Date(debut - ecart)
                  //                 .toISOString()
                  //                 .split(".")[0],
                  //               new Date(fin - ecart)
                  //                 .toISOString()
                  //                 .split(".")[0],
                  //             ],
                  //           },
                  //         ],
                  //         (status, result) => {
                  //           if (status === 200) {
                  //             if (result) {
                  //               let total = 0;
                  //               if (result.length > 0) {
                  //                 if (
                  //                   parseInt(indicatorRef.current.operation) !==
                  //                   2
                  //                 ) {
                  //                   total = result[0].value;
                  //                 } else {
                  //                   total = result[0].avg;
                  //                 }
                  //               }
                  //               dispatch(
                  //                 actionSetIndicatorData({
                  //                   indicator: {
                  //                     ...indicatorRef.current,
                  //                     lastValue: total,
                  //                   },
                  //                 })
                  //               );
                  //             }
                  //           }
                  //         }
                  //       )
                  //     );
                  //   }, 100);
                  // }
                }, 100);
              }
            }
          )
        );
      }
    } else {
      document.getElementById("chart").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  }, [indicator]);

  const sendComment = (e) => {
    e.preventDefault();
    if (description && email && idUser && indicator && indicator.idindicator) {
      if (!isSendComment) {
        dispatch(
          actionSendComment(
            email,
            idUser,
            indicator.idindicator,
            description,
            (status) => {
              if (status === 200) {
                setDescription("");
              }
            }
          )
        );
      }
    }
  };

  const cancel = () => {
    setVisibility(false);
  };
  let usersTeams = [];
  const users = [];
  for (let i = 0; i < teams.length; i++) {
    usersTeams.push(
      ...teams[i].userTeams.map((u) => {
        return {
          label: `${u.user.firstName} ${u.user.lastName}`,
          value: u.user.id,
          key: u.user.id,
        };
      })
    );
  }
  usersTeams = usersTeams.filter((t) => +t.value !== +idUser);
  for (let i = 0; i < usersTeams.length; i++) {
    const element = usersTeams[i];
    if (!users.find((u) => +u.value === element.value)) users.push(element);
  }
  useEffect(() => {
    dispatch(actionGetTeamsByUser(idUser, token));
  }, [dispatch, idUser, token]);

  return (
    <div>
      <Modal
        visible={deleteVisibility}
        title={
          currentGoal
            ? `Supprimer l'objectif ${currentGoal.name}`
            : "Confirmer la suppression"
        }
        onCancel={isDeleteGoal ? null : () => setDeleteVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDeleteGoal ? null : () => setDeleteVisibility(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDeleteGoal}
                onClick={() => {
                  dispatch(
                    actionDeleteGoal(
                      currentGoalRef.current.idgoal,
                      tokenRef.current,
                      (status) => {
                        if (status === 200) {
                          setDeleteVisibility(false);
                          setCurrentGoal(null);
                        }
                      }
                    )
                  );
                }}
              >
                {isDeleteGoal ? "Supprimer..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <ModalAddGoal
        visibility={visibility}
        onCancel={cancel}
        users={users || []}
      />
      <ModalUpdateGoal
        goal={currentGoal}
        users={users || []}
        visibility={visibilityUpdateGoal}
        onCancel={() => {
          setVisibilityUpdateGoal(false);
          setCurrentGoal(null);
        }}
      />

      <Header />
      <main>
        <div className="container mt-[40px]">
          <div className="container-fluid" id="viabber-board-container">
            <div className="row actions-nav" style={{ marginTop: "20px" }}>
              {idUser ? (
                <Link to="/board" title="Tableau de board" target="_self">
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "25px",
                      backgroundColor: "#05baf375",
                      marginRight: "10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{ color: "#FFF" }}
                    ></i>
                  </div>
                </Link>
              ) : null}
              <h1
                className="title-1"
                style={{ fontWeight: "700", fontSize: "22px" }}
              >
                {indicator && indicator.name}
              </h1>
            </div>
          </div>
          <div className="row" id="chart" style={{ paddingTop: "80px" }}>
            <div className="col-lg-9">
              <div className="bloc-fond-fonce2 rose">
                <div className="row wrap-stats-objectifs">
                  <div className="col-md stats bloc-fond-fonce-indicator">
                    <IndicatorView large />
                  </div>
                  {/* <div
                    className="col-lg-auto objectif"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div className="bloc-border-blanc-inner large">
                      <p className="objectif">
                        Objectif <br />
                        au 30/11/2021
                      </p>
                      <div id="chiffre-1" className="gpercent"></div>
                      <p className="t-center">1165</p>
                    </div>
                    <div className="legende">
                      <ul>
                        <li>
                          <p>
                            <span></span>Successful Deals
                          </p>
                          <i>78%</i>
                        </li>
                        <li>
                          <p>
                            <span className="rose"></span>Failed Deals
                          </p>
                          <i>12%</i>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              {/* <p className="sub-title">Options</p> */}
              <div className="options-list add">
                <p>Ajouter à l’indicateur</p>
                <ul>
                  <li>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        setVisibility(true);
                      }}
                    >
                      Objectifs
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      className={!collapse ? "" : "added"}
                      onClick={(e) => {
                        e.preventDefault();
                        setCollapse(!collapse);
                      }}
                    >
                      Données
                    </a>
                  </li>
                  <div
                    style={{ overflow: "auto", maxHeight: "200px" }}
                    className="custom-scroll-bar-list"
                  >
                    <Collapse orientation="vertical" in={collapse}>
                      <table className="display responsive">
                        <thead>
                          <tr>
                            {columns?.map((item) => (
                              <th>
                                <span title={item.label}>{item.label}</span>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {rows?.map((item) => (
                            <tr>
                              {/* <td>{item[0]}</td>
                            <td>{item[1]}</td> */}
                              {item?.map((value) => (
                                <td title={value}>{`${
                                  isDate(value)
                                    ? value.toISOString().split("T")[0]
                                    : value
                                }`}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* <li>
                      <a
                        href=""
                        className="added"
                        onClick={(e) => e.preventDefault()}
                      >
                        Saisie manuelle
                      </a>
                    </li> */}
                    </Collapse>
                  </div>
                </ul>
              </div>
              {/* <div className="options-list">
                <p>Action</p>
                <ul>
                  <li>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <i className="fa-light fa-share-nodes"></i>Partager
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <i className="fa-light fa-pen-to-square"></i>Update
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={(e) => e.preventDefault()}>
                      <i className="fa-light fa-trash-can"></i>Supprimer
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <br id="reference" />
          {items.length > 0 ? (
            <div className="row">
              <div className="col-lg-9" id="h-tab-goals">
                <div className="bloc-fond-fonce2 ">
                  <br />
                  <GoalList
                    items={items}
                    setItems={(data) => {
                      dispatch(actionSetGoals({ type: "ALL", data }));
                    }}
                    onDelete={(item) => {
                      setCurrentGoal(item);
                      setDeleteVisibility(true);
                    }}
                    onUpdate={(item) => {
                      setCurrentGoal(item);
                      setVisibilityUpdateGoal(true);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <br />
          <br />
          <div className="row">
            <div className="col-lg-9">
              <div className="commentaires">
                <p className="sub-title">Commentaires</p>
                {Boolean(email) ? (
                  <div className="add-com">
                    <img src="./styles/img/user-2.jpg" alt="Prénom Nom" />
                    <form action="" onSubmit={sendComment}>
                      <span>
                        <input
                          type="text"
                          required
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="input-blanc"
                          placeholder="Ecrivez un commentaire…"
                          style={{
                            // color: "#000"
                            color:
                              theme === "0"
                                ? "#000"
                                : theme === "1"
                                ? "#1f2128"
                                : "#1f2128",
                            backgroundColor:
                              theme === "0"
                                ? "#ffffff"
                                : theme === "1"
                                ? "#f3f5f8"
                                : "#f3f5f8",
                          }}
                        />
                      </span>
                      <button
                        type="submit"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        disabled={isSendComment}
                      >
                        <i
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className={
                            isSendComment ? "" : "fa-light fa-paper-plane-top"
                          }
                        >
                          {isSendComment ? <LoadingOutlined /> : null}
                        </i>
                      </button>
                    </form>
                  </div>
                ) : null}
                <div className="coms">
                  {isGet ? (
                    <>
                      <Skeleton avatar paragraph={{ rows: 1 }} active />
                      <br />
                      <Skeleton avatar paragraph={{ rows: 1 }} active />
                    </>
                  ) : (
                    commentaires.map((item) => (
                      <div className="com">
                        <div
                          style={{
                            // width: "44px",
                            // height: "44px",
                            margin: "0 20px",
                          }}
                        >
                          <Avatar.Group
                            maxCount={1}
                            size="large"
                            maxStyle={{
                              color: "#f56a00",
                              backgroundColor: "#fde3cf",
                            }}
                          >
                            <Tooltip
                              title={
                                item.user.firstName + " " + item.user.lastName
                              }
                              placement="bottomLeft"
                            >
                              <Avatar
                                style={{
                                  backgroundColor: stc(
                                    item.user.firstName +
                                      item.user.lastName +
                                      item.user.email
                                  ),
                                }}
                              >
                                {item.user.firstName.substr(0, 1) +
                                  " " +
                                  item.user.lastName.substr(0, 1)}
                              </Avatar>
                            </Tooltip>
                          </Avatar.Group>
                          {/* <Avatar
                            style={{
                              backgroundColor: stc(
                                item.user.firstName +
                                  item.user.lastName +
                                  item.user.email
                              ),
                              // width: "44px",
                              // height: "44px",
                              // margin: "0 20px",
                            }}
                          >
                            {item.user.firstName.substr(0, 1) +
                              " " +
                              item.user.lastName.substr(0, 1)}
                          </Avatar> */}
                        </div>
                        {/* <img src="./styles/img/user-4.jpg" alt="Prénom Nom" /> */}
                        <div className="texte">
                          <span className="com-span">
                            {item.email} - {item.date.substring(10, 0)}
                          </span>
                          <p className="com-p">{item.description}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                {/* <div className="pagination">
                  <a href="">
                    <i className="fa-regular fa-angle-left"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="#">1</a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li>
                      <a href="#">4</a>
                    </li>
                  </ul>
                  <a href="">
                    <i className="fa-regular fa-angle-right"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
