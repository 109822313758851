import React, { useState } from "react";
import styled from "styled-components";
import { TeamOutlined, UserAddOutlined } from "@ant-design/icons";
import { MinusOutlined } from "@ant-design/icons";
import Pagination from "./Pagination";
import { useSelector, useDispatch } from "react-redux";
import { AspectRatioRounded, CloseOutlined } from "@material-ui/icons";
import Modal from "../Views/Modal";
import { useSnackbar } from "notistack";
import Photo from "../../img/user-1.jpg";
import {
  actionAddSubTeam,
  actionRemoveSubTeam,
} from "../../redux/actions/teamAction";
import CustomSelect from "../Views/CustomSelect";

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

function ListCard({ subteams, color, role, id }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector((state) => state.user.token);
  const isLoading = useSelector((state) => state.team.isUpdateTeamUser);
  const teams = useSelector((state) => state.team.teams);
  const [team, setTeam] = useState(null);
  const parent = teams.find((t) => +t.idteam === +id);
  let formattedTeams = teams.map((team) => {
    return { label: team.name, value: team.idteam };
  });
  if (id) {
    formattedTeams = formattedTeams.filter((t) => t.value !== id);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [visibility, setVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);

  const cardsPerPage = 7;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = subteams.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onInvite = (e) => {
    e.preventDefault();
    dispatch(
      actionAddSubTeam(id, team.value, token, (is) => {
        if (is) {
          setVisibility(false);
          enqueueSnackbar("L'équipe a été rajouter ave succès.", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(
            "Une erreur est survenue lors l'envoie de l'invitation.",
            {
              variant: "warning",
            }
          );
        }
      })
    );
  };

  const show = () => {
    setTeam(null);
    setVisibility(true);
  };

  const onDelete = (e) => {
    e.preventDefault();
    dispatch(
      actionRemoveSubTeam(id, team.idteam, token, (is) => {
        if (is) {
          setDeleteVisibility(false);
          setTeam(null);
          enqueueSnackbar("L'équipe a bien été retirer de l'équipe.", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Une erreur est survenue.", {
            variant: "error",
          });
        }
      })
    );
  };

  return (
    <>
      {team && (
        <Modal
          visible={deleteVisibility}
          title={`Retirer de l'équipe "${team.name}" `}
          onCancel={
            isLoading
              ? null
              : () => {
                  setTeam(null);
                  setDeleteVisibility(false);
                }
          }
        >
          <div className="row">
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={
                    isLoading
                      ? null
                      : () => {
                          setTeam(null);
                          setDeleteVisibility(false);
                        }
                  }
                >
                  Annuler
                </button>
                <button
                  className="btn-full"
                  disabled={isLoading}
                  onClick={onDelete}
                >
                  {isLoading ? "Retirer..." : "Retirer"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <Modal
        visible={visibility}
        height={"400px"}
        title={"Ajouter une sous-équipe"}
        onCancel={isLoading ? null : () => setVisibility(false)}
      >
        <div className="row">
          <div className="col-md-12">
            <label className="label-default">
              Sélectionner l'équipe à rajouter
            </label>
            <CustomSelect
              id="teams"
              // isClearable
              value={team}
              style={{ textSize: "5px" }}
              options={formattedTeams}
              onChange={(val) => {
                setTeam(val);
              }}
            />
          </div>

          <div className="col-md-12 wrap-btn-modal">
            <ButtonGroup>
              <button
                className="btn-border close-btn-submit"
                onClick={isLoading ? null : () => setVisibility(false)}
              >
                Fermer
              </button>
              <button
                className="btn-full"
                disabled={isLoading}
                onClick={onInvite}
              >
                {isLoading ? "Ajouter le membre..." : "Ajouter le membre"}
              </button>
            </ButtonGroup>
          </div>
        </div>
      </Modal>

      <div>
        <p className="label-default" style={{ marginTop: 10 }}>
          Liste des sous équipes ({subteams.length})
        </p>
        <List>
          {role === "OWNER" && (
            <AddCard
              onClick={show}
              style={
                color === "0"
                  ? {
                      backgroundColor: "#323541",
                    }
                  : {
                      backgroundColor: "#e7e7e7",
                    }
              }
            >
              <Text>Ajouter une équipe</Text>
              <span>
                <TeamOutlined
                  style={{
                    color: "rgb(84, 183, 237)",
                    fontSize: 30,
                  }}
                />
              </span>
            </AddCard>
          )}
          {currentCards.map((team, index) => {
            return (
              <CardContainer key={`team-${team.idteam}`}>
                {parent.role === "OWNER" && (
                  <DeleteButton
                    onClick={() => {
                      setTeam(team);

                      setDeleteVisibility(true);
                    }}
                  >
                    <CloseOutlined color="white" fontSize="25px" />
                  </DeleteButton>
                )}
                <CardTop>
                  <CardImg
                    src={index % 2 === 0 ? "./planche2.jpg" : "./planche1.jpg"}
                  />
                </CardTop>
                <CardBottom>
                  <CardBottomContent>
                    <Title className="title-4">{team.name}</Title>
                  </CardBottomContent>
                  {/* <CardText> */}
                </CardBottom>
              </CardContainer>
            );
          })}
        </List>
        {subteams.length > 7 ? (
          <Pagination
            cardsPerPage={cardsPerPage}
            totalCards={subteams.length}
            currentPage={currentPage}
            paginate={paginate}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

const ButtonGroup = styled.div`
  margin-top: 50px;
  column-gap: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Text = styled.div`
  font-size: 0.8rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 4px;
`;

const AddCard = styled.div`
  height: 162px;
  background-color: #e7e7e7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 7px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 0.9rem;
  text-align: center;
`;

const CardTop = styled.div`
  width: 100%;
  height: 50%;
`;

const CardBottom = styled.div`
  height: 15%;
  display: flex;
  justify-content: space-between;
  padding: 2px 8px;
  border-radius: 0 0 8px 8px;
  background: ${theme === "0" ? "#323541" : "#e0e0e0"};
`;
const CardBottomContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 3;
  height: 100%;
`;
const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  height: 250px;
  position: relative;
`;
// const Title = styled.h1`
//   font-size: 16px;
//   margin-bottom: 0;
//   padding: 0;
//   font-weight: 600;
//   margin-bottom: 2px;
// `;
const CardImg = styled.img`
  background-position-y: center;
  background-size: cover;
  background-position-x: center;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;
const Span = styled.span`
  font-size: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CardText = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 3;
  align-items: center;
`;
const DeleteButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 25px;
  text-align: center;
  border-radius: 5px;
  background-color: #ff725e;
  color: #6d1f1f;
  cursor: pointer;
`;
export default ListCard;
