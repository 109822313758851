import { array } from "zod";
import host from "./host";

import { actionSetWorkpsace as actionDefaultWorkspace } from "./UserAction";

export const ACTION_ADD_WORKSPACE = "ACTION_ADD_WORKSPACE";

export const ACTION_IS_ADD_WORKSPACE = "ACTION_IS_ADD_WORKSPACE";

export const ACTION_IS_DELETE_WORKSPACE = "ACTION_IS_DELETE_WORKSPACE";

export const ACTION_IS_GET_WORKSPACE = "ACTION_IS_GET_WORKSPACE";

export const ACTION_IS_UPDATE_WORKSPACE = "ACTION_IS_UPDATE_WORKSPACE";

export const ACTION_UPDATE_WORKSPACE = "ACTION_UPDATE_WORKSPACE";

export const ACTION_SET_ALL_WORKSPACE = "ACTION_SET_ALL_WORKSPACE";

export const ACTION_REMOVE_WORKSPACE = "ACTION_REMOVE_WORKSPACE";

export const ACTION_IS_SEND_INVITATION = "ACTION_IS_SEND_INVITATION";

export const ACTION_IS_CHANGE_ROLE = "ACTION_IS_CHANGE_ROLE";

export const ACTION_CHANGE_ROLE = "ACTION_CHANGE_ROLE";

export const ACTION_IS_REMOVE_USER = "ACTION_IS_REMOVE_USER";

export const ACTION_REMOVE_USER = "ACTION_REMOVE_USER";

export const actionAddWorkspace = (payload) => ({
  type: ACTION_ADD_WORKSPACE,
  payload: payload,
});

export const actionRemoveWorkspace = (payload) => ({
  type: ACTION_REMOVE_WORKSPACE,
  payload: payload,
});

export const actionSetAllWorkspace = (payload) => ({
  type: ACTION_SET_ALL_WORKSPACE,
  payload: payload,
});

export const actionIsAddWorkspace = (payload) => ({
  type: ACTION_IS_ADD_WORKSPACE,
  payload: payload,
});

export const actionIsDeleteWorkspace = (payload) => ({
  type: ACTION_IS_DELETE_WORKSPACE,
  payload: payload,
});

export const actionIsUpdateWorkspace = (payload) => ({
  type: ACTION_IS_UPDATE_WORKSPACE,
  payload: payload,
});

export const actionIsGetWorkspace = (payload) => ({
  type: ACTION_IS_GET_WORKSPACE,
  payload: payload,
});

export const actionIsSendInvitation = (payload) => ({
  type: ACTION_IS_SEND_INVITATION,
  payload: payload,
});

export const actionIsChangeRole = (payload) => ({
  type: ACTION_IS_CHANGE_ROLE,
  payload: payload,
});

export const actionChangeRole = (payload) => ({
  type: ACTION_CHANGE_ROLE,
  payload: payload,
});

export const actionIsRemoveUser = (payload) => ({
  type: ACTION_IS_REMOVE_USER,
  payload: payload,
});

export const actionRemoveUser = (payload) => ({
  type: ACTION_REMOVE_USER,
  payload: payload,
});

export const addWorkspace = (payload) => ({
  type: ACTION_ADD_WORKSPACE,
  payload: payload,
});

export const actionSetWorkspace = (payload) => ({
  type: ACTION_UPDATE_WORKSPACE,
  payload: payload,
});

export const actionCreateWorkspace = (name, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsAddWorkspace(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        name,
      }),
      redirect: "follow",
    };

    fetch(`${host}/workspace/create`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { status, data } = result;
        callback(result);
        if (status) {
          dispatch(actionAddWorkspace(data));
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actionIsAddWorkspace(false));
      });
  };
};

export const actionUpdateWorkspace = (id, name, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsUpdateWorkspace(true));

    let myHeaders = new Headers();
    // console.log(token);
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/workspace/update/${id}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data } = result;
        callback(result);
        if (result) {
          dispatch(actionSetWorkspace(data));
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch(actionIsUpdateWorkspace(false));
      });
  };
};

export const actionGetWorkspaceByCompany = (token, callback) => {
  return (dispatch) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/workspace/get-by-company`, requestOptions)
      .then((response) => {
        return response.json() || [];
      })
      .then((result) => {
        const { data, status } = result;

        callback(result);
        if (status) {
          if (data.length > 0 && data !== null) {
            const iddatasource = data[0].id;
            console.log(iddatasource);
            localStorage.setItem("idworkspace", iddatasource);
            dispatch(actionDefaultWorkspace(iddatasource));
          }
          dispatch(actionSetAllWorkspace(data));
        }
      })
      .catch(() => {});
  };
};

export const actionDeleteWorkspace = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsDeleteWorkspace(true));
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/workspace/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        callback(result);
        if (status) {
          dispatch(actionRemoveWorkspace(id));
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch(actionIsDeleteWorkspace(false));
      });
  };
};

export const actionSendInvitationWorkspace = (
  email,
  role,
  idcompany,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsSendInvitation(true));
    const myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const emails = [{ email, role }];

    const raw = JSON.stringify({ emails, idcompany });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/workspace/send-invitation`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        callback(result);
        if (status) {
          console.log(result);
          // dispatch(actionGetUsers(data));
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actionIsSendInvitation(false)));
  };
};

export const actionChangeRoleWorkspace = (id, role, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsChangeRole(true));
    const myHeaders = new Headers();

    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ id, role });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/workspace/change-user-role`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        callback(result);
        if (status) {
          dispatch(actionChangeRole(data));
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actionIsChangeRole(false)));
  };
};
export const actionRemoveUserWorkspace = (id, idworkspace, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsRemoveUser(true));
    const myHeaders = new Headers();

    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(`${host}/workspace/remove-user/${id}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const { data, status } = result;
        callback(result);
        if (status) {
          dispatch(actionRemoveUser({ id, idworkspace }));
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actionIsRemoveUser(false)));
  };
};
