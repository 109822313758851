import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Card from "./Card";
import Modal from "../Views/Modal";
import CardXLarge from "./CardXLarge";
import CardLarge from "./CardLarge";
import CardMini from "./CardMini";
import Popover from "@mui/material/Popover";
import { Droppable, Draggable } from "react-dnd-beautiful";
import { EditText } from "react-edit-text";
import {
  actionisDeleteList,
  actionDeleteList,
  actionUpdateList,
} from "../../redux/actions/listAction";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function List({
  data,
  isLarge,
  key,
  index,
  onAddIndicator,
  setIdList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lists = useSelector((state) => state.list.lists);
  const isDelete = useSelector((state) => state.list.isDelete);
  const isViewOnly = useSelector((state) => state.board.isViewOnly);
  const idUser = useSelector((state) => state.user.id);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const token = useSelector((state) => state.user.token);

  const confirmDeleteList = () => {
    if (data.idlist && data.name) {
      dispatch(
        actionDeleteList(data.idlist, token, (status) => {
          if (status) {
            //  alert("Liste suprrimer !");
            setDeleteVisible(false);
            enqueueSnackbar("Cette liste a été supprimé", {
              variant: "success",
            });
          } else {
            setDeleteVisible(false);
            enqueueSnackbar("Cette liste n'est pas supprimé !", {
              variant: "warning",
            });
          }
        })
      );
    }
    //  setDeleteVisible(false);
  };

  const [isDraggable, setIsDraggable] = useState(true);
  const [col1, setCol1] = useState([]);
  const [col2, setCol2] = useState([]);
  const [label, setLabel] = useState(data.name);

  const update = () => {
    setAnchorEl(null);
    let element = document.getElementById(
      `input-default-text-editable-${data.idlist}`
    );
    let evObj = document.createEvent("Events");
    evObj.initEvent("click", true, false);
    element.dispatchEvent(evObj);
    setTimeout(() => {
      document
        .getElementById(`input-default-text-editable-${data.idlist}`)
        .focus();
    }, 250);
  };

  const [priorities, setPriorities] = useState([]);
  const [minima, setMinima] = useState([]);
  const [normaux, setNormaux] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [landa, setLanda] = useState("title-11");

  const listUpdater = (listname, listid) => {
    if (listid && listname) {
      dispatch(
        actionUpdateList(
          listname,
          true,
          listid,
          data.positionx,
          data.positiony,
          token,
          (status) => {
            if (status) {
            }
          }
        )
      );
    }
  };

  // const ouverture = ()=>{
  //   setOpen(null);
  //   setDeleteVisible(true);
  // }

  const open = Boolean(anchorEl);
  const tag = open ? `simple-list-popover${data.idlist}` : undefined;
  // const [tag, setTag] = useState(open ? `simple-list-popover${data.idlist}` : undefined)
  let theme = "1";
  const data2 = localStorage.getItem("theme");
  if (data2) {
    theme = JSON.parse(data2).theme;
  }

  useEffect(() => {
    const col1 = [];
    const col2 = [];

    const minima = _.filter(data.indicators, (o) => {
      return o.taille === "0";
    });

    const priorities = _.filter(data.indicators, (o) => {
      return o.taille === "2" || (o.taille === null && o.large);
    });

    const finaleData = _.filter(data.indicators, (o) => {
      return (
        (o.taille !== "0" && o.taille !== "2") ||
        (o.taille === null && !o.large)
      );
    });

    for (let i = 0; i < finaleData.length; i++) {
      const element = finaleData[i];
      if (i % 2 === 0) {
        col1.push(element);
      } else {
        col2.push(element);
      }
    }
    setCol2(col2);
    setCol1(col1);
    setMinima(minima);
    setNormaux(finaleData);
    setPriorities(priorities);
  }, [data, lists]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Draggable
        draggableId={data.idlist + "-list"}
        index={index}
        isDragDisabled={isViewOnly || !idUser || !isDraggable}
      >
        {(provided) => (
          <div
            className={
              isLarge || priorities.length > 0
                ? "col-xl-7 col-md-12 col-12"
                : "col-xl-4 col-md-6 col-9"
            }
            key={key}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div
              className={
                isLarge ? "bloc-border-blanc bloc-large" : "bloc-border-blanc"
              }
              style={{
                transitionDuration: "0s",
                // height:"50%"
                // maxHeight: "95%",
              }}
            >
              <div className="bloc-head">
                {/* <p>Jerom</p> */}
                {/* {isOwner ? ( */}
                <EditText
                  id={`input-default-text-editable-${data.idlist}`}
                  defaultValue={data.name}
                  value={label}
                  style={{ fontSize: "16px" }}
                  onChange={setLabel}
                  // className={landa}
                  className="dcolor"
                  type="text"
                  onBlur={() => setLanda("title-55")}
                  onEditMode={() => setLanda("input-default-text-editable")}
                  onSave={() => {
                    if (!label) {
                      setLabel(data.name);
                    } else {
                      listUpdater(label, data.idlist);
                    }
                  }}
                />
                {/* ) : (
                  <h5 className="title-5">{name}</h5>
                )} */}
                {/* <p>{data.name}</p> */}
                <i
                  aria-describedby={tag}
                  onClick={handleClick}
                  className="fa-light fa-ellipsis more"
                  style={{
                    color:
                      theme === "0"
                        ? "#fff"
                        : theme === "1"
                        ? "#1f2128"
                        : "#ffffff",
                  }}
                ></i>
                <Popover
                  id={tag}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                >
                  <div className="mui-popover-custom">
                    <ul>
                      <li>
                        <a
                          href="#"
                          title="Modifier"
                          className="mui-popover-item"
                          onClick={update}
                          // onClick={(e) => e.preventDefault()}
                        >
                          Modifier
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Inviter à rejoindre"
                          className="mui-popover-item"
                          onClick={(e) => e.preventDefault()}
                        >
                          Inviter à rejoindre
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Supprimer"
                          className="mui-popover-item"
                          onClick={() => {
                            handleClose();
                            setDeleteVisible(true);
                          }}
                        >
                          Supprimer
                        </a>
                      </li>
                    </ul>
                  </div>
                </Popover>
              </div>

              <div
                className="custom-scroll-bar-list"
                style={{
                  transitionDuration: "0s",
                  paddingTop: "5px",
                  // maxHeight: "20%",
                  // maxHeight: `${(window.screen.height * 45) / 100}px`,
                  maxHeight: `${window.screen.height - 400}px`,
                  // maxHeight: "480px",
                  // backgroundColor: "yellow",
                  overflowY: "auto",
                  overflowX: "hidden",
                  borderRadius: "8px",
                }}
              >
                {minima.length > 0 ? (
                  <Droppable
                    droppableId={data.idlist + "-t"}
                    direction="horizontal"
                    isDropDisabled={isViewOnly || !idUser}
                  >
                    {(provided) => (
                      <div
                        className="row"
                        ref={provided.innerRef}
                        style={{
                          height: "75px",
                          transitionDuration: "0s",
                          display: "flex",
                          flexDirection: "row",
                          margin: "0px 0px 10px 0px",
                        }}
                        {...provided.droppableProps}
                      >
                        {minima.map((indicator, index) => {
                          return (
                            <CardMini
                              isLarge={isLarge}
                              data={indicator}
                              key={indicator.idindicator}
                              index={index}
                              setIsDraggable={setIsDraggable}
                              isDraggable={isDraggable}
                            />
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ) : null}

                {priorities.length > 0 ? (
                  <Droppable
                    droppableId={data.idlist + ""}
                    isDropDisabled={isViewOnly || !idUser}
                  >
                    {(provided) => (
                      <div
                        className="row"
                        ref={provided.innerRef}
                        style={{ minHeight: "50px", transitionDuration: "0s" }}
                        {...provided.droppableProps}
                      >
                        {data.indicators.length === 1
                          ? priorities.map((indicator, index) => {
                              return (
                                <CardXLarge
                                  isLarge={isLarge}
                                  data={indicator}
                                  key={indicator.idindicator}
                                  index={index}
                                  setIsDraggable={setIsDraggable}
                                  isDraggable={isDraggable}
                                />
                              );
                            })
                          : priorities.map((indicator, index) => {
                              return (
                                <CardLarge
                                  isLarge={isLarge}
                                  data={indicator}
                                  key={indicator.idindicator}
                                  index={index}
                                  setIsDraggable={setIsDraggable}
                                  isDraggable={isDraggable}
                                />
                              );
                            })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ) : null}

                {!isLarge && !(priorities.length > 0) ? (
                  <Droppable
                    droppableId={data.idlist + ""}
                    isDropDisabled={isViewOnly || !idUser}
                  >
                    {(provided) => (
                      <div
                        className="row"
                        ref={provided.innerRef}
                        style={{ minHeight: "50px", transitionDuration: "0s" }}
                        {...provided.droppableProps}
                      >
                        {normaux.map((indicator, index) => {
                          return (
                            <Card
                              isLarge={isLarge}
                              data={indicator}
                              key={indicator.idindicator}
                              index={index}
                              setIsDraggable={setIsDraggable}
                              isDraggable={isDraggable}
                            />
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ) : (
                  <div className="row">
                    <div className="col-6">
                      <Droppable
                        droppableId={data.idlist + "-l"}
                        direction="vertical"
                        isDropDisabled={isViewOnly || !idUser}
                      >
                        {(provided) => (
                          <div
                            className="row"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{ transitionDuration: "0s" }}
                          >
                            {col1.map((indicator, index) => {
                              return (
                                <Card
                                  isLarge={isLarge}
                                  data={indicator}
                                  key={indicator.idindicator}
                                  index={index}
                                  setIsDraggable={setIsDraggable}
                                  isDraggable={isDraggable}
                                />
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                    <div className="col-6">
                      <Droppable
                        droppableId={data.idlist + "-r"}
                        isDropDisabled={isViewOnly || !idUser}
                        direction="vertical"
                      >
                        {(provided) => (
                          <div
                            className="row"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{ transitionDuration: "0s" }}
                          >
                            {col2.map((indicator, index) => {
                              return (
                                <Card
                                  isLarge={isLarge}
                                  data={indicator}
                                  key={indicator.idindicator}
                                  index={index}
                                  setIsDraggable={setIsDraggable}
                                  isDraggable={isDraggable}
                                />
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                )}
              </div>

              {/* <button
                  to={""}
                  target="_self"
                  className="btn-border-rose with-picto-droite"
                  onClick={() => {
                    onAddIndicator(true);
                    setIdList(data.idlist);
                  }}
                >
                  <i className="fa-solid fa-plus">
                  </i> 
                  <span style={{color: "#000"}}>Ajouter un indicateur</span>  
                </button> */}

              {idUser && !isViewOnly ? (
                <div>
                  <Link
                    to={""}
                    title="Ajouter une vue"
                    target="_self"
                    className="btn-border-rose with-picto-droite"
                    onClick={(e) => {
                      e.preventDefault();
                      onAddIndicator(true);
                      setIdList(data.idlist);
                    }}
                  >
                    <i className="fa-solid fa-plus"></i>
                    <span style={{ color: "#000" }}>Ajouter une vue</span>
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Draggable>

      <Modal
        visible={deleteVisible}
        title={"Confirmer la suppression"}
        onCancel={isDelete ? null : () => setDeleteVisible(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                disabled={isDelete}
                onClick={isDelete ? null : () => setDeleteVisible(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDelete}
                onClick={
                  isDelete
                    ? null
                    : () => {
                        confirmDeleteList();
                      }
                }
              >
                {isDelete ? "Confirmer..." : "Confirmer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
