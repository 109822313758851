import { useState, useEffect, useMemo } from "react";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export default function SalesCalendar({ x, y, rows, columns }) {
  const [startIndex, setStartIndex] = useState(0);
  const visibleMonths = Math.round(x / 100);

  const handleNavigation = (direction) => {
    setStartIndex((prevIndex) => {
      if (direction === "left") {
        return Math.max(0, prevIndex - 1);
      } else {
        return Math.min(12 - visibleMonths, prevIndex + 1);
      }
    });
  };

  const { salesData, categoryColors } = useMemo(() => {
    const salesData = {};
    const categoryColors = {};

    // Extraire les labels des colonnes et créer un objet de correspondance
    // console.log(columns);
    columns.slice(1).forEach((col, index) => {
      const key = col.label.charAt(0).toUpperCase();
      categoryColors[key] = col.baseColor;
    });

    // Initialiser l'objet salesData avec tous les mois
    months.forEach((month) => {
      salesData[month] = {};
      Object.keys(categoryColors).forEach((key) => {
        salesData[month][key] = 0;
      });
    });
    // Transformer et regrouper les données
    rows.forEach((row) => {
      const date = row[1].value;
      const month = months[date.getMonth()];

      columns.slice(1).forEach((col, index) => {
        const key = col.label.charAt(0).toUpperCase();
        salesData[month][key] += row[index + 2].value;
      });
    });

    return { salesData, categoryColors };
  }, [columns, rows]);

  return (
    <div className={`w-full h-[${y}px] pt-0 mt-0 flex flex-col`}>
      <div className="flex justify-center gap-5 items-center w-full font-poppins mb-3 font-[500px]">
        {Object.entries(categoryColors).map(([key, color]) => (
          <div key={key} className="flex items-center gap-5">
            <span style={{ color }}>{key}</span>
            <span>
              {
                columns.find((col) => col.label.charAt(0).toUpperCase() === key)
                  .label
              }
            </span>
          </div>
        ))}
      </div>
      <div className="flex-grow overflow-hidden border border-gray-200 rounded-lg">
        <div className="h-full overflow-auto">
          <table className="w-full">
            <thead className="sticky top-0 bg-white">
              <tr>
                <th className="p-1 w-[10px] border-b border-gray-200 cursor-pointer">
                  <button
                    onClick={() => handleNavigation("left")}
                    disabled={startIndex === 0}
                    className="p-1 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    <ChevronLeft className="w-5 h-5" />
                    <span className="sr-only">Mois précédents</span>
                  </button>
                </th>
                {months
                  .slice(startIndex, startIndex + visibleMonths)
                  .map((month, index) => (
                    <th
                      key={month}
                      className="p-2 text-center font-semibold text-gray-600 font-poppins w-[100px]"
                      colSpan={index === 1 ? "1" : "2"}
                    >
                      {month}
                    </th>
                  ))}
                <th className="p-2 w-[10px] cursor-pointer" colSpan={"1"}>
                  <button
                    onClick={() => handleNavigation("right")}
                    disabled={startIndex + visibleMonths >= 12}
                    className="p-1 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    <ChevronRight className="w-5 h-5" />
                    <span className="sr-only">Mois suivants</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(categoryColors).map(([category, color], i) => {
                // console.log(category);
                return (
                  <tr key={i} className="max-w-4">
                    {months
                      .slice(startIndex, startIndex + visibleMonths)
                      .map((month, index) => (
                        <>
                          <td className="p-1 border-r border-gray-200 "></td>
                          <td
                            key={`${month}-${category}`}
                            className="p-2 border-r  border-gray-200 flex justify-between items-center font-poppins font-[500px]"
                          >
                            <span className={`font-medium`} style={{ color }}>
                              {category}
                            </span>
                            {salesData[month][category]}
                          </td>
                        </>
                      ))}
                    <td className="p-1 border-r border-gray-200 "></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
