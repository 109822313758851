import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./Modal";
import { Radio, Select, Spin, Input, TextArea, Button } from "antd";
import {
  actionGetColumn,
  actionGetGoalColumn,
  actionGetGoals,
} from "../../redux/actions/GoalAction";
import { actionGetTeamsByUser } from "../../redux/actions/teamAction";
import { actionDeleteRule, createRule } from "../../redux/actions/ruleAction";
import CalendarView from "./CalendarView";
import {
  actionCreateBonus,
  actionGetBonusesByUser,
} from "../../redux/actions/bonusAction";
import { enqueueSnackbar } from "notistack";
import { Popover } from "@mui/material";
import * as math from "mathjs";
import {
  actionCreatePeriod,
  actionGetPeriodByUser,
} from "../../redux/actions/periodAction";
import Confetti from "./Confetti.js";
export default function ModalAddBonus({ visibility, onCancel, show }) {
  const { TextArea } = Input;
  const [rules, setRules] = useState([]);
  const allRules = useSelector((state) => state.rule.rules);
  const [ruleName, setRuleName] = useState("");
  const [formula, setFormula] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const dispatch = useDispatch();
  const isAddBonus = useSelector((state) => state.bonus.isAddBonus);
  const isAddRule = useSelector((state) => state.rule.isAddRule);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const goals = useSelector((state) => state.goal.goals);
  const teams = useSelector((state) => state.team.teams);
  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.id);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [periode, setPeriode] = useState("0");
  const [interval, setInterval] = useState([null, null]);
  const [periodName, setPeriodName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [bonusName, setBonusName] = useState("");
  const [users, setUsers] = useState([]);
  const [percent, setPercent] = useState("100");
  const [anchorEl, setAnchorEl] = useState(null);
  const [goalCol, setGoalCol] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const currentGoalCol = goalCol.find((el) => el["id"] === selectedGoal);
  const [selectedGoalColumn, setSelectedGoalColumn] = useState(null);
  const [teamOrUser, setTeamOrUser] = useState("user");
  const [isFormulaValid, setIsFormulaValid] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setselectedPeriod] = useState(null);
  const [setIsAddBonus] = useState(false);
  const [createdRule, setCreatedRule] = useState({});
  const [savedCreatedRules, setSavedCreatedRules] = useState([]);
  const [bonusState, setBonusState] = useState(null);
  const [deletableRules, setDeletableRules] = useState([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  useEffect(() => {
    if (selectedGoal && !currentGoalCol)
      dispatch(
        actionGetGoalColumn(selectedGoal, token, (data) => {
          if (typeof data === "object")
            setGoalCol([...goalCol, { id: selectedGoal, data: data.columns }]);
        })
      );
  }, [dispatch, idUser, token, selectedGoal, goalCol, currentGoalCol]);

  useEffect(() => {
    dispatch(
      actionGetPeriodByUser(idUser, token, (periods, data) => {
        if (periods) {
          setPeriods(data);
        }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      actionGetGoals(idUser, token, (status) => {
        if (status === 200) {
        }
      })
    );
    dispatch(actionGetTeamsByUser(idUser, token));
  }, [dispatch, idUser, token]);

  useEffect(() => {
    if (!visibility) {
      setRules([]);
      setBonusName("");
      setTeamOrUser("user");
      setRuleName("");
      setIsFormulaValid(null);
    }
  }, [visibility]);
  //delete savedCreatedRules if bonus not created:
  useEffect(() => {
    if (!show && bonusState === null) {
      // Afficher la fenêtre contextuelle de confirmation
      if (deletableRules?.length > 0) setConfirmModalVisible(true);
    }
  }, [show, bonusState]);

  // Fonction pour gérer la confirmation de l'utilisateur
  const handleConfirmModalOk = () => {
    // Fermer la fenêtre contextuelle de confirmation
    setConfirmModalVisible(false);
    setDeletableRules([]);

    // Supprimer les règles
    deletableRules?.map((rule) => {
      dispatch(
        actionDeleteRule(rule.idrule, token, (success) => {
          if (success) {
            // setRules(rules.filter((item) => item.idrule !== rule.idrule));
            enqueueSnackbar(
              "Les règles précédemment créees ont bien été supprimées.",
              {
                variant: "success",
              }
            );
          } else {
            enqueueSnackbar("Une erreur est survenue lors de la suppression.", {
              variant: "warning",
            });
          }
        })
      );
    });
  };

  // Fonction pour annuler la suppression des règles
  const handleConfirmModalCancel = () => {
    // Fermer la fenêtre contextuelle de confirmation
    setConfirmModalVisible(false);
  };

  useEffect(() => {
    if (!isModalVisible) {
      setRuleName("");
      setSelectedGoal(null);
      setFormula("");
      setSelectedUsers([]);
      setIsFormulaValid(null);
      setPeriodName(null);
      setInterval([null, null]);
      setPeriode("0");
      setselectedPeriod(null);
    }
  }, [isModalVisible]);
  useEffect(() => {
    setUsers(
      Array.from(
        new Set(
          teams
            .flatMap((team) => team.userTeams.map((userTeam) => userTeam.user))
            .map((user) => user.id)
        ),
        (id) =>
          teams
            .flatMap((team) =>
              team.userTeams
                .filter((userTeam) => userTeam.user.id === id)
                .map((userTeam) => userTeam.user)
            )
            .pop()
      )
    );
  }, [teams]);
  useEffect(() => {
    // Vérifier si la règle créée n'est pas vide
    if (Object.keys(createdRule).length !== 0)
      setSavedCreatedRules([...savedCreatedRules, createdRule]);
  }, [createdRule]);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedGoalColumn(null);
  };

  const handleCurrentGoalCol = (column) => {
    setFormula((prevFormula) => prevFormula + `${column}`);
    setAnchorEl(null);
  };
  const formulaValidator = (formula, columns) => {
    const intitialisations = columns.map((item) => `var ${item} = 1;`);

    try {
      const dynamicInitializations = currentGoalCol.data.map(
        (col) => `var ${col} = 1;`
      );

      // eslint-disable-next-line no-eval
      const result = eval(
        `
        function getIndicatorValueByPeriod(a,b,c){return 1};
        ${intitialisations.join(" ")} ${dynamicInitializations.join(
          " "
        )} ${formula} `
      );
      if (typeof result === "number") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("e ===============================", e);
      return false;
    }
  };

  const handleFormulaChange = (event) => {
    const enteredFormula = event.target.value;

    if (enteredFormula.includes("#")) {
      setAnchorEl(event.currentTarget);
    } else {
      let updatedFormula = enteredFormula;

      if (selectedGoalColumn) {
        const formattedColumn = selectedGoalColumn.replace(/#/g, "");
        updatedFormula += formattedColumn;
        setAnchorEl(null);
      }
      try {
        const isValid = formulaValidator(updatedFormula, ["value", "quantity"]);
        setIsFormulaValid(isValid);
        setFormula(updatedFormula);
      } catch (error) {
        console.log("error==================================", error);
      }
    }
  };
  const handleGoalChange = (value) => {
    const goalInSelect = goals.find((goal) => goal.idgoal === value);
    setSelectedGoal(value);
  };
  const handlePercent = (e) => {
    setPercent(e.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddRule = () => {
    if (!ruleName) {
      enqueueSnackbar("Veuillez entrer le nom de la règle.", {
        variant: "warning",
      });
      return;
    }

    if (!selectedGoal) {
      enqueueSnackbar("Veuillez sélectionner un objectif.", {
        variant: "warning",
      });
      return;
    }

    if (!formula) {
      enqueueSnackbar("Veuillez saisir une formule.", {
        variant: "warning",
      });
      return;
    }
    if (!isFormulaValid) {
      enqueueSnackbar("Veuillez saisir une formule valide.", {
        variant: "warning",
      });
      return;
    }

    setCreatedRule({
      ruleName,
      formula,
      selectedPeriod,
      selectedGoal,
      percent,
      startDate,
      endDate,
      token,
    });
    dispatch(
      createRule(
        ruleName,
        formula,
        selectedPeriod,
        selectedGoal,
        percent,
        startDate,
        endDate,
        token,
        (status, data) => {
          setDeletableRules([...deletableRules, data]);
          if (status === 200) {
            setIsModalVisible(false);
            setRules([...rules, data]);
            setRuleName("");
            setFormula("");
            setSelectedGoal(null);
            setPeriodName("");
            setInterval([null, null]);
            enqueueSnackbar("La règle a été créée avec succès.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              "Un problème a été rencontré lors de la création de la règle.",
              {
                variant: "error",
              }
            );
          }
        }
      )
    );
  };

  const handleUserChange = (value) => {
    if (teamOrUser === "user") {
      setSelectedUsers(value);
      setSelectedTeam(null);
    }
  };
  const handleTeamChange = (value) => {
    if (teamOrUser === "team") {
      setSelectedTeam(value);
      setSelectedUser(null);
    }
  };
  const handleTeamOrUserChange = (e) => {
    setTeamOrUser(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!bonusName) {
      enqueueSnackbar("Veuillez entrer le nom de la prime", {
        variant: "warning",
      });
      return;
    }
    if (
      (teamOrUser === "user" && !selectedUsers) ||
      (teamOrUser === "team" && !selectedTeam)
    ) {
      enqueueSnackbar(
        teamOrUser === "user"
          ? "Veuillez sélectionner au moins un utilisateur"
          : "Veuillez sélectionner une équipe",
        {
          variant: "warning",
        }
      );
      return;
    }

    const idRules = rules.map((rule) => rule.idrule);
    const idTeam = selectedTeam;
    const idusers = selectedUsers;
    const idOwner = idUser;
    dispatch(
      actionCreateBonus(
        bonusName,
        idTeam,
        idusers,
        idOwner,
        idRules,
        token,
        (bonus) => {
          if (bonus) {
            setBonusState(bonus);
            setBonusName("");
            setRuleName("");
            setFormula("");
            setSelectedGoal(null);
            setPeriode("0");
            setselectedPeriod(null);
            setInterval([null, null]);
            setPeriodName("");
            setStartDate(null);
            setEndDate(null);
            setBonusName("");
            setSelectedUsers([]);
            setSelectedTeam(null);
            onCancel();

            enqueueSnackbar("La prime a été créée avec succès.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              "Un problème a été rencontré lors de la création de la prime.",
              {
                variant: "error",
              }
            );
          }
        }
      )
    );
  };
  const renderedRules = rules?.map((rule) => {
    if (rule.goal) {
      return (
        <div
          key={rule.idrule}
          className="w-full border-[1px] rounded-lg px-2 py-2 border-[#32b4ff]"
        >
          <div className="flex flex-row items-center justify-start text-sm text-left text-gray-500 ">
            <div className="relative truncate ...">
              Règle appliquée : {rule.ruleName}{" "}
            </div>
          </div>
          <div className="flex flex-col gap-2 w-full box-border truncate ...">
            <div className="flex flex-row items-center justify-start gap-[5px] overflow-hidden">
              <div className="rounded bg-[#CCF3FF] flex flex-row items-center justify-start py-0 px-[5px] text-xs text-[#a6c9d3]">
                <div className="relative font-medium opacity-[0.8]">SI</div>
              </div>
              <div className="rounded bg-[#A28FFC] opacity-40 flex flex-row items-center justify-center py-px px-[3px] text-mediumpurple-300">
                <div className="relative">{rule.goal.name}</div>
              </div>
              <div className="relative opacity-[0.5]">{rule.goal.operator}</div>
              <div className="relative opacity-[0.5]">
                {rule.goal.targetvalue}
              </div>
            </div>
            <div className="w-full flex flex-row items-center justify-start gap-[5px]">
              <div className="rounded bg-[#CCF3FF] flex flex-row items-center justify-start py-0 px-[5px] text-xs text-[#05BAF3]">
                <div className="relative font-medium opacity-[0.8]">ALORS</div>
              </div>
              <div className="rounded bg-[#A28FFC] opacity-40 flex flex-row items-center justify-center py-px px-[3px] text-mediumpurple-300">
                <div className="relative">Commission</div>
              </div>
              <div className="relative opacity-[0.5]">=</div>
              <div className="relative opacity-[0.5]"> {rule.formula}</div>
            </div>
          </div>
        </div>
      );
    }
  });

  const content = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        minWidth: "200px",
      }}
    >
      <div className="">
        <label htmlFor="formule" className="label-default">
          Nom de la règle *
        </label>
        <Input
          required
          placeholder="Entrer le nom de la règle"
          value={ruleName}
          onChange={(e) => setRuleName(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="goal" className="label-default">
          Objectif associé à la règle
        </label>
        <div className="flex self-stretch gap-4">
          <div className="relative font-medium opacity-[0.8] text-xs rounded bg-[#CCF3FF]  flex flex-row items-center justify-center py-1 px-2 text-[#05BAF3]">
            SI
          </div>
          <div className="">
            {goals.length > 0 ? (
              <Select
                required
                placeholder="Sélectionner un objectif"
                onChange={handleGoalChange}
                value={selectedGoal}
                className="w-[230px]"
              >
                {goals.map((goal) => (
                  <Select.Option key={goal.idgoal} value={goal.idgoal}>
                    {goal.name}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select placeholder="Aucun objectif disponible" disabled />
            )}
          </div>
          <div className="truncate w-full relative font-medium opacity-45 text-sm rounded bg-[#CCF3FF] flex flex-row items-center justify-center py-1 px-2 text-[#05BAF3]">
            est atteint à
          </div>
          <div className="w-[400px] rounded bg-[#A28FFC] flex items-center justify-center gap-1 px-2 text-sm font-bold text-white opacity-45">
            <input
              type="text"
              className="rounded w-full text-center bg-[#fff] text-[#989898] focus:outline-none "
              value={percent}
              onChange={handlePercent}
              required
            />
            <b className="">%</b>
          </div>
        </div>
        <div>
          <div className="flex items-center gap-4">
            <div className="relative font-medium opacity-[0.8] text-xs rounded bg-[#CCF3FF] w-14 flex flex-row items-center justify-center py-1 px-2 text-[#05BAF3]">
              ALORS
            </div>
            <div className="relative font-medium opacity-45 text-sm rounded bg-[#A28FFC] w-24 flex flex-row items-center justify-center py-1 px-2 text-[#fff] ">
              Commission
            </div>
            <div className="relative font-medium opacity-[0.8] text-sm rounded bg-[#CCF3FF] w-14 flex flex-row items-center justify-center py-1 px-2 text-[#05BAF3]">
              =
            </div>
            <div className="flex-1">
              <TextArea
                aria-describedby={id}
                onChange={handleFormulaChange}
                className={`rounded-lg ${
                  isFormulaValid === true ? "border-green-500" : ""
                } ${
                  isFormulaValid === false ? "border-red-500 animate-blink" : ""
                }`}
                required
                placeholder="Entrer la formule"
                value={formula}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
              {isFormulaValid === false && (
                <div className="absolute mt-1 text-red-500">
                  La formule n'est pas valide.
                </div>
              )}
              {isFormulaValid === true && (
                <div className="absolute mt-1 text-green-500 ">
                  La formule est valide.
                </div>
              )}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className="mui-popover-custom">
                  <ul className="grid items-center content-center grid-cols-1 gap-2">
                    {currentGoalCol &&
                      currentGoalCol["data"].map((column) => (
                        <li
                          key={column}
                          className="transition-all duration-300 hover:cursor-pointer hover:bg-[#05BAF3] hover:text-white opacity-45 bg-[#E3E3E3] rounded-lg text-center"
                          onClick={() => handleCurrentGoalCol(column)}
                        >
                          {column}
                        </li>
                      ))}
                  </ul>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>

      <div className="input-container">
        <label htmlFor="periode" className="label-default">
          Période
        </label>
        <div className="flex flex-col w-full gap-5">
          <CalendarView
            selectStyle={{ width: "100%" }}
            calendarStyle={{ width: "100%" }}
            value={periode}
            interval={interval}
            period={selectedPeriod}
            onChange={(interval, value) => {
              if (value) {
                setPeriode(value);
                setInterval(interval);
                setStartDate(interval[0]);
                setEndDate(interval[1]);
                setselectedPeriod(null);
              }
            }}
            onPeriodChange={(value) => {
              if (value) {
                setselectedPeriod(value);
                setInterval([null, null]);
                setStartDate(null);
                setEndDate(null);
              }
            }}
          />
        </div>
      </div>
      <button
        onClick={handleAddRule}
        className="btn-full"
        type="submit"
        disabled={isAddRule}
      >
        {isAddRule ? "Soumettre la règle..." : "Soumettre la règle"}
      </button>
    </div>
  );

  return (
    <>
      <div className="z-50 absolute top-[50%]">
        <Modal visible={visibility} onCancel={onCancel}>
          <div className="relative w-full">
            <div className="absolute top-[-90px] text-[#05BAF3] font-extrabold text-[20px]">
              Paramétrer une nouvelle prime
            </div>
            <form onSubmit={onSubmit}>
              <div className="flex flex-col items-start justify-between">
                <div className="w-full">
                  <div className="input-container">
                    <label for="nom" className="label-default">
                      Nom de la prime
                    </label>
                    <span className="span-block">
                      <Input
                        name="name"
                        value={bonusName}
                        onChange={(e) => setBonusName(e.target.value)}
                        placeholder="Nombre de deals"
                        className="px-2 border border-gray-300 rounded w-72 focus:outline-none focus:border-blue-500"
                      />
                    </span>
                  </div>
                </div>
                <div>
                  <label for="rule" className="label-default">
                    Création de la règle
                  </label>
                  {rules?.length > 0 && (
                    <div>
                      <h3>Règles ajoutées:</h3>
                      <div className="grid items-center justify-center grid-cols-2 gap-4 ">
                        {renderedRules}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Button className="mt-2 btn-full" onClick={showModal}>
                + Ajouter une règle
              </Button>

              <img className="h-1 mt-3" src="imgPrimes/Vector 381.png" alt="" />
              <div className="flex flex-col items-start justify-between w-full mt-3">
                <div className="flex flex-col w-full gap-2 input-container">
                  <label for="periode" className="label-default">
                    Attribuer la prime à{" "}
                  </label>
                  <div className="flex flex-row items-start justify-between gap-4">
                    <span className="span-block">
                      <Radio.Group
                        onChange={handleTeamOrUserChange}
                        value={teamOrUser}
                      >
                        <Radio value="user">Un utilisateur</Radio>
                        <Radio value="team">Une équipe</Radio>
                      </Radio.Group>
                    </span>
                    {teamOrUser === "user" ? (
                      <Select
                        onChange={handleUserChange}
                        value={selectedUsers}
                        style={{ width: "300px" }}
                        className="space-x-3"
                        mode="multiple"
                      >
                        {users.map((user) => (
                          <Select.Option key={user.id} value={user.id}>
                            {`${user.firstName} ${user.lastName}`}{" "}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        defaultValue="default"
                        onChange={handleTeamChange}
                        style={{ width: "300px" }}
                        value={selectedTeam}
                      >
                        <Select.Option value="default" disabled>
                          Sélectionner une équipe
                        </Select.Option>
                        {teams.map((team) => (
                          <Select.Option key={team.idteam} value={team.idteam}>
                            {team.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </div>
                  <img
                    className="h-1 mt-3"
                    src="imgPrimes/Vector 381.png"
                    alt=""
                  />
                </div>
              </div>

              <div className="mt-3 col-md-12 wrap-btn-modal">
                <div className="btn-container">
                  <button
                    className="btn-border close-btn-submit"
                    type="reset"
                    onClick={onCancel}
                  >
                    Annuler
                  </button>
                  <button
                    className="btn-full"
                    type="submit"
                    disabled={isAddBonus}
                  >
                    {isAddBonus ? "Loading.." : "Soumettre la prime"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>

      {isModalVisible && (
        <div className="z-50 absolute top-[50%]">
          <Modal
            title="Ajouter une règle"
            visible={isModalVisible}
            onCancel={handleCancel}
          >
            {content}
          </Modal>
        </div>
      )}
      <Modal
        title="Confirmation"
        visible={confirmModalVisible}
        onCancel={handleConfirmModalCancel}
      >
        <div>
          Êtes-vous sûr de vouloir fermer le modal ? Cela supprimera toutes les
          règles.
        </div>
        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <Button
            onClick={handleConfirmModalCancel}
            style={{ marginRight: "10px" }}
          >
            Annuler
          </Button>
          <Button type="primary" onClick={handleConfirmModalOk}>
            Poursuivre
          </Button>
        </div>
      </Modal>
    </>
  );
}
