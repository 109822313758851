import {
  ACTION_SET_TEAM,
  ACTION_SET_TEAM_USERS,
  ACTION_SET_TEAMS,
  ACTION_ADD_TEAM,
  ACTION_REMOVE_TEAM,
  ACTION_ADD_SUB_TEAM,
  ACTION_REMOVE_SUB_TEAM,
  ACTION_IS_GET_TEAM,
  ACTION_IS_UPDATE_TEAM,
  ACTION_IS_DELETE_TEAM,
  ACTION_IS_ADD_TEAM,
  ACTION_IS_SET_TEAM_USERS,
  ACTION_IS_ADD_SUB_TEAM,
  ACTION_IS_REMOVE_SUB_TEAM,
} from "../actions/teamAction";

const initialStateUser = {
  teams: [],
  isUpdateTeamUser: false,
  isDelete: false,
  isUpdate: false,
  isGet: false,
  isAdd: false,
};

const teamReducer = (state = initialStateUser, action) => {
  function removeDuplicates(array, key) {
    return array.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t[key] ===
            obj[
              key
            ] /* Ajoutez d'autres propriétés pour la comparaison si nécessaire */
        )
    );
  }

  switch (action.type) {
    case ACTION_SET_TEAMS: {
      return {
        ...state,
        teams: action.payload,
      };
    }

    case ACTION_REMOVE_TEAM: {
      const teams = [...state.teams];
      const newTeam = teams.find((team) => team.idteam === action.payload);
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (team.idparent === parseInt(action.payload)) {
          teams[i].idparent = null;
          teams[i].parent = null;
        }
      }
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (parseInt(team.idteam) === parseInt(newTeam.idparent)) {
          teams[i].childreen = teams[i].childreen.filter(
            (t) => t.idteam !== newTeam.idteam
          );
        }
      }
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (parseInt(team.idteam) === parseInt(action.payload)) {
          teams.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        teams: [...teams],
      };
    }

    case ACTION_ADD_TEAM: {
      const teams = [...state.teams];
      const { idparent } = action.payload;
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (parseInt(team.idteam) === parseInt(idparent)) {
          const childreen = teams[i].childreen ? teams[i].childreen : [];
          teams[i].childreen = [...childreen, action.payload];
          break;
        }
      }
      return {
        ...state,
        teams: [...state.teams, action.payload],
      };
    }

    case ACTION_SET_TEAM: {
      const teams = [...state.teams];
      const newTeam = action.payload;
      console.log(newTeam);
      const parent =
        teams.find((team) => +team.idteam === +action.payload.idparent) || null;

      if (parent) {
        for (let i = 0; i < teams.length; i++) {
          const team = teams[i];
          if (parseInt(team.idteam) === parseInt(newTeam.idparent)) {
            teams[i].childreen = teams[i].childreen.filter(
              (t) => +t.idteam !== +newTeam.idteam
            );
          }
        }

        for (let i = 0; i < teams.length; i++) {
          const team = teams[i];
          const lastChildreen = team.childreen;
          if (parseInt(team.idteam) === parseInt(parent.idteam)) {
            const newChildreen = [...lastChildreen, newTeam];
            teams[i].childreen = removeDuplicates(newChildreen, "idteam");
            // break;
          }
        }
      }

      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (+parseInt(team.idteam) === +parseInt(newTeam.id)) {
          teams[i].name = newTeam.name;
          teams[i].description = newTeam.description;
          if (parent) {
            teams[i].idparent = parent.idteam;
            teams[i].parent = parent;
          } else {
            teams[i].idparent = null;
            teams[i].parent = null;
          }
          // break;
        }
      }

      return {
        ...state,
        teams: [...teams],
      };
    }

    case ACTION_ADD_SUB_TEAM: {
      const teams = [...state.teams];
      const subteam = teams.find(
        (t) => parseInt(t.idteam) === parseInt(action.payload.idsubteam)
      );
      const parent = teams.find((t) => +t.idteam === +action.payload.idteam);

      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (+team.idteam === +subteam.idteam) {
          teams[i].idparent = parent.idteam;
          teams[i].parent = parent;
        } else {
          const childreen = [...team.childreen].filter(
            (t) => +t.idteam !== +subteam.idteam
          );
          teams[i].childreen = childreen;
        }
      }

      for (let i = 0; i < teams.length; i++) {
        const element = teams[i];
        if (+element.idteam === +action.payload.idteam) {
          const childreen = [...element.childreen, subteam];
          teams[i].childreen = removeDuplicates(childreen, "idteam");
          break;
        }
      }

      return {
        ...state,
        teams: [...teams],
      };
    }

    case ACTION_REMOVE_SUB_TEAM: {
      const teams = [...state.teams];
      const subteam = teams.find(
        (t) => parseInt(t.idteam) === parseInt(action.payload.idsubteam)
      );
      const parent = teams.find((t) => +t.idteam === +action.payload.idteam);

      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (team.idparent === parseInt(parent.idteam)) {
          teams[i].idparent = null;
          teams[i].parent = null;
        }
      }
      for (let i = 0; i < teams.length; i++) {
        const element = teams[i];
        if (+element.idteam === +action.payload.idteam) {
          const childreen = [];
          for (let i = 0; i < element.childreen.length; i++) {
            const child = element.childreen[i];
            if (+child.idteam !== +subteam.idteam) childreen.push(child);
          }
          teams[i].childreen = removeDuplicates(childreen, "idteam");
          break;
        }
      }
      return {
        ...state,
        teams: [...teams],
      };
    }

    case ACTION_SET_TEAM_USERS: {
      const teams = [...state.teams];
      const { data, type } = action.payload;
      if (type === "ADD") {
        for (let i = 0; i < teams.length; i++) {
          const team = teams[i];
          if (parseInt(team.idteam) === parseInt(data.idteam)) {
            teams[i].userTeams.push(data);
            break;
          }
        }

        return {
          ...state,
          teams: [...teams],
        };
      } else if (type === "DELETE") {
        loop: for (let i = 0; i < teams.length; i++) {
          const team = teams[i];
          for (let j = 0; j < team.userTeams.length; j++) {
            const userTeam = team.userTeams[j];
            if (+userTeam.iduser === +data) {
              teams[i].userTeams.splice(j, 1);
              break loop;
            }
          }
        }

        return {
          ...state,
          teams: [...teams],
        };
      } else {
        return state;
      }
    }

    case ACTION_IS_GET_TEAM: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    case ACTION_IS_ADD_TEAM: {
      return {
        ...state,
        isAdd: action.payload,
      };
    }

    case ACTION_IS_UPDATE_TEAM: {
      return {
        ...state,
        isUpdate: action.payload,
      };
    }

    case ACTION_IS_DELETE_TEAM: {
      return {
        ...state,
        isDelete: action.payload,
      };
    }

    case ACTION_IS_SET_TEAM_USERS: {
      return {
        ...state,
        isUpdateTeamUser: action.payload,
      };
    }

    default:
      return state;
  }
};

export default teamReducer;
