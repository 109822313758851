import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Modal from "./Modal";
import FilterView from "./FilterView";
import CalendarView from "./CalendarView";
import CustomSelect from "./CustomSelect";
import { Popover, Checkbox, Radio, DatePicker } from "antd";
import { Link } from "react-router-dom";
import {
  actionGetOptions,
  actionSetIndicator,
} from "../../redux/actions/indicatorAction";
import { actionUpdateGoal } from "../../redux/actions/GoalAction";
import moment from "moment";
import {
  actionGetOperators,
  actionSetOperator,
} from "../../redux/actions/operatorAction";
import { useSnackbar } from "notistack";
import { truncateText } from "../utils/truncateWords";

let theme = "1";
const data = localStorage.getItem("theme");
if (data) {
  theme = JSON.parse(data).theme;
}
const formuleOptions = [
  { value: 1, label: "Somme" },
  { value: 2, label: "Moyenne" },
  { value: 3, label: "Compteur" },
];

export default function ModalUpdateGoal({
  visibility,
  onCancel,
  large,
  goal,
  users,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //Orperators on Goal:
  const operators = useSelector((state) => state.operator.operators);
  const datasources = useSelector((state) => state.datasource.datasources);
  const triggers = [
    {
      label: "Lors de la mise à jour de la source de donnée",
      value: "0",
      key: 0,
    },
    // { label: "A la date de fin de l'objectif", value: "1", key: 1 },
  ];

  useEffect(() => {
    dispatch(actionGetOperators());
  }, [dispatch]);

  const [datasource, setDatasource] = useState(null);
  const [metric, setMetric] = useState(null);
  const [axeDate, setAxeDate] = useState(null);
  const [formule, setFormule] = useState(formuleOptions[0]);

  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [periode, setPeriode] = useState("0");
  const [periodeValue, setPeriodeValue] = useState({
    value: 1,
    label: "Chaque jour",
  });
  const [interval, setInterval] = useState([null, null]);
  const [dateBegin, setDateBegin] = useState(null);
  const [periodique, setPeriodique] = useState(false);
  const [filters, setFilters] = useState([]);
  const [trigger, setTrigger] = useState(triggers[0]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [operator, setOperator] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const filtersRef = useRef();
  filtersRef.current = filters;

  const isUpdate = useSelector((state) => state.goal.isUpdate);

  const idUser = useSelector((state) => state.user.id);
  const idUserRef = useRef();
  idUserRef.current = idUser;

  const token = useSelector((state) => state.user.token);
  const tokenRef = useRef();
  tokenRef.current = token;

  const onChangeDateBegin = (value) => {
    setDateBegin(value);
  };

  const [idperiod, setIdPeriod] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    let f = [];

    for (let index = 0; index < filtersRef.current.length; index++) {
      const element = filtersRef.current[index];
      if (element.checked && element.values?.length > 0) {
        f.push(element);
      }
    }

    dispatch(
      actionUpdateGoal(
        goal.idgoal,
        name,
        datasource?.value,
        metric?.value,
        formule.value,
        axeDate?.value,
        f,
        periodique,
        new Date(dateBegin),
        periodeValue.value,
        new Date(interval[0]),
        new Date(interval[1]),
        idperiod,
        operator?.value,
        value,
        trigger.value || 0,
        selectedUsers.map((user) => user.value) || [],
        idUser,
        tokenRef.current,
        (status) => {
          if (status === 200) {
            enqueueSnackbar("Cet objectif a été mis à jour !", {
              variant: "success",
            });
            onCancel();
          }
        }
      )
    );
  };

  const handlerChangeValue = (value) => {
    if (/^[0-9]*$/.test(value)) {
      setValue(value);
    }
  };

  useEffect(() => {
    if (datasource) {
      let filtersLanda = [];
      for (let i = 0; i < datasource?.columns?.length; i++) {
        const element = datasource.columns[i];
        if (parseInt(element.dataType) === 2) {
          filtersLanda.push({
            id: element.idcolumn,
            name: element.name,
            type: parseInt(element.dataType),
            checked: false,
            loading: parseInt(element.dataType) === 2 ? true : false,
            values: [],
          });
          dispatch(
            actionGetOptions(
              element.iddatasource,
              element.name,
              (status, result) => {
                if (status === 200) {
                  let alpha = [...filtersRef.current];
                  for (let j = 0; j < alpha.length; j++) {
                    const item = alpha[j];
                    if (parseInt(item.id) === parseInt(element.idcolumn)) {
                      alpha[j]["options"] = result;
                      alpha[j]["loading"] = false;
                      setFilters([...alpha]);
                    }
                  }
                }
              }
            )
          );
        }
      }
      // console.log("filtersLanda :>> ", filtersLanda);
      setFilters(filtersLanda);
    }
  }, [datasource, dispatch]);

  useEffect(() => {
    if (!visibility) {
      setName("");
      setValue("");
      setPeriode("0");
      setIdPeriod(null);
      setPeriodeValue({ value: 1, label: "Chaque jour" });
      setInterval([null, null]);
      setPeriodique(false);
      setDateBegin(null);
      setFilters([]);
      setDatasource(null);
      setMetric(null);
      setAxeDate(null);
      setFormule(formuleOptions[0]);
    }
  }, [visibility]);

  useEffect(() => {
    if (filtersRef.current && filtersRef.current.length > 0) {
      let filters = [...filtersRef.current];
      for (let j = 0; j < filters.length; j++) {
        filters[j].checked = false;
        filters[j].values = [];
      }
      setFilters(filters);
    }
    if (goal) {
      setName(goal?.name || "");
      setValue(`${goal?.targetvalue || 0}`);
      setPeriode(goal?.idperiod ? goal.idperiod : "0");
      setPeriodique(Boolean(goal.periodique));
      setIdPeriod(goal.idperiod);
      setTrigger(triggers.find((item) => +item.value === +goal.trigger));
      const idusers = goal.goalusers.filter((user) => +user.iduser !== +idUser);
      setFormule(formuleOptions.find((item) => +item.value === +goal.formule));
      setDatasource({
        label: goal.datasource?.name,
        value: goal.datasource?.iddatasource,
        columns: goal.datasource?.columns,
      });
      if (goal.metric) {
        setMetric({
          label: goal.metric?.name,
          value: goal.metric.idcolumn,
        });
      }
      if (goal.axeDate) {
        setAxeDate({
          label: goal.axeDate.name,
          value: goal.axeDate.idcolumn,
        });
      }

      const usersSeleced = [];
      for (let i = 0; i < idusers.length; i++) {
        const user = users.find((u) => +u.value === +idusers[i].iduser);
        usersSeleced.push(user);
      }
      setSelectedUsers(usersSeleced);
      setOperator({ label: goal.operator, value: goal.operator });
      if (Boolean(goal.periodique)) {
        setPeriodeValue(
          [
            { value: 1, label: "Chaque jour" },
            { value: 2, label: "Chaque semaine" },
            { value: 3, label: "Chaque mois" },
            { value: 4, label: "Chaque trimestre" },
            { value: 5, label: "Chaque année" },
          ][parseInt(goal.periodicite) - 1]
        );
      } else {
        setInterval([
          moment(new Date(goal.startdate)),
          moment(new Date(goal.enddate)),
        ]);
      }
      setDateBegin(moment(new Date(goal.dateBeginOperation || goal.startdate)));
      if (goal?.datasource?.columns && goal?.datasource?.columns.length > 0) {
        const filtersLanda = goal.datasource.columns
          .filter((item) => [2].includes(+item.dataType))
          .map((element) => ({
            id: element.idcolumn,
            key: element.idcolumn,
            name: element.name,
            type: parseInt(element.dataType),
            checked: false,
            loading: parseInt(element.dataType) === 2 ? true : false,
            values: [],
          }));
        console.log(filtersLanda);
        setTimeout(() => {
          if (goal.goalColumns && goal.goalColumns.length > 0) {
            let filters = [...filtersLanda];

            for (let i = 0; i < goal.goalColumns.length; i++) {
              const a = goal.goalColumns[i];

              for (let j = 0; j < filters.length; j++) {
                const b = filters[j];
                if (parseInt(a.idcolumn) === parseInt(b.id)) {
                  filters[j].checked = true;
                  filters[j].values = a.values ? JSON.parse(a.values) : [];
                  break;
                }
              }
            }
            setFilters(filters);
          }
        }, 200);
      }
    }
  }, [goal]);

  return (
    <>
      <Modal
        visible={visibility}
        title={"Modifier objectif"}
        onCancel={onCancel}
        large
      >
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Nom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Sélectionner la source de donnée *
                </label>
                <CustomSelect
                  id="datasource"
                  value={datasource}
                  onChange={(val) => {
                    setDatasource(val);
                    setMetric(null);
                    setAxeDate(null);
                  }}
                  options={datasources.map((item, index) => {
                    return {
                      key: index,
                      label: truncateText(item.name, true, 100),
                      value: +item.iddatasource,
                      columns: item.columns,
                    };
                  })}
                  // noSearchable
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Sélectionner la métric *
                </label>
                <CustomSelect
                  id="metric"
                  value={metric}
                  onChange={setMetric}
                  options={
                    datasource?.columns
                      ?.filter((item) => [1, 4].includes(+item.dataType))
                      ?.map((item, index) => ({
                        key: index,
                        label: truncateText(item.name, true, 100),
                        value: item.idcolumn,
                      })) || []
                  }
                  disabled
                  noSearchable
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Formule
                </label>
                <CustomSelect
                  id="periodicite"
                  value={formule}
                  onChange={setFormule}
                  options={formuleOptions}
                  noSearchable
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Sélectionner l'axe date par défaut *
                </label>
                <CustomSelect
                  id="periodicite"
                  value={axeDate}
                  onChange={setAxeDate}
                  options={
                    datasource?.columns
                      ?.filter((item) => [3].includes(+item.dataType))
                      ?.map((item) => ({
                        label: item.name,
                        value: item.idcolumn,
                        key: item.idcolumn,
                      })) || []
                  }
                  noSearchable
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-container">
                <label for="filter" className="label-default">
                  Filtre
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FilterView
                    filtersOptions={filters}
                    setFilterOptions={setFilters}
                  />
                  <div
                    className="filtres-actions"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Popover
                      placement={"bottom"}
                      content={
                        <div>
                          {!filters
                            ? null
                            : filters.map((e, index) => (
                                <p key={index}>
                                  <Checkbox
                                    checked={filters[index].checked}
                                    onClick={() => {
                                      let landa = [...filters];
                                      landa[index].checked =
                                        !filters[index].checked;
                                      setFilters([...landa]);
                                    }}
                                  >
                                    <span
                                      style={{ color: "#000" }}
                                      className="color-black"
                                    >
                                      {e.name}
                                    </span>
                                  </Checkbox>
                                </p>
                              ))}
                        </div>
                      }
                      title="Ajouter un filtre"
                      trigger="click"
                    >
                      <Link
                        to=""
                        style={{
                          backgroundColor: "#05BAF3",
                          // theme === "2" ? "#007FC1" : "#cf2172",
                          borderRadius: "50%",
                          color: "#fff",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i className="fa-light fa-plus"></i>
                      </Link>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="periodique" className="label-default">
                  Periodique
                </label>
                <div className="custom-options-h">
                  <Radio.Group
                    options={[
                      { label: "Oui", value: true },
                      { label: "Non", value: false },
                    ]}
                    onChange={(e) => setPeriodique(e.target.value)}
                    value={periodique}
                  />
                </div>
              </div>
            </div>
            {!periodique ? (
              <div className="col-md-6">
                <div className="input-container">
                  <label for="periode" className="label-default">
                    Periode
                  </label>
                  <CalendarView
                    selectStyle={{ width: "40%" }}
                    calendarStyle={{ width: "55%", marginLeft: "10px" }}
                    value={periode}
                    interval={interval}
                    period={idperiod}
                    onPeriodChange={(value) => {
                      setIdPeriod(value);
                    }}
                    onChange={(interval, value) => {
                      setInterval(interval);
                      setPeriode(value);
                      if (!dateBegin) {
                        setDateBegin(interval[0]);
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-6">
                <div className="input-container">
                  <label for="periodicite" className="label-default">
                    Periodicite
                  </label>
                  <CustomSelect
                    id="periodicite"
                    value={periodeValue}
                    onChange={setPeriodeValue}
                    options={[
                      { value: 1, label: "Chaque jour" },
                      { value: 2, label: "Chaque semaine" },
                      { value: 3, label: "Chaque mois" },
                      { value: 4, label: "Chaque trimestre" },
                      { value: 5, label: "Chaque année" },
                    ]}
                    noSearchable
                  />
                </div>
              </div>
            )}
            <div className="col-md-6">
              <div className="input-container">
                <label htmlFor="operator" className="label-default">
                  Opérateur
                </label>
                <span className="span-block">
                  <CustomSelect
                    options={operators.map((operator, index) => ({
                      value: `${operator}`,
                      label: `(${operator})`,
                      key: index,
                    }))}
                    onChange={setOperator}
                    value={operator}
                  />
                </span>
              </div>
            </div>
            <div className="flex justify-between w-full col-md-6">
              <div className="w-full input-container">
                <label for="value" className="label-default">
                  Faire{" "}
                  {parseInt(formule?.value) === 1
                    ? "le cumul"
                    : parseInt(formule?.value) == 2
                    ? "la moyenne"
                    : "le compte"}{" "}
                  des données depuis le *
                </label>
                <span className="span-block">
                  <DatePicker
                    placeholder="Date de début"
                    onChange={onChangeDateBegin}
                    value={dateBegin}
                    format={"DD-MM-YYYY"}
                    style={{ width: 175 }}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label for="value" className="label-default">
                  Valeur *
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="value"
                    id="value"
                    type="text"
                    value={value}
                    onChange={(e) => handlerChangeValue(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label htmlFor="operator" className="label-default">
                  Quand verifie t-on atteinte de l'objectif?
                </label>
                <span className="span-block">
                  <CustomSelect
                    value={trigger}
                    options={triggers}
                    onChange={(item) => {
                      setTrigger(item);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container">
                <label htmlFor="Utilisateur(s)" className="label-default">
                  Utilisateur(s)
                </label>
                <span className="span-block">
                  <CustomSelect
                    isMulti={true}
                    value={selectedUsers}
                    options={users}
                    onChange={(item) => {
                      setSelectedUsers(item);
                    }}
                    required={false}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={onCancel}
                >
                  Retour
                </button>
                <button className="btn-full" type="submit" disabled={isUpdate}>
                  {isUpdate ? "Modifier..." : "Modifier"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
