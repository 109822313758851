import host from "./host";
import { sortBy } from "lodash";
import { actionSetLists } from "./listAction";
export const ACTION_SET_DASHBOARD = "ACTION_SET_DASHBOARD";
export const ACTION_SET_DASHBOARDS = "ACTION_SET_DASHBOARDS";
export const ACTION_ADD_DASHBOARD = "ACTION_ADD_DASHBOARD";
export const ACTION_REMOVE_DASHBOARD = "ACTION_REMOVE_DASHBOARD";
export const ACTION_IS_GET_DASHBOARD = "ACTION_IS_GET_DASHBOARD";
export const ACTION_IS_UPDATE_DASHBOARD = "ACTION_IS_UPDATE_DASHBOARD";
export const ACTION_IS_DELETE_DAHSBOARD = "ACTION_IS_DELETE_DAHSBOARD";
export const ACTION_IS_ADD_DAHSBOARD = "ACTION_IS_ADD_DAHSBOARD";
export const ACTION_SET_MANAGER_DAHSBOARD = "ACTION_SET_MANAGER_DAHSBOARD";
export const ACTION_SET_ROLES_DAHSBOARD = "ACTION_SET_ROLES_DAHSBOARD";
export const ACTION_GET_ONE_DASHBOARD = "ACTION_GET_ONE_DASHBOARD";

// * --------- Begin actions --------

export const actionSetRolesDashboard = (payload) => {
  return { type: ACTION_SET_ROLES_DAHSBOARD, payload };
};

export const actionGetOneDashboard = (payload) => {
  return { type: ACTION_GET_ONE_DASHBOARD, payload };
};

export const actionSetManagerDashboard = (payload) => {
  return { type: ACTION_SET_MANAGER_DAHSBOARD, payload };
};

export const actionIsGetDashboard = (payload) => {
  return { type: ACTION_IS_GET_DASHBOARD, payload };
};

export const actionIsUpdateDashboard = (payload) => {
  return { type: ACTION_IS_UPDATE_DASHBOARD, payload };
};

export const actionIsDeleteDashboard = (payload) => {
  return { type: ACTION_IS_DELETE_DAHSBOARD, payload };
};

export const actionIsAddDashboard = (payload) => {
  return { type: ACTION_IS_ADD_DAHSBOARD, payload };
};

export const actionSetDashboard = (payload) => {
  return { type: ACTION_SET_DASHBOARD, payload };
};

export const actionSetDashboards = (payload) => {
  return { type: ACTION_SET_DASHBOARDS, payload };
};

export const actionRemoveDashboard = (payload) => {
  return { type: ACTION_REMOVE_DASHBOARD, payload };
};

export const actionAddDashboard = (payload) => {
  return { type: ACTION_ADD_DASHBOARD, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionGetDashboardsByUser = (id, token) => {
  return (dispatch) => {
    dispatch(actionIsGetDashboard(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/by-workspace/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          if (response.status === 401) {
            localStorage.removeItem("data");
            window.location.replace("/");
          }
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          // dispatch(
          //   actionSetDashboards({ data: result.userDashboards, type: "all" })
          // );
          console.log(result.data);
          dispatch(
            actionSetDashboards({
              data: sortBy(result.data, [
                (o) => {
                  return o.positionx;
                },
              ]),
              type: "all",
            })
          );
        }
      })
      .catch((/* error */) => {
        /* console.log("error", error) */
      })
      .then(() => dispatch(actionIsGetDashboard(false)));
  };
};

export const actionGetDashboardById = (token, id) => {
  return (dispatch) => {
    dispatch(actionIsGetDashboard(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${host}/dashboard/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionGetOneDashboard(result));
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetDashboard(false)));
  };
};

export const actionGetDashboard = (id, idUser, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetDashboard(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${host}/dashboard/${id}?iduser=${idUser}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          const users = [...result.userDashboards];
          sessionStorage.setItem("dashboard", JSON.stringify(result));
          for (let index = 0; index < result.userDashboards.length; index++) {
            const element = result.userDashboards[index];
            if (element.role === "OWNER") {
              dispatch(actionSetManagerDashboard(element.user));
              dispatch(actionSetDashboard(result));
              result.userDashboards.splice(index, 1);
              break;
            }
          }
          dispatch(actionSetDashboard(result));
          dispatch(
            actionSetLists({
              type: "GET",
              data: sortBy(result.lists, [
                (o) => {
                  return o.positionx;
                },
              ]),
            })
          );
          callback(200, result, users);
        }
      })
      .catch(() => {})
      .then(() => dispatch(actionIsGetDashboard(false)));
  };
};

export const actionUpdateDashboard = (
  name,
  id,
  idworkspace,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsUpdateDashboard(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name: name,
      positionx: "0",
      positiony: "0",
      idworkspace,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/update/${id}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        callback(result);
        // console.log(result);
        if (result) {
          dispatch(actionSetDashboards({ id, name, type: "one" }));
          // message.success(
          //   this.props.t("Modifications réalisées avec succés")
          // );
        }
      })
      .catch((/* error */) => {
        // console.log("error", error);
        // message.error(this.props.t(" bad_insert"));
      })
      .then(() => {
        dispatch(actionIsUpdateDashboard(false));
      });
  };
};

export const actionDeleteDashboard = (id, idworkspace, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsDeleteDashboard(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/delete/${idworkspace}/${id}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        callback(result);
        if (result.status) {
          dispatch(actionRemoveDashboard(id));
        }
      })
      .catch((/* error */) => {
        /* console.log("error", error) */
      })
      .then(() => dispatch(actionIsDeleteDashboard(false)));
  };
};

export const actionAddDashboardMid = (
  name,
  type,
  workspace,
  token,
  callBack
) => {
  return (dispatch) => {
    dispatch(actionIsAddDashboard(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name: name,
      type: type,
      positionx: "0",
      positiony: "0",
      idworkspace: workspace,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/create`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        callBack(result);
        if (result.status) {
          // dispatch(actionAddDashboard(result.new));
          dispatch(actionAddDashboard(result.data));
        }
      })
      .catch((/* error */) => {
        // console.log("error", error);
        // message.error(this.props.t(" bad_insert"));
      })
      .then(() => dispatch(actionIsAddDashboard(false)));
  };
};

export const actionChangeTime = (
  iddashboard,
  iduser,
  interval,
  datedebut,
  datefin,
  idperiod,
  token,
  callBack
) => {
  return (/* dispatch */) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      iddashboard,
      iduser,
      interval,
      datedebut,
      datefin,
      idperiod,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/update-userdashord-time`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(false);
          return null;
        }
        callBack(true);
        return response.json();
      })
      .then((/* result */) => {
        // console.log(result);
      })
      .catch((/* error */) => {
        // console.log("error", error);
      });
  };
};
export const actionMoveDashboards = (data, token) => {
  return (dispatch) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      data,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/dashboard/move-many`, requestOptions)
      .then((response) => {
        if (response.status != 200 && response.status != 201) {
          return null;
        }
      })
      .then((result) => {})
      .catch(() => {});
  };
};
export const actionSetUserRoleDashboard = (
  iddashboard,
  iduser,
  role,
  token,

  callBack
) => {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      iddashboard: iddashboard,
      iduser: iduser,
      role: role,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${host}/dashboard/update-userdashord-role`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        callBack();
        // console.log(JSON.parse(result).update);
        dispatch(
          actionSetDashboards({
            data: JSON.parse(result).update,
            iduser: iduser,
            type: "update",
          })
        );
      })
      .catch((error) => console.log("error", error));
  };
};
export const actionSetTeamRoleDashboard = (
  iddashboard,
  idteam,
  role,
  token,

  callBack
) => {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      iddashboard: iddashboard,
      idteam: idteam,
      role: role,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${host}/dashboard/update-teamdashboard-role`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        callBack();
        // console.log(JSON.parse(result).update);
        dispatch(
          actionSetDashboards({
            data: JSON.parse(result).update,
            idteam: idteam,
            type: "updateteamdashboard",
          })
        );
      })
      .catch((error) => console.log("error", error));
  };
};
export const removeUserDashboard = (iddashboard, iduser, token, callBack) => {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      iddashboard: iddashboard,
      iduser: iduser,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    dispatch(
      actionSetDashboards({
        iduser: iduser,
        type: "delete",
      })
    );
    fetch(`${host}/dashboard/remove-userdashord`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        callBack();
      })
      .catch((error) => console.log("error", error));
  };
};
export const removeTeamDashboard = (iddashboard, idteam, token, callBack) => {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      iddashboard: iddashboard,
      idteam: idteam,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    dispatch(
      actionSetDashboards({
        idteam: idteam,
        type: "deleteteamdashboard",
      })
    );
    fetch(`${host}/dashboard/remove-teamdashboard`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        callBack();
      })
      .catch((error) => console.log("error", error));
  };
};
export const removeUserDashboardList = (
  iduserdashboard,
  idlist,
  token,
  callBack
) => {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      iduserdashboard: iduserdashboard,
      idlist: idlist,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    dispatch(
      actionSetDashboards({
        iduserdashboard: iduserdashboard,
        idlist: idlist,
        type: "deleteuserlistDashboard",
      })
    );
    fetch(`${host}/dashboard/remove-userdashboardlist/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        callBack();
      })
      .catch((error) => console.log("error", error));
  };
};
export const removeTeamDashboardList = (
  idteamdashboard,
  idlist,
  token,
  callBack
) => {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      idteamdashboard: idteamdashboard,
      idlist: idlist,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    dispatch(
      actionSetDashboards({
        idteamdashboard: idteamdashboard,
        idlist: idlist,
        type: "deleteteamdashboardlist",
      })
    );
    fetch(`${host}/dashboard/remove-teamdashboardlist/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        callBack();
      })
      .catch((error) => console.log("error", error));
  };
};
// * --------- End --------
