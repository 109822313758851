import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Eye, EyeOff, User, Lock } from "lucide-react";
import host from "../../redux/actions/host";
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}
const formSchema = z
  .object({
    nom: z.string().min(2, "Le nom doit contenir au moins 2 caractères"),
    prenom: z.string().min(2, "Le prénom doit contenir au moins 2 caractères"),
    motDePasse: z
      .string()
      .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
    confirmMotDePasse: z.string(),
  })
  .refine((data) => data.motDePasse === data.confirmMotDePasse, {
    message: "Les mots de passe ne correspondent pas",
    path: ["confirmMotDePasse"],
  });

export default function InvitedCompany() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nom: "",
      prenom: "",
      motDePasse: "",
      confirmMotDePasse: "",
    },
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToken(searchParams.get("noreply"));
    if (!searchParams.get("noreply")) {
      navigate("/");
    }
  }, []);

  const onSubmit = async (data) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      firstName: data.prenom,
      lastName: data.nom,
      password: data.motDePasse,
      token,
    });

    const options = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/company/accepte-invitation-new`, options)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.json();
        }
        return null;
      })
      .then((result) => {
        if (result) {
          enqueueSnackbar("Informations enregistrées.", { variant: "success" });
          navigate("/");
        } else {
          enqueueSnackbar("Une erreur c'est produite.", { variant: "error" });
        }
      })
      .catch(() => {
        enqueueSnackbar("Une erreur c'est produite.", { variant: "error" });
      })
      .then(() => {
        setLoading(false);
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="relative flex flex-col justify-center overflow-hidden bg-white/2">
      <div className="flex flex-col items-center justify-center min-h-screen gap-3 px-4 overflow-y-auto">
        <div className="mb-0 mt-2">
          <img
            src="./update/logo.png"
            alt="Viabber"
            className="object-contain w-80 h-50 left-0 top-[15%]"
          />
        </div>
        <main className="z-10 flex flex-col items-start justify-start flex-grow font-poppins">
          <form
            action=""
            className="flex  flex-col px-5 py-7 rounded-[30px]  w-[540px]  border-3 border-black bg-white shadow-2xl font-poppins space-y-3"
            onSubmit={loading ? null : handleSubmit(onSubmit)}
          >
            <h1 className="flex-1 shrink gap-3 self-center w-full text-2xl font-bold  whitespace-nowrap max-w-[497px]  max-md:max-w-full font-poppins ">
              S'inscrire
            </h1>
            <>
              <div className="flex flex-col my-1">
                <label htmlFor="nom" className="font-poppins font-[400px]">
                  Votre nom<span className="text-red-300">*</span>
                </label>
                <div className="relative mt-1">
                  <User
                    className="absolute transform -translate-y-1/2 left-3 top-1/2"
                    size={18}
                  />
                  <input
                    {...register("nom")}
                    type="text"
                    id="nom"
                    className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                    placeholder="Saisir votre nom"
                  />
                </div>
                <p className="mt-1 text-xs text-red-300 ">
                  {errors.nom && errors.nom.message}
                </p>
              </div>
              <div className="flex flex-col m-0">
                <label htmlFor="prenom" className="font-poppins font-[400px]">
                  Votre prénom<span className="text-red-300">*</span>
                </label>
                <div className="relative mt-1">
                  <User
                    className="absolute transform -translate-y-1/2 left-3 top-1/2"
                    size={18}
                  />
                  <input
                    {...register("prenom")}
                    type="text"
                    id="prenom"
                    className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                    placeholder="Saisir votre prénom"
                  />
                </div>
                <p className="mt-1 text-xs text-red-300 ">
                  {errors.prenom && errors.prenom.message}
                </p>
              </div>
              <div className="flex flex-col m-0">
                <label
                  htmlFor="motDePasse"
                  className="block text-sm font-medium "
                >
                  Mot de passe<span className="text-red-300">*</span>
                </label>
                <div className="relative mt-1">
                  <Lock
                    className="absolute transform -translate-y-1/2 left-3 top-1/2"
                    size={18}
                  />
                  <input
                    {...register("motDePasse")}
                    type={showPassword ? "text" : "password"}
                    id="motDePasse"
                    className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                    placeholder="Saisir votre mot de passe"
                  />
                  <button
                    type="button"
                    onClick={togglePassword}
                    className="absolute transition duration-200 transform -translate-y-1/2 right-3 top-1/2 "
                  >
                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                  </button>
                </div>
                <p className="mt-1 text-xs text-red-300">
                  {errors.motDePasse && errors.motDePasse.message}
                </p>
              </div>
              <div className="flex flex-col m-0">
                <label
                  htmlFor="confirmMotDePasse"
                  className="block text-sm font-medium "
                >
                  Confirmer le mot de passe
                  <span className="text-red-300">*</span>
                </label>
                <div className="relative mt-1">
                  <Lock
                    className="absolute transform -translate-y-1/2 left-3 top-1/2"
                    size={18}
                  />
                  <input
                    {...register("confirmMotDePasse")}
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmMotDePasse"
                    className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                    placeholder="Confirmer votre mot de passe"
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPassword}
                    className="absolute transition duration-200 transform -translate-y-1/2 right-3 top-1/2 "
                  >
                    {showConfirmPassword ? (
                      <EyeOff size={18} />
                    ) : (
                      <Eye size={18} />
                    )}
                  </button>
                </div>
                <p className="mt-1 text-xs text-red-300">
                  {errors.confirmMotDePasse && errors.confirmMotDePasse.message}
                </p>
              </div>
            </>
            <div className="flex justify-between space-x-3">
              <button
                type="submit"
                className={`px-6 py-3 focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-200 w-full bg-colorText text-white p-2 rounded-[10px] font-poppins  font-semibold outline-none hover:outline-none w-full`}
              >
                {loading ? "En cours..." : "S'inscrire"}
              </button>
            </div>
          </form>
        </main>
        <svg
          className="absolute bottom-[-20%] left-0 right-0 size-full 2xl:bottom-[-40%] "
          viewBox="0 0 1440 448"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="719.5"
            cy="535"
            rx="535"
            ry="978.5"
            transform="rotate(-90 719.5 535)"
            fill="url(#paint0_linear_715_2262)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_715_2262"
              x1="1254.23"
              y1="535.076"
              x2="184.958"
              y2="535.076"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.00549451" stop-color="#21263C" />
              <stop offset="0.475" stop-color="#4C557D" />
              <stop offset="0.93" stop-color="#586398" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}
