import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionRegister } from "../../redux/actions/UserAction";
import validator from "validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Eye,
  EyeOff,
  User,
  Mail,
  Lock,
  Briefcase,
  CreditCard,
} from "lucide-react";
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}
const formSchema = z
  .object({
    nom: z.string().min(2, "Le nom doit contenir au moins 2 caractères"),
    prenom: z.string().min(2, "Le prénom doit contenir au moins 2 caractères"),
    email: z.string().email("Email invalide"),
    motDePasse: z
      .string()
      .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
    confirmMotDePasse: z.string(),
    type: z.enum(["UTILISATEUR", "ENTREPRISE"]),
    entreprise: z
      .object({
        nomEntreprise: z
          .string()
          .min(2, "Le nom de l'entreprise doit contenir au moins 2 caractères"),
        siret: z
          .string()
          .length(14, "Le numéro SIRET doit contenir 14 chiffres"),
      })
      .optional(),
  })
  .refine((data) => data.motDePasse === data.confirmMotDePasse, {
    message: "Les mots de passe ne correspondent pas",
    path: ["confirmMotDePasse"],
  });

export default function Register() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [step1Validated, setStep1Validated] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nom: "",
      prenom: "",
      email: "",
      motDePasse: "",
      confirmMotDePasse: "",
      type: "UTILISATEUR",
    },
  });

  const typeCompte = watch("type");

  const onSubmit = async (data) => {
    if (step === 1 && typeCompte === "ENTREPRISE") {
      const isValid = await trigger([
        "nom",
        "prenom",
        "email",
        "motDePasse",
        "confirmMotDePasse",
        "type",
      ]);
      if (isValid) {
        setStep1Validated(true);
        if (data.type === "ENTREPRISE") {
          setStep(2);
        } else {
          console.log("Données du formulaire:", data);
          // Ici, vous pouvez envoyer les données à votre API
        }
      }
    } else {
      dispatch(
        actionRegister(
          data.prenom,
          data.nom,
          data.email,
          data.motDePasse,
          data.type,
          data.entreprise?.nomEntreprise || "",
          data.entreprise?.siret || "",
          ({ message, status }) => {
            if (status) {
              enqueueSnackbar(message || "", {
                variant: "success",
              });
              localStorage.setItem("email", data.email);
              navigate("/otp");
            } else {
              enqueueSnackbar(message || "", {
                variant: "warning",
              });
            }
          }
        )
      );
      // Ici, vous pouvez envoyer les données à votre API
    }
  };

  const handleBack = () => {
    console.log("coucou");
    setStep(1);
  };
  const handleNext = async () => {
    if (step1Validated && typeCompte === "ENTREPRISE") {
      setStep(2);
    } else {
      const isValid = await trigger([
        "nom",
        "prenom",
        "email",
        "motDePasse",
        "confirmMotDePasse",
        "type",
      ]);
      if (isValid) {
        setStep1Validated(true);
        if (typeCompte === "entreprise") {
          setStep(2);
        }
      }
    }
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   if (firstName && lastName && email && password && confirm) {
  //     if (password.length >= 8) {
  //       if (password === confirm) {
  //         if (validator.isEmail(email)) {
  //           dispatch(
  //             actionRegister(
  //               firstName,
  //               lastName,
  //               email,
  //               phone,
  //               password,
  //               (status) => {
  //                 if (status === 200) {
  //                   navigate("/home");
  //                 } else if (status === 409) {
  //                   enqueueSnackbar(
  //                     "Un compte avec cette adresse existe déjà",
  //                     {
  //                       variant: "warning",
  //                     }
  //                   );
  //                 }
  //               }
  //             )
  //           );
  //         } else {
  //           enqueueSnackbar("L'adresse email n'est pas valide !", {
  //             variant: "warning",
  //           });
  //           // alert("Invalid mail");
  //         }
  //       } else {
  //         // alert("password et password confirm ne sont pas egaux");
  //         enqueueSnackbar("Les mots de passe doivent être identiques!", {
  //           variant: "warning",
  //         });
  //         setConfirm("");
  //       }
  //     } else {
  //       // alert("mot de passe min 8 caracteres");
  //       enqueueSnackbar(
  //         "Le mot de passe doit comporter au minimum 8 caractères!",
  //         {
  //           variant: "warning",
  //         }
  //       );
  //     }
  //   } else {
  //     // alert("all require");
  //     enqueueSnackbar("Tous les champs sont obligatoires!", {
  //       variant: "warning",
  //     });
  //   }
  // };

  return (
    // <div className="justify-center pt-3 overflow-y-auto bg-white/2 ">
    <div className="justify-center pt-3 overflow-y-auto bg-white/2  h-[calc(100vh)] w-full overflow-clip  ">
      {/* Header */}
      <div className="flex items-center justify-end gap-3 pr-8 mt-2">
        <span className="font-semibold font-poppins">
          Vous avez déjà un compte ?
        </span>
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6875 3.9375C20.4135 3.9375 21 4.52402 21 5.25C21 5.97598 20.4135 6.5625 19.6875 6.5625H11.1562V3.9375H19.6875ZM13.125 11.8125C13.851 11.8125 14.4375 12.399 14.4375 13.125C14.4375 13.851 13.851 14.4375 13.125 14.4375H10.5C9.77402 14.4375 9.1875 13.851 9.1875 13.125C9.1875 12.399 9.77402 11.8125 10.5 11.8125H13.125ZM15.75 9.1875C15.75 9.91348 15.1635 10.5 14.4375 10.5H12.4688C11.7428 10.5 11.1562 9.91348 11.1562 9.1875C11.1562 8.46152 11.7428 7.875 12.4688 7.875H14.4375C15.1635 7.875 15.75 8.46152 15.75 9.1875ZM11.8125 15.75C12.5385 15.75 13.125 16.3365 13.125 17.0625C13.125 17.7885 12.5385 18.375 11.8125 18.375H9.1875C8.46152 18.375 7.875 17.7885 7.875 17.0625C7.875 16.3365 8.46152 15.75 9.1875 15.75H11.8125ZM8.20312 11.8125H8.22773C8.00625 12.198 7.875 12.6451 7.875 13.125C7.875 13.6664 8.03906 14.1668 8.31797 14.5852C7.29668 14.942 6.5625 15.9182 6.5625 17.0625C6.5625 17.5424 6.68965 17.9895 6.91523 18.375H6.5625C2.93672 18.375 0 15.4383 0 11.8125V9.28184C0 7.54277 0.693164 5.87344 1.92363 4.64297L2.39941 4.16719C3.38379 3.17871 4.7209 2.625 6.11133 2.625H7.21875C8.6666 2.625 9.84375 3.80215 9.84375 5.25V8.85938C9.84375 9.76582 9.10957 10.5 8.20312 10.5C7.29668 10.5 6.5625 9.76582 6.5625 8.85938V6.5625C6.5625 6.20156 6.26719 5.90625 5.90625 5.90625C5.54531 5.90625 5.25 6.20156 5.25 6.5625V8.85938C5.25 10.4918 6.5707 11.8125 8.20312 11.8125Z"
            fill="#04B6EE"
          />
        </svg>

        <Link
          to="/"
          className="px-4 py-2 font-semibold text-white rounded-md outline-none bg-colorText font-poppins hover:outline-none"
        >
          Se connecter
        </Link>
      </div>

      <div className="flex flex-col items-center justify-center w-full h-full gap-2 ">
        <div className="m-0">
          <img
            src="./update/logo.png"
            alt="Viabber"
            className="object-contain w-80 h-50"
          />
        </div>
        <main className="relative w-full flex h-[100vh] flex-col items-center justify-center font-poppins">
          <form
            action=""
            className="flex  z-10 flex-col px-5 py-7 rounded-[30px]  w-[540px] h  border-3 border-black bg-white shadow-2xl font-poppins space-y-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="flex-1 z-10 shrink gap-3 self-center w-full text-2xl font-bold  whitespace-nowrap max-w-[497px]   font-poppins ">
              S'inscrire
            </h1>
            {step === 1 && (
              <>
                <div className="flex flex-col my-1">
                  <label htmlFor="nom" className="font-poppins font-[400px]">
                    Votre nom<span className="text-red-300">*</span>
                  </label>
                  <div className="relative mt-1">
                    <User
                      className="absolute transform -translate-y-1/2 left-3 top-1/2"
                      size={18}
                    />
                    <input
                      {...register("nom")}
                      type="text"
                      id="nom"
                      className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                      placeholder="Saisir votre nom"
                    />
                  </div>
                  <p className="mt-1 text-xs text-red-300 ">
                    {errors.nom && errors.nom.message}
                  </p>
                </div>
                <div className="flex flex-col m-0">
                  <label htmlFor="prenom" className="font-poppins font-[400px]">
                    Votre prénom<span className="text-red-300">*</span>
                  </label>
                  <div className="relative mt-1">
                    <User
                      className="absolute transform -translate-y-1/2 left-3 top-1/2"
                      size={18}
                    />
                    <input
                      {...register("prenom")}
                      type="text"
                      id="prenom"
                      className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                      placeholder="Saisir votre prénom"
                    />
                  </div>
                  <p className="mt-1 text-xs text-red-300 ">
                    {errors.prenom && errors.prenom.message}
                  </p>
                </div>
                <div className="flex flex-col m-0 ">
                  <label htmlFor="email" className="font-poppins font-[400px]">
                    Votre email<span className="text-red-300">*</span>
                  </label>
                  <div className="relative mt-1">
                    <Mail
                      className="absolute transform -translate-y-1/2 left-3 top-1/2"
                      size={18}
                    />
                    <input
                      {...register("email")}
                      type="text"
                      id="email"
                      className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                      placeholder="Saisir votre email"
                    />
                  </div>
                  <p className="mt-1 text-xs text-red-300">
                    {errors.email && errors.email.message}
                  </p>
                </div>
                <div className="flex flex-col m-0">
                  <label
                    htmlFor="motDePasse"
                    className="block text-sm font-medium "
                  >
                    Mot de passe<span className="text-red-300">*</span>
                  </label>
                  <div className="relative mt-1">
                    <Lock
                      className="absolute transform -translate-y-1/2 left-3 top-1/2"
                      size={18}
                    />
                    <input
                      {...register("motDePasse")}
                      type={showPassword ? "text" : "password"}
                      id="motDePasse"
                      className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                      placeholder="Saisir votre mot de passe"
                    />
                    <button
                      type="button"
                      onClick={togglePassword}
                      className="absolute transition duration-200 transform -translate-y-1/2 right-3 top-1/2 "
                    >
                      {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                    </button>
                  </div>
                  <p className="mt-1 text-xs text-red-300">
                    {errors.motDePasse && errors.motDePasse.message}
                  </p>
                </div>
                <div className="flex flex-col m-0">
                  <label
                    htmlFor="confirmMotDePasse"
                    className="block text-sm font-medium "
                  >
                    Confirmer le mot de passe
                    <span className="text-red-300">*</span>
                  </label>
                  <div className="relative mt-1">
                    <Lock
                      className="absolute transform -translate-y-1/2 left-3 top-1/2"
                      size={18}
                    />
                    <input
                      {...register("confirmMotDePasse")}
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmMotDePasse"
                      className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                      placeholder="Confirmer votre mot de passe"
                    />
                    <button
                      type="button"
                      onClick={toggleConfirmPassword}
                      className="absolute transition duration-200 transform -translate-y-1/2 right-3 top-1/2 "
                    >
                      {showConfirmPassword ? (
                        <EyeOff size={18} />
                      ) : (
                        <Eye size={18} />
                      )}
                    </button>
                  </div>
                  <p className="mt-1 text-xs text-red-300">
                    {errors.confirmMotDePasse &&
                      errors.confirmMotDePasse.message}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <label className="block text-sm font-medium ">
                    Type de compte<span className="text-red-300">*</span>
                  </label>
                  <div className="flex mt-1 space-x-4">
                    <label className="flex-1">
                      <input
                        {...register("type")}
                        type="radio"
                        value="UTILISATEUR"
                        className="sr-only"
                      />
                      <span
                        className={`flex items-center justify-center px-4 py-3 rounded-xl border-2 ${
                          typeCompte === "UTILISATEUR"
                            ? "text-colorText "
                            : " bg-opacity-20"
                        } cursor-pointer transition-all duration-200 hover:bg-opacity-30`}
                      >
                        <User className="mr-2" size={18} />
                        Individuelle
                      </span>
                    </label>
                    <label className="flex-1">
                      <input
                        {...register("type")}
                        type="radio"
                        value="ENTREPRISE"
                        className="sr-only"
                      />
                      <span
                        className={`flex items-center justify-center px-4 py-3 rounded-xl border-2 ${
                          typeCompte === "ENTREPRISE"
                            ? " text-colorText "
                            : " bg-opacity-20 "
                        } cursor-pointer transition-all duration-200 hover:bg-opacity-30`}
                      >
                        <Briefcase className="mr-2" size={18} />
                        Entreprise
                      </span>
                    </label>
                  </div>
                  <p className="mt-1 text-xs text-red-300">
                    {errors.type && errors.type.message}
                  </p>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div className="flex flex-col gap-2 ">
                  <label
                    htmlFor="nomEntreprise"
                    className="block text-sm font-medium "
                  >
                    Nom de l'entreprise<span className="text-red-300">*</span>
                  </label>
                  <div className="relative">
                    <Briefcase
                      className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
                      size={18}
                    />
                    <input
                      {...register("entreprise.nomEntreprise")}
                      type="text"
                      id="nomEntreprise"
                      className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                      placeholder="Saisir le nom de l'entreprise"
                    />
                  </div>
                  {errors.entreprise?.nomEntreprise && (
                    <p className="mt-1 text-xs text-red-300">
                      {errors.entreprise.nomEntreprise.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2 ">
                  <label htmlFor="siret" className="block text-sm font-medium ">
                    Numéro SIRET<span className="text-red-300">*</span>
                  </label>
                  <div className="relative">
                    <CreditCard
                      className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
                      size={18}
                    />
                    <input
                      {...register("entreprise.siret")}
                      type="text"
                      id="siret"
                      className="w-full py-2 pl-10 pr-4 transition duration-200 border-2 rounded-xl focus:outline-none focus:ring-2 border-inputBorderColor border-zinc-200"
                      placeholder="Saisir le numéro SIRET"
                    />
                  </div>
                  {errors.entreprise?.siret && (
                    <p className="mt-1 text-xs text-red-300">
                      {errors.entreprise.siret.message}
                    </p>
                  )}
                </div>
              </>
            )}
            <div className="flex justify-between space-x-3">
              {step === 2 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="w-full px-6 py-3 text-white transition duration-200 bg-colorText rounded-xl hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-opacity-50"
                >
                  Retour
                </button>
              )}
              <button
                type="submit"
                className={`px-6 py-3   focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-200 w-full bg-colorText text-white p-2 rounded-[10px] font-poppins  font-semibold outline-none hover:outline-none ${
                  step === 1 && typeCompte === "UTILISATEUR"
                    ? "w-full"
                    : "flex-grow"
                }`}
                onClick={
                  step === 1 && typeCompte === "ENTREPRISE"
                    ? handleNext
                    : handleSubmit(onSubmit)
                }
              >
                {step === 1
                  ? typeCompte === "ENTREPRISE"
                    ? "Suivant"
                    : "S'inscrire"
                  : "S'inscrire"}
              </button>
            </div>
          </form>
          <svg
            className="absolute z-1 bottom-[-10%] left-0 right-0  2xl:bottom-[-40%] "
            viewBox="0 0 1440 448"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="719.5"
              cy="535"
              rx="535"
              ry="978.5"
              transform="rotate(-90 719.5 535)"
              fill="url(#paint0_linear_715_2262)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_715_2262"
                x1="1254.23"
                y1="535.076"
                x2="184.958"
                y2="535.076"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.00549451" stop-color="#21263C" />
                <stop offset="0.475" stop-color="#4C557D" />
                <stop offset="0.93" stop-color="#586398" />
              </linearGradient>
            </defs>
          </svg>
        </main>
      </div>
    </div>
  );
}
