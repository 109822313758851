import React, { useRef, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import stringToColor from "string-to-color";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const formatDataForChart = (rows, columns) => {
  return rows.map((row) => {
    const formattedRow = {};
    columns.forEach((column, index) => {
      if (typeof row[index] === "string") {
        formattedRow[column.label] = row[index + 1];
      } else {
        formattedRow[column.label] = row[index + 1];
      }
      formattedRow["style"] = row[0];
    });

    return formattedRow;
  });
};

export default function CardLine({ rows, columns }) {
  const chartRef = useRef(null);
  const [visibleDataPoints, setVisibleDataPoints] = useState(rows.length);
  const [chartHeight, setChartHeight] = useState(0);
  const [legend, setLegend] = useState(true);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartRef.current) {
        const height = chartRef.current.clientHeight;
        setChartHeight(height);
        // Calculer le nombre de points de données visibles en fonction de la hauteur
        // Supposons que chaque point de données nécessite au moins 30px de hauteur
        const newVisibleDataPoints = Math.floor(height / 10);
        setVisibleDataPoints(Math.min(newVisibleDataPoints, rows.length));
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [rows.length]);

  const formattedData = formatDataForChart(rows, columns);
  const formatDate = (date) => {
    const options = { weekday: "short" };
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const weekday = date.toLocaleString("fr-FR", options);
    return `${weekday} ${day}/${month}/${year}`;
  };

  const visibleData = formattedData.slice(-visibleDataPoints);

  const data = {
    labels: visibleData.map((item, index) => {
      if (columns[1].type === "datetime")
        return formatDate(item[columns[0].label].value);
      return item[columns[0].label].value;
    }),
    datasets: columns
      .slice(1)
      .filter((item) => item.isVisible)
      .map((column, index) => {
        return {
          label: column.label,
          data: visibleData.map((item) => item[column.label].value),

          borderWidth: 1,
          backgroundColor: visibleData.map((d, i) => {
            let color = d[column.label].color;
            if (Object.keys(d[column.label].style).length > 0) {
              color = d[column.label].style.color;
            }

            if (Object.keys(d[column.label].style).length > 0 && legend)
              setLegend(false);
            return color;
          }),
          borderColor: visibleData.map((d, i) => {
            let color = d[column.label].color;
            if (Object.keys(d[column.label].style).length > 0) {
              color = d[column.label].style.color;
            }

            if (Object.keys(d[column.label].style).length > 0 && legend)
              setLegend(false);
            return color;
          }),
          borderRadius: 6,
          type: column.type === "number" ? "line" : "bar",
          fill: false,
        };
      }),
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true, // Utiliser des points stylisés pour la légende
          pointStyle: "circle", // Style de point circulaire
          padding: 20, // Espacement entre les éléments de la légende
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div ref={chartRef} style={{ width: "100%", height: "100%", padding: 8 }}>
      <Line data={data} options={{ ...options, responsive: true }} />
    </div>
  );
}
