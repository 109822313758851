import React, { useEffect, useCallback, useRef } from "react";
import Header from "../Header";
import useState from "react-usestateref";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { actionGetConnectors } from "../../redux/actions/connectorAction";
import {
  actionGetDatasources,
  actionSetOneDatasource,
} from "../../redux/actions/datasourceAction";
import Modal from "../Views/Modal";
import { useSelector, useDispatch } from "react-redux";
import CustomSelect from "../Views/CustomSelect";
import { useSnackbar } from "notistack";
import { parseInt } from "lodash";
import { Popover } from "@mui/material";
import { Input } from "antd";
import {
  actionCreateIndicators,
  actionGetIndicatorsByDataSource,
  actionUpdateIndicators,
  actionDeleteIndicators,
} from "../../redux/actions/IndicatorsAction";
import { truncateText } from "../utils/truncateWords";
import Select from "react-select";
import { Search } from "lucide-react";

import { ChevronDownIcon } from "lucide-react";
import { Tooltip, Checkbox, Popover as PopoverAntd } from "antd";
import FilterView from "../Views/FilterView";
import { Link } from "react-router-dom";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  Type,
  Bold,
  Italic,
  Underline,
} from "lucide-react";
import { X, Edit2 } from "lucide-react";
import { actionGetConditionByDdataSource } from "../../redux/actions/ConditionAction";
import { actionGetOptions } from "../../redux/actions/indicatorAction";
import TableRowLoader from "../Views/TableLoading";

const _ = require("lodash");
let theme = "1";
const data = localStorage.getItem("theme");

const xlsxHost = "https://xlsx.viabber.com";

export default function Columns({ source }) {
  const { TextArea } = Input;
  const datasources = useSelector((state) => state.datasource.datasources);
  const datasource = datasources.find(
    (i) => i.iddatasource === source.iddatasource
  );
  const indicators = useSelector((state) => state.indicators.indicators);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  const idUser = useSelector((state) => state.user.id);

  const token = useSelector((state) => state.user.token);
  const conditions = useSelector((state) => state.condition.conditions);
  const optionsConditions = conditions.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const [columnsM, setColumnsM] = useState(
    datasource
      ? datasource.columns.map((item) => ({
          value: item.idcolumn,
          label: item.name.toLocaleLowerCase(),
          type: +item.dataType,
        }))
      : []
  );
  const formuleOptions = [
    { value: 1, label: "Somme" },
    { value: 2, label: "Moyenne" },
    // { value: 3, label: "Aucun calcul" },
    { value: 4, label: "Compteur" },
  ];
  const targetOptions = [
    { value: 0, label: "en tête" },
    { value: 1, label: "le graphe" },
    { value: 2, label: "les deux" },
  ];

  const [isUpdate, setIsUpdate] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [isFormulaValid, setIsFormulaValid] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [idColumn, setIdColumn] = useState("");
  const [isExtension, setIsExtension] = useState(false);
  const [isPart, setIsPart] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [idMetric, setIdMetric] = useState(null);
  const [formule, setFormule] = useState("");
  const [color, setColor] = useState("#000000");
  const [filter, setFilter] = useState([]);
  const [rules, setRules] = useState([]);
  const [operation, setOperation] = useState(formuleOptions[0]);
  const [symbole, setSymbole] = useState("");

  const open = Boolean(anchorEl);
  const f = [];

  for (let i = 0; i < datasource.columns.length; i++) {
    const column = datasource.columns[i];

    f.push({
      id: column.idcolumn,
      name: column.name,
      type: parseInt(column.dataType),
      checked: false,
      indicator: Boolean(column.columnType === "indicator"),
      min: null,
      max: null,
      loading: parseInt(column.dataType) === 2 ? true : false,
      value: null,
      values: [],
      options: [],
      interval: [null, null],
    });
  }

  const [filters, setFilters, filtersRef] = useState(f);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [condition, setCondition] = useState(null);
  const [target, setTarget] = useState({ value: 0, label: "en tête" });
  const [comp, setComp] = useState({ value: "0", label: "Apparence" });
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [editingId, setEditingId] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [idIndicateur, setIdIndicateur] = useState(null);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [item, setItem] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingId !== null) {
      setRules(
        rules.map((item) =>
          item.id === editingId
            ? {
                ...item,
                title,
                comp,
                condition,
                color: selectedColor,
                isBold,
                isItalic,
                isUnderline,
                fontSize,
                target,
              }
            : item
        )
      );
      setEditingId(null);
    } else {
      setRules([
        ...rules,
        {
          id: Date.now(),
          title,
          condition,
          comp,
          color: selectedColor,
          isBold,
          isItalic,
          isUnderline,
          fontSize,
          target,
        },
      ]);
    }
    handleCancel();
  };

  const handleCancel = (e) => {
    setTitle("");
    setComp({ value: "0", label: "Apparence" });
    setCondition(null);
    setTarget({ value: 0, label: "en tête" });
    // setRules([]);
    setSelectedColor("#000000");
    setIsBold(false);
    setIsItalic(false);
    setFontSize(14);
    setIsOpen(false);
    setEditingId(null);
    setVisibility(true);
    setIsUnderline(false);
  };

  const handleEdit = (item) => {
    setTitle(item.title);
    setComp(item.comp);
    setCondition(item.condition);
    setTarget(item.target);
    setSelectedColor(item.color);
    setIsBold(item.isBold);
    setIsItalic(item.isItalic);
    setIsUnderline(item.isUnderline);
    setFontSize(item.fontSize);
    setEditingId(item.id);
    setIsOpen(true);
  };

  const handleDelete = (id) => {
    setRules(rules.filter((item) => item.id !== id));
  };
  const fontSizes = [12, 14, 16, 18, 20, 24];

  useEffect(() => {
    // if (formule) {
    const isValid = formulaValidator(formule, columnsM);
    setIsFormulaValid(isValid);
    // }
  }, [formule]);

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data) {
      navigate("/");
    } else {
      if (datasource) {
        setLoading(true);
        dispatch(
          actionGetConditionByDdataSource(
            datasource.iddatasource,
            "Bearer " + JSON.parse(data).token,
            (status) => {}
          )
        );
        dispatch(
          actionGetIndicatorsByDataSource(
            datasource.iddatasource,
            "Bearer " + JSON.parse(data).token,
            (status) => {}
          )
        );
      }
      setLoading(false);
    }
  }, []);

  const formulaValidator = (formula, columns) => {
    const intitialisations = columns.map((item) => {
      if (item.type == 1 && item.type == 4) return `var ${item.label} = 1;`;
      else if (item.type == 2) return `var ${item.label} = "a";`;
      else if (item.type == 3) return `var _${item.label} = new Date();`;
      else return `var ${item.label} = 1;`;
    });
    try {
      const dynamicInitializations = columns.map((item) => {
        if (item.type == 1 && item.type == 4) return `var ${item.label} = 1;`;
        else if (item.type == 2) return `var ${item.label} = "a";`;
        else if (item.type == 3) return `var ${item.label} = new Date();`;
        else return `var ${item.label} = 1;`;
      });

      // eslint-disable-next-line no-eval
      const result = eval(
        `
        ${intitialisations.join(" ")} ${dynamicInitializations.join(
          " "
        )} ${formula} `
      );

      if (typeof result === "number") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const handleFormulaChange = (event) => {
    const enteredFormula = event.target.value;

    if (enteredFormula.includes("#")) {
      setAnchorEl(event.currentTarget);
    } else {
      let updatedFormula = enteredFormula;
      try {
        setFormule(updatedFormula);
      } catch (error) {}
    }
  };

  const cancel = () => {
    setFormule("");
    setName("");
    setIsFormulaValid(null);
    setIsUpdate(false);
    setSymbole("");
    setOperation(formuleOptions[0]);
    setIdColumn(null);
    setLoading(false);
    setColor("#000000");
    setIdMetric(null);
    setIsExtension(false);
    setIsPart(false);
    setIsVisible(true);
    setRules([]);
    setIdIndicateur(null);
    setItem(null);
    if (datasource.iddatasource) {
      const resetFilter = filters.map((item) => {
        item.values = [];
        item.interval = [null, null];
        item.checked = false;
        item.value = null;
        item.min = null;
        item.max = null;
        return item;
      });

      setFilters([...resetFilter]);
    }
  };

  const closeModal = () => {
    setVisibility(false);
    cancel();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrentGoalCol = (column) => {
    setFormule((prevFormula) => prevFormula + `${column}`);
    setAnchorEl(null);
  };

  const submit = (e) => {
    e.preventDefault();

    const viewConditions = rules.map((item) => ({
      idCondition: item.condition.value,
      name: item.title,
      target: item.target.value,
      appearances: [
        {
          bold: item.isBold,
          color: item.color,
          italic: item.isItalic,
          size: item.fontSize,
          underline: item.isUnderline,
        },
      ],
    }));

    const f = [];
    for (let index = 0; index < filters.length; index++) {
      const element = filters[index];
      if (element.checked) {
        f.push({
          id: element.id,
          min: element.interval[0]
            ? new Date(element.interval[0]).getTime() / 1000
            : +element.min,
          max: element.interval[0]
            ? new Date(element.interval[0]).getTime() / 1000
            : +element.max,
          values: element.values,
        });
      }
    }
    if (!isUpdate) {
      add({
        name,
        isExtension,
        isPart,
        isVisible,
        idMetric: isExtension ? idMetric.value : null,
        iddatasource: datasource.iddatasource,
        operation: operation.value,
        formule: isExtension ? null : formule,
        color,
        symbole,
        filters: isExtension ? f : [],
        viewConditions,
      });
    } else {
      if (idIndicateur) {
        update({
          idIndicateur,
          name,
          isExtension,
          isPart,
          isVisible,
          idMetric: isExtension ? idMetric.value : null,
          iddatasource: datasource.iddatasource,
          operation: operation.value,
          formule: isExtension ? null : formule,
          color,
          symbole,
          filters: isExtension ? f : [],
          viewConditions,
        });
      }
    }
  };

  const add = ({
    name,
    isExtension,
    isPart,
    isVisible,
    idMetric,
    iddatasource,
    operation,
    formule,
    color,
    symbole,
    filters,
    viewConditions,
  }) => {
    dispatch(
      actionCreateIndicators(
        name,
        isExtension,
        isPart,
        isVisible,
        idMetric,
        iddatasource,
        operation,
        formule,
        color,
        symbole,
        filters,
        viewConditions,
        token,
        (status) => {
          if (status) {
            enqueueSnackbar("Insertion réalisée avec succès", {
              variant: "success",
            });
            closeModal();
          } else {
            enqueueSnackbar("Erreur lors de la création", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const update = ({
    idIndicateur,
    name,
    isExtension,
    isPart,
    isVisible,
    idMetric,
    iddatasource,
    operation,
    formule,
    color,
    symbole,
    filters,
    viewConditions,
  }) => {
    dispatch(
      actionUpdateIndicators(
        idIndicateur,
        name,
        isExtension,
        isPart,
        isVisible,
        idMetric,
        iddatasource,
        operation,
        formule,
        color,
        symbole,
        filters,
        viewConditions,
        token,
        (status) => {
          if (status) {
            enqueueSnackbar("Insertion réalisée avec succès", {
              variant: "success",
            });
            closeModal();
          } else {
            enqueueSnackbar("Erreur lors de la création", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const customStylesVue = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff", // bg-red-600
      border: "none",
      boxShadow: "none",
      outline: "none",
      padding: "0",
      fontWeight: "500",
      fontFamily: "Poppins",
      fontSize: "16 px", // tailwind equivalent of small font
      minHeight: "20px",
      minWidth: "130px",
      cursor: "pointer",
      padding: 0,
      margin: 0,
      width: 320,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "5px",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Poppins", // tailwind equivalent of small font
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "15px", // tailwind equivalent of small font
    }),
    singleValue: (provided) => ({
      ...provided, // text color inside the select fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "15px", // tailwind equivalent of small font
    }),
  };

  const UpdateItem = (item) => {
    setIdIndicateur(item.id);
    setIsUpdate(true);
    setVisibility(true);
    setName(item.name);
    setIsExtension(item.isExtension);
    setIsPart(item.isPart);
    setIsVisible(item.isVisible);
    setFormule(item.formule);
    if (item.metric) {
      setIdMetric({
        value: item.idmetric,
        label: item.metric.name,
      });
    }
    setSymbole(item.symbole);
    setColor(item.color);
    setOperation(formuleOptions.find((i) => +i.value === +item.operation));
    const rules =
      item.indicateurConditions.map((i) => ({
        id: i.id,
        condition: {
          value: i.condition.id,
          label: i.condition.name,
        },
        title: i.name,
        isBold: i.appearances[0].bold,
        isItalic: i.appearances[0].italic,
        isUnderline: i.appearances[0].underline,
        fontSize: i.appearances[0].size,
        color: i.appearances[0].color,
        comp: { value: "0", label: "Apparence" },
        target:
          targetOptions.find((t) => +t.value === +i.target) || targetOptions[0],
      })) || [];
    setRules(rules);

    if (
      item.indicateurFilterColumns &&
      item.indicateurFilterColumns.length > 0
    ) {
      let f = [...filters];

      // Collecter toutes les promesses
      const promises = [];

      for (let i = 0; i < item.indicateurFilterColumns.length; i++) {
        const a = item.indicateurFilterColumns[i];

        for (let j = 0; j < f.length; j++) {
          const b = f[j];
          if (parseInt(a.idColumn) === parseInt(b.id)) {
            f[j].checked = true;
            f[j].values = a.values ? JSON.parse(a.values) : [];
            f[j].min = a.min;
            f[j].max = a.max;

            // Ajouter une promesse pour chaque appel à dispatch
            const promise = new Promise((resolve) => {
              dispatch(
                actionGetOptions(
                  datasource.iddatasource,
                  f[j].name,
                  (status, result) => {
                    if (status === 200) {
                      f[j]["options"] = result;
                      f[j]["loading"] = false;
                    }
                    resolve(); // Résolution de la promesse
                  }
                )
              );
            });
            promises.push(promise);
            break;
          }
        }
      }

      // Attendre que toutes les promesses soient terminées
      Promise.all(promises).then(() => {
        setFilters(f); // Mise à jour de setFilters après toutes les requêtes
      });
    }
  };

  const remove = (e) => {
    e.preventDefault();
    if (idIndicateur && datasource.iddatasource) {
      dispatch(
        actionDeleteIndicators(
          idIndicateur,
          datasource.iddatasource,
          token,
          (status) => {
            if (status) {
              enqueueSnackbar("Indicateur retirer avec succès", {
                variant: "success",
              });
              cancel();
            } else {
              enqueueSnackbar("Erreur lors de la suppression", {
                variant: "error",
              });
            }
          }
        )
      );
    }
  };

  return (
    <div className="h-full">
      {item && (
        <Modal
          visible={deleteVisibility && idIndicateur}
          title={`Suppréssion d'un indicateur`}
          onCancel={cancel}
          width={500}
        >
          <div className="flex flex-col gap-4">
            {(item.indicators.length || 0) > 0 && (
              <div className="flex flex-col items-center p-2 pt-0 ">
                {/* Icône et titre */}
                <div className="flex items-center mb-3 space-x-1">
                  <h2 className="text-lg text-[24px] text-red-600 font-poppins font-bold">
                    {name}
                  </h2>
                </div>

                {/* Texte explicatif */}
                <p className="font-normal text-center text-gray-800 font-poppins">
                  Cet indicateur est rattaché à{" "}
                  <span className="font-semibold text-red-600">
                    {item.indicators.length} vue
                    {item.indicators.length > 1 ? "s" : ""}
                  </span>
                  . <br />
                  Cette action entraînera la suppression de l’indicateur dans
                  toutes les vues auxquelles il est rattaché.
                </p>
              </div>
            )}

            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={cancel}
                >
                  Annuler
                </button>
                <button
                  // disabled={!confirmDelete || item.indicators.length === 0}
                  className={`btn-full-sup hover:bg-red-500 px-4 py-2  rounded-md font-poppins disabled:bg-gray-400 disabled:border-0 disabled:hover:bg-gray-400 disabled:text-white `}
                  type="submit"
                  onClick={remove}
                >
                  {"Supprimer"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <Modal
        visible={visibility}
        title={isUpdate ? "Modifier un indicateur" : "Ajouter un indicateur"}
        onCancel={closeModal}
        large={true}
        width={900}
      >
        <form onSubmit={submit} className="flex flex-col w-full h-full gap-4 ">
          <div className="flex flex-col gap-2">
            <label for="nom" className="font-poppins font-[400px]">
              Entrer le nom de l'indicateur
            </label>

            <input
              className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor"
              id="nom"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Obligatoire"
              required
            />
          </div>
          <div className="flex gap-3 p-0 m-0">
            <div className="flex flex-col gap-2  w-[50%]">
              <Checkbox
                checked={isExtension}
                onClick={() => {
                  setIsExtension(!isExtension);
                }}
              >
                <span className="font-poppins font-[400px]">
                  lié à une colonne
                </span>
              </Checkbox>
            </div>
            <div className="flex flex-col gap-2 w-[50%]">
              <Checkbox
                checked={isPart}
                onClick={() => {
                  setIsPart(!isPart);
                }}
              >
                <span className="font-poppins font-[400px]">
                  Affiché par palier
                </span>
              </Checkbox>
            </div>
          </div>
          <div className="flex gap-3 p-0 m-0">
            <div className="flex flex-col gap-2 w-[50%]">
              <Checkbox
                checked={isVisible}
                onClick={() => {
                  setIsVisible(!isVisible);
                }}
              >
                <span className="font-poppins font-[400px]">Visible</span>
              </Checkbox>
            </div>
          </div>
          {isExtension ? (
            <div className="flex flex-col gap-2">
              <label for="symbole" className="font-poppins font-[400px]">
                Colonne
              </label>

              <CustomSelect
                id="metric"
                value={idMetric}
                options={columnsM.filter((item) => [1, 4].includes(+item.type))} //here we get the options Somme, Moyenne, Aucun Calcul, Compteur
                onChange={setIdMetric}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <label for="metric" className="font-poppins font-[400px]">
                Formule (saisir # pour afficher les colonnes de de la source de
                donnée.)
              </label>

              <textarea
                style={{ minHeight: 100 }}
                id="textArea"
                type="text"
                value={formule}
                onChange={handleFormulaChange}
                className="block w-full h-[150px] resize-none border border-gray-300 rounded-md py-2 px-3 text-base focus:ring-1  focus:outline-none placeholder-gray-400 font-poppins placeholder:font-poppins"
                placeholder="Entrez votre formule ici..."
              />
              {isFormulaValid === false && (
                <div className="mt-1 text-red-500 font-poppins">
                  La formule n'est pas valide.
                </div>
              )}
              {isFormulaValid === true && (
                <div className="mt-1 text-green-500 font-poppins">
                  La formule est valide.
                </div>
              )}
              <Popover
                // id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
              >
                <div className="mui-popover-custom">
                  <ul className="grid items-center content-center grid-cols-1 gap-2">
                    {columnsM &&
                      columnsM.map((column, index) => (
                        <li
                          key={index}
                          className="transition-all duration-300 hover:cursor-pointer hover:bg-[#05BAF3] hover:text-white opacity-45 bg-[#E3E3E3] rounded-lg text-center"
                          onClick={() => handleCurrentGoalCol(column.label)}
                        >
                          {column.label}
                        </li>
                      ))}
                  </ul>
                </div>
              </Popover>
            </div>
          )}
          <div className="flex gap-3 p-0 m-0">
            <div className="flex flex-col gap-2 w-[50%]">
              <label for="symbole" className="font-poppins font-[400px]">
                Entrer le symbole (optionnel)
              </label>

              <input
                className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor"
                id="symbole"
                type="text"
                value={symbole}
                onChange={(e) => setSymbole(e.target.value)}
                placeholder="Ex. %"
              />
            </div>
            <div className="flex flex-col gap-2 w-[50%]">
              <label for="symbole" className="font-poppins font-[400px]">
                Opération
              </label>

              <CustomSelect
                id="operation"
                value={operation}
                options={formuleOptions} //here we get the options Somme, Moyenne, Aucun Calcul, Compteur
                onChange={setOperation}
              />
            </div>
          </div>
          <div>
            <label>Couleur par défaut</label>
            <div className="flex items-center justify-start gap-4 mt-1">
              <input
                type="text"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                className="p-2 border-2 rounded-md outline-none border-inputBorderColor"
              />
              <span
                type="button"
                onClick={(e) => setColor(!showColorPicker)}
                // className="p-2 border-2 rounded-md outline-none border-inputBorderColor"
                className="w-10 h-10 border rounded"
                style={{ backgroundColor: color }}
              />
            </div>
          </div>
          {isExtension && (
            <div className="flex flex-col gap-2">
              <label className="font-poppins font-[400px]">Filtres</label>
              <div className="flex flex-wrap items-center justify-start">
                <FilterView
                  filtersOptions={filters}
                  setFilterOptions={setFilters}
                />
                <PopoverAntd
                  placement={"right"}
                  content={
                    <div>
                      {filters.map((e, index) => (
                        <p key={index}>
                          <Checkbox
                            checked={filters[index].checked}
                            onClick={() => {
                              let landa = [...filters];
                              landa[index].checked = !filters[index].checked;

                              dispatch(
                                actionGetOptions(
                                  datasource.iddatasource,
                                  landa[index].name,
                                  (status, result) => {
                                    if (status === 200) {
                                      landa[index]["options"] = result;
                                      landa[index]["loading"] = false;
                                    }
                                    setFilters([...landa]);
                                  }
                                )
                              );
                            }}
                          >
                            <span
                              style={{
                                color: "#1f2128",
                              }}
                              className="color-black"
                            >
                              {e.name}
                            </span>
                          </Checkbox>
                        </p>
                      ))}
                    </div>
                  }
                  title="Ajouter un filtre"
                  trigger="click"
                >
                  <Link to="">
                    <i className="fa-light fa-plus add-filter"></i>
                  </Link>
                </PopoverAntd>
              </div>
            </div>
          )}
          <div>
            <div>
              <div className="flex justify-end mt-2 mb-2">
                <div
                  onClick={() => setIsOpen(true)}
                  className="btn-full font-poppins font-[400px] cursor-pointer"
                >
                  <PlusIcon className="inline-block w-3 h-3 mr-1" />
                  Ajouter une règle
                </div>
              </div>

              {isOpen && (
                <Modal
                  visible={isOpen}
                  title={
                    editingId == null
                      ? "Ajouter une règle"
                      : "Modifier une règle"
                  }
                  onCancel={() => {
                    handleCancel();
                  }}
                  large={true}
                  width={475}
                >
                  {/* <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"> */}
                  <div className="w-full max-w-md bg-white rounded-lg">
                    <div className="space-y-4">
                      <div className="flex flex-col gap-2">
                        <label for="nom" className="font-poppins font-[400px]">
                          Entrer le nom de la règle
                        </label>

                        <input
                          className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor hover:outline-none active:outline-none"
                          id="nom"
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Obligatoire"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label for="nom" className="font-poppins font-[400px]">
                          Sélectionner la condition
                        </label>

                        <CustomSelect
                          id="axe-condition"
                          isClearable
                          value={condition}
                          options={optionsConditions}
                          onChange={setCondition}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label for="nom" className="font-poppins font-[400px]">
                          Sélectionner la cible
                        </label>

                        <CustomSelect
                          id="axe-condition"
                          isClearable
                          value={target}
                          options={targetOptions}
                          onChange={setTarget}
                        />
                      </div>

                      <div className="flex flex-col gap-2">
                        <label for="nom" className="font-poppins font-[400px]">
                          Sélectionner le comportement
                        </label>

                        <CustomSelect
                          id="axe-comp"
                          isClearable
                          value={comp}
                          disabled
                          options={[{ value: "0", label: "Apparence" }]}
                          onChange={setComp}
                        />
                      </div>

                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Style du texte
                        </label>
                        <div className="flex items-center space-x-4">
                          <button
                            type="button"
                            onClick={() => setIsBold(!isBold)}
                            className={`p-1 rounded ${
                              isBold ? "bg-gray-200" : "hover:bg-gray-100"
                            }`}
                          >
                            <Bold className="w-4 h-4" />
                          </button>
                          <button
                            type="button"
                            onClick={() => setIsItalic(!isItalic)}
                            className={`p-1 rounded ${
                              isItalic ? "bg-gray-200" : "hover:bg-gray-100"
                            }`}
                          >
                            <Italic className="w-4 h-4" />
                          </button>
                          <button
                            type="button"
                            onClick={() => setIsUnderline(!isUnderline)}
                            className={`p-1 rounded ${
                              isUnderline
                                ? "bg-gray-200 underline"
                                : "hover:bg-gray-100"
                            }`}
                          >
                            <Underline className="w-4 h-4" />
                          </button>
                          <select
                            value={fontSize}
                            onChange={(e) =>
                              setFontSize(Number(e.target.value))
                            }
                            className="border-gray-300 rounded"
                          >
                            {fontSizes.map((size) => (
                              <option key={size} value={size}>
                                {size}px
                              </option>
                            ))}
                          </select>

                          <input
                            type="text"
                            value={selectedColor}
                            onChange={(e) => setSelectedColor(e.target.value)}
                            className="w-24 px-2 py-1 text-sm border rounded"
                          />
                          <span
                            type="button"
                            onClick={(e) =>
                              setShowColorPicker(!showColorPicker)
                            }
                            className="w-6 h-6 border rounded"
                            style={{ backgroundColor: selectedColor }}
                          />
                        </div>
                      </div>

                      <div className="flex justify-end space-x-2">
                        <span
                          onClick={handleCancel}
                          className="px-3 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                          Annuler
                        </span>
                        <span
                          onClick={handleSubmit}
                          className="px-3 py-2 text-sm font-medium text-white bg-blue-500 rounded-md cursor-pointer hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          {editingId ? "Modifier" : "Ajouter"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </Modal>
              )}

              {Array.isArray(rules) && rules.length > 0 && (
                <div className="flex flex-wrap p-2 border-gray-200 bg-gray-50">
                  {rules.map((item) => (
                    <div
                      key={item.id}
                      className="m-2 transition-all duration-300 ease-in-out rounded-lg"
                    >
                      <span
                        key={item.id}
                        style={{ backgroundColor: "#05BAF3" }}
                        className="flex items-center px-3 py-1 mb-1 mr-2 text-sm font-medium text-white transition-all duration-300 ease-in-out rounded-full"
                      >
                        {item.title}
                        <span
                          className="ml-2 transition-opacity duration-300 cursor-pointer focus:outline-none hover:opacity-75"
                          onClick={() => handleEdit(item)}
                        >
                          <Edit2 className="w-3 h-3 text-white" />
                        </span>
                        <span
                          className="ml-1 transition-opacity duration-300 cursor-pointer focus:outline-none hover:opacity-75"
                          onClick={() => handleDelete(item.id)}
                        >
                          <X className="w-3 h-3 text-white" />
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center gap-4 mt-">
            <button
              className="btn-border close-btn-submit"
              type="reset"
              onClick={closeModal}
            >
              Annuler
            </button>
            <button className="btn-full" type="submit" disabled={loading}>
              {loading
                ? isUpdate
                  ? "Modifier..."
                  : "Ajouter..."
                : isUpdate
                ? "Modifier"
                : "+ Ajouter"}
            </button>
          </div>
        </form>
      </Modal>

      <main className="w-full h-full mx-auto ">
        <>
          <div className="px-2 mb-3 w-full row actions-nav mx-auto border-r-black rounded-[20px]  items-center gap-2">
            <div className="flex items-center justify-between w-full gap-2 font-poppins ">
              <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center ml-1">
                <div className="relative flex items-center ">
                  <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                  <input
                    type="text"
                    placeholder="Recherche"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                  />
                </div>
              </div>

              <div className="flex items-center gap-2">
                <p
                  className="hover:text-white hover:bg-colorText border-2 border-colorText text-colorText  rounded-[5px] p-0 m-0 text-center font-poppins py-1 w-[180px] font-semibold cursor-pointer text-[12px]"
                  onClick={() => {
                    setVisibility(true);
                    setIsUpdate(false);
                  }}
                  type="submit"
                >
                  +Ajouter un indicateur
                </p>
              </div>
            </div>
          </div>

          {/* <div className="bloc-fond-fonce2 wrap-table"> */}
          <div className="w-full px-3 bg-customBg h-[83vh] rounded-xl overflow-auto">
            <table className="w-full bg-white shadow-sm ">
              <thead className="mb-[600px] font-poppins leading-[50px] ">
                <tr className="bg-white rounded-xl">
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                    Nom
                  </th>
                  <th className="p-[3px] font-semibold text-left text-black border-b text-[12px]">
                    Opération
                  </th>

                  <th className="p-[3px] font-semibold text-center text-black border-b text-[12px]">
                    Nombre de filtre(s)
                  </th>
                  <th className="p-[3px] font-semibold text-center text-black border-b text-[12px]">
                    Nombre de règle(s)
                  </th>
                  <th className="p-[3px] font-semibold text-center text-black border-b text-[12px]">
                    Nombre de vue(s)
                  </th>

                  <th className="p-[3px] font-semibold text-center text-black border-b text-[12px]">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-[12px] mt-[900px] border-t-8 border-customBg overflow-y-auto">
                {loading
                  ? [...Array(5)].map((_, index) => (
                      <tr
                        key={index}
                        className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold"
                      >
                        <td colSpan={6}>
                          <TableRowLoader col={6} />
                        </td>
                      </tr>
                    ))
                  : indicators.map((item, index) => (
                      <tr
                        className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-[40px]  cursor-pointer font-[400px]"
                        key={index}
                      >
                        <td className="p-0 pl-2 text-[12px] text-black font-[500px]">
                          {item.name}
                        </td>
                        <td className="p-0 text-[12px] text-black font-[400px]">
                          {formuleOptions.find(
                            (i) => +i.value === +item.operation
                          ).label || ""}
                        </td>

                        <td className=" text-center text-black font-[400px]">
                          {item.indicateurFilterColumns?.length || 0}
                        </td>
                        <td className=" text-center text-black font-[400px]">
                          {item.indicateurConditions?.length || 0}
                        </td>
                        <td className=" text-center text-black font-[400px]">
                          {" "}
                          {item.indicators?.length || 0}
                        </td>

                        <td className="p-0 text-[12px]">
                          <div className="flex justify-center gap-3 items-left">
                            <button
                              className={`text-gray-600 focus:outline-none`}
                              onClick={() => UpdateItem(item)}
                            >
                              <PencilIcon className="w-5 h-5" />
                            </button>
                            <button
                              className={`text-gray-600 focus:outline-none`}
                              onClick={() => {
                                setDeleteVisibility(true);
                                setItem(item);
                                setIdIndicateur(item.id);
                                setName(item.name);
                              }}
                            >
                              <TrashIcon className="w-5 h-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </>
      </main>
    </div>
  );
}
