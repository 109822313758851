import host from "./host";

export const ACTION_ADD_INDICATORS = "ACTION_ADD_INDICATORS";
export const ACTION_IS_ADD_INDICATORS = "ACTION_IS_ADD_INDICATORS";
export const ACTION_IS_DELETE_INDICATORS = "ACTION_IS_DELETE_INDICATORS";
export const ACTION_IS_GET_INDICATORS = "ACTION_IS_GET_INDICATORS";
export const ACTION_IS_UPDATE_INDICATORS = "ACTION_IS_UPDATE_INDICATORS";
export const ACTION_UPDATE_INDICATORS = "ACTION_UPDATE_INDICATORS";
export const ACTION_SET_ALL_INDICATORS = "ACTION_SET_ALL_INDICATORS";
export const ACTION_REMOVE_INDICATORS = "ACTION_REMOVE_INDICATORS";

export const actionAddIndicators = (payload) => ({
  type: ACTION_ADD_INDICATORS,
  payload: payload,
});

export const actionRemoveIndicators = (payload) => ({
  type: ACTION_REMOVE_INDICATORS,
  payload: payload,
});

export const actionSetAllIndicators = (payload) => ({
  type: ACTION_SET_ALL_INDICATORS,
  payload: payload,
});

export const actionIsAddIndicators = (payload) => ({
  type: ACTION_IS_ADD_INDICATORS,
  payload: payload,
});

export const actionIsDeleteIndicators = (payload) => ({
  type: ACTION_IS_DELETE_INDICATORS,
  payload: payload,
});

export const actionIsUpdateIndicators = (payload) => ({
  type: ACTION_IS_UPDATE_INDICATORS,
  payload: payload,
});

export const actionIsGetIndicators = (payload) => ({
  type: ACTION_IS_GET_INDICATORS,
  payload: payload,
});

export const addIndicators = (payload) => ({
  type: ACTION_ADD_INDICATORS,
  payload: payload,
});

export const actionSetIndicators = (payload) => ({
  type: ACTION_UPDATE_INDICATORS,
  payload: payload,
});

export const actionCreateIndicators = (
  name,
  isExtension,
  isPart,
  isVisible,
  idmetric,
  iddatasource,
  operation,
  formule,
  color,
  symbole,
  filtersColumns,
  indicateurConditions,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsAddIndicators(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        name,
        isExtension,
        isPart,
        isVisible,
        idmetric,
        iddatasource,
        operation,
        formule,
        color,
        symbole,
        filtersColumns,
        indicateurConditions,
      }),
      redirect: "follow",
    };

    fetch(`${host}/indicateur/create`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(
            actionGetIndicatorsByDataSource(iddatasource, token, () => {})
          );
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch((error) => {
        callback(false);
      })
      .then(() => {
        dispatch(actionIsAddIndicators(false));
      });
  };
};

export const actionUpdateIndicators = (
  id,
  name,
  isExtension,
  isPart,
  isVisible,
  idmetric,
  iddatasource,
  operation,
  formule,
  color,
  symbole,
  filtersColumns,
  indicateurConditions,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsUpdateIndicators(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      isExtension,
      isPart,
      isVisible,
      idmetric,
      iddatasource,
      operation,
      formule,
      color,
      symbole,

      filtersColumns,
      indicateurConditions,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/indicateur/update/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(
            actionGetIndicatorsByDataSource(iddatasource, token, () => {})
          );
          callback(true);
        }
      })
      .catch(() => {})
      .then(() => {
        dispatch(actionIsUpdateIndicators(false));
      });
  };
};

export const actionGetIndicatorsByDataSource = (
  iddatasource,
  token,
  callback
) => {
  return (dispatch) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${host}/indicateur/get-by-datasource/${iddatasource}`,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          // console.log(result);
          dispatch(actionSetAllIndicators(result));
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch(() => {
        callback(false);
      });
  };
};

export const actionDeleteIndicators = (id, iddatasource, token, callback) => {
  return (dispatch) => {
    // dispatch(actionDeleteIndicators(true));
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/indicateur/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(
            actionGetIndicatorsByDataSource(iddatasource, token, () => {})
          );
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch(() => {
        callback(false);
      });
  };
};
