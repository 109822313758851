import React, { useMemo } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const CustomMarker = ({ position, values, country }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const iconSize = 40;
  const maxHeight = 80;

  const barWidth = Math.max(15, Math.min(25, 100 / values.length));

  const customIcon = L.divIcon({
    className: "custom-div-icon",
    html: `
    <div style="
      position: relative;
      width: ${iconSize}px;
      height: ${maxHeight}px;
    ">
      ${values
        .filter((item) => item.isVisible)
        .sort((a, b) => parseFloat(b.value) - parseFloat(a.value)) // Tri pour que le plus grand soit en bas
        .map((val, index) => {
          const diameter = Math.max(
            10,
            (parseFloat(val.value) /
              Math.max(...values.map((v) => parseFloat(v.value)))) *
              (maxHeight - 10) *
              0.8 // Réduction de taille pour un meilleur rendu
          );

          return `
          <div style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: ${diameter}px;
            height: ${diameter}px;
            background: radial-gradient(circle, ${val.color} 0%, rgba(255,255,255,0.2) 100%);
            border-radius: 50%;
            opacity: 1; /* Légère transparence */  
            z-index: ${index};
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease, background-color 0.3s ease;
          "></div>
        `;
        })
        .join("")}
    </div>
  `,
    iconSize: [iconSize, maxHeight],
    iconAnchor: [iconSize / 2, maxHeight],
    popupAnchor: [0, -maxHeight],
  });

  return (
    <Marker
      position={position}
      icon={customIcon}
      eventHandlers={{
        click: () => {
          setIsOpen(true);
        },
      }}
    >
      {isOpen && (
        <Popup position={position} onClose={() => setIsOpen(false)}>
          <div
            style={{
              fontFamily: "Arial, sans-serif",
              padding: "12px",
              maxWidth: "250px",
              minWidth: "200px",
            }}
          >
            <h3
              style={{
                marginBottom: "12px",
                borderBottom: "2px solid #eee",
                paddingBottom: "8px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Détails des indicateurs
            </h3>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {values.map((val, index) => {
                console.log("val", val);
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 0",
                    }}
                  >
                    <div
                      style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor: val.color,
                        marginRight: "8px",
                        borderRadius: "3px",
                        flexShrink: 0,
                      }}
                    ></div>
                    <div style={{ flex: 1 }}>
                      <div style={{ fontSize: "14px", color: "#666" }}>
                        {val.name}
                      </div>
                      <div style={{ fontSize: "15px", fontWeight: "500" }}>
                        {val.value}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Popup>
      )}
      <Tooltip direction="top" offset={[0, -40]} opacity={1}>
        <div
          style={{
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
            background: "rgba(255, 255, 255, 0.85)" /* Fond semi-transparent */,
            padding: "12px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" /* Ombre plus marquée */,
            color: "#1f2937",
            backdropFilter: "blur(4px)" /* Effet de flou */,
          }}
        >
          {country && (
            <span
              style={{
                fontSize: "16px" /* Texte plus grand pour le pays */,
                fontWeight: "bold",
                color: "#111827",
                marginBottom: "8px",
                display: "block",
                textAlign: "center",
              }}
            >
              {country}
            </span>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px" /* Espacement entre les éléments */,
            }}
          >
            {values
              .filter((item) => item.isVisible)
              .map((val, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: val.color || "#4b5563",
                        fontWeight: "600",
                        fontSize: "14px" /* Texte légèrement plus grand */,
                      }}
                    >
                      {val.name}
                    </span>
                    <span
                      style={{
                        background: val.color || "#d1d5db",
                        color: "#fff",
                        borderRadius: "12px" /* Coins plus arrondis */,
                        padding: "4px 10px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        textShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      {val.value}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </Tooltip>
    </Marker>
  );
};

const MultiIndicatorMap = ({ data = [] }) => {
  // Validation de l'objet data
  const removeDataNull = data.filter((item) => {
    if (!Array.isArray(item) || item.length < 2) return false;

    // Vérification que l'élément 0 contient une "value" valide (en JSON)
    const value = item[0]?.value;
    if (!value || typeof value !== "string") return false;

    try {
      // Tentative de conversion de la valeur en JSON
      const parsedValue = JSON.parse(value);

      // Vérification que lat et lng existent et sont des nombres valides
      const { lat, lng } = parsedValue;
      if (typeof lat !== "number" || typeof lng !== "number") {
        return false;
      }

      return true; // L'objet est valide
    } catch (error) {
      return false; // JSON invalide
    }
  });

  const isValidData =
    Array.isArray(removeDataNull) &&
    removeDataNull.every((item) => {
      // Vérification que chaque élément est un tableau et a au moins 2 éléments
      if (!Array.isArray(item) || item.length < 2) return false;

      // Vérification que l'élément 0 contient une "value" valide (en JSON)
      const value = item[0]?.value;
      if (!value || typeof value !== "string") return false;

      try {
        // Tentative de conversion de la valeur en JSON pour extraire lat et lng
        const { lat, lng } = JSON.parse(value);

        const isLatValid = typeof lat === "number" && lat >= -90 && lat <= 90;
        const isLngValid = typeof lng === "number" && lng >= -180 && lng <= 180;

        // Retourner true si au moins une donnée contient des coordonnées valides
        return isLatValid && isLngValid;
      } catch (e) {
        // En cas d'erreur dans le parsing JSON (coordonnées invalides), on retourne false
        return false;
      }
    });

  // Assurez-vous que les hooks sont appelés en haut
  const groupedData = useMemo(() => {
    if (!isValidData) return {};

    const grouped = {};
    removeDataNull.forEach((item) => {
      const [locationData, ...valueData] = item;

      // Tentative de parsing des coordonnées lat/lng
      let location;
      try {
        location = JSON.parse(locationData.value);
      } catch (e) {
        return; // Ignore les éléments avec des coordonnées invalides
      }

      const { lat, lng } = location;

      // Validation des coordonnées lat et lng
      const isLatValid = typeof lat === "number" && lat >= -90 && lat <= 90;
      const isLngValid = typeof lng === "number" && lng >= -180 && lng <= 180;

      // Si les coordonnées sont invalides, on passe à l'élément suivant
      if (!isLatValid || !isLngValid) {
        return;
      }

      const position = `${lat},${lng},${location.address}`;
      if (!grouped[position]) {
        grouped[position] = [];
      }

      // Ajout des valeurs associées à cette position
      grouped[position] = valueData.map((val, index) => ({
        name: val.name,
        value: val.value,
        isVisible: val.isVisible,
        color: val.style.color
          ? val.style.color
          : val.color || `hsl(${index * (360 / valueData.length)}, 70%, 50%)`,
      }));
    });

    return grouped;
  }, [removeDataNull, isValidData]);

  const center = useMemo(() => {
    const allPositions = Object.keys(groupedData).map((key) => {
      const [lat, lng] = key.split(",").map(Number);
      return [lat, lng];
    });
    if (allPositions.length === 0) return [0, 0];
    const latSum = allPositions.reduce((sum, pos) => sum + pos[0], 0);
    const lngSum = allPositions.reduce((sum, pos) => sum + pos[1], 0);
    return [latSum / allPositions.length, lngSum / allPositions.length];
  }, [groupedData]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MapContainer
        center={center}
        zoom={5}
        style={{ height: "100%", width: "100%" }}
        zoomControl={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {Object.keys(groupedData).map((key, index) => {
          const values = groupedData[key];
          const [lat, lng, ...address] = key.split(",");
          return (
            <CustomMarker
              key={index}
              position={[parseFloat(lat), parseFloat(lng)]}
              values={values}
              country={address.join(" ")}
            />
          );
        })}
      </MapContainer>
    </div>
  );
};

export default MultiIndicatorMap;
