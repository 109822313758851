import React, { useState, useEffect } from "react";
import { PlusCircle, Trash2, CheckCircle2, XCircle } from "lucide-react";
import Modal from "../Views/Modal";
import {
  actionCreateCondition,
  actionDeleteCondition,
  actionGetConditionByDdataSource,
  actionUpdateCondition,
} from "../../redux/actions/ConditionAction";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Input } from "antd";
import { Popover } from "@mui/material";

const getOperators = (fieldType) => {
  switch (fieldType) {
    case "string":
      return [
        { idOperator: "contient", label: "Contient" },
        { idOperator: "egal", label: "Est égal à" },
      ];
    case "number":
      return [
        { idOperator: 1, label: "Est égal à", code: "==" },
        { idOperator: 2, label: "Différent de", code: "!=" },
        { idOperator: 3, label: "Est supérieur à", code: ">" },
        { idOperator: 4, label: "Est inférieur à", code: "<" },
        { idOperator: 5, label: "Est supérieur ou égal à", code: ">=" },
        { idOperator: 6, label: "Est inférieur ou égal à", code: "<=" },
      ];
    case "boolean":
      return [
        { idOperator: "est_vrai", label: "Est vrai" },
        { idOperator: "est_faux", label: "Est faux" },
      ];
    case "date":
      return [
        { idOperator: "egal", label: "Est égal à" },
        { idOperator: "avant", label: "Est avant" },
        { idOperator: "apres", label: "Est après" },
      ];
    default:
      return [];
  }
};

const logicalOperators = [
  { id: "&&", label: "ET" },
  { id: "||", label: "OU" },
];

export default function Condition({
  isUpdate,
  visibility,
  setVisibility,
  columns,
  iddatasource,
  condition,
  setCondition,
  setIsUpdate,
}) {
  const { TextArea } = Input;
  const [root, setRoot] = useState({
    id: "root",
    type: "group",
    children: [],
    logicalOperator: "",
  });

  useEffect(() => {
    if (isUpdate) {
      setName(condition?.name);
      setRoot(condition?.root);
    }
  }, [condition]);

  const fields = [
    {
      type: "number",
      label: "indicateur_value",
      id: 0,
    },
    ...columns.filter((f) => f.type === "number"),
  ];

  const token = useSelector((state) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // console.log({ root });
  const [isValid, setIsValid] = useState(false);
  const [name, setName] = useState("");
  // const [expression, setExpression] = useState("");
  const [loading, setLoading] = useState(false);

  const validateConditions = (group) => {
    if (!group.children || group.children.length === 0) {
      return false;
    }

    return group.children.every((child) => {
      if (child.type === "group") {
        return validateConditions(child);
      } else {
        const idColumn = fields.find((f) => +f.id === +child.idColumn);

        if (!idColumn) return false;

        switch (idColumn.type) {
          case "number":
            return child.value !== "" && !isNaN(child.value);
          case "boolean":
            return true;
          case "date":
            return child.value !== "" && !isNaN(Date.parse(child.value));
          default:
            return child.value !== "";
        }
      }
    });
  };

  useEffect(() => {
    const expression = generateFormula2(root);
    setIsValid(validateConditions(root));
  }, [root]);

  const addCondition = (parentId) => {
    const newCondition = {
      id: Date.now().toString(),
      type: "condition",
      idColumn: fields[0].id,
      idOperator: getOperators(fields[0].type)[0].idOperator,
      value: "",
      logicalOperator: "",
    };
    setRoot(addConditionToGroup(root, parentId, newCondition));
  };

  const addGroup = (parentId) => {
    const newGroup = {
      id: Date.now().toString(),
      type: "group",
      logicalOperator: "",
      children: [],
    };
    setRoot(addConditionToGroup(root, parentId, newGroup));
  };

  const addConditionToGroup = (group, targetId, newItem) => {
    if (group.id === targetId) {
      const updatedChildren = [...group.children];
      updatedChildren.push(newItem);

      const finalChildren = updatedChildren.map((child, index) => ({
        ...child,
        logicalOperator: index === 0 ? "" : child.logicalOperator || "&&",
      }));

      return { ...group, children: finalChildren };
    }

    return {
      ...group,
      children: group.children.map((child) =>
        child.type === "group"
          ? addConditionToGroup(child, targetId, newItem)
          : child
      ),
    };
  };

  const updateCondition = (conditionId, idColumn, value) => {
    setRoot(updateConditionInGroup(root, conditionId, idColumn, value));
  };

  const updateConditionInGroup = (group, conditionId, idColumn, value) => {
    return {
      ...group,
      children: group.children.map((child) =>
        child.type === "group"
          ? updateConditionInGroup(child, conditionId, idColumn, value)
          : child.id === conditionId
          ? { ...child, [idColumn]: value }
          : child
      ),
    };
  };

  const updateGroupOperator = (groupId, operator) => {
    setRoot(updateGroupOperatorInGroup(root, groupId, operator));
  };

  const updateGroupOperatorInGroup = (group, groupId, operator) => {
    if (group.id === groupId) {
      return { ...group, logicalOperator: operator };
    }
    return {
      ...group,
      children: group.children.map((child) =>
        child.type === "group"
          ? updateGroupOperatorInGroup(child, groupId, operator)
          : child
      ),
    };
  };

  const updateConditionOperator = (conditionId, idOperator) => {
    setRoot(updateConditionOperatorInGroup(root, conditionId, idOperator));
  };

  const updateConditionOperatorInGroup = (group, conditionId, operator) => {
    return {
      ...group,
      children: group.children.map((child) =>
        child.type === "group"
          ? updateConditionOperatorInGroup(child, conditionId, operator)
          : child.id === conditionId
          ? { ...child, logicalOperator: operator }
          : child
      ),
    };
  };

  const deleteConditionOrGroup = (itemId) => {
    setRoot(deleteItemFromGroup(root, itemId));
  };

  const deleteItemFromGroup = (group, itemId) => {
    const filteredChildren = group.children.filter(
      (child) => child.id !== itemId
    );

    const updatedChildren = filteredChildren.map((child, index) => ({
      ...child,
      logicalOperator: index === 0 ? "" : child.logicalOperator || "&&",
    }));

    return {
      ...group,
      children: updatedChildren.map((child) =>
        child.type === "group" ? deleteItemFromGroup(child, itemId) : child
      ),
    };
  };

  const generateFormula = (group, isRoot = false) => {
    if (!group.children || group.children.length === 0) return "";

    const formula = group.children.map((child, index) => {
      let elementFormula = "";
      if (child.type === "group") {
        elementFormula = generateFormula(child);
        if (child.children.length > 1) {
          elementFormula = `<span class="text-purple-600">(</span>${elementFormula}<span class="text-purple-600">)</span>`;
        }
      } else {
        const idColumn = fields.find((f) => f.id == child.idColumn);
        const operatorLabel = getOperators(idColumn.type).find(
          (op) => op.idOperator == child.idOperator
        )?.code;

        let fieldColor;
        switch (idColumn.type) {
          case "string":
            fieldColor = "text-blue-600";
            break;
          case "number":
            fieldColor = "text-green-600";
            break;
          case "date":
            fieldColor = "text-orange-600";
            break;
          case "boolean":
            fieldColor = "text-red-600";
            break;
          default:
            fieldColor = "text-gray-600";
        }

        const isValid = validateFieldValue(idColumn.type, child.value);
        const valueClass = isValid ? "font-bold" : "font-bold text-red-500";

        elementFormula = `<span class="${fieldColor}">${idColumn.label}</span> <span class="text-gray-500">${operatorLabel}</span> <span class="${valueClass}">${child.value}</span>`;
      }

      return index === 0
        ? elementFormula
        : `<span class="text-pink-600 font-bold">${child.logicalOperator}</span> ${elementFormula}`;
    });

    return formula.join(" ");
  };

  const validateFieldValue = (type, value) => {
    switch (type) {
      case "number":
        return value !== "" && !isNaN(value);
      case "date":
        return value !== "" && !isNaN(Date.parse(value));
      case "boolean":
        return true;
      default:
        return value !== "";
    }
  };

  const renderCondition = (condition, index) => {
    const idColumn = fields.find((f) => f.id == condition.idColumn);

    const isValueValid = validateFieldValue("number", 0);

    return (
      <div key={condition.id} className="flex items-center gap-2 mb-2">
        {index > 0 && (
          <select
            value={condition.logicalOperator}
            onChange={(e) =>
              updateConditionOperator(condition.id, e.target.value)
            }
            className="p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
          >
            {logicalOperators.map((op) => (
              <option key={op.id} value={op.id}>
                {op.label}
              </option>
            ))}
          </select>
        )}
        <select
          value={condition.idColumn}
          onChange={(e) =>
            updateCondition(condition.id, "idColumn", e.target.value)
          }
          className="p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
        >
          {fields.map((idColumn) => (
            <option key={idColumn.id} value={idColumn.id}>
              {idColumn.label}
            </option>
          ))}
        </select>
        <select
          value={condition.idOperator}
          onChange={(e) =>
            updateCondition(condition.id, "idOperator", e.target.value)
          }
          className="p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
        >
          {getOperators(
            fields.find((f) => f.id == condition.idColumn)?.type
          ).map((op) => (
            <option key={op.idOperator} value={op.idOperator}>
              {op.label}
            </option>
          ))}
        </select>
        {idColumn.type === "boolean" ? (
          <select
            value={condition.value}
            onChange={(e) =>
              updateCondition(condition.id, "value", e.target.value)
            }
            className="p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
          >
            <option value="true">Vrai</option>
            <option value="false">Faux</option>
          </select>
        ) : (
          <>
            <input
              type={
                idColumn.type === "date"
                  ? "date"
                  : idColumn.type === "number"
                  ? "number"
                  : "text"
              }
              value={condition.value}
              onChange={(e) =>
                updateCondition(condition.id, "value", e.target.value)
              }
              className={`p-2 text-sm border w-[100%] rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 ${
                isValueValid ? "border-gray-300" : "border-red-500"
              }`}
            />
          </>
        )}
        <button
          onClick={() => deleteConditionOrGroup(condition.id)}
          className="p-1 text-red-500 hover:text-red-600"
        >
          <Trash2 className="w-4 h-4" />
        </button>
      </div>
    );
  };

  const renderConditionGroup = (group, depth = 0) => {
    return (
      <div
        key={group.id}
        className={`ml-4 ${
          depth > 0 ? "border-l-2 border-gray-200" : ""
        } pl-4 my-4`}
      >
        <div className="flex items-center gap-2 mb-2">
          {depth > 0 && group.logicalOperator !== "" && (
            <select
              value={group.logicalOperator}
              onChange={(e) => updateGroupOperator(group.id, e.target.value)}
              className="p-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            >
              {logicalOperators.map((op) => (
                <option key={op.id} value={op.id}>
                  {op.label}
                </option>
              ))}
            </select>
          )}
          <button
            onClick={() => addCondition(group.id)}
            className="flex items-center px-3 py-1 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            <PlusCircle className="w-4 h-4 mr-1" /> Condition
          </button>
          <button
            onClick={() => addGroup(group.id)}
            className="flex items-center px-3 py-1 text-sm text-white bg-green-500 rounded-md hover:bg-green-600"
          >
            <PlusCircle className="w-4 h-4 mr-1" /> Groupe
          </button>
          {depth > 0 && (
            <button
              onClick={() => deleteConditionOrGroup(group.id)}
              className="p-1 text-red-500 hover:text-red-600"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          )}
        </div>
        {group.children.map((child, index) =>
          child.type === "group"
            ? renderConditionGroup(child, depth + 1)
            : renderCondition(child, index)
        )}
      </div>
    );
  };

  const cancel = () => {
    setName("");
    setRoot({
      id: "root",
      type: "group",
      children: [],
      logicalOperator: "",
    });
    setVisibility(false);

    // setIsValid(false);
    // setCondition(null);
    setIsUpdate(false);
  };
  const generateFormula2 = (group) => {
    // Si le groupe n'a pas d'enfants, retourner une chaîne vide
    if (!group.children || group.children.length === 0) return "";

    const formula = group.children.map((child, index) => {
      let elementFormula = "";

      if (child.type === "group") {
        // Si l'enfant est un groupe, appel récursif pour générer sa formule
        elementFormula = generateFormula2(child);
        // Si le groupe a plus d'un enfant, entourer la formule avec des parenthèses
        if (child.children.length > 1) {
          elementFormula = `(${elementFormula})`;
        }
      } else {
        // Générer la formule pour une condition (champ, opérateur, valeur)
        const idColumn = fields.find((f) => f.id == child.idColumn);
        const operatorLabel = getOperators(idColumn.type).find(
          (op) => op.idOperator == child.idOperator
        )?.code;

        // Générer la partie de la formule pour cette condition
        elementFormula = `${idColumn.label} ${operatorLabel} ${child.value}`;
      }

      // Ajouter l'opérateur logique entre les éléments (&&/OR)
      return index === 0
        ? elementFormula
        : `${child.logicalOperator} ${elementFormula}`;
    });

    // Joindre les éléments de la formule avec un espace
    return formula.join(" ");
  };

  // console.log(root);
  const submit = (e) => {
    e.preventDefault();
    const expression = generateFormula2(root);
    if (isUpdate) {
      update(name, root, expression);
    } else {
      add(name, root, expression);
    }
  };

  const add = (name, root, expression) => {
    dispatch(
      actionCreateCondition(
        name,
        root,
        iddatasource,
        expression,
        true,
        token,
        (status) => {
          if (status) {
            enqueueSnackbar("Insertion réalisée avec succès", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar("Erreur lors de la création", {
              variant: "error",
            });
          }
        }
      )
    );
  };
  const update = (name, root, expression) => {
    dispatch(
      actionUpdateCondition(
        condition?.id,
        name,
        root,
        iddatasource,
        expression,
        true,
        token,
        (status) => {
          if (status) {
            enqueueSnackbar("Insertion réalisé avec succès", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar("Erreur lors de la création", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  return (
    <Modal
      visible={visibility}
      title={isUpdate ? "Modifier une condition" : "Ajouter une condition"}
      onCancel={cancel}
      large={true}
      width={1000}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="flex flex-col w-full h-full gap-4 "
        method="POST"
      >
        <div className="flex flex-col gap-2">
          <label for="nom" className="font-poppins font-[400px]">
            Entrer le nom
          </label>

          <input
            className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor"
            id="nom"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Obligatoire"
          />
        </div>
        <div className="p-2">
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-bold">Conditions</h3>
              <div className="flex items-center gap-2">
                {isValid ? (
                  <div className="flex items-center text-green-600">
                    <CheckCircle2 className="w-5 h-5 mr-1" />
                    <span>Formule valide</span>
                  </div>
                ) : (
                  <div className="flex items-center text-red-600">
                    <XCircle className="w-5 h-5 mr-1" />
                    <span>Formule invalide</span>
                  </div>
                )}
              </div>
            </div>
            {renderConditionGroup(root)}
            <div className="mt-4">
              <h3 className="mb-2 font-bold">Formule complète</h3>
              <div
                className="p-4 font-mono text-lg bg-gray-100 rounded"
                dangerouslySetInnerHTML={{
                  __html: generateFormula(root, true),
                }}
              />
              <div className="mt-2 text-sm">
                <p className="text-gray-600">Légende :</p>
                <div className="grid grid-cols-3 gap-2 mt-1">
                  {/* <div className="flex items-center">
                    <span className="w-3 h-3 mr-2 bg-blue-600 rounded"></span>
                    <span>Texte</span>
                  </div> */}
                  <div className="flex items-center">
                    <span className="w-3 h-3 mr-2 bg-green-600 rounded"></span>
                    <span>Nombre</span>
                  </div>
                  {/* <div className="flex items-center">
                    <span className="w-3 h-3 mr-2 bg-orange-600 rounded"></span>
                    <span>Date</span>
                  </div>
                  <div className="flex items-center">
                    <span className="w-3 h-3 mr-2 bg-red-600 rounded"></span>
                    <span>Booléen</span>
                  </div> */}
                  <div className="flex items-center">
                    <span className="w-3 h-3 mr-2 bg-pink-600 rounded"></span>
                    <span>Opérateurs logiques</span>
                  </div>
                  <div className="flex items-center">
                    <span className="w-3 h-3 mr-2 bg-purple-600 rounded"></span>
                    <span>Parenthèses</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-4 mt-">
          <button
            className="btn-border close-btn-submit"
            type="reset"
            onClick={cancel}
          >
            Annuler
          </button>
          <button
            className="btn-full"
            type="submit"
            disabled={loading}
            onClick={submit}
          >
            {loading
              ? isUpdate
                ? "Modifier..."
                : "Ajouter..."
              : isUpdate
              ? "Modifier"
              : "+ Ajouter"}
          </button>
        </div>
      </form>
    </Modal>
  );
}
