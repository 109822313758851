"use client";

import { useState, useEffect } from "react";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import Header from "../Header";
import { Search } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../Views/Modal";
import {
  actionGetWorkspaceByCompany,
  actionCreateWorkspace,
} from "../../redux/actions/workspaceAction";
import { useSnackbar } from "notistack";
import WorkspaceCard from "./CardWorkSpace";
export default function WorkSpaces() {
  const defaultWorkspaces =
    useSelector((state) => state.workspaces.workspaces) || [];
  const isAdd = useSelector((state) => state.workspaces.isAddWorkspace);
  const isUpdate = useSelector((state) => state.workspaces.isUpdateWorkspace);
  const isDelete = useSelector((state) => state.workspaces.isDeleteWorkspace);
  const isGet = useSelector((state) => state.workspaces.isGetWorkspace);
  const token = useSelector((state) => state.user.token);
  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );
  const roleCompany = useSelector((state) => state.user.role);
  const isAdmin = ["ADMIN", "OWNER"].includes(roleCompany);
  const WORKSPACES_PER_PAGE = 20;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [name, setName] = useState("");
  const [id, setId] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const [updateVisibility, setUpdateVisibility] = useState(false);

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data) {
      navigate("/");
    } else {
      dispatch(
        actionGetWorkspaceByCompany(
          "Bearer " + JSON.parse(data).token,
          (result) => {
            if (result) {
              // console.log(result);
            }
          }
        )
      );
    }
  }, []);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const filteredWorkspaces = defaultWorkspaces.filter((workspace) =>
    workspace?.name?.toLowerCase().includes(searchQuery?.toLowerCase() || "")
  );
  const navigate = useNavigate();
  const totalPages = Math.ceil(filteredWorkspaces.length / WORKSPACES_PER_PAGE);
  const startIndex = (currentPage - 1) * WORKSPACES_PER_PAGE;
  const displayedWorkspaces = filteredWorkspaces.slice(
    startIndex,
    startIndex + WORKSPACES_PER_PAGE
  );

  const addWorkspace = (e) => {
    e.preventDefault();
    if (name.trim() !== "") {
      dispatch(
        actionCreateWorkspace(name.trim(), token, ({ status, message }) => {
          if (status) {
            setVisibility(false);
            enqueueSnackbar(message || "", {
              variant: "success",
            });
            setName("");
          } else {
            enqueueSnackbar(message || "", {
              variant: "warning",
            });
          }
        })
      );
    }
  };

  return (
    <div className="h-[100vh]">
      <Modal
        visible={visibility}
        title={"Ajouter un Espace de travail"}
        onCancel={
          isAdd
            ? null
            : () => {
                setVisibility(false);
                setName("");
              }
        }
        width={500}
      >
        <div className="flex flex-col w-full h-full gap-1 p-0 m-0">
          <form onSubmit={addWorkspace}>
            <div className="flex flex-col w-full h-full gap-1 p-0 m-0">
              <div className="flex flex-col gap-2">
                <label for="nom" className="font-poppins font-[400px]">
                  Entrer le nom *
                </label>

                <input
                  className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor hover:outline-none active:outline-none"
                  name="nom"
                  id="nom"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Obligatoire"
                  required
                />
              </div>

              <div className="flex items-center justify-center gap-4 mt-2">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isAdd ? null : () => setVisibility(false)}
                >
                  Annuler
                </button>
                <button className="btn-full" type="submit" disabled={isAdd}>
                  {isUpdate ? "Ajouter..." : "+ Ajouter"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Header />
      <main className="homemain mt-[60px] w-[98%] mx-auto ">
        <div className="overflow-hidden bg-white w-full  h-[70px] row actions-nav m-3 mx-auto border-r-black rounded-[5px] flex-col items-center gap-2">
          <div className="flex items-center justify-between w-full gap-2 px-6 font-poppins ">
            <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center">
              <p className="font-semibold text-center text-[18px]  mt-[20px]">
                Espaces de travail
              </p>
              <div className="relative flex items-center m-">
                <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                <input
                  type="text"
                  placeholder="Recherche"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                />
              </div>
            </div>
            {isAdmin && (
              <p
                className="hover:text-white m-0 p-0  inline-flex items-center justify-center px-4  hover:bg-colorText border-2 border-colorText text-colorText  rounded-[8px] text-center font-poppins py-1 w-[250px] font-semibold cursor-pointer text-[13px] "
                onClick={() => setVisibility(true)}
              >
                + Nouveau espace de travail
              </p>
            )}
          </div>
        </div>
        <div className="w-full mx-auto pb-8 h-[81vh] rounded-xl overflow-y-auto flex flex-col px-2 ">
          <div className="flex flex-wrap gap-3">
            {/* <div className="grid gap-3 sm:grid-cols-2 p-1 lg:grid-cols-3 xl:grid-cols-5 ml-[4px]"> */}
            {displayedWorkspaces.map((workspace) => {
              const isSelected = +workspace.id === +selectedWorkspace;

              // const periodes = workspace.periodes;
              // const datasources = workspace.datasources;
              return (
                <WorkspaceCard
                  key={workspace.id}
                  workspace={workspace}
                  isSelected={isSelected}
                  isAdmin={isAdmin || false}
                />
              );
            })}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="">
              <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8">
                <div className="p-4 ">
                  <div className="flex items-center justify-center gap-2">
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                      className="p-2 transition-colors rounded-md outline-none hover:bg-gray-100 disabled:opacity-50"
                    >
                      <svg
                        className="w-5 h-5 text-gray-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>

                    {[...Array(totalPages)].map((_, index) => {
                      const pageNumber = index + 1;

                      if (
                        pageNumber === 1 ||
                        pageNumber === totalPages ||
                        (pageNumber >= currentPage - 1 &&
                          pageNumber <= currentPage + 1)
                      ) {
                        return (
                          <button
                            key={pageNumber}
                            onClick={() => setCurrentPage(pageNumber)}
                            className={`flex h-8 w-8 items-center justify-center outline-none rounded-[4px] transition-colors active:outline-none focus:outline-none ${
                              currentPage === pageNumber
                                ? "bg-colorText text-white"
                                : "text-gray-600 hover:bg-gray-100"
                            }`}
                          >
                            {pageNumber}
                          </button>
                        );
                      }

                      if (pageNumber === 2 || pageNumber === totalPages - 1) {
                        return (
                          <span key={pageNumber} className="px-2">
                            ...
                          </span>
                        );
                      }

                      return null;
                    })}

                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                      className="p-2 transition-colors rounded-md hover:bg-gray-100 disabled:opacity-50"
                    >
                      <svg
                        className="w-5 h-5 text-gray-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
