import ContentLoader from "react-content-loader";

const TableRowLoader = ({ col = 6 }) => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={50}
      viewBox="0 0 1000 50"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {/* Simule les colonnes de chaque ligne */}
      {/* { [...Array(col)].map((_, index)} */}
      <rect x="10" y="15" rx="4" ry="4" width="120" height="20" />
      <rect x="140" y="15" rx="4" ry="4" width="100" height="20" />
      <rect x="270" y="15" rx="4" ry="4" width="120" height="20" />
      <rect x="410" y="15" rx="4" ry="4" width="120" height="20" />
      <rect x="550" y="15" rx="4" ry="4" width="200" height="20" />
      <rect x="770" y="15" rx="4" ry="4" width="100" height="20" />
    </ContentLoader>
  );
};

export default TableRowLoader;
