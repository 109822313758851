import React from "react";

export default function useReceive(url, onResponse, onError) {
  const [data, setData] = React.useState(null);
  const [lastId, setLastId] = React.useState(null);

  const getOrigin = (url) => {
    const segments = url.split("/");
    const protocol = segments[0];
    const host = segments[2];
    return protocol + "//" + host;
  };

  React.useEffect(() => {
    const receiver = (e) => {
      if (url) {
        if (e.origin === getOrigin(url)) {
          console.log("is same origin");
          const response = JSON.parse(e.data);
          switch (response.status) {
            case 200:
              if (+lastId !== +response.id) {
                setLastId(response.id);
                setData(response.data);
                onResponse && onResponse(response.data);
              }
              break;

            default:
              console.log("error when you get data from iframe");
              onError && onError();
          }
        } else {
          console.log("is not same origin");
        }
      } else {
        console.log("Error url is not valid");
      }
    };

    window.addEventListener("message", receiver, false);
    return () => {
      window.removeEventListener("message", receiver, false);
    };
  }, [url, onError, onResponse]);

  return [data, setData];
}
