import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionConfirmOtp } from "../../redux/actions/UserAction";
import validator from "validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { useSnackbar } from "notistack";

const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function Otp() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setEmail(email);
      localStorage.removeItem("email");
    } else {
      navigate("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code) {
      dispatch(
        actionConfirmOtp(code, email, ({ message, status }) => {
          if (status) {
            enqueueSnackbar(message || "", {
              variant: "success",
            });
            navigate("/");
          } else {
            enqueueSnackbar(message || "", {
              variant: "warning",
            });
          }
        })
      );
    }
  };

  return (
    <div className="relative flex flex-col justify-center overflow-hidden bg-white/2">
      {/* Header */}
      <header className="z-10 flex items-center justify-end gap-3 p-4">
        <span className="font-semibold font-poppins">
          Vous avez déjà un compte ?
        </span>
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6875 3.9375C20.4135 3.9375 21 4.52402 21 5.25C21 5.97598 20.4135 6.5625 19.6875 6.5625H11.1562V3.9375H19.6875ZM13.125 11.8125C13.851 11.8125 14.4375 12.399 14.4375 13.125C14.4375 13.851 13.851 14.4375 13.125 14.4375H10.5C9.77402 14.4375 9.1875 13.851 9.1875 13.125C9.1875 12.399 9.77402 11.8125 10.5 11.8125H13.125ZM15.75 9.1875C15.75 9.91348 15.1635 10.5 14.4375 10.5H12.4688C11.7428 10.5 11.1562 9.91348 11.1562 9.1875C11.1562 8.46152 11.7428 7.875 12.4688 7.875H14.4375C15.1635 7.875 15.75 8.46152 15.75 9.1875ZM11.8125 15.75C12.5385 15.75 13.125 16.3365 13.125 17.0625C13.125 17.7885 12.5385 18.375 11.8125 18.375H9.1875C8.46152 18.375 7.875 17.7885 7.875 17.0625C7.875 16.3365 8.46152 15.75 9.1875 15.75H11.8125ZM8.20312 11.8125H8.22773C8.00625 12.198 7.875 12.6451 7.875 13.125C7.875 13.6664 8.03906 14.1668 8.31797 14.5852C7.29668 14.942 6.5625 15.9182 6.5625 17.0625C6.5625 17.5424 6.68965 17.9895 6.91523 18.375H6.5625C2.93672 18.375 0 15.4383 0 11.8125V9.28184C0 7.54277 0.693164 5.87344 1.92363 4.64297L2.39941 4.16719C3.38379 3.17871 4.7209 2.625 6.11133 2.625H7.21875C8.6666 2.625 9.84375 3.80215 9.84375 5.25V8.85938C9.84375 9.76582 9.10957 10.5 8.20312 10.5C7.29668 10.5 6.5625 9.76582 6.5625 8.85938V6.5625C6.5625 6.20156 6.26719 5.90625 5.90625 5.90625C5.54531 5.90625 5.25 6.20156 5.25 6.5625V8.85938C5.25 10.4918 6.5707 11.8125 8.20312 11.8125Z"
            fill="#04B6EE"
          />
        </svg>

        <Link
          to="/"
          className="px-4 py-2 font-semibold text-white rounded-md outline-none bg-colorText font-poppins hover:outline-none"
        >
          Se connecter
        </Link>
      </header>

      <div className="flex flex-col items-center justify-center min-h-screen gap-3 px-4 overflow-y-auto h-[900px]">
        <div className="mb-0">
          <img
            src="./update/logo.png"
            alt="Viabber"
            className="object-contain w-80 h-50 left-0 top-[15%]"
          />
        </div>
        <main className="z-10 flex flex-col items-start justify-start flex-grow font-poppins">
          <form
            action=""
            className="flex  flex-col px-5 py-4 rounded-[30px]  w-[540px]  border-3 border-black bg-white shadow-2xl font-poppins space-y-3 mt-5"
            onSubmit={handleSubmit}
          >
            <h1 className="flex-1 shrink gap-3 self-center w-full text-2xl font-bold  whitespace-nowrap max-w-[497px]  max-md:max-w-full font-poppins ">
              Confirmation otp
            </h1>
            <OtpInput
              value={code}
              onChange={setCode}
              numInputs={6}
              className="flex justify-between space-x-3"
              separator={<span style={{ width: "8px" }}></span>}
              renderInput={(props) => (
                <input {...props} className="border border-1" />
              )}
              inputStyle={{
                border: "1px solid transparent",
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Poppins",
                color: "#05BAF3",
                fontWeight: "400",
                caretColor: "#05BAF3",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
              }}
              isInputNum={true}
              shouldAutoFocus={true}
              containerStyle={{
                display: "flex",
                justifyContent: "space-between",
              }}
              focusStyle={{
                border: "1px solid #05BAF3",
                outline: "none",
              }}
            />
            <div className="flex justify-between space-x-3">
              <button
                type="submit"
                className={
                  "px-6 py-3 rounded-xl text-white bg-colorText  focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-200 w-full"
                }
              >
                confirmer
              </button>
            </div>
          </form>
        </main>
        <svg
          className="absolute bottom-[-20%] left-0 right-0 size-full 2xl:bottom-[-40%] "
          viewBox="0 0 1440 448"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="719.5"
            cy="535"
            rx="535"
            ry="978.5"
            transform="rotate(-90 719.5 535)"
            fill="url(#paint0_linear_715_2262)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_715_2262"
              x1="1254.23"
              y1="535.076"
              x2="184.958"
              y2="535.076"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.00549451" stop-color="#21263C" />
              <stop offset="0.475" stop-color="#4C557D" />
              <stop offset="0.93" stop-color="#586398" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}
