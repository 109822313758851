import host from "./host";
import { actionSetGoals } from "./GoalAction";
import { actionSetIndicatorsLocations } from "./listAction";
export const ACTION_SET_INDICATOR = "ACTION_SET_INDICATOR";
export const ACTION_SET_INDICATORS = "ACTION_SET_INDICATORS";
export const ACTION_SET_INDICATOR_DATA = "ACTION_SET_INDICATOR_DATA";
export const ACTION_IS_GET_INDICATOR = "ACTION_IS_GET_INDICATOR";
export const ACTION_IS_GENERATING_INDICATOR = "ACTION_IS_GENERATING_INDICATOR";
export const ACTION_IS_UPDATE_INDICATOR = "ACTION_IS_UPDATE_INDICATOR";
export const ACTION_IS_DELETE_INDICATOR = "ACTION_IS_DELETE_INDICATOR";
export const ACTION_IS_ADD_INDICATOR = "ACTION_IS_ADD_INDICATOR";
export const ACTION_IS_SEND_COMMENT = "ACTION_IS_SEND_COMMENT";
export const ACTION_IS_DELETE_COMMENT = "ACTION_IS_DELETE_COMMENT";
export const ACTION_SET_COMMENTS = "ACTION_SET_COMMENTS";
export const ACTION_ADD_COMMENT = "ACTION_ADD_COMMENT";
export const ACTION_REMOVE_COMMENT = "ACTION_REMOVE_COMMENT";

// * --------- Begin actions --------

export const actionRemoveComment = (payload) => {
  return { type: ACTION_REMOVE_COMMENT, payload };
};

export const actionAddComment = (payload) => {
  return { type: ACTION_ADD_COMMENT, payload };
};

export const actionSetComments = (payload) => {
  return { type: ACTION_SET_COMMENTS, payload };
};

export const actionIsSendComment = (payload) => {
  return { type: ACTION_IS_SEND_COMMENT, payload };
};

export const actionIsDeleteComment = (payload) => {
  return { type: ACTION_IS_DELETE_COMMENT, payload };
};

export const actionSetIndicatorData = (payload) => {
  return { type: ACTION_SET_INDICATOR_DATA, payload };
};

export const actionIsGetIndicator = (payload) => {
  return { type: ACTION_IS_GET_INDICATOR, payload };
};

export const actionIsUpdateIndicator = (payload) => {
  return { type: ACTION_IS_UPDATE_INDICATOR, payload };
};

export const actionIsDeleteIndicator = (payload) => {
  return { type: ACTION_IS_DELETE_INDICATOR, payload };
};

export const actionIsAddIndicator = (payload) => {
  return { type: ACTION_IS_ADD_INDICATOR, payload };
};

export const actionIsGeneratingIndicator = (payload) => {
  return { type: ACTION_IS_GENERATING_INDICATOR, payload };
};

export const actionSetIndicator = (payload) => {
  return { type: ACTION_SET_INDICATOR, payload };
};

export const actionSetIndicators = (payload) => {
  return { type: ACTION_SET_INDICATORS, payload };
};

// * --------- End actions --------

// * --------- Begin --------

export const actionSendComment = (
  email,
  iduser,
  idindicator,
  description,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsSendComment(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      email,
      iduser,
      idindicator,
      description,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/indicator/add-comment`, requestOptions)
      .then((response) => {
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionAddComment(result.new));
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionIsSendComment(false));
      });
  };
};

export const actionMoveMany = (data, idlist, type, token) => {
  return (dispatch) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      data,
      idlist,
      type,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/indicator/move-many`, requestOptions)
      .then((response) => {
        if (response.status != 200 && response.status != 201) {
          return null;
        } else {
          dispatch(actionSetIndicatorsLocations({ idlist, data, type }));
          return response.json();
        }
      })
      .then((result) => {})
      .catch(() => {});
  };
};

export const actionGenerateLink = (id, callback) => {
  return (dispatch) => {
    dispatch(actionIsGeneratingIndicator(true));

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      id,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/indicator/link`, requestOptions)
      .then((response) => {
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          callback(200, result.url);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionIsGeneratingIndicator(false));
      });
  };
};

export const actionGetIndicator = (id, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetIndicator(true));

    let myHeaders = new Headers();
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/indicator/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          sessionStorage.setItem("indicator", JSON.stringify(result));
          dispatch(actionSetIndicator(result));
          dispatch(actionSetGoals({ type: "ALL", data: result.goals }));
          dispatch(actionSetComments([...result.commentaires.reverse()]));
          callback(200, result);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionIsGetIndicator(false));
      });
  };
};

export const actionGetIndicatorByToken = (token, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetIndicator(true));

    let myHeaders = new Headers();
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/indicator/one/${token}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          sessionStorage.setItem("indicator", JSON.stringify(result));
          dispatch(actionSetIndicator(result));
          dispatch(actionSetGoals({ type: "ALL", data: result.goals }));
          dispatch(actionSetComments([...result.commentaires.reverse()]));
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionIsGetIndicator(false));
      });
  };
};

export const actionGetIndicators = (id, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetIndicator(true));

    let myHeaders = new Headers();
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/indicator/get-by-user/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetIndicators(result));
          callback(200);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {
        dispatch(actionIsGetIndicator(false));
      });
  };
};

export const actionGetOptions = (id, name, callback) => {
  return () => {
    let myHeaders = new Headers();
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/data/option/${id}/${name}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          callback(200, result);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {});
  };
};

export const actionGetDefaultData = (
  id,
  metric,
  time,
  debut,
  fin,
  callback
) => {
  return () => {
    let myHeaders = new Headers();
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${host}/data/default-mongodb/${id}/${metric}/${time}/${debut}/${fin}`,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          callback(200, result);
        } else {
          callback(400);
        }
      })
      .catch((error) => {
        console.log(error);
        callback(400);
      })
      .then(() => {});
  };
};

export const actionGetDataByFilter = (
  id,
  idindicateurs,
  idview,
  axe,
  filtres,
  callback
) => {
  return () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      id,
      idindicateurs,
      idview,
      axe,
      filtres,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/data/filter-mongodb-metric`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          callback(200, result);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {});
  };
};

export const actionGetTotalByFilter = (id, metric, axe, filtres, callback) => {
  return () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      id,
      metric,
      axe,
      filtres,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/data/total-filter-mongodb`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          callback(200, result);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => {});
  };
};

export const actionAddIndicator = (
  iduser,
  idlists,
  idindicateurs,
  idfilter,
  iddefault,
  idEmailColumn,
  idcolumns,
  filtersColumns,
  name,
  description,
  type,
  token,
  operation,
  symbole,
  taille,
  showcumul,
  notshowcumulwithgoal,
  showperiode,
  showlastvalue,
  showByEmail,
  periode,
  rules,
  callBack
) => {
  return (dispatch) => {
    dispatch(actionIsAddIndicator(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      iduser,
      idlists,
      idindicateurs,
      idfilter,
      iddefault,
      idEmailColumn,
      idcolumns,
      filtersColumns,
      name,
      description,
      type,
      operation,
      symbole,
      taille,
      showcumul,
      notshowcumulwithgoal,
      showperiode,
      showlastvalue,
      showByEmail,
      periode,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/indicator/create`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callBack(400);
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          callBack(200, result.new);
          // const idView = result.new.idindicator;
          // const viewConditions = rules.map((item) => ({
          //   idView,
          //   idCondition: item.condition.value,
          //   name: item.title,
          //   target: item.target.value,
          //   appearances: [
          //     {
          //       bold: item.isBold,
          //       color: item.color,
          //       italic: item.isItalic,
          //       size: item.fontSize,
          //       underline: item.isUnderline,
          //     },
          //   ],
          // }));
          // const url = `${host}/view-condition/create`;
          // (async function () {
          //   for (const viewCondition of viewConditions) {
          //     try {
          //       let myHeaders = new Headers();
          //       myHeaders.append("Authorization", token);
          //       myHeaders.append("Content-Type", "application/json");
          //       const response = await fetch(url, {
          //         method: "POST",
          //         headers: {
          //           "Content-Type": "application/json",
          //         },
          //         body: JSON.stringify(viewCondition),
          //       });

          //       if (response.ok) {
          //         const result = await response.json();
          //         console.log("Réponse:", result);
          //       } else {
          //         console.error("Erreur dans la réponse:", response.status);
          //       }
          //     } catch (error) {
          //       console.error("Erreur lors de l'appel API:", error);
          //     }
          //   }
          // })();
          // console.log(viewCondition);
        }
      })
      .catch((e) => {
        callBack(400);
      })
      .then(() => dispatch(actionIsAddIndicator(false)));
  };
};

export const actionDeleteIndicator = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsDeleteIndicator(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/indicator/delete/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        } else {
          callback(true);
        }
      })
      .catch((/* error */) => {
        /* console.log("error", error) */
      })
      .then(() => dispatch(actionIsDeleteIndicator(false)));
  };
};

export const actionUpdateIndicator = (
  id,
  idlists,
  idindicateurs,
  idfilter,
  iddefault,
  idEmailColumn,
  idcolumns,
  filtersColumns,
  name,
  description,
  type,
  token,
  operation,
  symbole,
  taille,
  showcumul,
  notshowcumulwithgoal,
  showperiode,
  showlastvalue,
  showByEmail,
  periode,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsUpdateIndicator(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      idindicateurs,
      idfilter,
      iddefault,
      idEmailColumn,
      idcolumns,
      filtersColumns,
      name,
      description,
      type,
      operation,
      symbole,
      taille,
      showcumul,
      notshowcumulwithgoal,
      showperiode,
      showlastvalue,
      showByEmail,
      periode,
      idlists,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/indicator/update/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          callback(false);
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          callback(true, result.update);
        }
      })
      .catch(() => {})
      .then(() => {
        dispatch(actionIsUpdateIndicator(false));
      });
  };
};
