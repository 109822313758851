import React from "react";

export default function Modal({
  title,
  children,
  visible,
  onCancel,
  large,
  width,
}) {
  return (
    <>
      {visible ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#00000060",
            zIndex: 100,
            position: "fixed",
            display: visible ? "flex" : "none",
            flexDirection: "row",
            justifyContent: "center",
            left: 0,
            top: 0,
            overflow: "auto",
            padding: "30px 0px 30px 0px",
            marginBottom: 0,
          }}
          className="parent-modal-closable"
          onClick={(e) => {
            e.stopPropagation();
            if (e.target.className === "parent-modal-closable") {
              if (onCancel) {
                onCancel();
              }
            }
          }}
        >
          <div
            className="modal-custom bg-default"
            style={{
              display: visible ? "block" : "none",
              width: large ? "80%" : "40%",
              width: width ? width : "",
              minWidth: "300px",
              height: "max-content",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <div className="flex flex-row justify-between font-bold font-poppins">
              <h3 className="title-3">{title}</h3>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={onCancel}
                className="cursor-pointer"
              >
                <path
                  d="M6.39984 19.1911L4.80859 17.5998L10.4086 11.9998L4.80859 6.39984L6.39984 4.80859L11.9998 10.4086L17.5998 4.80859L19.1911 6.39984L13.5911 11.9998L19.1911 17.5998L17.5998 19.1911L11.9998 13.5911L6.39984 19.1911Z"
                  fill="black"
                />
              </svg>
            </div>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
}
