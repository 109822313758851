import React from "react";
import Select from "react-select";

export default function CustomSelect({
  status,
  options,
  className,
  defaultValue,
  value,
  onChange,
  isMulti,
  isClearable,
  id,
  noSearchable,
  style,
  placeholder,
  required = false,
}) {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      "::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none", // IE and Edge
      "scrollbar-width": "none", // Firefox
    }),
    menuList: (provided) => ({
      ...provided,
      "::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none", // IE and Edge
      "scrollbar-width": "none", // Firefox
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      borderLeft: "none", // Remove the small bar next to the chevron
    }),
  };
  return (
    <>
      <Select
        closeMenuOnSelect={!isMulti}
        isDisabled={status}
        id={id}
        isClearable={isClearable}
        isMulti={isMulti}
        value={value}
        className=" "
        style={{ with: 400 }}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        placeholder={placeholder || ""}
        isSearchable={!noSearchable}
        required={required}
        styles={customStyles}
      />
    </>
  );
}
