import React, { useEffect, useState, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "../Views/Modal";
import { useSnackbar } from "notistack";
import {
  actionCreatePeriod,
  actionDeletePeriod,
  actionGetPeriodByUserH,
  actionUpdatePeriod,
} from "../../redux/actions/periodAction";
import CalendarView from "../Views/CalendarView";
import moment from "moment";
import { truncateText } from "../utils/truncateWords";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  RefreshCwIcon,
  PencilIcon,
  TrashIcon,
} from "lucide-react";
import { Search } from "lucide-react";
import TableRowLoader from "../Views/TableLoading";
import { actionGetWorkspaceByCompany } from "../../redux/actions/workspaceAction";
const _ = require("lodash");

export default function Periode({ workspace }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const periodes = useSelector((state) => state.period.periodes);
  const selectedWorkspace = workspace.id;

  const tokenRef = useRef();
  tokenRef.current = token;
  const [role, setRole] = useState("ADD");
  const [name, setName] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [value, setValue] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const isDeleting = useSelector((state) => state.period.isDeletePeriod);
  const isUpdating = useSelector((state) => state.period.isUpdatePeriod);
  const isAdding = useSelector((state) => state.period.isAddPeriod);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [periode, setPeriode] = useState(null);
  const periodeRef = useRef();
  periodeRef.current = periode;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const cancel = () => {
    setRole("ADD");
    setName("");
    setEnd(null);
    setStart(null);
    setPeriode(null);
    setVisibility(false);
    setDeleteVisibility(false);
  };

  const add = () => {
    dispatch(
      actionCreatePeriod(
        name,
        start,
        end,
        selectedWorkspace,
        token,
        (status) => {
          if (status) {
            enqueueSnackbar("Insertion réalisée avec succès", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar("Erreur lors de la création", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const edit = () => {
    dispatch(
      actionUpdatePeriod(
        periode.idperiod,
        name,
        start,
        end,
        selectedWorkspace,
        token,
        ({ status, message }) => {
          if (status) {
            enqueueSnackbar(message || "", {
              variant: "success",
            });
            cancel();
          } else {
            enqueueSnackbar(message || "", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const submit = (e) => {
    e.preventDefault();
    if (name && start && end) {
      if (role === "ADD") {
        add();
      } else if (role === "UPDATE" && periode) {
        edit();
      }
    } else {
      enqueueSnackbar("Tous les champs sont obligatoires", {
        variant: "warning",
      });
    }
  };

  const remove = (e) => {
    e.preventDefault();
    if (periode) {
      dispatch(
        actionDeletePeriod(
          periode?.idperiod,
          selectedWorkspace,
          token,
          ({ status, message }) => {
            if (status) {
              enqueueSnackbar(message || "", {
                variant: "success",
              });
              cancel();
            } else {
              enqueueSnackbar(message || "", {
                variant: "error",
              });
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data && !selectedWorkspace) {
      navigate("/");
    } else {
      setLoading(true);
      dispatch(
        actionGetWorkspaceByCompany(
          "Bearer " + JSON.parse(data).token,
          (result) => {
            if (result) {
              // console.log(result);
            }
          }
        )
      );
      if (selectedWorkspace)
        dispatch(
          actionGetPeriodByUserH(
            selectedWorkspace,
            "Bearer " + JSON.parse(data).token,
            (status) => {}
          )
        );
    }
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   if (periode && visibility && role === "UPDATE") {
  //     setName(periode.periodName);
  //     setStart(moment(new Date(periode.startdate)));
  //     setEnd(moment(new Date(periode.enddate)));
  //   }
  // }, [periode, visibility, role]);

  return (
    <div className="h-full ">
      <Modal
        visible={deleteVisibility && periode}
        title={`Supprimer la période "${periode?.periodName}"`}
        onCancel={isDeleting ? null : cancel}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isDeleting ? null : cancel}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDeleting}
                onClick={isDeleting ? null : remove}
              >
                {isDeleting ? "Supprimer..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibility}
        title={role === "ADD" ? "Ajouter période" : "Modifier période"}
        onCancel={isAdding || isUpdating ? null : cancel}
        width={600}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="input-container">
              <label for="nom" className="label-default">
                Libellé *
              </label>
              <span className="span-block">
                <input
                  className="input-default inputCustom"
                  name="nom"
                  id="nom"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Obligatoire"
                  required
                />
              </span>
            </div>
          </div>

          <div className="col-md-12">
            <div className="input-container">
              <label for="nom" className="label-default">
                Période *
              </label>
              <span className="span-block">
                <CalendarView
                  selectStyle={{ width: "40%" }}
                  calendarStyle={{ width: "55%", marginLeft: "10px" }}
                  value={value}
                  interval={[start, end]}
                  onChange={(interval, value) => {
                    setStart(interval[0]);
                    setEnd(interval[1]);
                    setValue(value);
                  }}
                />
              </span>
            </div>
          </div>
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={isAdding ? null : cancel}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isAdding || isUpdating}
                onClick={isAdding || isUpdating ? null : submit}
              >
                {isAdding || isUpdating ? "Enregistrer..." : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <main className="h-[98%]  w-[98%] mx-auto overflow-hidden">
        <>
          <div className="px-2 mb-3 w-full row actions-nav mx-auto border-r-black rounded-[20px]  items-center gap-2">
            <div className="flex items-center justify-between w-full gap-2 font-poppins ">
              <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center ml-1">
                <div className="relative flex items-center ">
                  <div className="relative flex items-center ">
                    <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                    <input
                      type="text"
                      placeholder="Rechercher"
                      className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <p
                  className="hover:text-white hover:bg-colorText border-2 border-colorText text-colorText  rounded-[5px] p-0 m-0 text-center font-poppins py-1 w-[180px] font-semibold cursor-pointer text-[12px]"
                  type="submit"
                  onClick={() => {
                    setVisibility(true);
                  }}
                >
                  +Ajouter une période
                </p>
              </div>
            </div>
          </div>

          <div className="w-full  bg-customBg h-[83vh] rounded-xl overflow-auto">
            <table
              id="sources"
              className="relative w-full overflow-auto bg-white border-collapse shadow-sm"
            >
              <thead className="mb-[600px] font-poppins leading-[50px] sticky ">
                <tr className="h-10 bg-white">
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                    <span>Libellé</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                    <span>Date de début</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                    <span>Date de Fin</span>
                  </th>
                  <th className="p-1 pl-2 font-semibold text-center text-black border-b text-[12px] rounded-xl">
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="text-[12px] mt-[900px] border-t-8 border-customBg h-full">
                {loading
                  ? [...Array(5)].map((_, index) => (
                      <tr
                        key={index}
                        className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold"
                      >
                        <td colSpan={6}>
                          <TableRowLoader />
                        </td>
                      </tr>
                    ))
                  : periodes.map((period) => {
                      return (
                        <tr
                          id={period.idperiod}
                          key={period.idperiod}
                          className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold my-3"
                        >
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {truncateText(period.periodName)}
                          </td>
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {period?.startdate?.split("T")[0]}
                          </td>
                          <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                            {period?.enddate?.split("T")[0]}
                          </td>
                          <td className="p-1 text-[12px]">
                            <div className="flex items-center justify-around gap-2">
                              <button
                                className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                onClick={() => {
                                  setRole("UPDATE");
                                  setPeriode(period);
                                  setVisibility(true);
                                  setName(period.periodName);
                                  setStart(moment(new Date(period.startdate)));
                                  setEnd(moment(new Date(period.enddate)));
                                }}
                              >
                                <PencilIcon className="w-5 h-5" />
                              </button>
                              <button
                                className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                onClick={() => {
                                  setDeleteVisibility(true);
                                  setPeriode(period);
                                }}
                              >
                                <TrashIcon className="w-5 h-5" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </>
      </main>
      {/* <Footer /> */}
    </div>
  );
}
