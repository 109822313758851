import {
  ACTION_ADD_PERIOD,
  ACTION_IS_ADD_PERIOD,
  ACTION_IS_UPDATE_PERIOD,
  ACTION_SET_ALL_PERIODE,
  ACTION_REMOVE_PERIODE,
  ACTION_IS_DELETE_PERIOD,
  ACTION_UPDATE_PERIOD,
} from "../actions/periodAction";

const initialState = {
  periodes: [],
  periods: [],
  isAddPeriod: false,
  isUpdatePeriod: false,
  isDeletePeriod: false,
};

const periodReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_IS_ADD_PERIOD: {
      return {
        ...state,
        isAddPeriod: action.payload,
      };
    }

    case ACTION_UPDATE_PERIOD: {
      const periodes = [...state.periodes];

      for (let i = 0; i < periodes.length; i++) {
        const item = periodes[i];
        if (+item.idperiod === +action.payload.idperiod) {
          periodes[i] = action.payload;
        }
      }

      return {
        ...state,
        periodes: [...periodes],
      };
    }
    case ACTION_ADD_PERIOD: {
      return {
        ...state,
        periodes: [...state.periodes, action.payload],
      };
    }

    case ACTION_REMOVE_PERIODE: {
      const periodes = [...state.periodes];

      for (let i = 0; i < periodes.length; i++) {
        const item = periodes[i];
        if (+item.idperiod === +action.payload) {
          periodes.splice(i, 1);
        }
      }

      return {
        ...state,
        periodes: [...periodes],
      };
    }

    case ACTION_IS_UPDATE_PERIOD: {
      return {
        ...state,
        isUpdatePeriod: action.payload,
      };
    }

    case ACTION_IS_DELETE_PERIOD: {
      return {
        ...state,
        isDeletePeriod: action.payload,
      };
    }

    case ACTION_SET_ALL_PERIODE: {
      return {
        ...state,
        periodes: action.payload,
      };
    }

    default:
      return state;
  }
};

export default periodReducer;
