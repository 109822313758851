import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import PropagateLoader from "react-spinners/PropagateLoader";
import { FullscreenOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import RadioButton from "./RadioButton";
import { numberFormat } from "./IndicatorView";
import Map from "./Map";
import "leaflet/dist/leaflet.css";
import { getChartType, getChartColumnsAndRows } from "../../tools/Chart";
import CartTable from "./CartTable";
import CardCalendar from "./CardCalendar";
import CardPie from "./CardPie";
import CardBar from "./CardBar";
import CardLine from "./CardLine";
import { FiCopy } from "react-icons/fi";
import CardBarHorizontal from "./CardBarHorizontal";
const numeral = require("numeral");
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");
if (data) {
  theme = JSON.parse(data).theme;
}

export default memo(function CardResponsive({ indicator, onOpen, setIsMap }) {
  const container = useRef();
  const [value, setValue] = useState("1");

  const [total, setTotal] = useState(0);
  const [avg, setAvg] = useState(0);
  const [appearanceAvg, setAppearanceAvg] = useState(null);
  const [appearanceTotal, setAppearanceTotal] = useState(null);
  const [appearanceCount, setAppearanceCount] = useState(null);
  const [counter, setCounter] = useState(0);
  const [resultat, setResultat] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [x, setX] = useState(900);
  const [y, setY] = useState(500);

  const timeInterval = useSelector((state) => state.time.interval);
  const timeValue = useSelector((state) => state.time.value);

  const options = [
    { label: "Jour", value: "1" },
    { label: "Semaine", value: "4" },
    { label: "Mois", value: "2" },
    { label: "Année", value: "3" },
  ];

  useEffect(() => {
    if (container.current) {
      let h = 100;
      let w = 0;
      if (indicator.showlastvalue) {
        h += 30;
      }
      if (indicator.showperiode) {
        h += 40;
      }
      if (Array.isArray(indicator.goals) && indicator.goals.length > 0) {
        h += 30;
        w += 150;
      }
      setY(container.current.clientHeight - h);
      setX(container.current.clientWidth - w);
    }
  }, [container.current?.clientHeight, container.current?.clientWidth, indicator]);

  const renderLastValue = () => {
    const { total, lastValue } = indicator;
    const pourcentage = ((total - lastValue) * 100) / lastValue;

    const periode = [
      "Hier",
      "La semaine dernière",
      "Le mois dernier",
      "Le trimestre dernier",
      "Le semestre dernier",
      "L'année dernière",
    ];

    let text = "";

    const nbre = parseInt(timeValue) - 1;

    if (nbre >= 0) {
      text = periode[nbre];
    } else {
      text = `du ${new Date(timeInterval[0]).toISOString().split("T")[0]} au ${
        new Date(timeInterval[1]).toISOString().split("T")[0]
      }`;
    }

    if (total > lastValue) {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="haut" src="/haut.png" height={"20px"} />
          <i
            style={{
              color: "#2ECC71",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    } else {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="bas" src="/bas.png" height={"20px"} />
          <i
            style={{
              color: "#E74C3C",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {numberFormat(pourcentage)}%{" "}
            <i
              style={{
                color: parseInt(theme) === 0 ? "#FFF" : "#000",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {text}
            </i>
          </i>
        </span>
      );
    }
  };

  const getAxe = () => {
    let axe = null;
    for (let index = 0; index < indicator.columns.length; index++) {
      const element = indicator.columns[index];
      if (parseInt(element.idcolumn) === parseInt(indicator.idaxe)) {
        axe = element;
        break;
      }
    }
    return axe;
  };
  // console.log(indicator);
  useEffect(() => {
    if (indicator) {
      try {
        const { columns, rows, total, avg, counter, resultat } =
          getChartColumnsAndRows(
            indicator,
            null,
            indicator.data.data ? indicator.data.data : indicator.data || [],
            indicator.showperiode,
            indicator.periode
          );
        setTotal(total);
        setAvg(avg);
        setCounter(counter);
        setRows(rows);
        setResultat(resultat);
        setAppearanceCount(indicator.data.appearance_count);
        setAppearanceTotal(indicator.data.appearance);
        setAppearanceAvg(indicator.data.appearance_avg);
        setColumns(columns);
      } catch (error) {
        console.log(error);
      }
      if (indicator.showperiode) {
        setValue(indicator.periode);
      } else {
        setValue("1");
      }
    }
  }, [indicator, indicator.data]);

  useEffect(() => {
    if (indicator) {
      try {
        const { columns, rows, total, avg, counter, resultat } =
          getChartColumnsAndRows(
            indicator,
            null,
            indicator?.data.data || [],
            indicator.showperiode,
            value
          );
        setTotal(total);
        setAvg(avg);
        setCounter(counter);
        setResultat(resultat);
        setAppearanceCount(indicator.data.appearance_count);
        setAppearanceTotal(indicator.data.appearance);
        setAppearanceAvg(indicator.data.appearance_avg);
        setRows(rows);
        setColumns(columns);
      } catch (error) {
        console.log(error);
      }
    }
  }, [value]);

  const onDuplicate = () => {
    console.log("dupliquer", indicator);
    return;
  };
  function getStyle(operation) {
    let styles = {};
    switch (+operation) {
      case 1:
        styles = appearanceTotal
          ? {
              color: appearanceTotal.color,
              fontStyle: appearanceTotal.italic ? "italic" : "",
              fontWeight: appearanceTotal.bold ? "bold" : "",
              size: appearanceTotal.bold ? appearanceTotal.bold : "20px",
              textDecoration: appearanceTotal.underline ? "underline" : "",
            }
          : {};
        break;
      case 2:
        styles = appearanceAvg
          ? {
              color: appearanceAvg.color,
              fontStyle: appearanceAvg.italic ? "italic" : "",
              fontWeight: appearanceAvg.bold ? "bold" : "",
              size: appearanceAvg.bold ? appearanceAvg.bold : "20px",
              textDecoration: appearanceAvg.underline ? "underline" : "",
            }
          : {};
        break;
      case 4:
        styles = appearanceCount
          ? {
              color: appearanceCount.color,
              fontStyle: appearanceCount.italic ? "italic" : "",
              fontWeight: appearanceCount.bold ? "bold" : "",
              size: appearanceCount.bold ? appearanceCount.bold : "20px",
              textDecoration: appearanceCount.underline ? "underline" : "",
            }
          : {};
        break;
      default:
        styles = {};
        break;
    }
    return styles;
  }

  return (
    <>
      <div
        ref={(ref) => (container.current = ref)}
        style={{
          width: "100%",
          height: "100%",
          margin: 0,
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        id={`indicator-${indicator.idindicator}`}
        className="flex items-center justify-center p-0 m-0"
      >
        <div className="relative flex flex-col justify-between w-full h-full p-2 m-0 overflow-hidden bg-white rounded-br-none cursor-pointer ">
          {/* Header Begin */}
          <div className="relative flex items-center justify-between my-auto mb-0 font-bold top-3 ">
            <div className="flex items-start justify-between">
              <span className="font-poppins text-[20px]  font-semibold leading-[30px]  text-left">
                {indicator.name}
              </span>
            </div>
            {x >= 400 && (
              <div className="flex items-start justify-between w-auto gap-5 overflow-x-auto">
                {resultat.map((item, index) => {
                  return (
                    <div
                      className="inline-flex items-center px-3 py-2 text-sm bg-gray-100 rounded-full"
                      key={index}
                    >
                      <span className="mr-2 text-gray-600">
                        {item.nameIndicateur} :{" "}
                      </span>
                      <span className="font-semibold text-gray-800">
                        {item && +item.operation === 1
                          ? numberFormat(item.sum)
                          : item && +item.operation === 2
                          ? numberFormat(item.avg)
                          : item && +item.operation === 4
                          ? numberFormat(item.count)
                          : numberFormat(item.sum)}{" "}
                        {item?.symbole || ""}
                      </span>
                    </div>
                  );
                })}
                {/* <button
                onClick={onDuplicate}
                className="p-2 text-black transition-colors duration-300 hover:text-gray-700 focus:outline-none"
                title="Dupliquer"
              >
                <FiCopy className="w-5 h-5" />
              </button>  */}
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={(e) => {
                    onOpen(indicator);
                  }}
                  className="cursor-pointer mt-[7px]"
                >
                  <path
                    d="M0.5 17.5V12H2V14.9462L5.35 11.5963L6.40375 12.65L3.05375 16H6V17.5H0.5ZM12 17.5V16H14.9462L11.5962 12.65L12.65 11.5963L16 14.9462V12H17.5V17.5H12ZM5.35 6.40375L2 3.05375V6H0.5V0.5H6V2H3.05375L6.40375 5.35L5.35 6.40375ZM12.65 6.40375L11.5962 5.35L14.9462 2H12V0.5H17.5V6H16V3.05375L12.65 6.40375Z"
                    fill="black"
                  />
                </svg>
              </div>
            )}
          </div>

          {y >= 100 && x >= 240 && (
            <>
              {indicator.lastValue ? renderLastValue() : null}

              <div
                className="h-custom-options"
                hidden={!(indicator && indicator.showperiode)}
              >
                {(() => {
                  let axe = null;
                  for (
                    let index = 0;
                    index < indicator.columns.length;
                    index++
                  ) {
                    const element = indicator.columns[index];
                    if (
                      parseInt(element.idcolumn) ===
                      parseInt(indicator.iddefault)
                    ) {
                      axe = element;
                      break;
                    }
                  }
                  return parseInt(axe?.dataType) === 3 ? (
                    <RadioButton
                      onChange={(value) => {}}
                      defaultValue={"1"}
                      value={value}
                      setValue={setValue}
                      options={options}
                    />
                  ) : null;
                })()}
              </div>
              <div
                className="pt-0 mt-0 "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",

                  padding: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <div
                  className="gchart"
                  style={{
                    width: x,
                    height: y,
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsMap(true);
                  }}
                  onMouseUp={(event) => {
                    setIsMap(false);
                  }}
                >
                  {!indicator.is || indicator.isLoading ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "50px",
                      }}
                    >
                      <PropagateLoader
                        color={
                          theme === "0"
                            ? "#fff"
                            : theme === "1"
                            ? "#1f2128"
                            : "#1f2128"
                        }
                        loading={true}
                        size={5}
                        speedMultiplier={0.75}
                      />
                    </div>
                  ) : indicator && indicator?.type == "3" ? (
                    <Map
                      data={rows.map((items) => {
                        return items.slice(1);
                      })}
                    />
                  ) : indicator && indicator?.type == "4" ? (
                    <CartTable columns={columns} rows={rows} x={x} y={y} />
                  ) : indicator && indicator?.type == "5" ? (
                    <CardCalendar x={x} y={y} rows={rows} columns={columns} />
                  ) : indicator && indicator?.type == "2" ? (
                    <CardPie x={x} y={y} rows={rows} columns={columns} />
                  ) : indicator && indicator?.type == "0" ? (
                    <CardBar x={x} y={y} rows={rows} columns={columns} />
                  ) : indicator && indicator?.type == "6" ? (
                    <CardBarHorizontal
                      x={x}
                      y={y}
                      rows={rows}
                      columns={columns}
                    />
                  ) : indicator && indicator?.type == "1" ? (
                    <CardLine x={x} y={y} rows={rows} columns={columns} />
                  ) : (
                    <Chart
                      width={"100%"}
                      height={"100%"}
                      chartLanguage="fr"
                      chartType={getChartType(indicator?.type)}
                      // chartType={"Bar"}
                      loading={true}
                      loader={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "50px",
                          }}
                        >
                          <PropagateLoader
                            color={
                              theme === "0"
                                ? "#fff"
                                : theme === "1"
                                ? "#1f2128"
                                : "#1f2128"
                            }
                            loading={true}
                            size={5}
                            speedMultiplier={0.75}
                          />
                        </div>
                      }
                      columns={columns}
                      rows={rows.map((items) => {
                        console.log(items);

                        return items.slice(1).map((a) => a.value);
                      })}
                      options={{
                        calendar: {
                          cellSize: (x * 7.25) / 440,
                          daysOfWeek: "DLMMJVS",
                        },
                        showRowNumber: true,
                        height: "100%",
                        width: "100%",
                        chartArea: {
                          height:
                            indicator && parseInt(indicator.type) === 2
                              ? "95%"
                              : "70%",
                          width:
                            indicator && parseInt(indicator.type) === 2
                              ? "95%"
                              : "80%",
                        },
                        crosshair: { trigger: "both", orientation: "both" },
                        hAxis: {
                          gridlines: {
                            color: "transparent",
                          },
                          minorGridlines: {
                            count: 0,
                          },

                          textStyle: {
                            color:
                              theme === "0"
                                ? "#FFF"
                                : theme === "1"
                                ? "black"
                                : "black",
                          },
                        },
                        ...(indicator && indicator.type == "0"
                          ? {
                              seriesType: "bars",

                              isStacked: false,
                            }
                          : {}),
                        vAxis: {
                          baselineColor: theme === "0" ? "#FFF" : "#000",
                          minorGridlines: {
                            count: 0,
                          },
                          textStyle: {
                            color:
                              theme === "0"
                                ? "#FFF"
                                : theme === "1"
                                ? "#1f2128"
                                : "#1f2128",
                          },
                        },
                        backgroundColor: "transparent",
                        colors: [
                          "#05BAF3",
                          "#2979FF",
                          "#C62828",
                          "#F50057",
                          "#AB47BC",
                          "#4527A0",
                          "#FFA726",
                          "#EF5350",
                          "#FF3D00",
                          "#0277BD",
                          "#C6FF00",
                          "#EF6C00",
                          "#AD1457",
                          "#00B0FF",
                          "#D4E157",
                          "#283593",
                          "#3D5AFE",
                          "#6A1B9A",
                          "#42A5F5",
                          "#5C6BC0",
                          "#9E9D24",
                          "#FF7043",
                          "#651FFF",
                          "#7E57C2",
                          "#FF9100",
                          "#FF1744",
                          "#1565C0",
                          "#D500F9",
                          "#FF3D00",
                          "#EC407A",
                        ],
                        pieSliceText: "label",
                        legend:
                          indicator && parseInt(indicator.type) === 2
                            ? {
                                position: "left",
                                textStyle: {
                                  color: theme === "0" ? "#FFF" : "#000",
                                  fontSize: 12,
                                },
                              }
                            : {
                                position: "none",
                              },
                      }}
                    />
                  )}
                </div>
                {indicator && indicator.goals && indicator.goals.length > 0
                  ? (() => {
                      let noPeriodique = _.sortBy(indicator.goals, [
                        (o) => o.startdate,
                      ]);
                      let item = null;
                      let current = new Date().getTime();
                      for (let i = 0; i < noPeriodique.length; i++) {
                        const goal = noPeriodique[i];
                        let start = new Date(goal.startdate).getTime();
                        let end = new Date(goal.enddate).getTime();
                        if (current >= start && current <= end) {
                          item = goal;
                        }
                      }
                      if (!item) {
                        let end = new Date(noPeriodique[0].enddate).getTime();
                        if (current > end) {
                          item = noPeriodique[noPeriodique.length - 1];
                        } else {
                          item = noPeriodique[0];
                        }
                      }
                      return (
                        <div className="col-md-auto">
                          <div
                            className="bloc-border-blanc-inner"
                            title={`Objectif du ${item.startdate} au ${item.enddate}`}
                          >
                            <p className="objectif">{`${item.enddate}`}</p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Progress
                                type="circle"
                                width="50px"
                                percent={
                                  item.currentvalue
                                    ? _.round(
                                        (item.currentvalue / item.targetvalue) *
                                          100,
                                        1
                                      )
                                    : 0
                                }
                                strokeColor={"#05BAF3"}
                                trailColor={"#CCD4E2"}
                                format={(percent) => {
                                  return (
                                    <i
                                      style={{
                                        color:
                                          theme === "0"
                                            ? "#fff"
                                            : theme === "1"
                                            ? "#1f2128"
                                            : "#1f2128",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {_.round(
                                        (item.currentvalue / item.targetvalue) *
                                          100,
                                        1
                                      ) + "%"}
                                    </i>
                                  );
                                }}
                              />
                            </div>
                            <p className="t-center">
                              <br />
                              {item.targetvalue
                                ? numeral(item.targetvalue)
                                    .format("0,0")
                                    .split(",")
                                    .join(" ")
                                : 0}
                            </p>
                          </div>
                        </div>
                      );
                    })()
                  : null}
              </div>
              {indicator &&
              indicator.goals &&
              indicator.type !== "2" &&
              indicator.type !== "4" &&
              indicator.type !== "5" &&
              indicator.goals.length > 0 ? (
                <div
                  style={{
                    height: "25px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#25F700",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>Objectif</i>
                  </p>

                  {parseInt(indicator.operation) === 1 &&
                  !indicator.notshowcumulwithgoal ? (
                    <p>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          marginLeft: "10px",
                          backgroundColor: "blue",
                        }}
                      ></span>{" "}
                      <i style={{ fontSize: "15px" }}>Cumul</i>
                    </p>
                  ) : null}

                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                        backgroundColor: "#05BAF3",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>Réalisé</i>
                  </p>
                </div>
              ) : null}
              {indicator &&
              indicator.showcumul &&
              parseInt(indicator.operation) !== 2 &&
              indicator.type !== "2" &&
              indicator.type !== "4" &&
              indicator.type !== "5" &&
              !(indicator.goals.length > 0) ? (
                <div
                  style={{
                    height: "25px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                        backgroundColor: "blue",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>Cumul</i>
                  </p>

                  <p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginLeft: "10px",
                        backgroundColor: "#05BAF3",
                      }}
                    ></span>{" "}
                    <i style={{ fontSize: "15px" }}>
                      {indicator && getAxe() ? getAxe().name : null}
                    </i>
                  </p>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
});
