import React from "react";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { MenuOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { truncateText } from "../utils/truncateWords";
const options = [
  "Aujourd'hui",
  "Cette semaine",
  "Ce mois",
  "Ce trimestre",
  "Cette année",
];

export default function GoalList({
  items,
  setItems,
  onDelete,
  onUpdate,
  large,
}) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };
  const idUser = useSelector((state) => state.user.id);
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const Item = SortableElement((props) => <tr {...props} />);

  const Container = SortableContainer((props) => (
    <tbody {...props}>
      {props.items.map((item, index) => (
        <Item key={`item-${index}`} index={index}>
          <>
            <td className="table-style">
              <DragHandle />
            </td>
            <td className="table-style" style={{ textAlign: "left" }}>
              {truncateText(item.name)}
            </td>
            {large ? (
              <td className="table-style" style={{ textAlign: "left" }}>
                {truncateText(item.metric ? item.metric.name : "-")}
              </td>
            ) : null}
            <td className="table-style">{item.currentvalue}</td>
            <td className="table-style">{item.targetvalue}</td>
            <td className="table-style">
              {item.periodique
                ? options[parseInt(item.periodicite) - 1]
                : `Du ${item.startdate && item.startdate.substring(0, 10)} au ${
                    item.enddate && item.enddate.substring(0, 10)
                  }`}
            </td>
            <td className="table-style">{item.status} </td>
            <td className="table-style">
              <ul
                className="actions"
                style={{
                  color: +item.idowner !== +idUser ? "gray" : "",
                }}
              >
                <li>
                  <a
                    href="#"
                    className="btn-simple-round modifier"
                    onClick={() => {
                      if (+item.idowner === +idUser) onUpdate(item);
                    }}
                    style={{
                      pointerEvents: +item.idowner !== +idUser ? "none" : "",
                    }}
                  >
                    <i
                      className="fa-light fa-pen-to-square"
                      style={{ color: +item.idowner !== +idUser ? "gray" : "" }}
                    ></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="btn-simple-round supprimer"
                    onClick={() => {
                      if (+item.idowner === +idUser) onDelete(item);
                    }}
                    style={{
                      pointerEvents: +item.idowner !== +idUser ? "none" : "",
                    }}
                  >
                    <i
                      style={{ color: +item.idowner !== +idUser ? "gray" : "" }}
                      className="fa-light fa-trash-can"
                    ></i>
                  </a>
                </li>
              </ul>
            </td>
          </>
        </Item>
      ))}
    </tbody>
  ));

  return (
    <>
      <div className="wrap-table">
        <table
          id="sources"
          className="display responsive"
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th>
                <span>Priorité</span>
              </th>
              <th style={{ textAlign: "left", paddingLeft: 20 }}>
                <span>Nom</span>
              </th>
              {large ? (
                <th style={{ textAlign: "left", paddingLeft: 20 }}>
                  <span>Metric</span>
                </th>
              ) : null}
              <th>
                <span>Valeur</span>
              </th>
              <th>
                <span>Objectif</span>
              </th>
              <th>
                <span>Periode</span>
              </th>
              <th>
                <span>Status</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <Container
            items={items}
            axis="y"
            lockAxis="y"
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
          />
        </table>
      </div>
    </>
  );
}
