import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useSnackbar } from "notistack";
const _ = require("lodash");

let theme = "1";
const data = localStorage.getItem("theme");

if (data) {
  theme = JSON.parse(data).theme;
}

export default function InvitedSuccess() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-50 text-center">
      <div className="p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-green-600 mb-4">Invitation Acceptée !</h1>
        <p className="text-gray-600 mb-6">
          Félicitations ! Vous avez rejoint l'entreprise avec succès.
        </p>
        <button
          onClick={() => navigate("/")}
          className="px-6 py-3 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400"
        >
          Aller à l'accueil
        </button>
      </div>
    </div>
  );
}
