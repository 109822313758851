import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import IndicatorView from "./IndicatorView";
import Modal from "./Modal";
import ModalUpdateIndicator from "./ModalUpdateIndicator";
import { useDispatch, useSelector } from "react-redux";
import {
  actionSetIndicator,
  actionSetComments,
  actionDeleteIndicator,
} from "../../redux/actions/indicatorAction";
import { actionSetGoals } from "../../redux/actions/GoalAction";
import { actionSetLists } from "../../redux/actions/listAction";
import html2canvas from "html2canvas";

export default function ModalIndicator({
  visible,
  onCancel,
  data,
  setVisible,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShowDelete, setDelete] = useState(false);
  const [isShowUpdate, setUpdate] = useState(false);
  const idUser = useSelector((state) => state.user.id);
  const isViewOnly = useSelector((state) => state.board.isViewOnly);
  const role = useSelector((state) => state.user.role);
  const iscapable = ["ADMIN", "OWNER"].includes(role);
  const isDelete = useSelector((state) => state.indicator.isDelete);
  const token = useSelector((state) => state.user.token);
  const lists = useSelector((state) => state.list.lists);
  const listsRef = useRef();
  const viewRef = useRef(null);
  listsRef.current = lists;

  useEffect(() => {
    sessionStorage.setItem("indicator", JSON.stringify(data));
    dispatch(actionSetIndicator(data));
    dispatch(actionSetComments([...data.commentaires]));
  }, [visible]);

  const enlarge = (e) => {
    e.preventDefault();
    dispatch(actionSetGoals({ type: "ALL", data: data.goals }));
    navigate("/indicator");
  };

  const showDelete = (e) => {
    e.preventDefault();
    setUpdate(true);
    setVisible(false);
  };

  const showUpdate = (e) => {
    e.preventDefault();
    setDelete(true);
    setVisible(false);
  };

  const cancelDelete = () => {
    setDelete(false);
    setVisible(true);
  };

  const cancelUpdate = () => {
    setUpdate(false);
    setVisible(true);
  };
  const closer = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal
        visible={isShowDelete}
        title={
          data
            ? `Supprimer l'indicateur ${data.name}`
            : "Confirmer la suppression"
        }
        onCancel={isDelete ? null : () => cancelDelete(false)}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                disabled={isDelete}
                onClick={isDelete ? null : () => cancelDelete(false)}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDelete}
                onClick={() => {
                  dispatch(
                    actionDeleteIndicator(data.idindicator, token, (is) => {
                      if (is) {
                        setDelete(false);
                        const id = data.idindicator;
                        const listsNew = [...listsRef.current];
                        for (let a = 0; a < listsNew.length; a++) {
                          const list = listsNew[a];
                          for (let b = 0; b < list.indicators.length; b++) {
                            const indicator = list.indicators[b];
                            if (
                              parseInt(indicator.idindicator) === parseInt(id)
                            ) {
                              listsNew[a].indicators.splice(b, 1);
                              break;
                            }
                          }
                        }
                        dispatch(
                          actionSetLists({ type: "ALL", data: [...listsNew] })
                        );
                      }
                    })
                  );
                }}
              >
                {isDelete ? "Supprimer..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <ModalUpdateIndicator
        data={data}
        onCancel={cancelUpdate}
        visibility={isShowUpdate}
      />

      {visible ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#00000060",
            zIndex: 20,
            position: "fixed",
            display: visible ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            left: 0,
            top: 0,
            overflowY: "auto",
            cursor: "default",
            zIndex: 1001,
          }}
          className="parent-modal-closable"
          onClick={(e) => {
            if (e.target.className === "parent-modal-closable") {
              if (onCancel) {
                onCancel();
              }
            }
          }}
        >
          <div className="card-popup rose">
            <div className="head-card">
              <p>{data.name}</p>
              <ul>
                {iscapable || (idUser && !isViewOnly) ? (
                  <>
                    <li>
                      <a href="#" onClick={showUpdate}>
                        <i className="fa-light fa-trash-can"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={showDelete}>
                        <i className="fa-light fa-pen-to-square"></i>
                      </a>
                    </li>
                  </>
                ) : null}
                <li>
                  <a href="#">
                    <i
                      className="fa-light fa-camera-viewfinder"
                      onClick={async () => {
                        const element = viewRef.current;
                        const canvas = await html2canvas(element);
                        const url = canvas.toDataURL("image/png");
                        const link = document.createElement("a");
                        if (typeof link.download === "string") {
                          link.href = url;
                          link.download = `${
                            data.name
                          }-${new Date().toLocaleString()}.png`;
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        } else {
                          window.open(url);
                        }
                      }}
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i
                      className="fa-light fa-arrows-maximize"
                      onClick={enlarge}
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="#" onClick={closer}>
                    <i className="fa-light fa-xmark"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="bloc-fond-fonce" style={{ borderRadius: "0px" }}>
              <IndicatorView viewRef={viewRef} />
            </div>
            <div className="messages">
              <div className="user">
                <img src="./styles/img/user-1.jpg" alt="Prénom Nom" />
              </div>
              <div className="msg">
                <p>
                  Messages{" "}
                  <span className="iconstyle777">
                    {data.commentaires.length}
                  </span>
                </p>
              </div>
              <div className="settings">
                <i className="fa-light fa-gear"></i>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
