import React, { useRef, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const formatDataForChart = (rows, columns) => {
  return rows.map((row) => {
    const formattedRow = {};
    columns.forEach((column, index) => {
      formattedRow[column.label] = row[index + 1];
      formattedRow["style"] = row[0];
      formattedRow["baseColor"] = column.baseColor;
      formattedRow["isPart"] = column.isPart;
      formattedRow["isVisible"] = column.isVisible;
    });
    return formattedRow;
  });
};

export default function CardBar({ rows, columns, x, y }) {
  const chartRef = useRef(null);
  const [visibleDataPoints, setVisibleDataPoints] = useState(rows.length);
  const [chartHeight, setChartHeight] = useState(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartRef.current) {
        const height = chartRef.current.clientHeight;
        setChartHeight(height);
        const newVisibleDataPoints = Math.floor(height / 10);
        setVisibleDataPoints(Math.min(newVisibleDataPoints, rows.length));
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [rows]);

  const formattedData = formatDataForChart(rows, columns);
  const visibleData = formattedData.slice(-visibleDataPoints);

  const formatDate = (date) => {
    const options = { weekday: "short" };
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const weekday = date.toLocaleString("fr-FR", options);
    return `${weekday} ${day}/${month}/${year}`;
  };

  const getColor = (item, column) => {
    let color = column.baseColor;
    if (!column.isPart) {
      return Object.keys(item[column.label].style).length > 0
        ? item[column.label].style.color
        : item[column.label].color;
    }
    return color;
  };

  const datasets = [];

  for (let j = 1; j < columns.length; j++) {
    const column = columns[j];
    if (column.isVisible) {
      const conditions = column.conditions || [];
      conditions.sort((a, b) => {
        const limitA = Number(a.condition.split(" ")[2]);
        const limitB = Number(b.condition.split(" ")[2]);
        return limitB - limitA;
      });

      const baseData = visibleData.map((item) => {
        const val = item[column.label].value;
        return val > 0 ? val : 0;
      });

      const baseColors = visibleData.map((item) => getColor(item, column));

      if (conditions.length > 0 && column.isPart) {
        const conditionsValue = [];
        let remainingValues = [...baseData];

        for (let i = 0; i < conditions.length; i++) {
          const element = conditions[i];
          const conditionParts = element.condition.split(" ");
          const conditionOperator = conditionParts[1]; // Opérateur
          const conditionLimit = Number(conditionParts[2]); // Limite
          const conditionColor = element.color;

          const conditionData = remainingValues.map((val, index) => {
            let diff = 0;

            if (conditionOperator === ">=") {
              if (val >= conditionLimit) {
                diff = Math.min(val, val - conditionLimit);
              }
            } else if (conditionOperator === ">") {
              if (val > conditionLimit) {
                diff = Math.min(val, val - conditionLimit);
              }
            } else if (conditionOperator === "<=") {
              if (val <= conditionLimit) {
                diff = val;
              }
            } else if (conditionOperator === "<") {
              if (val < conditionLimit) {
                diff = val;
              }
            } else if (conditionOperator === "==") {
              if (val === conditionLimit) {
                diff = val;
              }
            } else if (conditionOperator === "!=") {
              if (val !== conditionLimit) {
                diff = val;
              }
            }

            remainingValues[index] -= diff;
            return diff;
          });

          conditionsValue.push({
            label: `${column.label} ${conditionOperator} ${conditionLimit}`,
            data: conditionData,
            borderWidth: 2,
            backgroundColor: conditionData.map(() => conditionColor),
            borderColor: conditionData.map(() => conditionColor),
            borderRadius: 5,
            stack: column.label || "default",
            type: column.type === "number" ? "bar" : "line",
            conditionLimit: conditionLimit,
          });
        }

        // Trier les conditions par conditionLimit en ordre croissant
        conditionsValue.sort((a, b) => a.conditionLimit - b.conditionLimit);

        // Ajouter les valeurs restantes comme jeu de données de base
        datasets.push(
          {
            label: `${column.label}`,
            data: remainingValues,
            borderWidth: 2,
            backgroundColor: baseColors,
            borderColor: baseColors,
            borderRadius: 5,
            stack: column.label || "default",
            type: column.type === "number" ? "bar" : "line",
          },
          ...conditionsValue // Ajouter les datasets des conditions
        );
      } else {
        // Si aucune condition, ajouter uniquement le dataset de base
        datasets.push({
          label: `${column.label}`,
          data: baseData,
          borderWidth: 2,
          backgroundColor: baseColors,
          borderColor: baseColors,
          borderRadius: 5,
          stack: column.label || "default",
          type: column.type === "number" ? "bar" : "line",
        });
      }
    }
  }

  const data = {
    labels: visibleData.map((item) => {
      if (columns[1].type === "datetime") {
        return formatDate(item[columns[0].label].value);
      }
      return item[columns[0].label].value;
    }),
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          // enabled: true,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div ref={chartRef} style={{ width: "100%", height: "100%" }}>
      <Bar data={data} options={options} />
    </div>
  );
}
