import host from "./host";

export const ACTION_ADD_PERIOD = "ACTION_ADD_PERIOD";
export const ACTION_IS_ADD_PERIOD = "ACTION_IS_ADD_PERIOD";
export const ACTION_IS_DELETE_PERIOD = "ACTION_IS_DELETE_PERIOD";
export const ACTION_IS_GET_PERIOD = "ACTION_IS_GET_PERIOD";
export const ACTION_IS_UPDATE_PERIOD = "ACTION_IS_UPDATE_PERIOD";
export const ACTION_UPDATE_PERIOD = "ACTION_UPDATE_PERIOD";
export const ACTION_SET_ALL_PERIODE = "ACTION_SET_ALL_PERIODE";
export const ACTION_REMOVE_PERIODE = "ACTION_REMOVE_PERIODE";

export const actionAddPeriod = (payload) => ({
  type: ACTION_ADD_PERIOD,
  payload: payload,
});

export const actionRemovePeriod = (payload) => ({
  type: ACTION_REMOVE_PERIODE,
  payload: payload,
});

export const actionSetAllPeriode = (payload) => ({
  type: ACTION_SET_ALL_PERIODE,
  payload: payload,
});

export const actionIsAddPeriod = (payload) => ({
  type: ACTION_IS_ADD_PERIOD,
  payload: payload,
});

export const actionIsDeletePeriod = (payload) => ({
  type: ACTION_IS_DELETE_PERIOD,
  payload: payload,
});

export const actionIsUpdatePeriod = (payload) => ({
  type: ACTION_IS_UPDATE_PERIOD,
  payload: payload,
});

export const actionIsGetPeriod = (payload) => ({
  type: ACTION_IS_GET_PERIOD,
  payload: payload,
});

export const addPeriod = (payload) => ({
  type: ACTION_ADD_PERIOD,
  payload: payload,
});

export const actionSetPeriod = (payload) => ({
  type: ACTION_UPDATE_PERIOD,
  payload: payload,
});

export const actionCreatePeriod = (
  name,
  startDate,
  endDate,
  idworkspace,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsAddPeriod(true));
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        name,
        startDate,
        endDate,
        idworkspace,
      }),
      redirect: "follow",
    };

    fetch(`${host}/period/create`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        callback(result);
        if (result.status) {
          dispatch(actionAddPeriod(result.data));
        }
      })
      .catch((error) => {
        // callback(false);
      })
      .then(() => {
        dispatch(actionIsAddPeriod(false));
      });
  };
};

export const actionUpdatePeriod = (
  idperiod,
  name,
  startDate,
  endDate,
  idworkspace,
  token,
  callback
) => {
  return (dispatch) => {
    dispatch(actionIsUpdatePeriod(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      name,
      startDate,
      endDate,
      idworkspace,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${host}/period/update/${idperiod}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        callback(result);
        if (result.status) {
          dispatch(actionSetPeriod(result.data));
        }
      })
      .catch(() => {})
      .then(() => {
        dispatch(actionIsUpdatePeriod(false));
      });
  };
};

export const actionGetPeriodByUserH = (idworkspace, token, callback) => {
  return (dispatch) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    console.log(idworkspace);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/period/get-by-workspace/${idworkspace}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        callback(result);
        if (result) {
          dispatch(actionSetAllPeriode(result.data));
        }
      })
      .catch(() => {
        callback(false);
      });
  };
};

export const actionDeletePeriod = (id, idworkspace, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsDeletePeriod(true));
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/period/delete/${idworkspace}/${id}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        callback(result);
        if (result.status) {
          dispatch(actionRemovePeriod(id));
        }
      })
      .catch(() => {
        callback(false);
      })
      .then(() => {
        dispatch(actionIsDeletePeriod(false));
      });
  };
};

export const actionGetPeriodByUser = (id, token, callback) => {
  return (dispatch) => {
    dispatch(actionIsGetPeriod(true));

    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/period/get-by-user/${id}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(actionSetPeriod({ type: "ALL", data: result.periods }));
          callback(200, result.periods);
        } else {
          callback(400);
        }
      })
      .catch(() => {
        callback(400);
      })
      .then(() => dispatch(actionIsGetPeriod(false)));
  };
};
