import React from "react";
import ContentLoader from "react-content-loader";
import { DragDropContext, Droppable } from "react-dnd-beautiful";
import List from "../Views/List";
import Modal from "../Views/Modal";
import { arrayMoveImmutable } from "array-move";
import host from "../../redux/actions/host";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { actionAddList, actionSetLists } from "../../redux/actions/listAction";
const _ = require("lodash");

export default function TrelloDashboard({
  lists,
  trelloY,
  setIdList,
  isViewOnly,
  onAddIndicator,
}) {
  const dispatch = useDispatch();
  const [label, setLabel] = useState("");
  const isAddList = useSelector((state) => state.list.isAdd);
  const [modalAddListVisibility, setModalAddListVisibility] = useState(false);
  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.board.isGet);
  const board = useSelector((state) => state.board.dashboard);
  const idUser = useSelector((state) => state.user.id);

  const onDragEnd = (ref) => {
    const { draggableId, type, source, destination } = ref;
    if (destination) {
      if (
        destination.index !== source.index ||
        destination.droppableId !== source.droppableId
      ) {
        if (type === "list") {
          let newList = [
            ...arrayMoveImmutable(lists, source.index, destination.index),
          ];

          dispatch(
            actionSetLists({
              type: "ALL",
              data: newList,
            })
          );

          for (let index = 0; index < newList.length; index++) {
            const element = newList[index];
            let myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
              name: element.name,
              positionx: index,
              positiony: "0",
            });

            let requestOptions = {
              method: "PUT",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            fetch(`${host}/list/update/${element.idlist}`, requestOptions)
              .then(() => {})
              .catch(() => {});
          }
        } else {
          let temp = null;
          const newLists = [];
          // On enleve l'indicateur de sont ancienne emplacement.
          for (let i = 0; i < lists.length; i++) {
            const list = { ...lists[i] };
            if (
              parseInt(list.idlist) ===
              parseInt(source.droppableId.split("-")[0])
            ) {
              for (let j = 0; j < list.indicators.length; j++) {
                const indicator = list.indicators[j];
                if (parseInt(indicator.idindicator) === parseInt(draggableId)) {
                  temp = { ...indicator };
                  list.indicators.splice(j, 1);
                  newLists.push(list);
                }
              }
            } else {
              newLists.push(list);
            }
          }
          // on ajoute l'element à son nouvelle emplacement
          for (let i = 0; i < newLists.length; i++) {
            const list = { ...newLists[i] };
            if (
              parseInt(list.idlist) ===
              parseInt(destination.droppableId.split("-")[0])
            ) {
              if (
                destination.droppableId.split("-").length === 2 &&
                destination.droppableId.split("-")[1] !== "t"
              ) {
                let col1 = [];
                let col2 = [];
                for (let j = 0; j < newLists[i].indicators.length; j++) {
                  const element = newLists[i].indicators[j];
                  if (j % 2 === 0) {
                    col1.push(element);
                  } else {
                    col2.push(element);
                  }
                }

                if (destination.droppableId.split("-")[1] === "l") {
                  col1.push(temp);
                  const length = col1.length;
                  col1 = arrayMoveImmutable(
                    col1,
                    length - 1,
                    destination.index
                  );
                } else {
                  col2.push(temp);
                  const length = col2.length;
                  col2 = arrayMoveImmutable(
                    col2,
                    length - 1,
                    destination.index
                  );
                }
                let result = [];
                let k = 0;
                while (k < _.max([col1.length, col2.length])) {
                  if (col1.length > k && col2.length > k) {
                    result.push(col1[k], col2[k]);
                    k++;
                  } else if (col1.length > k) {
                    result.push(col1[k]);
                    k++;
                  } else {
                    result.push(col2[k]);
                    k++;
                  }
                }
                newLists[i].indicators = [...result];
              } else {
                newLists[i].indicators.push(temp);
                const length = newLists[i].indicators.length;

                newLists[i].indicators = arrayMoveImmutable(
                  newLists[i].indicators,
                  length - 1,
                  destination.index
                );
              }

              break;
            }
          }

          let myHeaders = new Headers();
          myHeaders.append("Authorization", token);

          let requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow",
          };

          fetch(
            `${host}/indicator/move/${parseInt(draggableId)}/${parseInt(
              source.droppableId.split("-")[0]
            )}/${parseInt(destination.droppableId.split("-")[0])}`,
            requestOptions
          )
            .then(() => {})
            .catch((e) => {
              console.log("e :>> ", e);
            });

          dispatch(
            actionSetLists({
              type: "ALL",
              data: [...newLists],
            })
          );
        }
      }
    }
  };

  const getSizeOfList = (indicators) => {
    const small = _.filter(indicators, (o) => {
      return o.taille === "0";
    });

    const large = _.filter(indicators, (o) => {
      return o.taille === "2" || (o.taille === null && o.large);
    });

    const medium = _.filter(indicators, (o) => {
      return (
        (o.taille !== "0" && o.taille !== "2") ||
        (o.taille === null && !o.large)
      );
    });

    return small.length > 2 || large.length > 0 || medium.length > 2;
  };

  const cancelAddList = () => {
    setModalAddListVisibility(false);
    setLabel("");
  };

  const addList = (e) => {
    e.preventDefault();
    if (label) {
      dispatch(
        actionAddList(
          label,
          true,
          idUser,
          board.iddashboard,
          token,
          (status) => {
            if (status === 200) {
              cancelAddList();
              enqueueSnackbar("Une liste a été ajoutée", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("La liste n'a pas été ajoutée !", {
                variant: "warning",
              });
            }
          }
        )
      );
    } else {
      enqueueSnackbar("Le nom est obligatoire.", {
        variant: "warning",
      });
    }
  };
  return (
    <>
      <Modal
        visible={modalAddListVisibility}
        title={"Ajouter une liste"}
        onCancel={isAddList ? null : cancelAddList}
      >
        <form onSubmit={addList}>
          <div className="row">
            <div className="col-md-12">
              <div className="input-container">
                <label for="nom" className="label-default">
                  Entrer le nom
                </label>
                <span className="span-block">
                  <input
                    className="input-default inputCustom"
                    name="nom"
                    id="nom"
                    type="text"
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                    placeholder="Obligatoire"
                    required
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  onClick={isAddList ? null : cancelAddList}
                >
                  Annuler
                </button>
                <button className="btn-full" type="submit" disabled={isAddList}>
                  {isAddList ? "Ajouter..." : "Ajouter"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={"droppable-list-first"}
          direction="horizontal"
          type="list"
          isDropDisabled={isViewOnly}
        >
          {(provided) => (
            <div
              className="trello"
              id="trello"
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ height: `${trelloY}px`, maxHeight: `${trelloY}px` }}
            >
              {loading ? (
                <>
                  <div className={"col-xl-3 	col-md-6"}>
                    <div className={"bloc-border-blanc"}>
                      <div className="bloc-head">
                        <ContentLoader
                          height={"155px"}
                          width={"100%"}
                          speed={1}
                          backgroundColor={"#80808075"}
                          foregroundColor={"#a9a9a975"}
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="9px"
                            ry="9px"
                            width="100%"
                            height="20px"
                          />
                          <rect
                            x="0"
                            y="30px"
                            rx="9px"
                            ry="9px"
                            width="100%"
                            height="125px"
                          />
                        </ContentLoader>
                      </div>
                      <div className="row"></div>
                      <Link
                        to={""}
                        title="Ajouter une vue"
                        target="_self"
                        className="btn-border-rose with-picto-droite "
                      >
                        <i className="fa-solid fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                  <div className={"col-xl-3 	col-md-6"}>
                    <div className={"bloc-border-blanc"}>
                      <div className="bloc-head">
                        <ContentLoader
                          height={"290px"}
                          width={"100%"}
                          speed={1}
                          backgroundColor={"#80808075"}
                          foregroundColor={"#a9a9a975"}
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="9px"
                            ry="9px"
                            width="100%"
                            height="20px"
                          />
                          <rect
                            x="0"
                            y="30px"
                            rx="9px"
                            ry="9px"
                            width="100%"
                            height="125px"
                          />
                          <rect
                            x="0"
                            y="165px"
                            rx="9px"
                            ry="9px"
                            width="100%"
                            height="125px"
                          />
                        </ContentLoader>
                      </div>
                      <div className="row"></div>
                      <Link
                        to={""}
                        title="Ajouter une vue"
                        target="_self"
                        className="btn-border-rose with-picto-droite "
                      >
                        <i className="fa-solid fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                </>
              ) : lists.length > 0 ? (
                lists.map((list, index) => {
                  return (
                    <List
                      index={index}
                      data={list}
                      onAddIndicator={onAddIndicator}
                      key={list.idlist}
                      setIdList={setIdList}
                      isLarge={getSizeOfList(list.indicators)}
                    />
                  );
                })
              ) : (
                <p style={{ marginRight: "auto", marginLeft: "auto" }}>
                  Aucune une liste
                </p>
              )}
              {provided.placeholder}
              {!isViewOnly ? (
                <div className="add_list">
                  <button
                    className="btn-full"
                    type="submit"
                    onClick={() => setModalAddListVisibility(true)}
                    style={{
                      display: "inline-block",
                      width: "300px",
                      margin: "0px 10px",
                    }}
                  >
                    Ajouter une liste
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
