import {
  ACTION_SET_CONNECTORS,
  ACTION_IS_GET_CONNECTORS,
} from "../actions/connectorAction";

const initialStateUser = {
  connectors: [],
  isGet: false,
};

const connectorReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case ACTION_SET_CONNECTORS: {
      return {
        ...state,
        connectors: [...action.payload],
      };
    }

    case ACTION_IS_GET_CONNECTORS: {
      return {
        ...state,
        isGet: action.payload,
      };
    }

    default:
      return state;
  }
};

export default connectorReducer;
