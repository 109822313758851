import React, { useState, useMemo } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Sector,
} from "recharts";

// Fonction pour formater les données
const formatData = (rows) => {
  return rows.map(([styles, name, value], index) => {
    return {
      name: name.value,
      value: value.value,
      color: `hsl(${(index * 360) / rows.length}, 70%, 50%)`, // Génère des couleurs distinctes
      styles,
    };
  });
};

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cornerRadius={10}
      />
    </g>
  );
};

export default function CardPie({ x, y, rows, columns }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const padding = 2;
  const legendWidth = 120;
  const minSizeForLegend = 200;
  const minSizeForLabels = 400;
  const showLegend = Math.min(x, y) > minSizeForLegend;
  const showLabels = Math.min(x, y) > minSizeForLabels;
  const availableWidth = x - 2 * padding - (showLegend ? legendWidth : 0);
  const availableHeight = y - 2 * padding;
  const pieSize = Math.min(availableWidth, availableHeight);

  const data = useMemo(() => formatData(rows), [rows]);
  // console.log({ data });
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="p-2 text-center bg-white rounded-md shadow-md cursor-pointer font-poppins"
          style={{
            fontSize: Math.min(12, Math.max(8, pieSize * 0.05)) + "px",
            maxWidth: Math.min(150, pieSize * 0.6) + "px",
          }}
          onClick={() => setActiveIndex(payload[0].index)}
        >
          <h4 className="mb-1 font-bold" style={{ color: data.color }}>
            {data.name}
          </h4>
          <p className="font-bold">{`${data.value} ${columns[1].label}`}</p>
        </div>
      );
    }
    return null;
  };

  const onPieEnter = (_, index) => {
    setHoverIndex(index);
  };

  const onPieLeave = () => {
    setHoverIndex(null);
  };

  return (
    <div className="flex items-center justify-center w-full h-full p-2 bg-white rounded-lg font-poppins">
      <ResponsiveContainer width={x - 2 * padding} height={y - 2 * padding}>
        <PieChart>
          {showLegend && (
            <Legend
              align="right"
              verticalAlign="middle"
              layout="vertical"
              iconSize={8}
              iconType="circle"
              formatter={(value, entry, index) => (
                <span
                  style={{
                    // color: entry.color,
                    filter:
                      hoverIndex === entry.payload.index
                        ? "brightness(1.2)"
                        : "brightness(1)",
                    transition: "filter 0.3s ease-in-out",
                  }}
                  onClick={() => setActiveIndex(entry.payload.index)}
                  onMouseEnter={() => setHoverIndex(entry.payload.index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  className="cursor-pointer font-poppins font-[500px] text-[14px] leading-[21px] tracking-[-0.0375em]"
                >
                  {entry.payload.name}
                </span>
              )}
            />
          )}
          <Pie
            data={data}
            cx={showLegend ? availableWidth / 2 : "50%"}
            cy="50%"
            labelLine={false}
            label={
              showLabels
                ? ({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                : null
            }
            innerRadius={pieSize / 4}
            outerRadius={pieSize / 2}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            onClick={(_, index) => setActiveIndex(index)}
            paddingAngle={4}
            activeShape={renderActiveShape}
            activeIndex={activeIndex}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                stroke="#fff"
                strokeWidth={2}
                cornerRadius={10}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
