import React, { useEffect } from "react";
import Header from "../Header";
import useState from "react-usestateref";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { actionGetConnectors } from "../../redux/actions/connectorAction";
import { LinkOutlined } from "@ant-design/icons/lib/icons";
import {
  actionGetDatasources,
  actionDeleteDatasource,
  actionAddDatasource,
  actionSetOneDatasource,
  actionSetDataDatasources,
  actionUpdateDatasource,
  actionRefreshAuto,
  actionRefreshManual,
} from "../../redux/actions/datasourceAction";
import Modal from "../Views/Modal";
import { useSelector, useDispatch } from "react-redux";
import ModalAddSource from "../Views/ModalAddSource";
import ModalRefreshSource from "../Views/ModalRefreshSource";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import CustomSelect from "../Views/CustomSelect";
import { useSnackbar } from "notistack";
import { Checkbox } from "antd";
import useReceive from "./useReceive";
import useCheck from "./useCheck";
import { Alert } from "@mui/material";
import { truncateText } from "../utils/truncateWords";
import Select from "react-select";
import { ChevronLeft, Search } from "lucide-react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  RefreshCwIcon,
  PencilIcon,
  TrashIcon,
  ArrowRightToLine,
  CircleArrowRight,
} from "lucide-react";
import { useSocket } from "../../context/socketContext";
import TableRowLoader from "../Views/TableLoading";
import { actionGetWorkspaceByCompany } from "../../redux/actions/workspaceAction";
import { select } from "@material-tailwind/react";
import Columns from "../Colonnes";
import Condition from "../Conditions";
import Indicator from "../Indicators";
const _ = require("lodash");

const xlsxHost = "https://xlsx.viabber.com";

export default function DataSource() {
  const { enqueueSnackbar } = useSnackbar();
  const options = ["Heure", "Jour", "Semaine", "Mois", "Année"];
  const [updateVisibility, setUpdateVisibility] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const roleCompany = useSelector((state) => state.user.role);
  const isAdmin = ["ADMIN", "OWNER"].includes(roleCompany);
  const [choice, setChoice] = useState(0);
  const connectors = useSelector((state) => state.connector.connectors);
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const [sourceName, setSourceName, sourceNameRef] = useState("");
  const [new_sourceName, setNewSourceName] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const datasources = useSelector((state) => state.datasource.datasources);
  const selectedWorkspace = useSelector(
    (state) => state.user.selectedWorkspace
  );

  const [isAsync, setIsAsync] = useState(false);
  const [externalUrl, setExternalUrl] = useState("");
  const [iframeVisibility, setIframeVisibility] = useState(false);
  const [addVisibility, setAddVisibility] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [idconnector, setIdConnector, idConnectorRef] = useState(null);

  const nofilter = "Toutes les sources de données";
  const [startDate, setStartDate] = useState(null);
  const [externalIds, setExternalIds] = useState(null);
  const [sourcefilter, setSourcefilter] = useState({
    value: nofilter,
    label: nofilter,
  });
  const [dataAction, setDataAction] = useState("1"); // 2 for refresh, 1 for add.
  let selectchild = "Filtrer par connecteur";
  const [isSourcefiltered, setIsSourcefiltered] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [frequence, setFrequence, frequenceRef] = useState(1);
  const operationOptions = [
    { value: 1, label: "Remplacer les données." },
    { value: 2, label: "Ajouter les données." },
    { value: 3, label: "Ajout intelligent des données." },
  ];
  const [operation, setOperation, operationRef] = useState(operationOptions[0]);
  const [refresh, setRefresh, refreshRef] = useState({
    value: false,
    label: "Manuel",
  });
  const [period, setPeriod, periodRef] = useState({
    value: 0,
    label: "Heure",
  });
  const [refreshingData, setRefreshingData, refreshingDataRef] = useState([]);
  const [isCheckDownloadLink, setCheckDownloadLink] = useState(false);
  const [canUpdateDownloadLink, setCanUpdateDownloadLink] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [id_source, setId_source] = useState(null);
  const [currentDatasource, setcurrentDatasource, currentDatasourceRef] =
    useState(null);
  const isAdd = useSelector((state) => state.datasource.isAdd);
  const isDelete = useSelector((state) => state.datasource.isDelete);
  const isUpdate = useSelector((state) => state.datasource.isUpdate);
  const pourcent = useSelector((state) => state.datasource.pourcent);
  const visibilityProgress = useSelector(
    (state) => state.datasource.visibilityProgress
  );
  const [data, setData] = useReceive(url);
  const [loading, setLoading] = useState(true);
  useCheck();

  const { joinRoom, leaveRoom } = useSocket();

  const onRefresh = () => {
    setIsRefresh(false);
    setRefreshingData([
      ...refreshingDataRef.current,
      currentDatasourceRef.current,
    ]);
    dispatch(
      actionRefreshManual(
        currentDatasourceRef.current.iddatasource,
        {
          rows: data.rows,
          columns: data.columns,
        },
        token,
        (status) => {
          setRefreshingData(
            _.remove([...refreshingDataRef.current], (n) => {
              return !(
                parseInt(currentDatasourceRef.current.iddatasource) ===
                parseInt(n.iddatasource)
              );
            })
          );

          if (status === 200) {
            enqueueSnackbar("Rafraichit avec succès", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Une Erreur c'est produite", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const onAdd = (e) => {
    if (operation.value === 3) {
      if (Array.isArray(externalIds)) {
        for (let i = 0; i < externalIds.length; i++) {
          const column = externalIds[i];
          for (let j = 0; j < data.columns.length; j++) {
            if (data.columns[j].index === column.value) {
              data.columns[j].unique = true;
            }
          }
        }
      } else {
        enqueueSnackbar(
          "Veuillez sélectionner au moins un identifiant unique.",
          {
            variant: "warning",
          }
        );
      }
    }
    e.preventDefault();
    if (sourceNameRef.current) {
      dispatch(
        actionAddDatasource(
          idUser,
          idConnectorRef.current,
          {
            name: sourceNameRef.current,
            operation: operationRef.current.value,
            periode: refreshRef.current.value ? periodRef.current.value : 0,
            refreshed: Boolean(data.url) ? refreshRef.current.value : false,
            nbre: refreshRef.current.value ? frequenceRef.current : 1,
            url: data.url,
            rows: data.rows,
            columns: data.columns,
            callback: data.callback,
            isAsync: isAsync,
            externalUrl: data.externalUrl,
          },
          selectedWorkspace,
          token,
          ({ status, data: datasource }) => {
            if (status) {
              setAddVisibility(false);
              dispatch(
                actionSetDataDatasources({
                  id: datasource.iddatasource,
                  visibilityProgress: true,
                })
              );
            } else {
              enqueueSnackbar(
                "Une erreur c'est produite lors de l'insertion.",
                { variant: "error" }
              );
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    if (data) {
      if (dataAction === "1") {
        setIframeVisibility(false);
        setAddVisibility(true);
      } else {
        onRefresh();
      }
    }
  }, [data]);

  const onUpdate = (e) => {
    e.preventDefault();
    if (
      id_source &&
      new_sourceName &&
      (!refreshRef.current.value ||
        (refreshRef.current.value && Boolean(frequenceRef.current)))
    ) {
      dispatch(
        actionUpdateDatasource(
          new_sourceName,
          startDate,
          isAsync,
          operationRef.current.value,
          refreshRef.current.value ? periodRef.current.value : 0,
          refreshRef.current.value,
          refreshRef.current.value ? frequenceRef.current : 1,
          id_source,
          selectedWorkspace,
          token,
          (status) => {
            if (status) {
              setUpdateVisibility(false);
              enqueueSnackbar("Modification réalisé avec succès.", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("Une erreur c'est produite.", {
                variant: "error",
              });
            }
          }
        )
      );
    } else {
      enqueueSnackbar("Tous les champs sont obligatoires", {
        variant: "warning",
      });
    }
  };

  useEffect(() => {
    if (!updateVisibility) {
      setNewSourceName("");
      setStartDate(null);
      setExternalUrl("");
      setRefresh({ value: false, label: "Manuel" });
      setOperation(operationOptions[0]);
      setPeriod({ value: 0, label: "Heure" });
      setDownloadLink("");
      setCheckDownloadLink(false);
      setCanUpdateDownloadLink(false);
      setExternalIds(null);
      setFrequence(1);
    }
  }, [updateVisibility]);

  useEffect(() => {
    if (!isRefresh && !addVisibility) {
      setData(null);
    }
  }, [isRefresh, addVisibility]);

  useEffect(() => {
    setFrequence(1);
    setSourceName("");
    setOperation(operationOptions[0]);
    setExternalIds(null);
    setPeriod({ value: 0, label: "Heure" });
    setExternalIds(null);
    setExternalUrl(data?.externalUrl ? data?.externalUrl : "");
    if (data?.callback) {
      setIsAsync(true);
    } else {
      setIsAsync(false);
    }
    if (data?.url || data?.callback) {
      setRefresh({ value: true, label: "Automatique" });
    } else {
      setRefresh({ value: false, label: "Manuel" });
    }
  }, [addVisibility, data]);

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data || !selectedWorkspace) {
      navigate("/workspaces");
    } else {
      setLoading(true);
      dispatch(actionGetConnectors("Bearer " + JSON.parse(data).token));
      dispatch(
        actionGetWorkspaceByCompany(
          "Bearer " + JSON.parse(data).token,
          ({ data, status }) => {}
        )
      );
      dispatch(
        actionGetDatasources(
          selectedWorkspace,
          "Bearer " + JSON.parse(data).token,
          ({ data: result, message, status }) => {
            if (status) {
              for (let i = 0; i < result.length; i++) {
                const item = result[i];
                if (item.url && item.url.includes(xlsxHost)) {
                  let requestOptions = {
                    method: "GET",
                    redirect: "follow",
                  };

                  let token =
                    item.url.split("/")[item.url.split("/").length - 1];

                  fetch(`${xlsxHost}/data/${token}`, requestOptions)
                    .then((response) => {
                      if (response.status !== 200 && response.status !== 201) {
                        return null;
                      }
                      return response.json();
                    })
                    .then((datasource) => {
                      if (datasource) {
                        dispatch(
                          actionSetOneDatasource({
                            ...item,
                            source: datasource,
                          })
                        );
                      }

                      setLoading(false);
                    })
                    .catch(() => {});
                } else {
                  continue;
                }
              }
            }

            setLoading(false);
          }
        )
      );
    }
  }, [selectedWorkspace]);
  useEffect(() => {
    if (datasources) {
      // Rejoindre les rooms pour chaque datasource
      datasources.forEach((datasource) => {
        joinRoom(datasource.iddatasource.toString());
      });

      // Nettoyage à la destruction du composant
      return () => {
        datasources.forEach((datasource) => {
          leaveRoom(datasource.iddatasource.toString());
        });
      };
    }
  }, [datasources]);

  const refreshSource = (item) => {
    if (item.url) {
      setRefreshingData([...refreshingDataRef.current, item]);
      dispatch(
        actionRefreshAuto(item.iddatasource, token, (status) => {
          setRefreshingData(
            _.remove([...refreshingDataRef.current], (n) => {
              return !(
                parseInt(item.iddatasource) === parseInt(n.iddatasource)
              );
            })
          );

          if (status === 200) {
            enqueueSnackbar("Rafraichit avec succès", {
              variant: "success",
            });
          } else if (status === 202) {
            enqueueSnackbar("Le rafraichissement a été initié avec succès ", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Une Erreur c'est produite", {
              variant: "error",
            });
          }
        })
      );
    } else {
      setcurrentDatasource(item);
      setDataAction("2");
      setIsRefresh(true);
    }
  };

  const updateSource = (param, item) => {
    setUpdateVisibility(true);
    setId_source(param);
    setIsAsync(item.isAsync);
    setExternalUrl(item.externalUrl ? item.externalUrl : "");
    setcurrentDatasource(item);
    setNewSourceName(item.name);
    setRefresh({
      value: Boolean(item.refreshed),
      label: Boolean(item.refreshed) ? "Automatique" : "Manuel",
    });
    setPeriod({
      value: parseInt(item.periode),
      label: options[parseInt(item.periode)],
    });
    if (+item.operation) {
      const option = operationOptions.filter(
        (option) => +option.value === +item.operation
      );
      if (option.length > 0) {
        setOperation(option[0]);
        if (+option[0].value === 3) {
          const uniqueColumns = item.columns.filter((column) => column.unique);
          setExternalIds(
            uniqueColumns.map((column) => {
              return { value: +column.idcolumn, label: column.name };
            })
          );
        }
      } else {
        setOperation(operationOptions[0]);
      }
    } else {
      setOperation(operationOptions[0]);
    }

    setFrequence(parseInt(item.nbre));
    if (item.source) {
      setDownloadLink(item.source.url);
    }

    if (item.startdate) {
      setStartDate(new Date(item.startdate).toISOString().split("T")[0]);
    }
  };

  const deleteSource = (param) => {
    setDeleteVisibility(true);
    setId_source(param);
  };

  const confirmDeleteSource = () => {
    dispatch(
      actionDeleteDatasource(
        id_source,
        selectedWorkspace,
        token,
        ({ status, message }) => {
          if (status) {
            setDeleteVisibility(false);
            enqueueSnackbar(message || "", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(message || "", {
              variant: "warning",
            });
            setDeleteVisibility(false);
          }
        }
      )
    );
  };

  const selectedConnector = (value) => {
    setIsSourcefiltered(true);
    setSourcefilter(value);
  };

  const checkDownloadLink = () => {
    if (downloadLink.trim()) {
      setCheckDownloadLink(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const url = downloadLink.includes("https://docs.google.com")
        ? `https://drive.google.com/uc?export=download&id=${
            downloadLink.split("/")[5]
          }`
        : downloadLink;
      const raw = JSON.stringify({ url });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${xlsxHost}/data/getData`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            enqueueSnackbar(
              "Nous n'avons trouvé de fichier .xlsx, vérifié le lien",
              {
                variant: "error",
              }
            );
          }
        })
        .then((result) => {
          if (result) {
            updateDownloadLink(url);
          }
        })
        .catch((error) => console.log("error", error))
        .then(() => {
          setCheckDownloadLink(false);
        });
    }
  };

  const updateDownloadLink = (url) => {
    setCheckDownloadLink(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let token =
      currentDatasource.url.split("/")[
        currentDatasource.url.split("/").length - 1
      ];

    let raw = JSON.stringify({ url });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${xlsxHost}/data/update-url/${token}`, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          enqueueSnackbar("Le lien a bien été modifier", {
            variant: "success",
          });
          dispatch(
            actionSetOneDatasource({
              ...currentDatasource,
              source: { ...currentDatasource.source, url },
            })
          );
        } else {
          enqueueSnackbar(
            "Une erreur c'est produite le lien n'a pas été modifié",
            {
              variant: "error",
            }
          );
        }
      })
      .catch(() => {})
      .then(() => setCheckDownloadLink(false));
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: " #fff", // bg-red-600
      border: "none",
      boxShadow: "none",
      outline: "none",
      padding: "0",
      fontWeight: "600",
      fontFamily: "Poppins",
      fontSize: "18px", // tailwind equivalent of small font
      minHeight: "auto",
      minWidth: "150px",
      cursor: "pointer",
      padding: 0,
      margin: 0,
      marginTop: 10,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "5px",
      fontSize: "12px",
      fontWeight: "600",
      fontFamily: "Poppins", // tailwind equivalent of small font
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      // color: "#ffffff", fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "18px", // tailwind equivalent of small font
    }),
    singleValue: (provided) => ({
      ...provided,
      // color: "#ffffff", // text color inside the select fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "18px", // tailwind equivalent of small font
    }),
  };
  const customStylesVue = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff", // bg-red-600
      border: "none",
      boxShadow: "none",
      outline: "none",
      padding: "0",
      fontWeight: "500",
      fontFamily: "Poppins",
      fontSize: "16 px", // tailwind equivalent of small font
      minHeight: "20px",
      minWidth: "130px",
      cursor: "pointer",
      padding: 0,
      margin: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "5px",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Poppins", // tailwind equivalent of small font
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      // color: "#ffffff", fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "15px", // tailwind equivalent of small font
    }),
    singleValue: (provided) => ({
      ...provided,
      // color: "#ffffff", // text color inside the select fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "15px", // tailwind equivalent of small font
    }),
  };

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
  };

  return (
    <div className="h-[100vh] bg-white">
      <Snackbar
        open={visibilityProgress}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Paper
          elevation={3}
          style={{
            width: "200px",
            height: "75px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "#323541",
            color: "#fff",
          }}
        >
          <CircularProgress
            variant={pourcent === 0 ? "indeterminate" : "determinate"}
            value={pourcent}
            size={25}
          />
          Extration...
        </Paper>
      </Snackbar>

      <Header />

      <main className="h-[98%]  w-[98%] mx-auto overflow-hidden">
        <div className="pt-[40px] bg-white">
          {selectedDataSource ? (
            <div className="bg-white w-full  h-[60px] gap-3 items-end   border-r-black rounded-[20px] flex  m-3">
              <button
                onClick={() => setSelectedDataSource(null)}
                className="inline-flex items-center h-[30px] px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                <ChevronLeft className="w-4 h-4 " /> Retour aux datasources
              </button>
              <button
                onClick={() => setChoice(0)}
                className={`inline-flex h-[30px] items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border focus:outline-none rounded-md hover:bg-gray-50                 }`}
                style={{
                  color: choice == 0 ? "#05BAF3" : "",
                }}
              >
                Indicateurs
              </button>
              <button
                onClick={() => setChoice(1)}
                className={`inline-flex h-[30px] items-center px-4 py-2 text-sm focus:outline-none font-medium text-gray-700 bg-white border rounded-md hover:bg-gray-50                 }`}
                style={{
                  color: choice == 1 ? "#05BAF3" : "",
                }}
              >
                Colonnes
              </button>
              <button
                onClick={() => setChoice(2)}
                className={`inline-flex h-[30px] items-center px-4 py-2 text-sm focus:outline-none font-medium text-gray-700 bg-white border rounded-md hover:bg-gray-50 ${
                  choice == 2 ? "text-colorText " : " "
                }`}
                style={{
                  color: choice == 2 ? "#05BAF3" : "",
                }}
              >
                Conditions
              </button>
            </div>
          ) : (
            <div className="bg-white w-full  h-[60px] row actions-nav m-3 mx-auto border-r-black rounded-[20px] flex-col items-center gap-2">
              <div className="flex items-center justify-between w-full gap-2 font-poppins ">
                <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center ml-1">
                  <p className="font-semibold text-center text-[18px] mt-[20px]">
                    Datasets
                  </p>
                  <div className="relative flex items-center ">
                    <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                    <input
                      type="text"
                      placeholder="Rechercher"
                      className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                    />
                  </div>
                  <p className="text-[15px] hidden lg:block font-poppins font-thin m-0 p-0">
                    Filtrer par connecteur :
                  </p>
                  <Select
                    id="refresh"
                    value={
                      sourcefilter
                        ? sourcefilter
                        : { label: nofilter, value: nofilter }
                    }
                    styles={customStylesVue}
                    options={[
                      { label: nofilter, value: nofilter },
                      ...connectors.map((elt) => ({
                        label: truncateText(elt.name, true),
                        value: elt.name,
                      })),
                    ]}
                    onChange={(val) => {
                      selectedConnector(val);
                    }}
                  />
                </div>

                <div className="flex items-center gap-2">
                  {isAdmin && (
                    <p
                      className="hover:text-white hover:bg-colorText border-2 border-colorText text-colorText  rounded-[5px] p-0 m-0 text-center font-poppins py-1 w-[180px] font-semibold cursor-pointer text-[12px]"
                      onClick={() => {
                        setDataAction("1");
                        setIframeVisibility(true);
                      }}
                      type="submit"
                      disabled={isAdd}
                    >
                      +Ajouter une source
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="w-full p-3 bg-customBg h-[83vh] rounded-xl overflow-y-auto">
            {!selectedDataSource && (
              <table className="relative w-full overflow-auto bg-white border-collapse shadow-sm">
                <thead className="mb-[600px] font-poppins leading-[50px] sticky ">
                  <tr className="h-10 bg-white rounded-xl">
                    <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                      Libellé
                    </th>
                    <th className="p-1 font-semibold text-left text-black border-b text-[12px]">
                      Connecteur
                    </th>
                    <th className="p-1 font-semibold  text-black border-b text-[12px] text-center">
                      Type de rafraîchissement
                    </th>
                    <th className="p-1 font-semibold text-center text-black border-b text-[12px]">
                      Fréquence de rafraîchissement
                    </th>
                    <th className="p-1 font-semibold text-center text-black border-b text-[12px]">
                      Dernier rafraîchissement
                    </th>
                    {isAdmin && (
                      <>
                        <th className="p-1 font-semibold text-center text-black border-b text-[12px]">
                          actions
                        </th>
                        <th className="p-1 font-semibold text-center text-black border-b text-[12px]">
                          ...
                        </th>
                      </>
                    )}
                  </tr>
                </thead>

                <tbody className="text-[12px] mt-[900px] border-t-8 border-customBg h-full overflow-auto">
                  {loading
                    ? [...Array(5)].map((_, index) => (
                        <tr
                          key={index}
                          className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold"
                        >
                          <td colSpan={6}>
                            <TableRowLoader />
                          </td>
                        </tr>
                      ))
                    : datasources
                        .filter((elt) => {
                          if (sourcefilter?.value === "") {
                            return datasources;
                          } else if (sourcefilter?.value === nofilter) {
                            return datasources;
                          } else {
                            return elt.connector.name === sourcefilter?.value;
                          }
                        })
                        .map((elt) => {
                          return (
                            <tr
                              id={elt?.iddatasource}
                              key={elt?.iddatasource}
                              className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold my-3"
                            >
                              <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                                {truncateText(elt.name)}
                              </td>
                              <td className="p-1 text-[12px] text-black font-[500px]">
                                {elt.connector.name}
                              </td>
                              <td className=" text-center text-black font-[500px]">
                                <span className=" text-[12px] block p-1 rounded-lg bg-customBg">
                                  {elt.refreshed && elt.url
                                    ? "Automatique"
                                    : "Manuel"}
                                </span>
                              </td>
                              <td className="p-1 text-[12px] text-black font-[500px] text-center">
                                {elt.refreshed && elt.url
                                  ? `Chaque ${
                                      parseInt(elt.nbre) > 1 ? elt.nbre : ""
                                    } ${options[parseInt(elt.periode)]}${
                                      parseInt(elt.nbre) > 1 ? "s" : ""
                                    }`
                                  : "-"}
                              </td>
                              <td className="p-1 text-[12px] text-black font-[500px] text-center">
                                <span className=" text-[12px] block p-1 rounded-lg bg-customBg">
                                  {elt.lastdate
                                    ? `${elt.lastdate.split("T")[0]} ${
                                        elt.lastdate.split("T")[1].split(".")[0]
                                      }`
                                    : "-"}
                                </span>
                              </td>
                              {isAdmin && (
                                <>
                                  <td className="p-1 text-[12px]">
                                    <div className="flex items-center justify-around gap-2">
                                      <button
                                        className="text-gray-600 hover:text-gray-900 disabled:opacity-50 focus:outline-none"
                                        onClick={
                                          elt.status === 102
                                            ? null
                                            : () => refreshSource(elt)
                                        }
                                        disabled={elt.status === 102}
                                      >
                                        <RefreshCwIcon
                                          className={`h-5 w-5 ${
                                            _.findIndex(refreshingData, (o) => {
                                              return (
                                                parseInt(o.iddatasource) ===
                                                parseInt(elt.iddatasource)
                                              );
                                            }) !== -1 || elt.status === 102
                                              ? "animate-spin"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                      <button
                                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                        onClick={
                                          elt.status === 102
                                            ? null
                                            : () =>
                                                updateSource(
                                                  elt.iddatasource,
                                                  elt
                                                )
                                        }
                                        disabled={elt.status === 102}
                                      >
                                        <PencilIcon className="w-5 h-5" />
                                      </button>
                                      <button
                                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                        disabled={elt.status === 102}
                                        onClick={
                                          elt.status === 102
                                            ? null
                                            : () =>
                                                deleteSource(elt.iddatasource)
                                        }
                                      >
                                        <TrashIcon className="w-5 h-5" />
                                      </button>
                                    </div>
                                  </td>

                                  <td className="p-1 text-[12px] text-black font-[500px] ">
                                    <div className="flex items-center justify-around gap-2">
                                      <button
                                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                        onClick={() =>
                                          setSelectedDataSource(elt)
                                        }
                                      >
                                        <CircleArrowRight className="w-5 h-5 " />
                                      </button>
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                </tbody>
              </table>
            )}
            {selectedDataSource && (
              <div className="pb-3">
                {choice === 0 && <Indicator source={selectedDataSource} />}
                {choice === 1 && <Columns source={selectedDataSource} />}
                {choice === 2 && <Condition datasource={selectedDataSource} />}
              </div>
            )}
          </div>
        </div>

        <Modal
          visible={deleteVisibility}
          title={"Confirmer la suppression"}
          onCancel={isDelete ? null : () => setDeleteVisibility(false)}
        >
          <div className="row">
            <div className="col-md-12 wrap-btn-modal">
              <div className="btn-container">
                <button
                  className="btn-border close-btn-submit"
                  type="reset"
                  disabled={isDelete}
                  onClick={isDelete ? null : () => setDeleteVisibility(false)}
                >
                  Annuler
                </button>
                <button
                  className="btn-full"
                  type="submit"
                  disabled={isDelete}
                  onClick={confirmDeleteSource}
                >
                  {isDelete ? "Loading..." : "Confirmer"}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          visible={addVisibility && data !== null}
          title={"Ajouter une source de donnée"}
          onCancel={isAdd ? null : () => setAddVisibility(false)}
          width={600}
        >
          <form onSubmit={isAdd ? null : onAdd}>
            <div className="row">
              <div className="col-md-12">
                <div className="input-container">
                  <label form="nom" className="label-default">
                    Entrer le nom de la source de donnée *
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="nom"
                      id="nom"
                      type="text"
                      value={sourceName}
                      onChange={(e) => setSourceName(e.target.value)}
                      placeholder="Obligatoire"
                      required
                    />
                  </span>
                  {(data?.url || data?.callback) && (
                    <>
                      <br />
                      <label className="label-default">
                        Type de rafraichissement *
                      </label>
                      <CustomSelect
                        id="refresh"
                        value={refresh}
                        style={{ textSize: "5px" }}
                        options={[
                          { value: false, label: "Manuel" },
                          { value: true, label: "Automatique" },
                        ]}
                        onChange={setRefresh}
                      />
                      <br />
                      <label className="label-default">
                        Frequence et Periode de rafraichissement *
                      </label>
                      <div className="row">
                        <div className="col-1 col-md-1">
                          <input
                            className="input-default inputCustom"
                            disabled={!refresh.value}
                            type="number"
                            value={frequence}
                            min={1}
                            style={{ textAlign: "center" }}
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) > 0 ||
                                e.target.value === ""
                              ) {
                                setFrequence(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-9">
                          <CustomSelect
                            status={!refresh.value}
                            id="period"
                            value={period}
                            style={{ textSize: "5px", height: "20px" }}
                            options={[
                              { value: 0, label: "Heure" },
                              { value: 1, label: "Jour" },
                              { value: 2, label: "Semaine" },
                              { value: 3, label: "Mois" },
                              { value: 4, label: "Année" },
                            ]}
                            onChange={setPeriod}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <br />
                  <label className="label-default">
                    Opération à réalisé lors du rafraichissement
                  </label>
                  <Alert severity="info">
                    {operation.value === 1 ? (
                      <>
                        Lors du <strong>rafraichissement</strong> toutes les
                        données seront remplacées par les nouvelles données.
                      </>
                    ) : operation.value === 2 ? (
                      <>
                        Lors du <strong>rafraichissement</strong> les nouvelles
                        données seront ajoutées aux anciennes données.
                      </>
                    ) : (
                      <>
                        Lors du <strong>rafraichissement</strong> les nouvelles
                        données seront ajoutées aux anciennes données avec
                        <strong>possibilité de modifier un élement</strong>
                        nécessite <strong>un identifiant unique</strong>.
                      </>
                    )}
                  </Alert>
                  <br />
                  <CustomSelect
                    value={operation}
                    style={{ textSize: "5px" }}
                    options={operationOptions}
                    onChange={setOperation}
                  />
                  {operation.value === 3 && (
                    <>
                      <br />
                      <label className="label-default">
                        Indentifiant unique
                      </label>
                      <Alert severity="warning">
                        Après insertion
                        <strong>
                          cette valeur ne poura plus être modifiée
                        </strong>
                        .
                      </Alert>
                      <br />
                      <CustomSelect
                        isMulti
                        value={externalIds}
                        style={{ textSize: "5px" }}
                        options={
                          data?.columns
                            ? data.columns.map((item) => {
                                return { value: item.index, label: item.name };
                              })
                            : []
                        }
                        onChange={setExternalIds}
                      />
                    </>
                  )}

                  {data?.callback && (
                    <>
                      <br />
                      <span
                        className="span-block h-custom-options"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "left",
                        }}
                      >
                        <Checkbox
                          checked={isAsync}
                          onClick={() => {
                            setIsAsync(!isAsync);
                          }}
                        >
                          <span>
                            Insertion asynchrone (Pour des données volumineuses)
                          </span>
                        </Checkbox>
                      </span>
                    </>
                  )}

                  <br />
                </div>
              </div>
              <div className="col-md-12 wrap-btn-modal">
                <div className="btn-container">
                  <button
                    className="btn-border close-btn-submit"
                    type="reset"
                    onClick={isAdd ? null : () => setAddVisibility(false)}
                  >
                    Retour
                  </button>
                  <button className="btn-full" type="submit">
                    {isAdd ? "En cours..." : "Continuer"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>

        <Modal
          visible={updateVisibility}
          title={"Modifier la source de donnée"}
          onCancel={isUpdate ? null : () => setUpdateVisibility(false)}
          width={600}
        >
          <form onSubmit={onUpdate}>
            <div className="row">
              <div className="col-md-12">
                <div className="input-container">
                  <label className="label-default">
                    Entrer le nouveau nom de la source de donnée *
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="nom"
                      id="nom"
                      type="text"
                      value={new_sourceName}
                      onChange={(e) => setNewSourceName(e.target.value)}
                      placeholder="Obligatoire"
                      required
                    />
                  </span>

                  <br />
                  <label for="date" className="label-default">
                    Date de début de récupération des données (Optionnelle)
                  </label>
                  <span className="span-block">
                    <input
                      className="input-default inputCustom"
                      name="date"
                      id="date"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </span>
                  <br />
                  {externalUrl && (
                    <>
                      <label for="date" className="label-default">
                        Lien de rafraichissement
                      </label>
                      <div className="flex items-center justify-center span-block ">
                        <input
                          className="input-default inputCustom"
                          style={{ width: 450, marginRight: 10 }}
                          name="rafraissement"
                          id="rafraissement"
                          type="text"
                          disabled
                          value={externalUrl}
                        />
                        <i>
                          <LinkOutlined
                            style={{ color: "#05BAF3", textAlign: "center" }}
                            onClick={() => {
                              if (true) {
                                handleCopy(externalUrl);
                                enqueueSnackbar("Copied!", {
                                  variant: "success",
                                  autoHideDuration: 2000,
                                  anchorOrigin: {
                                    horizontal: "center",
                                    vertical: "top",
                                  },
                                });
                              }
                            }}
                          />
                        </i>
                      </div>
                    </>
                  )}
                  {(currentDatasource?.url || currentDatasource?.asyncUrl) && (
                    <>
                      <br />
                      <label className="label-default">
                        Type de rafraichissement
                      </label>
                      <CustomSelect
                        id="refresh"
                        value={refresh}
                        style={{ textSize: "5px" }}
                        options={[
                          { value: false, label: "Manuel" },
                          { value: true, label: "Automatique" },
                        ]}
                        onChange={setRefresh}
                      />
                      <br />
                      <label className="label-default">
                        Frequence et Periode de rafraichissement
                      </label>
                      <div className="row">
                        <div className="col-3 col-md-3">
                          <input
                            className="input-default inputCustom"
                            disabled={!refresh.value}
                            type="number"
                            value={frequence}
                            min={1}
                            style={{ textAlign: "center", height: 40 }}
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) > 0 ||
                                e.target.value === ""
                              ) {
                                setFrequence(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-9">
                          <CustomSelect
                            status={!refresh.value}
                            id="period"
                            value={period}
                            style={{ textSize: "5px", height: "20px" }}
                            options={[
                              { value: 0, label: "Heure" },
                              { value: 1, label: "Jour" },
                              { value: 2, label: "Semaine" },
                              { value: 3, label: "Mois" },
                              { value: 4, label: "Année" },
                            ]}
                            onChange={setPeriod}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <br />
                  <label className="label-default">
                    Opération à réalisé lors du rafraichissement
                  </label>
                  <Alert severity="info">
                    {operation.value === 1 ? (
                      <>
                        Lors du <strong>rafraichissement</strong> toutes les
                        données seront remplacées par les nouvelles données.
                      </>
                    ) : operation.value === 2 ? (
                      <>
                        Lors du <strong>rafraichissement</strong> les nouvelles
                        données seront ajoutées aux anciennes données.
                      </>
                    ) : (
                      <>
                        Lors du <strong>rafraichissement</strong> les nouvelles
                        données seront ajoutées aux anciennes données avec
                        <strong>possibilité de modifier un élement</strong>
                        nécessite <strong>un identifiant unique</strong>.
                      </>
                    )}
                  </Alert>
                  <br />
                  <CustomSelect
                    value={operation}
                    style={{ textSize: "5px" }}
                    options={
                      externalIds?.length > 0
                        ? operationOptions
                        : operationOptions.map((item) => {
                            if (+item.value === 3) {
                              return { ...item, isDisabled: true };
                            }
                            return item;
                          })
                    }
                    onChange={setOperation}
                  />
                  {operation.value === 3 && (
                    <>
                      <br />
                      <label className="label-default">
                        Indentifiant unique
                      </label>
                      <Alert severity="warning">
                        Cette valeur ne peut pas <strong>être modifiée</strong>.
                      </Alert>
                      <br />
                      <CustomSelect
                        status={true}
                        isMulti
                        disabled
                        value={externalIds}
                        style={{ textSize: "5px" }}
                        options={externalIds || []}
                        onChange={setExternalIds}
                      />
                    </>
                  )}
                  {currentDatasource?.asyncUrl && (
                    <>
                      <br />
                      <span
                        className="span-block h-custom-options"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "left",
                        }}
                      >
                        <Checkbox
                          checked={isAsync}
                          onClick={() => {
                            setIsAsync(!isAsync);
                          }}
                        >
                          <span>
                            Insertion asynchrone (Pour des données volumineuses)
                          </span>
                        </Checkbox>
                      </span>
                    </>
                  )}
                  <br />
                  {currentDatasource && currentDatasource.source ? (
                    <>
                      <span
                        className="span-block h-custom-options"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "left",
                        }}
                      >
                        <Checkbox
                          checked={canUpdateDownloadLink}
                          onClick={() => {
                            setCanUpdateDownloadLink(!canUpdateDownloadLink);
                          }}
                        >
                          <span>
                            Modifier le lien de telechargement du fichier
                          </span>
                        </Checkbox>
                      </span>
                      <br />
                      <div className="row" hidden={!canUpdateDownloadLink}>
                        <span className="span-block col-10">
                          <input
                            className="input-default inputCustom"
                            name="downloadLink"
                            id="downloadLink"
                            type="text"
                            value={downloadLink}
                            onChange={(e) => setDownloadLink(e.target.value)}
                          />
                        </span>
                        <button
                          className="btn-full col-2"
                          type="button"
                          onClick={checkDownloadLink}
                          disabled={!downloadLink.trim() && isCheckDownloadLink}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {!isCheckDownloadLink ? (
                            <SyncOutlined style={{ fontSize: 17 }} />
                          ) : (
                            <LoadingOutlined style={{ fontSize: 17 }} />
                          )}
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 wrap-btn-modal">
                <div className="btn-container">
                  <button
                    className="btn-border close-btn-submit"
                    type="reset"
                    disabled={isUpdate}
                    onClick={isUpdate ? null : () => setUpdateVisibility(false)}
                  >
                    Retour
                  </button>
                  <button
                    className="btn-full"
                    type="submit"
                    disabled={isUpdate}
                  >
                    {isUpdate ? "Loading..." : "Modifier"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>

        <ModalAddSource
          visibility={iframeVisibility}
          onCancel={() => setIframeVisibility(false)}
          url={url}
          id={idconnector}
          setId={setIdConnector}
          setUrl={setUrl}
        />

        <ModalRefreshSource
          visibility={isRefresh}
          onCancel={() => setIsRefresh(false)}
          url={currentDatasource && currentDatasource.connector.url}
        />
      </main>
    </div>
  );
}
