import React, { useEffect, Fragment } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { actionGetConnectors } from "../../redux/actions/connectorAction";
import ContentLoader from "react-content-loader";

export default function Connectors() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectors = useSelector((state) => state.connector.connectors);
  const isGet = useSelector((state) => state.connector.isGet);

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data) {
      navigate("/");
    } else {
      dispatch(actionGetConnectors("Bearer " + JSON.parse(data).token));
    }
  }, []);

  return (
    <>
      <Header />
      <main className="h-[98%]  w-[98%] mx-auto">
        <div className="">
          <div className="head">
            <h1 className="title-1 font-poppins">
              Les connecteurs disponibles
            </h1>
          </div>

          <div className="row">
            {!isGet ? (
              connectors.map((item) => (
                <div className="col-md-4 col-lg-2">
                  <div className="planche">
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url('${item.image}')`,
                      }}
                    ></div>
                    <div className="infos">
                      <h5 className="title-5">{item.name} </h5>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Fragment>
                {[1, 2, 3, 4, 5].map(() => (
                  <div className="mb-2 col-md-3 col-lg-2">
                    <ContentLoader
                      height={"195px"}
                      width={"100%"}
                      speed={1}
                      backgroundColor={"#80808075"}
                      foregroundColor={"#a9a9a975"}
                      className="planche"
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="9px"
                        ry="9px"
                        width="100%"
                        height="160px"
                      />
                      <rect
                        x="8px"
                        y="170px"
                        rx="9px"
                        ry="9px"
                        width="70%"
                        height="13px"
                      />
                    </ContentLoader>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
}
