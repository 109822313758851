import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "../Views/Modal";
import { useSnackbar } from "notistack";
import { truncateText } from "../utils/truncateWords";
import {
  PencilIcon,
  TrashIcon,
  CircleArrowRight,
  ChevronLeft,
} from "lucide-react";
import { Search } from "lucide-react";
import TableRowLoader from "../Views/TableLoading";
import {
  actionCreateWorkspace,
  actionDeleteWorkspace,
  actionGetWorkspaceByCompany,
  actionUpdateWorkspace,
} from "../../redux/actions/workspaceAction";
import UserWorkSpace from "./userWorkspace";
import Team from "../Equipes";
import Periode from "../Periode";

const _ = require("lodash");

export default function WorkSpace() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idUser = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const roleCompany = useSelector((state) => state.user.role);
  const [searchQuery, setSearchQuery] = useState("");
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const filteredWorkspaces = workspaces.filter((workspace) =>
    workspace?.name?.toLowerCase().includes(searchQuery?.toLowerCase() || "")
  );
  const isDelete = useSelector((state) => state.workspaces.isGetWorkspace);
  const isAdd = useSelector((state) => state.workspaces.isAddWorkspace);
  const isUpdate = useSelector(
    (state) => state.workspaces.workspaces.isDeleteWorkspace
  );
  const isGet = useSelector((state) => state.workspaces.isGetWorkspace);

  const tokenRef = useRef();
  tokenRef.current = token;
  const [role, setRole] = useState("ADD");
  const [name, setName] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const [choice, setChoice] = useState(0);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const cancel = () => {
    setRole("ADD");
    setName("");
    setWorkspace(null);
    setVisibility(false);
  };

  const add = () => {
    if (name.trim() !== "") {
      dispatch(
        actionCreateWorkspace(name.trim(), token, ({ status, message }) => {
          if (status) {
            setVisibility(false);
            enqueueSnackbar(message || "", {
              variant: "success",
            });
            setName("");
          } else {
            enqueueSnackbar(message || "", {
              variant: "warning",
            });
          }
        })
      );
    }
  };

  const edit = () => {
    if (name.trim() !== "" && workspace) {
      dispatch(
        actionUpdateWorkspace(
          workspace.id,
          name.trim(),
          token,
          ({ status, message }) => {
            if (status) {
              setVisibility(false);
              enqueueSnackbar(message || "", {
                variant: "success",
              });
              cancel();
            } else {
              enqueueSnackbar(message || "", {
                variant: "warning",
              });
            }
          }
        )
      );
    }
  };

  const submit = () => {
    if (name) {
      if (role === "ADD") {
        add();
      } else if (role === "UPDATE" && workspace) {
        edit();
      }
    } else {
      enqueueSnackbar("Tous les champs sont obligatoires", {
        variant: "warning",
      });
    }
  };

  const remove = () => {
    if (workspace) {
      dispatch(
        actionDeleteWorkspace(
          workspace.id,

          token,
          ({ status, message }) => {
            if (status) {
              enqueueSnackbar(message || "", {
                variant: "success",
              });
              cancel();
            } else {
              enqueueSnackbar(message || "", {
                variant: "error",
              });
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("data");
    if (!data) {
      navigate("/");
    } else {
      dispatch(
        actionGetWorkspaceByCompany(
          "Bearer " + JSON.parse(data).token,
          (result) => {
            if (result) {
              // console.log(result);
            }
          }
        )
      );
    }
  }, []);

  const options = [
    {
      value: "MEMBER",
      label: "Membre",
      key: 0,
    },
    {
      value: "VISITOR",
      label: "Visiteur",
      key: 1,
    },
    {
      value: "ADMIN",
      label: "Administrateur",
      key: 2,
    },
    {
      value: "OWNER",
      label: "Propriétaire",
      key: 3,
    },
  ];

  return (
    <div className="h-full bg-white">
      <Modal
        visible={deleteVisibility && workspace}
        title={`Supprimer l'espace de travail "${workspace?.name}"`}
        onCancel={isDelete ? null : cancel}
      >
        <div className="row">
          <div className="col-md-12 wrap-btn-modal">
            <div className="btn-container">
              <button
                className="btn-border close-btn-submit"
                type="reset"
                onClick={cancel}
              >
                Annuler
              </button>
              <button
                className="btn-full"
                type="submit"
                disabled={isDelete}
                onClick={isDelete ? null : remove}
              >
                {isDelete ? "Supprimer..." : "Supprimer"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibility}
        title={
          role === "ADD"
            ? "Ajouter espace de travail"
            : "Modifier espace de travail"
        }
        onCancel={cancel}
        width={600}
      >
        <div className="flex flex-col w-full h-full gap-1 p-0 m-0">
          <div className="flex flex-col gap-2">
            <label for="nom" className="font-poppins font-[400px]">
              Entrer le nom *
            </label>

            <input
              className="w-full p-2 border-2 rounded-md outline-none border-inputBorderColor hover:outline-none active:outline-none"
              name="nom"
              id="nom"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Obligatoire"
              required
            />
          </div>

          <div className="flex items-center justify-center gap-4 mt-2">
            <button
              className="btn-border close-btn-submit"
              type="reset"
              onClick={isAdd || isUpdate ? null : () => cancel()}
            >
              Annuler
            </button>
            <button
              className="btn-full"
              type="submit"
              // disabled={!isAdd || !isUpdate}
              onClick={submit}
            >
              {isUpdate || isAdd ? "Enregistrer..." : "+ Enregistrer"}
            </button>
          </div>
        </div>
      </Modal>

      <main className="h-[98%]  w-[98%] mx-auto overflow-hidden">
        <div className="bg-white ">
          {selectedWorkspace ? (
            <div className="bg-white w-full  h-[60px] gap-3 items-end   border-r-black rounded-[20px] flex  m-3">
              <button
                onClick={() => setSelectedWorkspace(null)}
                className="inline-flex items-center h-[30px] px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                <ChevronLeft className="w-4 h-4 " /> Retour aux espaces de
                travail
              </button>
              <button
                onClick={() => setChoice(0)}
                className={`inline-flex h-[30px] items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border focus:outline-none rounded-md hover:bg-gray-50                 }`}
                style={{
                  color: choice == 0 ? "#05BAF3" : "",
                }}
              >
                Utilisateurs
              </button>
              <button
                onClick={() => setChoice(1)}
                className={`inline-flex h-[30px] items-center px-4 py-2 text-sm focus:outline-none font-medium text-gray-700 bg-white border rounded-md hover:bg-gray-50                 }`}
                style={{
                  color: choice == 1 ? "#05BAF3" : "",
                }}
              >
                Periodes
              </button>
              <button
                onClick={() => setChoice(2)}
                className={`inline-flex h-[30px] items-center px-4 py-2 text-sm focus:outline-none font-medium text-gray-700 bg-white border rounded-md hover:bg-gray-50                 }`}
                style={{
                  color: choice == 2 ? "#05BAF3" : "",
                }}
              >
                Equipes
              </button>
            </div>
          ) : (
            <div className="bg-white w-full  h-[60px] row actions-nav m-3 mx-auto border-r-black rounded-[20px] flex-col items-center gap-2">
              <div className="flex items-center justify-between w-full gap-2 font-poppins ">
                <div className="flex items-center gap-2 text-[12px] m-0 p-0 justify-center ml-1">
                  <p className="font-semibold text-center text-[18px] mt-[20px]">
                    WorkSpaces
                  </p>
                  <div className="relative flex items-center ">
                    <Search className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Rechercher"
                      className="w-full py-2 pl-10 pr-4 text-gray-700 border-2 border-gray-250 bg-white rounded-[10px] outline-none  focus:bg-white"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  {["ADMIN", "OWNER"].includes(roleCompany) && (
                    <p
                      className="hover:text-white hover:bg-colorText border-2 border-colorText text-colorText  rounded-[5px] p-0 m-0 text-center font-poppins py-1 w-[180px] font-semibold cursor-pointer text-[12px]"
                      type="submit"
                      onClick={() => {
                        setVisibility(true);
                        setWorkspace(null);
                      }}
                    >
                      +Ajouter un workspace
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="w-full p-3 bg-customBg h-[83vh] rounded-xl overflow-auto">
            {!selectedWorkspace && (
              <table
                id="sources"
                className="relative w-full overflow-auto bg-white border-collapse shadow-sm"
              >
                <thead className="mb-[600px] font-poppins leading-[50px] sticky ">
                  <tr className="h-10 bg-white rounded-xl">
                    <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                      <span>Nom</span>
                    </th>
                    <th className="p-1 pl-2 font-semibold text-left text-black border-b text-[12px] rounded-xl">
                      <span>Role</span>
                    </th>
                    <th className="p-1 pl-2 font-semibold text-center text-black border-b text-[12px] rounded-xl">
                      <span>Utilisateur</span>
                    </th>
                    {["ADMIN", "OWNER"].includes(roleCompany) && (
                      <>
                        <th className="p-1 pl-2 font-semibold text-center text-black border-b text-[12px] rounded-xl">
                          <span>Actions</span>
                        </th>
                        <th className="p-1 pl-2 font-semibold text-center text-black border-b text-[12px] rounded-xl">
                          <span>...</span>
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody className="text-[12px] mt-[900px] border-t-8 border-customBg h-full">
                  {isGet
                    ? [...Array(5)].map((_, index) => (
                        <tr
                          key={index}
                          className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold"
                        >
                          <td colSpan={6}>
                            <TableRowLoader />
                          </td>
                        </tr>
                      ))
                    : filteredWorkspaces.map((workspace) => {
                        const userRole = options.find(
                          (item) => item.value === workspace.role
                        );
                        return (
                          <tr
                            id={workspace.id}
                            key={workspace.id}
                            className="border-b hover:bg-gray-50 font-poppins text-[15px] text-black  h-10  cursor-pointer font-semibold my-3"
                          >
                            <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                              {truncateText(workspace.name)}
                            </td>
                            <td className="p-1 pl-2 text-[12px] text-black font-[500px]">
                              {userRole.label || ""}
                            </td>
                            <td className="p-1 pl-2 text-[12px] text-center text-black font-[500px]">
                              {workspace.userWorkspaces.length || 1}
                            </td>
                            {["ADMIN", "OWNER"].includes(roleCompany) && (
                              <>
                                <td className="p-1 text-[12px]">
                                  <div className="flex items-center justify-around gap-2">
                                    <button
                                      className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                      onClick={() => {
                                        setRole("UPDATE");
                                        setWorkspace(workspace);
                                        setName(workspace.name);
                                        setVisibility(true);
                                      }}
                                    >
                                      <PencilIcon className="w-5 h-5" />
                                    </button>
                                    <button
                                      className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                      onClick={() => {
                                        setDeleteVisibility(true);
                                        setWorkspace(workspace);
                                      }}
                                    >
                                      <TrashIcon className="w-5 h-5" />
                                    </button>
                                  </div>
                                </td>
                                <td className="p-1 text-[12px] text-black font-[500px] ">
                                  <div className="flex items-center justify-around gap-2">
                                    <button
                                      className="text-gray-600 hover:text-gray-900 focus:outline-none"
                                      onClick={() =>
                                        setSelectedWorkspace(workspace)
                                      }
                                    >
                                      <CircleArrowRight className="w-5 h-5 " />
                                    </button>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            )}

            {selectedWorkspace && (
              <div className="pb-3">
                {choice === 0 && (
                  <UserWorkSpace workspace={selectedWorkspace} />
                )}
                {choice === 1 && <Periode workspace={selectedWorkspace} />}
                {choice === 2 && <Team workspace={selectedWorkspace} />}
              </div>
            )}
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}
