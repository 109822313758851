import {
  ACTION_ADD_INDICATORS,
  ACTION_IS_ADD_INDICATORS,
  ACTION_IS_UPDATE_INDICATORS,
  ACTION_SET_ALL_INDICATORS,
  ACTION_REMOVE_INDICATORS,
  ACTION_IS_DELETE_INDICATORS,
  ACTION_UPDATE_INDICATORS,
} from "../actions/IndicatorsAction";

const initialState = {
  indicators: [],
  isAddCondition: false,
  isUpdateCondition: false,
  isDeleteCondition: false,
};

const indicatorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_IS_ADD_INDICATORS: {
      return {
        ...state,
        isAddCondition: action.payload,
      };
    }

    case ACTION_UPDATE_INDICATORS: {
      const indicators = [...state.indicators];

      for (let i = 0; i < indicators.length; i++) {
        const item = indicators[i];
        if (+item.id === +action.payload.id) {
          indicators[i] = action.payload;
        }
      }

      return {
        ...state,
        indicators: [...indicators],
      };
    }
    case ACTION_ADD_INDICATORS: {
      return {
        ...state,
        indicators: [...state.indicators, action.payload],
      };
    }

    case ACTION_REMOVE_INDICATORS: {
      const indicators = [...state.indicators];

      for (let i = 0; i < indicators.length; i++) {
        const item = indicators[i];
        if (+item.id === +action.payload) {
          indicators.splice(i, 1);
        }
      }

      return {
        ...state,
        indicators: [...indicators],
      };
    }

    case ACTION_IS_UPDATE_INDICATORS: {
      return {
        ...state,
        isUpdatePeriod: action.payload,
      };
    }

    case ACTION_IS_DELETE_INDICATORS: {
      return {
        ...state,
        isDeleteCondition: action.payload,
      };
    }

    case ACTION_SET_ALL_INDICATORS: {
      return {
        ...state,
        indicators: action.payload,
      };
    }

    default:
      return state;
  }
};

export default indicatorsReducer;
